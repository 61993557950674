import React, {useContext} from 'react';
import "./resultModal.css";
import {Context} from "../../../index";



const ResultModal = (props) => {
const {settings,user} = useContext(Context)

    return (
        <div className={"resultModal "+props.controllClass}>

            <div className={"result_modal_wrapper"}>
                <div className={"image_status "+ settings.resultModalImage}>

                </div>
                <span className={"heading_result_modal_text"}>
                <noindex>{settings.resultModalTitle}</noindex>
            </span>
                <span className={"assist_result_modal_text"}>{settings.resultModalTextOne}</span>
                <span className={"assist_result_model_content"}>{settings.resultModalTextTwo}</span>
                <span
                    onClick={e => (settings.setResultModalActive(false), user.setShadowOverlay(false))}
                    className={"result_model_button"}>{settings.resultModalButtonText}</span>
            </div>
            
        </div>
    );
};

export default ResultModal;