import React from 'react';
import {Link} from "react-router-dom";
import './BreadCrumbsBlack.css';

const BreadcrumbsItemBlack = (props) => {
    return (
        <div className={"breadcrumbsblack_item_content"}>
            <Link itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem" to={props.item.link}>
                {props.item.name}
                <meta itemProp="position" content={props.position} />
            </Link>
        </div>
    );
};

export default BreadcrumbsItemBlack;
