import React, {useContext, useLayoutEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";

const Logotype = observer((props) => {

    const {settings} = useContext(Context)
    const location = useLocation();
    const [isHomePage, setIsHomePage] = useState(false)

    useLayoutEffect(() => {
        if (location.pathname === '/') {
            setIsHomePage(true)
        } else {
            setIsHomePage(false)
        }
    }, [location])

    return (
        <div>
            {
                settings.projectWidth && settings.projectWidth > 768 ?
                    isHomePage ? 
                        <span className={props.stateSearcht === false ? "logotype_wrap" : "logotype_wrap nonactive"}>
                            <img src={"/files/images/ui/logo_header.png"} alt="YouWanna - интернет-магазин одежды и аксессуаров"/>
                        </span>
                    :
                        <Link to={"/"} className={props.stateSearcht === false ? "logotype_wrap" : "logotype_wrap nonactive" } href="/">
                            <img src={"/files/images/ui/logo_header.png"} alt="YouWanna - интернет-магазин одежды и аксессуаров"/>
                        </Link>
                :
                    isHomePage ? 
                        <span className={props.stateSearcht === false ? "logotype_wrap" : "logotype_wrap nonactive"}>
                            {
                                props.stateSearcht === false  ?
                                    <img src={"/files/images/ui/logo_header.png"} alt="YouWanna - интернет-магазин одежды и аксессуаров"/>
                                : ''
                            }
                        </span>
                    :
                        <Link to={"/"} className={props.stateSearcht === false ? "logotype_wrap" : "logotype_wrap nonactive" } href="/">
                            {
                                props.stateSearcht === false  ?
                                    <img src={"/files/images/ui/logo_header.png"} alt="YouWanna - интернет-магазин одежды и аксессуаров"/>
                                : ''
                            }
                        </Link>
            }
        </div>
    );
});

export default Logotype;