import React, {useState} from 'react';
import './checkBoxPolitic.css'

const CheckBoxPolitic = (props) => {




    return (
        <div className={"link_politic"}>
            <div
                onClick={e=> props.setStateCheсkBox(!props.stateCheсkBox)}
                className={props.stateCheсkBox === true ? "checkbox_selector active" : "checkbox_selector"}>
                <div className="child_checkbox_selector">
                </div>
            </div>
            <span> Согласен с условиями  <a rel="noreferrer"  target={"_blank"} href="/personal/oferta/">договора-оферты</a> и <a rel="noreferrer"  target={"_blank"} href="/personal/privacy-policy/">политикой конфиденциальности</a> </span>
        </div>
    );
};

export default CheckBoxPolitic;