import React, {useEffect, useMemo, useState} from 'react';
import "./promoPage.css"
import {addNewPromo, changeActivePromo, fetchAllPromo} from "../../../../http/settingsApi";
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import {fetchClearCategory, fetchColors, getAllProducts} from "../../../../http/catalogApi";
import {searchProductsBySearchValue} from "../../../../http/orderApi";
import {login} from "../../../../http/userAPI";
import {searchPromocodeAndActive} from "../../../../http/basketApi";
import OnePromo from "./OnePromo";
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/ru';
import "react-datetime/css/react-datetime.css";
const Promocode = () => {


    const [refreshPage, setRefreshPage] = useState(false);
    const [allPromo,setAllPromo] = useState(null);
    const [allCategories,setAllCategories] = useState(null)
    const [searchValue, setSearchValue] = useState("");
    const [allProducts,setAllProducts] = useState(null)
    const [allColors,setAllColors] = useState(null);
    const [childProductsToVision, setChildProductsToVision] = useState(null)
    const [sortedAndSearchedProduct, setSortedAndSearchedProduct] = useState([]);

    const [isActiveChildProduct,setIsActiveChildProduct] = useState(null);





    const childSetVisionHandler = (items) => {
       setChildProductsToVision(items)
    }


    const changeSearchValueHandler = (value) => {
        setSearchValue(value);
    }

    const currColor = (id) => {
        let colorName = null;
        allColors.forEach((item) => {
            if(item.id === id) {
                colorName = item.color_name;
            }
        })
        return colorName;
    }

    useMemo( () => {
        if(searchValue) {
            getAllProducts('name', 'ASC', searchValue, 'name', 1, 10).then(response => {
                setSortedAndSearchedProduct(response.data.rows);
            });
        }
    }, [searchValue])

    useEffect(() => {
        fetchColors().then(response => {
            setAllColors(response);
        })

        fetchAllPromo().then(response => {
            if(response.length){
                if(response.error) {
                    return false;
                }

                setAllPromo(response)
            }
        })
        fetchClearCategory().then(response => {
            // console.log(response);
            setAllCategories(response);
        })


    },[refreshPage])



    const [promocodeSettings,setPromocodeSettings] = useState({
        name:"",
        promocode_value:"",
        one_use:false,
        start_summ:0,
        method_of_action: "",
        startDate: null,
        endDate: null,
        //percent  on fixedamount
        amount_discount: 0,
        auth_use: false,
        dop_options: false,
        category_array:[],
        productst_array:[],
    })


    const handlerSetActiveChild = (item) => {
        // console.log(item)
        let productArray = [...promocodeSettings.productst_array];
        let isExist = false;
        let newArray = [];
        productArray.forEach(itemForeach => {
                if(itemForeach.id === item.id) {
                    isExist = true;
                } else {
                    newArray.push(itemForeach);
                }

        })

        if(!isExist){
            newArray.push(item);
        }
        setPromocodeSettings({...promocodeSettings,productst_array:newArray})

    }

    const checkActive = (item) => {
        let active = false;
        promocodeSettings.productst_array.forEach((itemForeach) => {
            if(itemForeach.id === item.id) {
                active = true;
            }
        })
        return active;
    }




    const [openOption,setOpenOption] = useState("");
    const [promoNotification,setPromoNotification] = useState('');


    const addPromoHandler = (promo) => {
        promo.promocode_value = promo.promocode_value.trim();
        addNewPromo(promo).then(response => {
            // console.log(response)
            if(response.error) {
                setPromoNotification(response.error)
                return false;
            }
            setPromoNotification("Успешно добавлено")
            setPromocodeSettings({
                name:"",
                promocode_value:"",
                one_use:false,
                start_summ:0,
                method_of_action: "",
                //percent  on fixedamount
                amount_discount: 0,
                auth_use: false,
                dop_options: false,
                category_array:[],
                productst_array:[],
            })
            setRefreshPage(!refreshPage);
        })
    }

    const changeActivePromoHandler = (item) => {
        changeActivePromo(item).then(response => {
            if(response.message) {
                setRefreshPage(!refreshPage);
            }
        })
    }
    const addCategoryToPromoHandler = (item) => {
        // console.log(item)
        let categoryArr = [...promocodeSettings.category_array];
        let isExist = false;
        categoryArr.forEach(itemForeach => {
            if(itemForeach.id === item.id) {
                isExist = true;
            }
        })
        if(isExist) {
            return false;
        }
        categoryArr.push(item);
        setPromocodeSettings({...promocodeSettings,category_array:categoryArr})
    }

    const setStartDate = (value) => {
        if (moment.isMoment(value)) {
            let utcDate = value.valueOf();
            setPromocodeSettings({...promocodeSettings, startDate: utcDate})
        }
    }

    const setEndDate = (value) => {
        if (moment.isMoment(value)) {
            let utcDate = value.valueOf();
            setPromocodeSettings({...promocodeSettings, endDate: utcDate})
            
        }
    }

    const deleteSelectedCategory = (item) => {
        let categoryArr = [...promocodeSettings.category_array];
        let newCategoryArr = [];
        categoryArr.forEach((itemForeach) => {
            if(itemForeach.id != item.id) {
                newCategoryArr.push(itemForeach)
            }
        })
        setPromocodeSettings({...promocodeSettings,category_array:newCategoryArr})

    }

    return (
        <div className={"promo_page_wrapper"}>
            <div className="promo_page_title">Промокоды</div>
            <div className="promo_page_content_container add_new">
                <div className={"wrapper_for_promo_settings"}>
                    <div className="promo_page_content_container_heading">
                        Выберите тип нового промокода:
                    </div>
                    <div className={"select_promo_type_container"}>
                    <span
                        onClick={e => setPromocodeSettings({...promocodeSettings, method_of_action:"percent",amount_discount:0})}
                        className={promocodeSettings.method_of_action === "percent" ? "promo_page_select_promo_type active" : "promo_page_select_promo_type"}>Процентная скидка</span>
                        <span
                            onClick={e => setPromocodeSettings({...promocodeSettings, method_of_action:"fixedamount",amount_discount:0})}
                            className={promocodeSettings.method_of_action === "fixedamount" ? "promo_page_select_promo_type active" : "promo_page_select_promo_type"}>Фиксированная скидка</span>
                    </div>
                    {
                        promocodeSettings.method_of_action ?
                            <div className={"promo_settings_detail"}>
                                <div className={"promo_settings_detail_wrapper_input"}>
                                    <span className={"promo_input_heading"}>Укажите название для промокода</span>
                                    <input
                                        placeholder={"Введите название"}
                                        onChange={e => setPromocodeSettings({...promocodeSettings, name:e.target.value})}
                                        value={promocodeSettings.name ? promocodeSettings.name : ""} type="text"/>
                                </div>
                                <div className={"promo_settings_detail_wrapper_input"}>
                                    <span className={"promo_input_heading"}>Промокод, который должен ввести пользователь.</span>
                                    <span className={"promo_input_heading"}>Пример:  "YOU_10" /  "YOU-10" / "YOU10" / "SKIDKA15"</span>

                                    <input
                                        placeholder={"Введите значение без кавычек"}
                                        className={"promo_input"}
                                        onChange={e => setPromocodeSettings({...promocodeSettings, promocode_value:e.target.value})}
                                        value={promocodeSettings.promocode_value ? promocodeSettings.promocode_value : ""} type="text"/>
                                </div>
                                <div className={"promo_settings_detail_wrapper_input"}>
                                <span className={"promo_input_heading"}>
                                    {
                                        promocodeSettings.method_of_action === "percent" ?
                                            "Укажите скидку в процентах, только число"
                                            :
                                            "Укажите скидку в рублях, только число"
                                    }
                                </span>
                                    <input
                                        placeholder={"Введите название"}
                                        onChange={e => setPromocodeSettings({...promocodeSettings, amount_discount:e.target.value})}
                                        value={promocodeSettings.amount_discount ? promocodeSettings.amount_discount : ""} type="text"/>
                                </div>
                                <div className={"promo_settings_detail_wrapper_input"}>
                                <span className={"promo_input_heading"}>
                                     Начиная с какой суммы промокод доступен? Только цифры.
                                </span>
                                    <input
                                        placeholder={"Введите название"}
                                        onChange={e => setPromocodeSettings({...promocodeSettings, start_summ:e.target.value})}
                                        value={promocodeSettings.start_summ ? promocodeSettings.start_summ : ""} type="text"/>
                                </div>
                                <div className={"promo_settings_detail_wrapper_input"}>
                                    <span className={"promo_input_heading"}>Какой пользователь может использовать помокод?</span>

                                    <div className={"select_promo_type_container"}>
                                    <span
                                        onClick={e => setPromocodeSettings({...promocodeSettings, auth_use:true})}
                                        className={promocodeSettings.auth_use === true ? "promo_page_select_promo_type active" : "promo_page_select_promo_type"}>Авторизированный</span>
                                        <span
                                            onClick={e => setPromocodeSettings({...promocodeSettings, auth_use:false, one_use:false})}
                                            className={promocodeSettings.auth_use === false ? "promo_page_select_promo_type active" : "promo_page_select_promo_type"}>Не авторизированный</span>
                                    </div>
                                </div>

                                {
                                    promocodeSettings.auth_use ?
                                        <div className={"promo_settings_detail_wrapper_input"}>
                                            <span className={"promo_input_heading"}>Сколько раз можно использовать промокод?</span>

                                            <div className={"select_promo_type_container"}>
                                    <span
                                        onClick={e => setPromocodeSettings({...promocodeSettings, one_use:true})}
                                        className={promocodeSettings.one_use === true ? "promo_page_select_promo_type active" : "promo_page_select_promo_type"}>Один раз</span>
                                                <span
                                                    onClick={e => setPromocodeSettings({...promocodeSettings, one_use:false})}
                                                    className={promocodeSettings.one_use === false ? "promo_page_select_promo_type active" : "promo_page_select_promo_type"}>Много раз</span>
                                            </div>
                                        </div>
                                        : ""
                                }

                                <div className={"promo_add_section_and_notification"}>
                                <span
                                    onClick={e => addPromoHandler(promocodeSettings) }
                                    className={"promo_add_button"}>
                                    Добавить промокод
                                </span>
                                    <div className={"promo_server_response"}>
                                        {promoNotification ? promoNotification : ""}
                                    </div>
                                </div>
                            </div>
                            : ""
                    }
                </div>


                <div className={"promo_product_conditions"}>
                        <div  onClick={e => {setPromocodeSettings({...promocodeSettings, dop_options: !promocodeSettings.dop_options})}} className={"promo_dop_options_controll"}> { promocodeSettings.dop_options ? "дополнительные опции <on>" : "дополнительные опции <off>" } </div>
                    {
                        promocodeSettings.dop_options ?
                            <>

                            <div className={"promocode_dialog_wrapper"}>
                                <span className={"promocode_dialog_heading"}>Промокод либо на товары, либо на категории. Выберите:</span>
                                <div className={"promocode_variable_settings"}>
                                    <span className={openOption === "category" ? "product_selector_cat_or_product active" : "product_selector_cat_or_product"} onClick={e => setOpenOption("category")}> По категории</span>
                                    <span className={openOption === "products" ? "product_selector_cat_or_product active" : "product_selector_cat_or_product"} onClick={e => setOpenOption("products")}> По товарам </span>
                                </div>
                                <div className={"wrapper_options_container_promo"}>
                                    {
                                        openOption === "category" ?
                                            <div className={"category_select_from_promo_wrapper"}>
                                               <div className={"this_category_list_promo"}>
                                                   <span className={"selected_category_heading_line"}>Нажмите, чтобы выбрать</span>

                                                   {allCategories ? allCategories.map((item,index) => {
                                                        return(
                                                            <div
                                                                onClick={e => addCategoryToPromoHandler(item)}
                                                                key={item.id} className={"one_line_promo_category"}>{item.name}</div>
                                                        )
                                                   })
                                                       : ""

                                                   }
                                               </div>
                                                <div className={"selected_category_on_promo"}>
                                                    <span className={"selected_category_heading_line"}>Применить промокод к:</span>
                                                    {promocodeSettings.category_array[0] ? promocodeSettings.category_array.map((item,index) => {
                                                            return(
                                                                <div onClick={e => deleteSelectedCategory(item)}
                                                                    key={item.id} className={"one_line_promo_category"}>{item.name}</div>
                                                            )
                                                        })
                                                        : ""

                                                    }
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    {
                                        openOption === "products" ?
                                            <div className={"promocode_products_container"}>
                                                <input

                                                    className={"search_catalog_products_for_promo" }
                                                    value={searchValue ? searchValue : ""}
                                                    onChange={e => changeSearchValueHandler(e.target.value)}
                                                    //   placeholder={allProducts === null ? "Загружаю список товаров, подождите...." : "Введите название товара"}
                                                       type="text"/>
                                                 <div className={"product_search_wrapper_promo"}>
                                                     {
                                                         searchValue && sortedAndSearchedProduct && sortedAndSearchedProduct.length ?
                                                             sortedAndSearchedProduct.map((item,index) => {
                                                                 return(
                                                                     <div
                                                                         onClick={e => childSetVisionHandler(item.childProducts)}
                                                                         className={"promo_one_search_item"}
                                                                         key={item.id}>
                                                                         <span>{item.name} - {item.childProducts.length} цвет(а). </span>




                                                                     </div>
                                                                 )
                                                             })
                                                             :""
                                                     }

                                                 </div>
                                                {childProductsToVision && childProductsToVision[0] ?
                                                    <div className={"product_promo_config"}>
                                                        <span onClick={e => setChildProductsToVision(null)} className={"close_abs_modal"}><noindex>Закрыть</noindex></span>
                                                        {childProductsToVision.map((item,index) => {
                                                            return(
                                                                <div key={index}>
                                                                    <span
                                                                        className={checkActive(item) ? "promo_one_prod_color active" : "promo_one_prod_color"}
                                                                        onClick={e => handlerSetActiveChild(item)}
                                                                    >Цвет: {currColor(item.colorId)} </span>

                                                                </div>
                                                                 )
                                                        })}
                                                    </div>
                                                    : ""
                                                }
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className={"promocode_dialog_wrapper"}>
                            </div>
                                <div className={"promo_settings_detail_wrapper_input"}>
                                    <span className={"promo_input_heading"}>Дата/Время начала действия промокода</span>
                                    <Datetime locale='ru' onChange={e => setStartDate(e)}/>
                                </div>
                                <div className={"promo_settings_detail_wrapper_input"}>
                                    <span className={"promo_input_heading"}>Дата/Время окончания действия промокода</span>
                                    <Datetime locale='ru' onChange={e => setEndDate(e)}/>
                                </div>
                            </>
                            


                            : ""
                    }

                </div>

                {promocodeSettings.productst_array[0] ?
                    <div className={"info_list_promo_options"}>
                        <span>Полный список добавленных товаров. Чтобы удалить - нажмите на него.</span>

                            {
                                promocodeSettings.productst_array.map((item) => {
                                    return(<span
                                        className={"one_item_of_list_products_promo"}
                                        onClick={e => handlerSetActiveChild(item)}
                                    >{item.name}, цвет: {currColor(item.colorId)} </span>)
                                })
                            }

                    </div> :""
                }

            </div>

            <div className={"promo_variable_container"}>
            { allPromo ?
                <div>
                    <span className={"promo_page_title"}>Уже имеющиеся промокоды: </span>
                    <div className={"promo_page_content_container"}>
                        {
                            allPromo.map((item,index) => <OnePromo key={index} setRefreshPage={setRefreshPage} refreshPage={refreshPage} currColor={currColor} item={item} changeActivePromoHandler={e => changeActivePromoHandler(item)}/>)
                        }
                    </div>
                </div>
                : ""
            }
            </div>

        </div>
    );
};

export default Promocode;
//gt