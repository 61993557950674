import React, {useContext, useState} from 'react';
import './storeList.css';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";



const StoreList = observer((props) => {

    const {settings} = useContext(Context)

    const changeShopAndMap = () => {
        // if (settings.projectWidth && settings.projectWidth > 1024) {
        //     props.setActivCardsShop(props.item.id)
        //     props.setActiveMapsShop(props.item.map_url)
        //     props.setSwitchCityActive(false)
        // }
        // return false
        props.setActivCardsShop(props.item.id)
        props.setActiveMapsShop(props.item.map_url)
        props.setSwitchCityActive(false)

    }
    const changeShopAndMapsMobile = () => {
        props.goModalMaps()
        props.setActiveMapsShop(props.item.map_url)
    }
    const stateCityMap = () => {
        props.setActiveMapsShop(props.reduceArray.map_url)
        props.goModalMaps()

    }

    return (
        <div className={props.reduceArray.markets.length > 1 ? "one_shop" :  "one_shop_non"}>
                <div
                    onClick={ e => changeShopAndMap()}
                    className={props.activCardsShop === props.item.id ? "one_shop_child active" : "one_shop_child"}>
                    {/*<div className={"s_shop_img"}>*/}
                    {/*    <img className={"s_img"} src={props.item.img_shop ? process.env.REACT_APP_API_URL+"uploads/images/shop/"+ props.item.img_shop : null }*/}
                    {/*         alt=""/>*/}
                    {/*</div>*/}
                    <div className={"s_shop_conteiner"}>
                        <div className={"s_items_text"}>
                            <div className={"s_name_store"}>{props.item.name_market}</div>
                            {settings.projectWidth && settings.projectWidth < 1025 ?
                                <a href={"tel:+" + props.item.back_number}
                                   className={"s_phone_front"}>{props.item.front_number}</a>
                                :
                                ""
                            }
                            <div className={"s_adress"}>
                                <span className={"s_subline"}>Адрес:</span>
                                <p className={"s_text_info"}>{props.item.address}</p>
                            </div>
                            <div className={"s_worktime"}>
                                <span className={"s_subline"}>Режим работы:</span>
                                <p className={"s_text_info"}>{props.item.work_schedule}</p>
                            </div>
                        </div>
                        <div className={"s_phone"}>
                            <a href={"tel:+"+ props.item.back_number} className={"s_phone_button"}>Позвонить</a>
                            {settings.projectWidth && settings.projectWidth > 1024 ?
                                <a href={"tel:+" + props.item.back_number}
                                   className={"s_phone_front"}>{props.item.front_number}</a>
                                :
                                ""
                            }
                        </div>

                        {/*{settings.projectWidth && settings.projectWidth > 1024 ?*/}
                        {/*    ""*/}
                        {/*    :*/}
                        {/*    <div className={"maps_city_and_shop"}>*/}
                        {/*        <div*/}
                        {/*            onClick={e =>changeShopAndMapsMobile()}*/}
                        {/*            className={"container_maps_icons"}>*/}
                        {/*            <img className={"maps_icons"} src={"/files/images/mapmarker.png"} alt=""/>*/}
                        {/*            <div className={"maps_icons_text_one"}>Магазин на карте</div>*/}

                        {/*        </div>*/}
                        {/*        <div onClick={e => stateCityMap()} className={"button_open_maps"}>*/}
                        {/*            <img className={"maps_icons"} src={"/files/images/location-map.png"} alt=""/>*/}
                        {/*            <div className={"maps_icons_text_all"}>Все магазины</div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*}*/}
                    </div>

                </div>

        </div>
    );
});

export default StoreList;