import React from 'react'

const YM = window.ym

export const getClientID = () => {
    let ClientID = null;
    if (YM) {
        YM(45473325,"getClientID",function(a){
            ClientID = a;
        });
        return ClientID;
    }
    return null;
        
}

export default YM
