import React, {useEffect, useState} from 'react';
import './certList.css';
import OneLineCert from './oneLineCert/oneLineCert';
import Pagination from '../../../components/Paginstion';
import { useParams } from 'react-router';
import { getAllCerts, activateCert } from '../../../http/certApi';
import {priceToUserString} from "../../../http/basketApi";
import Spiner from "../spiner/Spiner";

const CertList = () => {

    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')

    if(!page || page <= 0)
        page = 1

    const [searchText, setSearchText] = useState('')
    const [certList, setCertList] = useState(null);
    const [listWidth, setListWidth] = useState(0);
    const [listHeight, setListHeight] = useState(0)
    const [showPreloader, setShowPreloader] = useState(false);
    const limit = 50;
    const [allCount,setAllCount] = useState(0)
    const [certNominal,setCertNominal] = useState(0)
    const [notificationSert,setNotificationSert] = useState("");

    const searchHandler = (e) => {
        e.preventDefault()
        setSearchText(e.target.value)
    }

    const setNominal = (e) => {
        e.preventDefault()
        setCertNominal(e.target.value)
    }

    const activateCertificate = () => {
        setShowPreloader(true)
        activateCert(certNominal).then(response => {
            setNotificationSert(response.message)
            setShowPreloader(false)
        })
    }

    const nominals = [
        3000,
        5000,
        10000,
        15000,
        30000
    ]

    useEffect(() => {

        setListWidth(window.innerWidth - 270);
        setListHeight(window.innerHeight - 350);
        getAllCerts(limit, (page-1)*limit, true, '').then(response => {
            setAllCount(response.count)
            setCertList(response.rows);
        })
        // getAllOrders(limit,(page-1)*limit,true,searchText).then(response =>  {
        //     // console.log(response);
        //     setCertList(response.rows);
        //
        // })
    },[page])

    useEffect(() => {
        const offset = searchText ? 0 : ~~((page - 1)*limit)

        setListWidth(window.innerWidth - 270);
        setListHeight(window.innerHeight - 350);

        getAllCerts(limit,offset,true,searchText).then(response =>  {
            setCertList(response.rows);
            setAllCount(response.count)
         })
    },[searchText])

    return (
        <div className={"orders_page_wrapper"}>
            {
                showPreloader ?
                    <Spiner />
                    : ""
            }

            <div className={"heading_order_list_page"}>
                <span>Все сертификаты</span>
            </div>

            <div className="cert_create_wrapper">
                Активация сертификата
                <select
                    className={'cert_nominal'}
                    value={certNominal}
                    onChange={e => setNominal(e)}
                >
                    <option></option>
                    {
                        nominals.map((item, index) => {
                            return (
                                <option value={item}>{item}</option>
                            )
                        })
                    }
                </select>
                <button
                    onClick={e => activateCertificate() }
                    className={certNominal <=0 ? "application_submission_button unactive" : "application_submission_button"}>
                    Активировать сертификат
                </button>
                <div className={"notification_sertif_pay_text"}>{notificationSert}</div>
            </div>

            <div className="orders_panel_wrapper">
                <div  className={"cert_container"}>

                    <div className={"container_for_search_field"}>
                        <input className={"search_input_products_page"} type="text"
                            value={searchText}
                            onChange={e => searchHandler(e)}
                            placeholder={"Код сертификата..."}
                            style={{width:"15%", marginBottom:"10px", marginLeft:"10px"}}
                        />  
                    </div>

                    <div className={"all_cert_fields_container"}>
                        <div className={"all_cert_fields_item"}> system id </div>
                        <div className={"all_cert_fields_item"}> Код сертификата </div>
                        <div className={"all_cert_fields_item"}> Номинал </div>
                        <div className={"all_cert_fields_item"}> Статус </div>
                        <div className={"all_cert_fields_item"}>Оплаченный заказ</div>
                        <div className={"all_cert_fields_item"}>Зарезервирован</div>
                        <div className={"all_cert_fields_item"}> Дата изменения </div>
                    </div>
                    <div style={{height: listHeight}} className={"wrapper_for_one_order"}>
                        {
                            certList ?
                                certList.map((item,index) => {
                                    return(
                                        <OneLineCert className={"order_one_line"} key={item.id} item={item}/>
                                    )
                                })
                            : ""
                        }
                    </div>
                </div>
            </div>
            <div className={"pagination_adm_wrapper"}>
                <Pagination page={page} limit={limit} count={allCount}/>
            </div>
        </div>
    );
}

export default CertList;