import React, {useCallback, useEffect, useRef, useState} from 'react';
import {debounce} from "lodash"
import {fetchCitiesByQuery} from "../../../../http/settingsApi";
import './citychanger.css'

const CityChanger = (props) => {
    const [searchQuery, setSearchQuery] = useState();
    const [cities, setCities] = useState([]);

    const fetchCityDelayed = useCallback(
        debounce(searchQuery => {
            fetchCitiesByQuery(searchQuery, 10).then((response) => {
                setCities(response);
            });
        }, 500), []
    )


    function setCityName(value){
        setSearchQuery(value);
    }

    useEffect(() => {
        if (searchQuery) {
            fetchCityDelayed(searchQuery);
        }
    }, [searchQuery]);

    useEffect(() => {
        fetchCityDelayed();
    }, []);



    return (
        <div className={"city_changer_wrapper"}>
            <div className={"target_city_vacancy"}>
                Выбран город:
                { props.targetCity?.city ||  "Не выбран"}
            </div>



            <div className={"city_list"}>
                <input name='city' placeholder={"поиск по городам"} className={"search_city_field_changer"}
                       type="text" value={searchQuery ? searchQuery : ""}
                       onChange={ (e)=> setCityName(e.target.value) }/>
                <div className={"city_changer_scroll_container"}>
                    {
                        searchQuery && cities.map((item, index) => {
                            return(
                                <div className={"one_city_change_item"}
                                     key={item.id}
                                     onClick={e => props.setCity(item)}
                                >
                                    {" "+item.name}
                                </div>
                            )
                        })
                    }
                </div>

            </div>

        </div>
    );
};

export default CityChanger;