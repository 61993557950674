import React, {useEffect, useState} from 'react';
import {fetchTrends, addNewTrend} from "../../../http/catalogApi";
import Spiner from "../spiner/Spiner";
import './trendManager.css'
import OneTrendAdmin from './oneTrendAdmin/OneTrendAdmin';
const TrendManager = () => {

    const [trends,setTrends] = useState([])
    const [spinActive,setSpinActive] = useState(true);
    const [addNewTrandName, setAddNewTrandName] = useState('')
    const [message,setMesaage] = useState("");
    const [trendTriger,setTrendTriger] = useState(false);

    useEffect(()=> {
        fetchTrends().then(data => {
            setTrends(data)
            setSpinActive(false)
        })
    },[trendTriger])


    const inputValueHandler = (value) => {
        setAddNewTrandName(value);
        setMesaage("");
    }

    const clickAddNewTrend = (value) => {
        if(!value) {
            setMesaage("Имя тренда не может быть пустым");
            return false;
        }
        addNewTrend(addNewTrandName).then(data => {
            if(data.data.error) {
                setMesaage(data.data.error);
                return false;
            }

            if(data.data.message) {
                setMesaage(data.data.message);
                setTrendTriger(!trendTriger);
                return false;
            }
        } )

    }


    return (
        <div className={"trend_page_manager"}>
            {spinActive ?
                <Spiner/>
                :
                <div className={"add_new_trend_container"}>
                    <div className={"trend_wrapper_content_area"}>
                        {
                            trends.map((item,index) =>
                                <OneTrendAdmin
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    trends={trends}
                                    setTrends={setTrends}
                                    trendTriger={trendTriger}
                                    setTrendTriger={setTrendTriger}
                                />

                            )
                        }
                    </div>

                    <div className={"input_wrapper_add_trend"}>
                        <input className={"inp_text_trend"}
                                value={addNewTrandName}
                                onChange={e => inputValueHandler(e.target.value)}
                                type="text" name="" id=""/>

                                <span
                                    onClick={e => clickAddNewTrend(addNewTrandName)}
                                    className={"add_trend_input_click"}>Добавить</span>
                                <span className={"message_trend"}> { message ? message : ""}</span>
                    </div>

                </div>
            }

        </div>
    );
};

export default TrendManager;