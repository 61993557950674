import React, {useContext, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";

import './PrivacyPages.css'
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import BreadCrumbsBlack from "../../../components/Breadcrumbs/breadCrumbsBlack/BreadCrumbsBlack";
import PolicyAndOffer from "../../../components/policyAndOffer/PolicyAndOffer";

const PrivacyPolicy = observer(()=> {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const {settings} = useContext(Context)
    const margTop = settings.headerHeight

    const [stateButtonTitle, setStateButtonTitle] = useState('')
    const [activeArray, setActiveArray] = useState('')


    const politicTitleArray = [
        {title: 'Политика конфиденциальности', linkGo: 'politica'},
        {title: 'Согласие на обработку персональных данных', linkGo: 'soglasie'},
        {title: 'Политика использования файлов сookie', linkGo: 'сookie'},
    ]


    const politicArrayText = [
        {title:"", text:[
                {p: "г. Москва «04» апреля 2018 г."},
                {p: "Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Интернет-магазин «You Wanna», расположенный на доменном имени you-wanna.ru, может получить о Пользователе во время использования сайта Интернет-магазина, программ и продуктов Интернет-магазина."}
            ],
        },
        {title:"1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ", text:[
                {p: '1.1. В настоящей Политике конфиденциальности используются следующие термины:'},
                {p: '1.1.1. «Администрация сайта Интернет-магазина (далее – Администрация сайта) » – уполномоченные сотрудники на управления сайтом, действующие от имени ИП Кабулова Юлия Борисовна, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.'},
                {p: '1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).'},
                {p: '1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.'},
                {p: '1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.'},
                {p: '1.1.5. «Пользователь сайта Интернет-магазина (далее ‑ Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт интернет-магазина.'},
                {p: '1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.'},
                {p: '1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.'},
            ]},
        {title:"2. ОБЩИЕ ПОЛОЖЕНИЯ", text:[
                {p: '2.1. Использование Пользователем сайта Интернет-магазина означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.'},
                {p: '2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта Интернет-магазина.'},
                {p: '2.3. Настоящая Политика конфиденциальности применяется только к сайту Интернет-магазина You Wanna. Интернет-магазин не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте Интернет-магазина.'},
                {p: '2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта Интернет-магазина.'},
            ]},
        {title:"3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ", text:[
                {p: '3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта интернет-магазина по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте интернет-магазина или при оформлении заказа для приобретения Товара.'},
                {p: '3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте интернет-магазина You Wanna в разделах Регистрация и Оформление заказа и включают в себя следующую информацию:'},
                {p: '3.2.1. фамилию, имя, отчество Пользователя;'},
                {p: '3.2.2. контактный телефон Пользователя;'},
                {p: '3.2.3. адрес электронной почты (e-mail);'},
                {p: '3.2.4. адрес доставки Товара;'},
                {p: '3.2.5. место жительство Пользователя.'},
                {p: '3.3. Интернет-магазин защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):'},
                {p: '- IP адрес;'},
                {p: '- информация из cookies;'},
                {p: '- информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);'},
                {p: '- время доступа;'},
                {p: '- адрес страницы, на которой расположен рекламный блок;'},
                {p: '- реферер (адрес предыдущей страницы).'},
                {p: '3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта Интернет-магазина, требующим авторизации.'},
                {p: '3.3.2. Интернет-магазин осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.'},
                {p: '3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.'},


            ]},
        {title:"4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ", text:[
                {p: '4.1. Персональные данные Пользователя Администрация сайта интернет-магазина может использовать в целях:'},
                {p: '4.1.1. Идентификации Пользователя, зарегистрированного на сайте Интернет-магазина, для оформления заказа и (или) заключения Договора купли-продажи товара дистанционным способом с You Wanna.'},
                {p: '4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта интернет-магазина.'},
                {p: '4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта интернет-магазина, оказания услуг, обработка запросов и заявок от Пользователя.'},
                {p: '4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.'},
                {p: '4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.'},
                {p: '4.1.6. Создания учетной записи для совершения покупок, если Пользователь дал согласие на создание учетной записи.'},
                {p: '4.1.7. Уведомления Пользователя Сайта интернет-магазина о состоянии Заказа.'},
                {p: '4.1.7. Уведомления Пользователя Сайта интернет-магазина о состоянии Заказа.'},
                {p: '4.1.9. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта интернет-магазина.'},
                {p: '4.1.10. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Интернет-магазина или от имени партнеров Интернет-магазина.'},
                {p: '4.1.11. Осуществления рекламной деятельности с согласия Пользователя.'},
                {p: '4.1.12. Предоставления доступа Пользователю на сайты или сервисы партнеров Интернет-магазина с целью получения продуктов, обновлений и услуг.'},
            ]},
        {title:"5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ", text:[
                {p: '5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.'},
                {p: '5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на Сайте интернет-магазина «You Wanna», включая доставку Товара.'},
                {p: '5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.'},
                {p: '5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.'},
                {p: '5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.'},
                {p: '5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.'},
            ]},
        {title:"6. ОБЯЗАТЕЛЬСТВА СТОРОН", text:[
                {p: ''},
            ]},
        {title:"6.1. Пользователь обязан:", text:[
                {p: '6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом интернет-магазина.'},
                {p: '6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.'},
            ]},
        {title:"6.2. Администрация сайта обязана:", text:[
                {p: '6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.'},
                {p: '6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.'},
                {p: '6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.'},
                {p: '6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.'},
            ]},
        {title:"7. ОТВЕТСТВЕННОСТЬ СТОРОН", text:[
                {p: '7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.'},
                {p: '7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:'},
                {p: '7.2.1. Стала публичным достоянием до её утраты или разглашения.'},
                {p: '7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.'},
                {p: '7.2.3. Была разглашена с согласия Пользователя.'},

            ]},
        {title:"8. РАЗРЕШЕНИЕ СПОРОВ", text:[
                {p: '8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта Интернет-магазина и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).'},
                {p: '8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.'},
                {p: '8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.'},
                {p: '8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.'},
                {p: ''},
                {p: ''},
            ]},
        {title:"9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ", text:[
                {p: '9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.'},
                {p: '9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте интернет-магазина, если иное не предусмотрено новой редакцией Политики конфиденциальности.'},
                {p: '9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать info@you-wanna.ru'},
                {p: '9.4. Действующая Политика конфиденциальности размещена на странице по адресу you-wanna.ru.'},
            ]},
        {title:"", text:[
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
                {p: ''},
            ]},
    ]

    const soglasieArrayText = [
        {title:"", text:[
                {p: 'Настоящим я, далее – «Субъект Персональных Данных», во исполнение требований Федерального закона от 27.07.2006 г. № 152-ФЗ «О персональных данных» (с изменениями и дополнениями) свободно, своей волей и в своем интересе даю свое согласие ИП Кабулова Юлия Борисовна (далее – «Интернет-магазин», юридический адрес: 141315, Сергиево-Посадский р-н, г. Сергиев Посад г., Дружбы ул., дом № 09 "а", кв.45) на обработку своих персональных данных, указанных при регистрации путем заполнения веб-формы на сайте Интернет-магазина you-wanna.ru (далее – Сайт), направляемой (заполненной) с использованием Сайта.'},
                {p: 'Под персональными данными я понимаю любую информацию, относящуюся ко мне как к Субъекту Персональных Данных, в том числе мои фамилию, имя, отчество, адрес, образование, профессию, контактные данные (телефон, факс, электронная почта, почтовый адрес), фотографии, иную другую информацию. Под обработкой персональных данных я понимаю сбор, систематизацию, накопление, уточнение, обновление, изменение, использование, распространение, передачу, в том числе трансграничную, обезличивание, блокирование, уничтожение, бессрочное хранение), и любые другие действия (операции) с персональными данными.'},
                {p: 'Обработка персональных данных Субъекта Персональных Данных осуществляется исключительно в целях регистрации Субъекта Персональных Данных в базе данных Интернет-магазина с последующим направлением Субъекту Персональных Данных почтовых сообщений и смс-уведомлений, в том числе рекламного содержания, от Интернет-магазина, его аффилированных лиц и/или субподрядчиков, информационных и новостных рассылок, приглашений на мероприятия Интернет-магазина и другой информации рекламно-новостного содержания, а также с целью подтверждения личности Субъекта Персональных Данных при посещении мероприятий Интернет-магазина.'},
                {p: 'Датой выдачи согласия на обработку персональных данных Субъекта Персональных Данных является дата отправки регистрационной веб-формы с Сайта Интернет-магазина.'},
                {p: 'Обработка персональных данных Субъекта Персональных Данных может осуществляться с помощью средств автоматизации и/или без использования средств автоматизации в соответствии с действующим законодательством РФ и внутренними положениями Интернет-магазина.'},
                {p: 'Интернет-магазин принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных, а также принимает на себя обязательство сохранения конфиденциальности персональных данных Субъекта Персональных Данных. Интернет-магазин вправе привлекать для обработки персональных данных Субъекта Персональных Данных субподрядчиков, а также вправе передавать персональные данные для обработки своим аффилированным лицам, обеспечивая при этом принятие такими субподрядчиками и аффилированными лицами соответствующих обязательств в части конфиденциальности персональных данных.'},
                {p: 'Я ознакомлен(а), что:'},
                {p: '1. настоящее согласие на обработку моих персональных данных, указанных при регистрации на Сайте Интернет-магазина, направляемых (заполненных) с использованием Cайта, действует в течение 20 (двадцати) лет с момента регистрации на Cайте Интернет-магазина;'},
                {p: '2. согласие может быть отозвано мною на основании письменного заявления в произвольной форме;'},
                {p: '3. предоставление персональных данных третьих лиц без их согласия влечет ответственность в соответствии с действующим законодательством Российской Федерации.'},
                {p: '4. ИП Кабулова Юлия Борисовна имеет право на передачу Обществу с ограниченной ответственностью «ТКС» (ОГРН 1067760370050, ИНН 7704626485, адрес: 127287 г. Москва, ул. Хуторская 2- я, дом 38А, стр. 25, этаж 3, помещ. 11) всех моих персональных данных, имеющихся в распоряжении/доступе ИП Кабулова Юлия Борисовна.'},
            ]},
    ]

    const сookieArrayText = [
        {title:"", text:[
                {p: 'На этом веб-сайте используются файлы cookie, которые обеспечивают работу всех функций для наиболее эффективной навигации по странице. Если вы не хотите принимать постоянные файлы cookie, пожалуйста, выберите соответствующие настройки на своем компьютере. Продолжая навигацию по сайту, вы косвенно предоставляете свое согласие на использование файлов cookie на этом веб-сайте. Более подробная информация предоставляется в нашей Политике конфиденциальности и Политике использования файлов сookie you-wanna.ru/personal/privacy-policy/'},
            ]},
    ]



    const openTextLinkButton = (item) => {
        setStateButtonTitle(item.linkGo)
        if (item.linkGo === 'politica') {
            setActiveArray(politicArrayText)
        }
        if (item.linkGo === 'soglasie') {
            setActiveArray(soglasieArrayText)
        }
        if (item.linkGo === 'сookie') {
            setActiveArray(сookieArrayText)
        }
        // console.log(item.linkGo)
    }

    useEffect(() => {
        if (politicArrayText) {
            setStateButtonTitle('politica')
            setActiveArray(politicArrayText)
        }
    }, [])
    // console.log(activeArray)


    return (
        <div className={"privacy_policy"}>
            <Helmet>
                <title>Политика конфиденциальности интернет-магазина YOU WANNA</title>
                <meta name="description" content="Политика конфиденциальности интернет-магазина YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07" />
            </Helmet>
            <div
                // style={{top:margTop, paddingBottom:margTop }}
                style={{marginTop:margTop }}
                className={"public_offer_wrapper"}>
                {/*{*/}
                {/*    settings.projectWidth &&  settings.projectWidth > 1024 ?*/}
                {/*        <div className={"indent_bread"}>*/}
                {/*            <BreadCrumbsBlack*/}
                {/*                margTop={settings.headerHeight}*/}
                {/*                items={[*/}
                {/*                    {name:"Youwanna", link:"/"},*/}
                {/*                    {name:"ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ", link:"/content/privacy-policy/"}*/}
                {/*                ]}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        ""*/}
                {/*}*/}
            </div>
            <div className={"pp_content"}>

                <h1 className={"pp_title"}>Политика конфиденциальности</h1>
                <div className={"wrapper_switch"}>
                    {
                        politicTitleArray ? politicTitleArray.map((item, index) =>
                            <div key={index} onClick={e=> openTextLinkButton(item)} className={stateButtonTitle === item.linkGo ? "tab_switch active" : "tab_switch"}>
                                {item.title}
                            </div>
                        ) : ''
                    }
                </div>


                <div className={"pp_conteiner_text politica"}>
                    <div className={"pp_child_text"}>
                    {
                        activeArray ? activeArray.map((item, index) =>
                            <PolicyAndOffer
                                item={item}
                                index={index}
                                key={index}
                            />
                        ) : ''
                    }
                    </div>
                </div>
            </div>


            {/*<PolicyAndOffer*/}
            {/*    titlePolicy={"Политика конфиденциальности"}*/}
            {/*    policyText={"г. Москва «04» апреля 2018 г. Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Интернет-магазин «You Wanna», расположенный на доменном имени you-wanna.ru, может получить о Пользователе во время использования сайта Интернет-магазина, программ и продуктов Интернет-магазина."}*/}
            {/*    policyTextSubline={"1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ"}*/}
            {/*    policyTextOne={"1.1. В настоящей Политике конфиденциальности используются следующие термины:"}*/}
            {/*    policyTextTwo={"1.1.1. «Администрация сайта Интернет-магазина (далее – Администрация сайта) » – уполномоченные сотрудники на управления сайтом, действующие от имени ИП Кабулова Юлия Борисовна, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными."}*/}
            {/*/>*/}

        </div>
    );
});

export default PrivacyPolicy;