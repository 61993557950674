import React, {useEffect} from 'react';
import './obmer.css';

const Obmer = (props) => {

const deleteObmer = (val) => {

    const reduceArray = [...props.obmerState];
    reduceArray.splice(val,1)

    props.setObmerState(reduceArray)

    props.setOneProductCreate({...props.oneProductCreate, obmer:JSON.stringify(reduceArray)})
    // props.setNewReduceObmer({...props.newReduceObmer, obmerSize:reduceArray} )
}

const obmerSizeToJson = (val,item) => {

    /// тут была добавлена позиция, особо не разбираясь. Если возникнут какие-либо проблемы с добавлением обмеров - искать тут.

    props.setObmerState([...props.obmerState], ([props.obmerState[props.index].size_name_obmer = val ],[props.obmerState[props.index].position = item.position ]))

    props.setOneProductCreate({...props.oneProductCreate, obmer:JSON.stringify(props.obmerState)})

    // props.setNewReduceObmer({...props.newReduceObmer, obmerSize:props.obmerState} )


}

const mestoToJson = (val,index) => {
    props.setObmerState([...props.obmerState], [props.obmerState[props.index].fields[index].name = val])

    props.setOneProductCreate({...props.oneProductCreate, obmer:JSON.stringify(props.obmerState)})
    // props.setNewReduceObmer({...props.newReduceObmer, obmerSize:props.obmerState} )

    }

const valueToJson = (val,index) => {
    props.setObmerState([...props.obmerState], [props.obmerState[props.index].fields[index].value = val ])

    props.setOneProductCreate({...props.oneProductCreate, obmer:JSON.stringify(props.obmerState)})
    // props.setNewReduceObmer({...props.newReduceObmer, obmerSize:props.obmerState} )

}

    return (
        <div className={"one_ombmer_container"}>
            <div className={"obmer_size_name"}>
                <div className={"obmer_size_container"}>
                {
                    props.size ?
                        props.size.map((item,index) => {
                         return( <div onClick={e => obmerSizeToJson(item.size_name,item) } className={"mimi_size_obmer"} key={index}> {item.size_name} </div> )
                        })
                        : ""
                }
                </div>
                <span className={'omb_label'}>Укажите размер</span>
                <input className={"obmer_name_size_input"} type="text"
                    value={props.obmerState[props.index].size_name_obmer}
                    onChange={ e => e.preventDefault()}
                />
            </div>
            <div className={"obmer_wrapper_fields_pdn"}>
                {

                    props.item.fields ?
                        props.item.fields.map((itemFields,index) => {
                            return(
                                <div key={index} className={"obmer_parrent_wrapper"}>

                                    <div className={"obmer_field_two_wrapper"}>
                                        {/*<span className={"omb_label name_obmer"}>{props.obmerState[props.index].fields[index].name}</span>*/}
                                        <span className={"omb_label"}>Наименование</span>
                                        <input
                                            value={props.obmerState[props.index].fields[index].name ? props.obmerState[props.index].fields[index].name : ""}
                                            onChange={ e => mestoToJson(e.target.value,index) }
                                            className={"obmer_name_param"} type="text" name="" id=""/>

                                    </div>
                                    <div className={"obmer_field_two_wrapper"}>
                                        <span className={"omb_label"}>Значение</span>
                                        <input
                                            value={props.obmerState[props.index].fields[index].value}
                                            onChange={ e => valueToJson(e.target.value, index)}
                                            className={"obmer_name_param"} type="text" name="" id=""/>
                                    </div>
                                    <div
                                        onClick={e => props.setObmerState([...props.obmerState], [props.obmerState[props.index].fields.splice(index,1)]) }
                                        className={"remove_fields_obmer"}>Удалить</div>
                                </div>

                            )
                        }): ""
                }
            </div>
            <div onClick={e =>  props.setObmerState([...props.obmerState], [props.obmerState[props.index].fields.push(Object.assign({}, props.obmerFields)) ])}  className={"add_fields_obmer"}>+</div>
            <span onClick={e => deleteObmer(props.index)}className={"delete_obmer_button"}> Удалить </span>
        </div>
    );
};

export default Obmer;