import React from 'react';
import './modalSubscriptions.css'
import {Link} from "react-router-dom";

const ModalSubscriptions = (props) => {

    return (
        <div className={"result_modal_subscriptions " + props.className}>
            <div className={"result_modal_subscriptions_wrapper"}>
                <div className={"result_modal_subscriptions_close"}>
                    <div
                        onClick={props.closeClick}
                        className={"subscriptions_modal_exit_div"}></div>
                </div>
                <div className={"subscriptions_result_content"}>
                    <div className="subscriptions_result_content_chilld">

                        <div className="subsc_success_check_mark"></div>
                        <span className="subsc_successfully_sent"><noindex>Подписка оформлена!</noindex></span>

                        <div
                            onClick={props.closeClick}
                            className="container_sent_button">
                            <Link to={"/"} className={"subsc_successfully_sent_button"}>
                                <noindex>На главную</noindex>
                            </Link>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default ModalSubscriptions;