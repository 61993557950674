import {$host} from "./index";

export const addFavorite = async (productId, userId) => {
    try {
        const response = await $host.post('api/like/add', {productId, userId})
        return  response;

    } catch (e) {
        return e.response
    }
}

export const deleteFavorite = async (productId, userId) => {
    try {
        const response = await $host.post('api/like/delete', {productId, userId})
        return  response;

    } catch (e) {
        return e.response
    }
}

export const syncFavorites = async (productIds, userId) => {
    try {
        const response = await $host.post('api/like/sync', {productIds, userId})
        return  response;

    } catch (e) {
        return e.response
    }
}
