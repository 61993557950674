import './allStockAndTrends.css'
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import OneLineItem from "./oneLineItem/OneLineItem";
import { addNewStockOrTrend, getAllStockAndTrends, setSortStockAndTrends } from '../../../../../http/contentApi';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Spiner from "../../../spiner/Spiner";
const AllStockAndTrends = () => {

    const [searchParam, setSearchParam] = useState('name');
    const [sortField, setSortField] = useState('sort');
    const [sortParam, setSortParam] = useState('ASC');
    const [searchText, setSearchText] = useState('');
    const [stockAndTrends, setStockAndTrends] = useState([]);
    const Navigate = useNavigate()
    const [spinActive,setSpinActive] = useState(true);

    const changeSortFieldHandler = (param) => {
        if (sortField !== param) {
            setSortField(param);
            setSortParam('ASC')
            return false;
        }
        if (sortParam === "ASC") {
            setSortParam('DESC')
        } else {
            setSortParam('ASC')
        }
    }

    useEffect(() => {

        getAllStockAndTrends(sortField, sortParam).then(data => {
            setStockAndTrends(data.data)
            setSpinActive(false)
        })

    }, [sortField, sortParam])

    const addNew = async (e) => {
        const { data } = await addNewStockOrTrend();
        if (data.error) {
            return false;
        }
        Navigate("/admindashboard/content/stock-trend/oneItem/" + data.id + "/")
    }

    const searchHandler = (e) => {
        e.preventDefault()
        setSearchText(e.target.value)
    }

    const sortedAndSearched = useMemo(() => {
        if (searchText) {
            return [...stockAndTrends].filter(item => String(item[searchParam]).toLowerCase().includes(searchText.toLowerCase()));
        }
        return stockAndTrends;
    }, [searchText, stockAndTrends])

    const onDragEnd = function(result){
        const item = sortedAndSearched[result.source.index]
        const targetItem = sortedAndSearched[result.destination.index]

        const newListItems = Array.from(sortedAndSearched);
        newListItems.splice(result.source.index, 1);
        newListItems.splice(result.destination.index, 0, sortedAndSearched[result.source.index]);
        setStockAndTrends(Array.from(newListItems))

        setSpinActive(true)
        setSortStockAndTrends(item.id, item.sort, targetItem.sort).then(() => {
            getAllStockAndTrends(sortField, sortParam).then(data => {
                setStockAndTrends(data.data)
                setSpinActive(false)
            })
        })
    }

    return (
        <div className={"stock_and_trends_page_wrapper"}>

            {spinActive ?
                <Spiner/>
                :""
            }

            <div className={"stock_and_trends_controll_bord"}>

                <div className={"info_bord_adm_panel"}>
                    <span className={"info_name"}>Все акции и тренды</span>
                </div>
                <div className="add_stock_or_trend">
                    <span onClick={e => addNew()}> Добавить акцию/тренд </span>
                </div>

            </div>
            <div className={"stock_and_trends_panel_wrapper"}>
                <div className="table_stock_and_trends_adm">
                    <div className={"filters_and_controll_stock_and_trends_block"}>
                        <div className={"search_filter_container"}>
                            <div className={"controls_button_search"}>
                                <div
                                    onClick={e => setSearchParam("name")}
                                    className={searchParam === "name" ? "search_btn_param active" : "search_btn_param"}>Название
                                </div>
                                <div
                                    onClick={e => setSearchParam("id")}
                                    className={searchParam === "id" ? "search_btn_param active" : "search_btn_param"}>ID
                                </div>
                            </div>
                            <div className={"container_for_search_field"}>
                                <input className={"search_input_stock_and_trends_page"} type="text"
                                    value={searchText}
                                    onChange={e => searchHandler(e)}
                                    placeholder={"Поиск..."}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"wrapper_for_operating_mode_container"}>
                        <div className={"adm_marker_block"}>
                            <div className={"lft_block"}>
                                <div className={"info_of_stock_and_trends id"}>ID</div>
                                <div className={"info_of_stock_and_trends img"}>IMG</div>
                                <div className={"info_of_stock_and_trends name "}> Название </div>
                                <div className={"info_of_stock_and_trends status"}> Статус </div>
                            </div>
                            <div className={"rgt_block"}>
                                <div className={"time_bl_rgt sort_field_p"}>
                                    Дата созан.
                                </div>
                                <div className={"time_bl_rgt"}>
                                    Посл. ред
                                </div>
                                {/* <div className={"user_bl_rgt"}>
                                    Редактор
                                </div> */}
                                <div className={"del_bl_rgt"}>
                                    Удал.
                                </div>
                            </div>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="stock-and-trands">
                            {
                                (provided, snapshot) => {
                                    return (
                                        <div className={"product_wrapper_gener"} ref={provided.innerRef} {...provided.droppableProps}>
                                        {
                                            sortedAndSearched ?
                                                sortedAndSearched.map((item, index) =>
                                                    <Draggable key={item.id} draggableId={item.id.toString()}  index={index}>
                                                        {provided => {
                                                            return (
                                                                <div {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps} className={"one_stock_or_trend_line_wrapper"}>
                                                                    <OneLineItem key={index} index={index} item={item} stockAndTrends={stockAndTrends} setStockAndTrends={setStockAndTrends} />
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                )
                                                : ""
                                        }
                                            {provided.placeholder}
                                        </div>
                                    )
                                }
                            }
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AllStockAndTrends;