import React from 'react';
import './reviewsCards.css'
const ReviewsCards = (props) => {

    return (
        <div className={"reviews_cards_container_parent"}>
            <div className={"reviews_cards_container"}>
                <div className={"reviews_name_and_city"}>
                    <div className={"reviews_name"}> {props.item.name_user}</div>
                    <div className={"reviews_city"}>{props.item.city_user}</div>
                </div>
                <div className={"reviews_grade_and_data"}>
                    <div className={"reviews_grade_container"}>
                        <span className={"reviews_cards_grade"}>ОЦЕНКА:</span>
                        <span className={"reviews_cards_grade_points"}>{props.item.stars} / 10</span>
                    </div>
                    <div className={"reviews_cards_data"}>{props.item.custom_date}</div>
                </div>
                <div className={"reviews_cards_child_border"}>
                    <div className={"reviews_cards_child"}>
                        <div className={"reviews_cards_child_left"}>

                            <div className={"reviews_cards_review"}>{props.item.review_text}</div>
                            <div className={"reviews_cards_border"}>
                                {/*<div className={"reviews_cards_border_left"}></div>*/}
                                <span className={"answer_name_shop"}>Ответ YOUWANNA</span>
                                {/*<div className={"reviews_cards_border_right"}></div>*/}
                                <div className={"reviews_right_alignment"}>
                                    <div className={"reviews_cards_answer"}>{props.item.answer_admin}</div>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};

export default ReviewsCards;