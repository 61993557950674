import React from 'react';
import './fioInput.css'
import InputMask from "react-input-mask";


const FioInput = (props) => {
    return (
        <div ref={props.errRef} className={"wrapper_fio_inputs"}>
            <div className={"title_input_lk"}>
                {props.fieldsName}
            {props.requiredField ? <span className={"required_field"}>{props.requiredField}</span> : ''}
            </div>
            <InputMask
                className = {"fio_fields "+props.errorClass}
                // placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                alwaysShowMask={true}
                maskChar={""}
            />
            <div className={"error_block_text "+props.errorClass}>
                {props.errorText ? props.errorText : ""}
            </div>
        </div>
    );
};

export default FioInput;