import React from 'react';
import './SwitchesST.css';

const SwitchesSt = (props) => {

    return (
        <div className={"st_switches_parents"}>
            <div className={"st_switches"}>
                <div className={props.pagesActive === "stock" ? "st_caret stock" : "st_caret trends"}></div>
                <h2
                    onClick={props.setPagesStocks}
                    className={props.pagesActive === "stock" ? "switches_stock active" : "switches_stock"}>{props.st_stocks}</h2>
                <h2
                    onClick={props.setPagesTrends}
                    className={props.pagesActive === "trends" ? "switches_trends active" : "switches_trends"}>{props.st_trends}</h2>
            </div>
        </div>
    );
};

export default SwitchesSt;