import React, {useEffect, useState} from 'react';
import "./orderStatus.css";
import {createNewStatus} from "../../../../http/settingsApi";
import {login} from "../../../../http/userAPI";
import {getAllOrderStatus} from "../../../../http/orderApi";
import OneStatusUpd from "./OneStatusUpd";

const OrderStatus = () => {
    const [allOrderStatus,setAllOrderStatus] = useState(null)
    const [newStatus,setNewStatus] = useState({name:"", retailCode:""});
    const [responseServer, setResponseServer] = useState("");

    const createStatusHandler = (status) => {
        createNewStatus(status).then(response => {

            if(response.message) {
                setResponseServer(response.message);
            }
            if(response.error) {
                setResponseServer(response.error)
            }
        })
    }

    useEffect(() => {
        getAllOrderStatus().then(response => {
            setAllOrderStatus(response)
        })
    },[])


    return (
        <div className={"wrapper_status_adm_page"}>
            <div className={"status_adm_title"}>Статусы заказов</div>
            <div className={"status_adm_page_work_area"}>
                <span>Добавить новый статус заказа:</span>
                <div className={"input_wrapper_status"}>
                    <span className={"status_page_heading_input"}>Название</span>
                    <input type="text"
                           className={"input_status_page"}
                           placeholder={"Введите название"}
                        value={newStatus.name ? newStatus.name : ""}
                        onChange={e => setNewStatus({...newStatus, name: e.target.value})}
                    />
                </div>
                <div className={"input_wrapper_status"}>
                    <span className={"status_page_heading_input"}>Код в RetailCRM</span>
                    <input type="text"
                           className={"input_status_page"}
                           placeholder={"Введите код"}
                           value={newStatus.retailCode ? newStatus.retailCode : ""}
                           onChange={e => setNewStatus({...newStatus, retailCode: e.target.value})}
                    />
                </div>
                <div className={"server_status_response"}>
                    {responseServer}
                </div>
                <div
                    onClick={ e => createStatusHandler(newStatus)}
                    className={"save_status_page_btn"}>сохранить</div>
            </div>

            <div className={"status_adm_title"}>Изменение существующих статусов</div>
            {
                allOrderStatus ?
                    allOrderStatus.map((item,index) =>  <OneStatusUpd key={item.id} item={item} /> )
                    :""
            }
        </div>
    );
};

export default OrderStatus;