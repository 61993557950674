import React, {useContext, useEffect, useState} from 'react';
import './Personalcabinet.css';
import ControllBar from "./controllBar/ControllBar";
import ContentArea from "./contentArea/ContentArea";
import {useParams} from "react-router";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";






const Personal = observer((props) => {

    const {user, settings} = useContext(Context)

    // const [currentPage, setCurrentPage] = useState('userinfo');



    const [barItems, setBarItems] = useState([
        { nameButton:"Личная информация",position:"0",toPage:"profile", padding:false, isActive:true, disabled:false },
        // { nameButton:"Избранное",position:"1",toPage:"favorites", isActive:false, padding:false, disabled:false },
        { nameButton:"Покупки",position:"2",toPage:"order", isActive:false, padding:false, disabled:false },
        { nameButton:"Мои бонусы",position:"3",toPage:"bonuses", isActive:false, padding:false, disabled:false },
        { nameButton:"Чат с консультантом",position:"4",toPage:"callmanager", padding:false, isActive:false, disabled:false },
        // { nameButton:"Чат с консультантом",position:"3",toPage:"https://api.whatsapp.com/send/?phone=79268877007&text&app_absent=0", padding:false, isActive:false, disabled:false },
        // { nameButton:"Баллы",position:"4",toPage:"viewballs", isActive:false, padding:false, disabled:true },
        // { nameButton:"Уведомления",position:"5",toPage:"messages", isActive:false, padding:false, disabled:true },
    ])

    const [oldCarretTarget, setOldCarretTarget] = useState(0);


    const controllPages = (indexObject) => {


      //Посмотреть старую и новую позицию каретки.


     /////////////////////////////////////////////////////


        barItems.forEach((item,indexElement) => {

            // Текущий элемент, на который прошел клик.
            if(indexElement*1 === indexObject*1) {
                setBarItems([...barItems], [barItems[indexObject].isActive = true])
                user.setCurrentPage(item.toPage);
            } else {
                    if((indexElement*1 >= oldCarretTarget*1 && indexElement*1 < indexObject*1) || (indexElement*1 <= oldCarretTarget*1 && indexElement*1 > indexObject*1)) {
                        setBarItems([...barItems], [barItems[indexElement].isActive = false])

                    } else {
                        //ответвление обрабатывающее остальное (пока что неактивные)//
                    }


            }
        })

            setOldCarretTarget(indexObject);

    }



    let params = useParams();

    useEffect(() => {

        if(params.searchParam) {
            user.setCurrentPage(params.searchParam);

            barItems.forEach((item,index) => {
                    if(item.toPage !== params.searchParam) {
                        setBarItems([...barItems], [barItems[index].isActive = false])
                    } else {
                        setBarItems([...barItems], [barItems[index].isActive = true])
                        user.setCurrentPage(item.toPage);
                        setOldCarretTarget(index);
                    }
            })
        }
    },[])



    // console.log(user.currentPage)
    return (
        <div className={"personal_cabinet"}>
            {
                settings.projectWidth && settings.projectWidth > 768 ?
                    <ControllBar
                        barItems={barItems}
                        trigerAnimate={controllPages}
                    />
                    :
                    ""
            }

            <ContentArea
                // elementRef={props.elementRef}
                // elementRefOneCards={props.elementRefOneCards}
                // currentPage={user.currentPage}
            />

        </div>
    );
});

export default Personal;