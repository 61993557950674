import React from 'react';
import './activeSocialIcons.css'

const ActiveSocialIcons = (props) => {

    const activSocial = props.nameRecordingObject

    const deleteJsonIcons = (object) => {

        let reduceActiveIcons = {...props.noJson};
        let customIndex = null;
            reduceActiveIcons[activSocial].forEach((item,index) => {
                if(item.id === object.id) {
                    customIndex = index
                }
            })

                if(customIndex !== null) {
                    reduceActiveIcons[activSocial].splice(customIndex,1)
                    props.setNoJsonContent(reduceActiveIcons)
                    props.setUpdateSocialSaveTriger(true)
                }

        return false;
    }



    return (
        <div
            onClick={e => deleteJsonIcons(Object.assign({}, props.item))}
            className={"active_icons_social"}>
            <div className={"active_block_padding"} dangerouslySetInnerHTML={{__html: props.item.image_code}}></div>
        </div>
    );
};

export default ActiveSocialIcons;