import React, { useEffect, memo } from 'react';
import { createPortal } from 'react-dom';
import styles from './NoodlesRain.module.scss';

const NoodlesRain = memo(() => {
    useEffect(() => {
        sessionStorage.setItem('noodles_rain', 'true');
    }, [sessionStorage.getItem('noodles_rain')])


    if (sessionStorage.getItem('noodles_rain')) {
        return null;
    }

    return createPortal(
        (
            <div className={styles.root}>
                {new Array(36).fill(0).map((noodle, index) => (
                    <img key={index} className={styles.noodle} src={`/files/images/noodles/${index + 1}.png`} alt=""/>
                ))}
                {new Array(36).fill(0).map((noodle, index) => (
                    <img key={index} className={styles.noodle} src={`/files/images/noodles/${index + 1}.png`} alt=""/>
                ))}
                {new Array(36).fill(0).map((noodle, index) => (
                    <img key={index} className={styles.noodle} src={`/files/images/noodles/${index + 1}.png`} alt=""/>
                ))}
                {new Array(36).fill(0).map((noodle, index) => (
                    <img key={index} className={styles.noodle} src={`/files/images/noodles/${index + 1}.png`} alt=""/>
                ))}
                {new Array(36).fill(0).map((noodle, index) => (
                    <img key={index} className={styles.noodle} src={`/files/images/noodles/${index + 1}.png`} alt=""/>
                ))}
                {new Array(36).fill(0).map((noodle, index) => (
                    <img key={index} className={styles.noodle} src={`/files/images/noodles/${index + 1}.png`} alt=""/>
                ))}
            </div>
        ),
        document.body
    );
});

export default NoodlesRain;
