import React from 'react';
import './ContactsCards.css';

const SocialIcon = (props) => {


    return (


        <div className={"c_social_icon"}>
            {
                true ?
                    <a dangerouslySetInnerHTML={{__html: props.item.image_code}} className="c_social_link" href={props.item.link_svg} rel="noreferrer" target={"_blank"}  >
                    </a>
                    : ""

            }
        </div>
    );
};

export default SocialIcon;