import React, {useContext} from 'react';
import './OneAndFour.css';
import AbsoluteShadow from "../../UI/AbsoluteShadow/AbsoluteShadow";
import WiewButton from "../../UI/WiewButton/WiewButton";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const OneAndFour = observer(() => {

    const {settings} = useContext(Context)

    return (
        <div className="home_OneAndFour">
            <div className="home_OneAnd_Four_wrap">
                <div className="OneAndFour left">
                    <a href="/" className="smallBlockFour  top">
                        <AbsoluteShadow shadow={true}/>
                        <img src={'/files/images/small1.jpg'} alt=""/>
                        <span className="goToPublish"> Перейти к публикации </span>
                    </a>
                    <a href="/" className="smallBlockFour  top">
                        <AbsoluteShadow shadow={true}/>
                        <img src={'/files/images/small2.jpg'} alt=""/>
                        <span className="goToPublish"> Перейти к публикации </span>
                    </a>
                    {
                        settings.projectWidth &&  settings.projectWidth < 428 ?
                            <a href="/" className="OneAndFour right ">

                                <img src={'/files/images/Bwomen.jpg'} alt=""/>
                                <AbsoluteShadow shadow={true}/>
                                <span className="goToPublish"> Перейти к публикации </span>
                            </a>
                            :
                            ""
                    }
                    <a href="/" className="smallBlockFour  bottom">
                        <AbsoluteShadow shadow={true}/>
                        <img src={'/files/images/small3.jpg'} alt=""/>
                        <span className="goToPublish"> Перейти к публикации </span>
                    </a>
                    <a href="/" className="smallBlockFour  bottom">
                        <AbsoluteShadow shadow={true}/>
                        <img src={'/files/images/small4.jpg'} alt=""/>
                        <span className="goToPublish"> Перейти к публикации </span>
                    </a>
                </div>
                {
                    settings.projectWidth &&  settings.projectWidth >428 ?
                        <a href="/" className="OneAndFour right ">

                            <img src={'/files/images/Bwomen.jpg'} alt=""/>
                            <AbsoluteShadow shadow={true}/>
                            <span className="goToPublish"> Перейти к публикации </span>
                        </a>
                        :
                        ""
                }

            </div>
            <WiewButton link="/instashop" button="Смотреть еще" black={true} />
        </div>
    );
});

export default OneAndFour;