import React, {useEffect, useState} from 'react';
import "./categoryManager.css";
import {addNewCategory, fetchCategory, fetchClearCategory} from "../../../http/catalogApi";
import HierarhyOneItem from "./hierarhyOneItemContainer/HierarhyOneItem";
import OneCategoryItem from "./oneCategoryItem/OneCategoryItem";

const CategoryManager = () => {

    const [newCategory,setNewCategory] = useState({name:"", parrentId:null})
    const [errorCategory,setErrorCategory] = useState('');
    const [refreshCategories,setRefreshCategories] = useState(false);

    const addNewCategoryHandler = (fields) => {
        addNewCategory(fields).then(response => {
            if(response.error) {
                setErrorCategory(response.error)
                return false;
            }
            if(response.message) {
                window.location.reload();
            }
            setErrorCategory("")
        } )
    }

    const [categoryAndChild, setCategoryAndChild] = useState([])
    const [clearCategoryList, setClearCategoryList] = useState([])

    useEffect(() => {
        fetchCategory().then(response => {
            if(!response.error) {
                setCategoryAndChild(response)
            }
        } )
        fetchClearCategory().then(response => {
            if(response) {
                setClearCategoryList(response)
            }
        } )
    },[])


   useEffect(() => {
       fetchClearCategory().then(response => {
           if(response) {
               setClearCategoryList(response)
           }
       } )
   },[refreshCategories])

    return (
        <div className={"category_wrapper_page"}>
            <div className={"page_container_category"}>
                <div className={"first_category_controller"}>
                       <span className={"heading_page_category"}>
                        Управление категориями
                       </span>
                   <div className={"left_wrapper_category_controller"}>
                       <div className={"catalog_manager_field_wrap"}>
                           <span className={"category_field_label"}>* Название</span>
                           <input
                               className={"category_fields_vac"}
                               placeholder={"Ввести название..."}
                               value={newCategory.name ? newCategory.name : ""}
                               onChange={e => setNewCategory({...newCategory,name:e.target.value})}
                               type="text"/>
                       </div>

                       <div className={"catalog_manager_field_wrap"}>
                           <span className={"category_field_label"}>* URL категории.  Пример:   ubki   , без цифр и слешей. </span>
                           <input
                               className={"category_fields_vac"}
                               placeholder={"Ввести название..."}
                               value={newCategory.slug ? newCategory.slug : ""}
                               onChange={e => setNewCategory({...newCategory, slug:e.target.value})}
                               type="text"/>
                       </div>
                   </div>
                    <div className={"save_and_error"}>
                        <span onClick={e => addNewCategoryHandler(newCategory)}  className={"add_new_category_button"}> Добавить </span>
                        <span className={"error_category_message"} > {errorCategory} </span>
                    </div>
                </div>

                <div className={"cheme_vision_category"}>
                    <span className={"heading_page_category"}>
                        Памятка
                    </span>
                    <div className={"ct_manager_pam"}>
                        URL категории - как символьный код. Только буквы англ. алфавита и " - _ ". Без слешей.
                        Пример заполнения:
                        <ul>
                            <li>ubki</li>
                            <li>jempers</li>
                            <li>boots</li>
                        </ul>

                    </div>
                </div>


                <div className={"all_category_redaction_wrapper"}>
                    {
                        clearCategoryList ? clearCategoryList.map((item,index) =>
                            <OneCategoryItem key={item.id} index={index} id={item.id}
                                             clearCategoryList={clearCategoryList}
                                             setClearCategoryList={setClearCategoryList}
                                             item={item}
                                             setRefreshCategories={setRefreshCategories}
                                             refreshCategories={refreshCategories}
                            />
                        ) : "net"
                    }

                </div>

            </div>
        </div>
    );
};

export default CategoryManager;