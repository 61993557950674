import React from 'react';

const CloseIcon = ({width = 10, height = 10 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
        >
            <path
                d="M1 1L10.0455 10.0454M10.0455 10.0454L1 19M10.0455 10.0454L19 1M10.0455 10.0454L19 19"
               
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default CloseIcon;