import React, {useContext, useEffect, useState} from 'react';
import './CardsRefound.css';
import CardsRefound from "./CardsRefound";
import {Context} from "../../../index";
import TextCardsShop from "./TextCardsShop";
import {observer} from "mobx-react-lite";

const PageRefound = observer((props) => {


    const {settings} = useContext(Context)


    const [minHeight,setMinHeight] = useState(0);
    const [useeffChange, setUseffChange] = useState(false);
    const refounfDopText = props.deliveryNoJson.refundTextInfo + props.deliveryNoJson.refundDopInfoText

    const [refoundTextState, setRefoundTextState] = useState(false)
    const controllerTextRefound = () => {
        setRefoundTextState(!refoundTextState);
    }

    const [heightRefoundBlock,setHheightRefoundBlock] = useState(false)

    return (
        <div className={"page_refound"}>
            <div
                // style={{minHeight:minHeight}}
                 className={"content_refound"}>
                {/*<div className={"wrap_relative"}>*/}
                {/*    <div*/}
                {/*        id={"refound_absolute"}*/}
                {/*        className={"refound_absolute"}>*/}
                        <div className={"wrap_content"}>

                            {
                                props.deliveryNoJson.arrayRefund ?  props.deliveryNoJson.arrayRefund .map((item,index) =>
                                        <CardsRefound
                                            item={item}
                                            key={index}
                                            index={index}
                                            setUseffChange={setUseffChange}
                                            useeffChange={useeffChange}
                                            setHheightRefoundBlock={e=> setHheightRefoundBlock(e)}
                                            heightRefoundBlock={heightRefoundBlock}
                                        />

                                ) : ""
                            }
                        </div>
                        {
                            settings.projectWidth && settings.projectWidth > 1024 ?
                                <div className={"border_style"}></div> : ""
                        }
                    {/*</div>*/}
                {/*</div>*/}
            </div>

            <div
                id={"conteinet_text_below"}
                className={"conteinet_text_below"}>
                <div className={"title_text"}>{props.deliveryNoJson.refundTitleInfo ? props.deliveryNoJson.refundTitleInfo : ""}</div>
                {/*{refoundTextState === false && settings.projectWidth && settings.projectWidth < 1024?*/}
                {/*    <div className={"additional_text"}>{minQuantityText}</div> :*/}
                    <div>
                        <div className={"additional_text"}>{props.deliveryNoJson.refundTextInfo ? props.deliveryNoJson.refundTextInfo : ""}</div>
                        <div className={"additional_text bold"}>{props.deliveryNoJson.refundDopInfoTitle ? props.deliveryNoJson.refundDopInfoTitle : ""}</div>
                        <div className={"additional_grey"}>{props.deliveryNoJson.refundDopInfoText ? props.deliveryNoJson.refundDopInfoText : ""}</div>
                    </div>
                {/*}*/}


                {/*{*/}
                {/*    settings.projectWidth && settings.projectWidth < 1024 && resultSumText > '414' ?*/}
                {/*        <div*/}
                {/*            onClick={e => controllerTextRefound()}*/}
                {/*            className={"refound_open_dop_text"}>{refoundTextState === true ? "" : "Показать все"}</div>*/}
                {/*        :*/}
                {/*        ""*/}
                {/*}*/}

            </div>
        </div>
    );
});

export default PageRefound;