import React, { memo, useEffect, useState } from 'react';

const TwoCard = memo((props) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (window.innerWidth <= 1024) return;

        const sections = document.querySelectorAll('.child_msc');

        sections.forEach(section => {
            const items = section.querySelectorAll('.conteiner_subline_child');

            let maxHeight = 0;

            items.forEach(item => {
                maxHeight = Math.max(maxHeight, item.offsetHeight);
            })

            items.forEach(item => item.style.height = `${maxHeight}px`);
        })
    }, [])

    const content = (
        <div className={"cards_moscow"}>
            <div className={props.item.color_block === true ? "wrap_moscow delivery_express" : "wrap_moscow"}>
                {
                    props.text ? props.text.map((item, index) =>
                        <div
                            key={index}
                            className={"subtitle_conteiner"}
                        >
                            <span className={"subtitle_delivery"}>{item.child_subtitle}</span>
                            <span className={"text_delivery"}>{item.text_delivery}</span>
                        </div>
                    ) : ""
                }
            </div>
        </div>
    )

    return (
        <div
            className={"child_moscow"}
        >
            <div className={props.item.color_block === true ? "subline_moscow" : "subline_moscow content_scroll"}>
                <div className={"conteiner_subline_child" + (isActive ? ' _active' : '')} onClick={() => setIsActive(!isActive)}>
                    <span className={"mkad_subtitle"}>{props.subtitle}</span></div>
                {window.innerWidth <= 1024 ?
                    isActive ? content : ''
                    : content
                }
            </div>
        </div>
    );
});

export default TwoCard;