import React from 'react';
import './contactUs.css';
import {Link} from "react-router-dom";

const ContactUs = (props) => {
    return (
        <div className={"footer_contact_us"}>
                <div className={"social_contact_us"}>
                    <span className={"social_contact_us_seti_text"}>
                        Наши соц сети
                    </span>
                    <div className={"contact_us_link_wrapper"}>
                        {
                            props.noJsonContentFooter.footerSocial ? props.noJsonContentFooter.footerSocial.map((item,index) =>
                                <a key={index} dangerouslySetInnerHTML={{__html: item.image_code}} className={"social_link_us"} href={item.link_svg} rel="noreferrer" target={"_blank"}  >
                                </a>
                            ) : ''
                        }
                    </div>
                </div>
                <div className={"mesend_and_tel_contact_us"}>
                     <span className={"mesenj_telephone_text"}>
                        Свяжитесь с нами
                    </span>
                    <div className={"mesenj_icons_wrapper"}>
                        {
                            props.noJsonContentFooter.contactUs ?  props.noJsonContentFooter.contactUs.map((item,index) =>
                                <a key={index} dangerouslySetInnerHTML={{__html: item.image_code}}  className={"social_link_us"} href={item.link_svg} rel="noreferrer" target={"_blank"}  >
                                </a>
                            ) : ''
                        }
                        {
                            props.noJsonContentFooter.dopNumberContact ?  props.noJsonContentFooter.dopNumberContact.map((item,index) =>{
                                if (item.website == "телефон") {
                                    return (
                                        <a key={index} className={"number_contact_us"} rel="noreferrer"  href={"tel:"+item.link}>
                                            <span className={"number_contact_hover"}>{item.name ? item.name : ''}</span>
                                        </a>
                                )}
                                if (item.website == "сайт") {
                                    return (
                                        <Link key={index}  to={item.link} className="number_contact_us">
                                            <span className={"number_contact_hover"}>{item.name ? item.name : ''}</span>
                                        </Link>
                                )}
                                if (item.website == "сторонний") {
                                    return (
                                        <a key={index} className={"number_contact_us"} rel="noreferrer" target={"_blank"}  href={item.link}>
                                            <span className={"number_contact_hover"}>{item.name ? item.name : ''}</span>
                                        </a>
                                )}
                            }) : ''
                        }


                    </div>
                </div>
        </div>
    );
};

export default ContactUs;