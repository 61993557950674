import React, { useEffect, useMemo, useState } from 'react';
import './addOneStockOrTrend.css';
import { getOneStock, updateOneStock } from '../../../../../http/contentApi';
import { useParams } from 'react-router-dom';
import OneImageField from '../../../UI/oneImageField/OneImageField';
import { addImages, deleteImage } from '../../../../../http/imagesApi';
import SearchChildProductArea from '../../../products/addOneProduct/searchChildProductsComponent/SearchChildProductArea';
import IsActiveIndicator from '../../../UI/isActiveIndicator/IsActiveIndicator';
import { getchildsforslider } from '../../../../../http/catalogApi';
import IsActiveCheckbox from '../../../../../components/UI/isActiveCheckbox/isActiveCheckbox';
import AdmManagedSliders from '../../../../../components/admManagedSliders/admManagedSliders';


const templatesMap = {"old":"Старый","new":"Новый"};
const AddOneStockOrTrend = () => {

    let { itemId } = useParams();

    const [oneStockCreate, setOneStockCreate] = useState({});
    const [sliderImages, setSliderImages] = useState([]);
	const [sliders, setSliders] = useState([]);
	const [deletedSliders, setDeletedSliders] = useState([]);
    const [textImages, setTextImages] = useState([]);

	const updateTopSliderName = (e) => {
		const value = e.target.value;
		if(!value) return;
		setOneStockCreate(prev => ({...prev,top_slider_name:value}))
	}

	const updateBottomSliderName = (e) => {
		const value = e.target.value;
		if(!value) return;
		setOneStockCreate(prev => ({...prev,bottom_slider_name:value}))
	}

    const [responseTopProducts,setResponseTopProducts] = useState(null);
    const [responseBottomProducts,setResponseBottomProducts] = useState(null);
    const imageTemplate = {id: null, image: ""}

    useEffect(() => {
        getOneStock(itemId).then(response => {
            const newStock = {
				...response.data,
				productSliders: response.data.sliders.length ? response.data.sliders.map(
					(slider) => {
						if(slider.type == 'products' && !!slider) {
							return slider;
						}
					}
				) : [],
				imageSliders: response.data.sliders ? response.data.sliders.map(
					(slider) => {
						if(slider.type == 'images' && slider){
							return slider;
						}
					}
				) : [],
			};
            setOneStockCreate(newStock);
            setTemplate(response.data.template)
            addNewImageField(Object.assign({}, imageTemplate), response.data.slider_images, setSliderImages)
            addNewImageField(Object.assign({}, imageTemplate), response.data.text_images, setTextImages)
        });
    }, [])

    const [template, setTemplate] = useState(oneStockCreate.template || "old")

    useMemo(() => {
        if(oneStockCreate.top_slider_products) {
            getchildsforslider(oneStockCreate.top_slider_products).then(response => {
                if(response){
                    setResponseTopProducts(response);
                }
            })
        }
    },[oneStockCreate.top_slider_products])

    const saveTextImgDescription = (e,index) => {
        const newTextImageDescriptions = [...oneStockCreate.text_images_desc];
        newTextImageDescriptions[index] = e.target.value;
        
        const newStockCreate = {...oneStockCreate, text_images_desc:newTextImageDescriptions};
        setOneStockCreate(newStockCreate)
    }

    useMemo(() => {
        if(oneStockCreate.bottom_slider_products) {
            getchildsforslider(oneStockCreate.bottom_slider_products).then(response => {
                if(response){
                    setResponseBottomProducts(response);
                }
            })
        }
    },[oneStockCreate.bottom_slider_products])

    const addNewImageField = (object, images, setAction) => {
        let reduceOtherImages = []
        if(images !== null) {
            reduceOtherImages = [...images];
            if (images.length) {
                let maxId = images.reduce((acc, curr) => acc.b > curr.b ? acc : curr).id;
                reduceOtherImages.push({...object, id:maxId+1});
                setAction(reduceOtherImages)
            } else {
                reduceOtherImages.push({...object, id:1});
                setAction(reduceOtherImages)
            }
        }
    }

    const uploadImage = (e, objectName, nameField, fieldType, index = null, sliderId, itemId) => {
        let newFile = e.target.files[0];
        let formData = new FormData();
        formData.append(nameField, newFile);
        addImages(formData, 'stockAndTrends', objectName, null).then(response => {
            if(response.message) {
                switch (fieldType) {
                    case 'slider': {
                        sliderImages[index].image = response.fileName;
                        let filterImages = sliderImages.filter(item => item.image)
                        setOneStockCreate({...oneStockCreate, slider_images: filterImages})
                        addNewImageField(Object.assign({}, imageTemplate), sliderImages, setSliderImages)
                        break;
                    }

                    case 'text': {
                        textImages[index].image = response.fileName;
                        let filterImages = textImages.filter(item => item.image)
                        setOneStockCreate({...oneStockCreate, text_images: filterImages})
                        addNewImageField(Object.assign({}, imageTemplate), textImages, setTextImages)
                        break;
                    }

                    case 'image': {
                        setOneStockCreate({...oneStockCreate, image: response.fileName})
                        break;

                    }
                    case 'detail_image': {
                        setOneStockCreate({...oneStockCreate, detail_image: response.fileName})
                        break;
                    }
					case 'custom_image_slider': {
						setOneStockCreate(prev => {
							let updateSliderIndex;
							const updatedSlider = prev.imageSliders.find(
								(slider, index) => {
									if (slider.id == sliderId) {
										updateSliderIndex = index;
										return slider;
									}
								}
							);
							if (!updatedSlider) return;
							const updatedItem = updatedSlider.items.find(
								(item) => item.id == itemId
							);
							updatedItem.image = response.fileName;
							return {
								...prev,
								imageSliders: [
									...prev.imageSliders.map((item) => {
										if (item.type == 'images' && item.id != updatedSlider.id) {
											return item;
										} else if (
											item.type == 'images' &&
											item.id == updatedSlider.id
										) {
											return { ...updatedSlider };
										}
									}),
								],
							};
						})
						
                        break;
					}
                }
            }
        }) 
    }

    const addProductToTopSlider = (data) => {
        if (oneStockCreate.top_slider_products && !oneStockCreate.top_slider_products.includes(data.id)) {
            let newSliderArray = [...oneStockCreate.top_slider_products, data.id];
            setOneStockCreate({...oneStockCreate, top_slider_products: newSliderArray});
        }
    }

    const addProductToBottomSlider = (data) => {
        if (!oneStockCreate.bottom_slider_products.includes(data.id)) {
            let newSliderArray = [...oneStockCreate.bottom_slider_products, data.id];
            setOneStockCreate({...oneStockCreate, bottom_slider_products: newSliderArray});
        }
    }

    const deleteOneItem = (item, type) => {
        switch (type) {
            case 'top': {
                let newSliderArray = oneStockCreate.top_slider_products.filter(id => id !== item.id);
                setOneStockCreate({...oneStockCreate, top_slider_products: newSliderArray});
                break;
            }
            case 'bottom': {
                let newSliderArray = oneStockCreate.bottom_slider_products.filter(id => id !== item.id);
                setOneStockCreate({...oneStockCreate, bottom_slider_products: newSliderArray});
                break;
            }
        }
    }

    const updateStock = () => {
		oneStockCreate.deletedSliders = deletedSliders;
        updateOneStock(oneStockCreate).then(response => {
            if (response && !response.error) {
                window.location.reload();
            }
			if(response.error) {
				console.error(response.error)
			}

        })
    }

	const deleteImageSlider = (filename, objectName, fieldType, id = null) => {
        deleteImage(filename, "stockAndTrends", objectName).then(response  => {
            switch (fieldType) {
                case 'slider': {
                    let newImages = sliderImages.filter(item => item.id !== id);
                    setOneStockCreate({...oneStockCreate, slider_images: newImages})
                    setSliderImages(newImages);
                    break;
                }

                case 'text': {
                    let newImages = textImages.filter(item => item.id !== id);
                    setOneStockCreate({...oneStockCreate, text_images: newImages})
                    setTextImages(newImages);
                    break;
                }

                case 'image': {
                    setOneStockCreate({...oneStockCreate, image: null})
                    break;
                }
                case 'detail_image': {
                    setOneStockCreate({...oneStockCreate, detail_image: null})
                    break;
                }
            }
        })
    }

    return (
		<div className={'add_one_stock_and_trend'}>
			<div className={'add_stock_and_trend_space_wrap'}>
				<span className={'page_name_add_product'}>Add new Stock or Trend</span>
				<div className={'add_stock_and_trend_space_container'}>
					<div className={'main_add_stock_and_trend_work_area'}>
						<div className={'stock_and_trend_information'}>
							<div className={'item_type_change_buttons'}>
								<span
									onClick={(e) =>
										setOneStockCreate({ ...oneStockCreate, type: 'stock' })
									}
									className={
										oneStockCreate.type === 'stock'
											? 'btn_for_control_item_type active'
											: 'btn_for_control_item_type'
									}
								>
									Акция
								</span>
								<span
									onClick={(e) =>
										setOneStockCreate({ ...oneStockCreate, type: 'trend' })
									}
									className={
										oneStockCreate.type === 'trend'
											? 'btn_for_control_item_type active'
											: 'btn_for_control_item_type'
									}
								>
									Тренд
								</span>
							</div>
							<div className={'admin_inputs_wrapper'}>
								<span className="field_info">
									<span className={'red_sup'}>*</span>Выберите шаблон
								</span>
								<div className="wrap_for_row_field">
									{' '}
									<IsActiveCheckbox
										active={template == 'new'}
										change={() => {
											const newTemplate = Object.keys(templatesMap).filter(
												(item) => item != template
											)[0];
											setTemplate(newTemplate);
											setOneStockCreate({
												...oneStockCreate,
												template: newTemplate,
											});
										}}
									/>
									<span className="admin__template-name">
										{templatesMap[template]}
									</span>
								</div>
							</div>
							<div className={'admin_inputs_wrapper'}>
								<span className={'field_info'}>
									<span className={'red_sup'}>*</span>Имя
								</span>
								<input
									className={'admin_field_styles'}
									placeholder={'Укажите имя'}
									value={oneStockCreate.name ? oneStockCreate.name : ''}
									onChange={(e) =>
										setOneStockCreate({
											...oneStockCreate,
											name: e.target.value,
										})
									}
									type="text"
									name=""
									id=""
								/>
							</div>
							<div className={'admin_inputs_wrapper'}>
								<span className={'field_info'}>
									<span className={'red_sup'}>*</span>SEO title
								</span>
								<input
									className={'admin_field_styles'}
									placeholder={'Укажите заголовок для SEO'}
									value={oneStockCreate.seo_title ? oneStockCreate.seo_title : ''}
									onChange={(e) =>
										setOneStockCreate({
											...oneStockCreate,
											seo_title: e.target.value,
										})
									}
									type="text"
									name=""
									id=""
								/>
							</div>
							<div className={'admin_inputs_wrapper'}>
								<span className={'field_info'}>
									<span className={'red_sup'}>*</span>SEO description
								</span>
								<input
									className={'admin_field_styles'}
									placeholder={'Укажите описание для SEO'}
									value={
										oneStockCreate.seo_description
											? oneStockCreate.seo_description
											: ''
									}
									onChange={(e) =>
										setOneStockCreate({
											...oneStockCreate,
											seo_description: e.target.value,
										})
									}
									type="text"
									name=""
									id=""
								/>
							</div>
							<div className={'admin_inputs_wrapper'}>
								<span className={'field_info'}>
									<span className={'red_sup'}>*</span>Текст
								</span>
								<textarea
									className={'admin_field_styles'}
									value={oneStockCreate.main_text ? oneStockCreate.main_text : ''}
									type="text"
									onChange={(e) =>
										setOneStockCreate({
											...oneStockCreate,
											main_text: e.target.value,
										})
									}
								></textarea>
							</div>

							{template == 'new' && (
								<div className={'admin_inputs_wrapper'}>
									<span className={'field_info'}>
										Изобрaжения в тексте{' '}
										<span className="field_hint_message">
											(Используйте текст формата "##IMAGE_X##" для вставки в
											текст с новой строчки и "##IMAGE_INLINE_X##" для вставки
											несколько фото в ряд)
										</span>
									</span>
									<div className="stock_slider_images">
										{textImages.map((item, index) => {
											console.log(oneStockCreate.text_images_desc[index],oneStockCreate.text_images_desc,index,'lksjdflkjsdlf')
											return (
												<div className="stock_slider_one_image">
													<span className="stock_slider_insert_in_text_hint">
														##IMAGE_{index}##
													</span>
													<span className="stock_slider_insert_in_text_hint">
														##IMAGE_INLINE_{index}##
													</span>
													<OneImageField
														text={'Изображение'}
														image={
															item.image
																? process.env.REACT_APP_API_URL +
																  'uploads/images/stockAndTrends/' +
																  oneStockCreate.id +
																  '/' +
																  item.image
																: null
														}
														onChange={(e) =>
															uploadImage(
																e,
																oneStockCreate.id,
																'image',
																'text',
																index
															)
														}
														deleteImage={(e) =>
															deleteImageSlider(
																item.image,
																oneStockCreate.id,
																'text',
																item.id
															)
														}
													/>
													<input
														type="text"
														className="admin_field_styles stock_and_trend_text_image_descr"
														placeholder="Подпись под фото"
														defaultValue={
															oneStockCreate.text_images_desc[index].replace(/\[|\]/,'')
														}
														name="text_image_description"
														onBlur={(e) =>
															saveTextImgDescription(e, index)
														}
													/>
												</div>
											);
										})}
									</div>
								</div>
							)}

							<div className="wrapper_top_images_stock_container">
								<div className={'wrapper_two_images_stock'}>
									<OneImageField
										text={'Фото в слайдере'}
										image={
											oneStockCreate.image
												? process.env.REACT_APP_API_URL +
												  'uploads/images/stockAndTrends/' +
												  oneStockCreate.id +
												  '/' +
												  oneStockCreate.image
												: null
										}
										onChange={(e) =>
											uploadImage(e, oneStockCreate.id, 'image', 'image')
										}
										deleteImage={(e) =>
											deleteImageSlider(
												oneStockCreate.image,
												oneStockCreate.id,
												'image'
											)
										}
									/>
									<OneImageField
										text={'Фото на детальной'}
										image={
											oneStockCreate.detail_image
												? process.env.REACT_APP_API_URL +
												  'uploads/images/stockAndTrends/' +
												  oneStockCreate.id +
												  '/' +
												  oneStockCreate.detail_image
												: null
										}
										onChange={(e) =>
											uploadImage(
												e,
												oneStockCreate.id,
												'image',
												'detail_image'
											)
										}
										deleteImage={(e) =>
											deleteImageSlider(
												oneStockCreate.detail_image,
												oneStockCreate.id,
												'detail_image'
											)
										}
									/>
								</div>
							</div>
							<div className={'admin_inputs_wrapper'}>
								<div className={'wrap_for_row_field'}>
									<span className={'field_info'}>
										<span className={'red_sup'}>*</span> Включить слайдер
										изображений{' '}
									</span>
									<IsActiveIndicator
										active={oneStockCreate.main_slider_active}
										change={(e) =>
											setOneStockCreate({
												...oneStockCreate,
												main_slider_active:
													!oneStockCreate.main_slider_active,
											})
										}
									/>
								</div>
							</div>

							<div className={'admin_inputs_wrapper'}>
								<span className={'field_info'}>
									Изобрaжения слайдера на детальной странице
								</span>
								<div className="stock_slider_images">
									{sliderImages.map((item, index) => {
										return (
											<div className="stock_slider_one_image">
												<OneImageField
													text={'Изображение'}
													image={
														item.image
															? process.env.REACT_APP_API_URL +
															  'uploads/images/stockAndTrends/' +
															  oneStockCreate.id +
															  '/' +
															  item.image
															: null
													}
													onChange={(e) =>
														uploadImage(
															e,
															oneStockCreate.id,
															'image',
															'slider',
															index
														)
													}
													deleteImage={(e) =>
														deleteImageSlider(
															item.image,
															oneStockCreate.id,
															'slider',
															item.id
														)
													}
												/>
											</div>
										);
									})}
								</div>
							</div>
							<div className={'admin_inputs_wrapper'}>
								<div className={'wrap_for_row_field'}>
									<span className={'field_info'}>
										<span className={'red_sup'}>*</span> Включить верхний
										слайдер{' '}
									</span>
									<IsActiveIndicator
										active={oneStockCreate.top_slider_active}
										change={(e) =>
											setOneStockCreate({
												...oneStockCreate,
												top_slider_active:
													!oneStockCreate.top_slider_active,
											})
										}
									/>
								</div>
								<input
									class="admin_field_styles admin_field_styles--short"
									placeholder="Укажите название слайдера"
									type="text"
									defaultValue={oneStockCreate.top_slider_name}
									onBlur={updateTopSliderName}
								/>
							</div>
							<SearchChildProductArea addProduct={addProductToTopSlider} />
							<div className={'products_in_slider'}>
								{responseTopProducts
									? responseTopProducts.map((item, index) => {
											return (
												<div
													className={'one_slide_product_wrapper'}
													key={item.id}
												>
													<div className={'one_slide_img_wrapper'}>
														<img
															src={
																process.env.REACT_APP_API_URL +
																'uploads/images/' +
																item.product.id +
																'/' +
																item.id +
																'/214_' +
																item.img_preview
															}
															alt=""
														/>
													</div>
													<span>{item.name}</span>
													<span
														onClick={(e) => deleteOneItem(item, 'top')}
														className={'destroy_one_slide'}
													>
														Удалить
													</span>
												</div>
											);
									  })
									: ''}
							</div>
							<div className={'admin_inputs_wrapper'}>
								<div className={'wrap_for_row_field'}>
									<span className={'field_info'}>
										<span className={'red_sup'}>*</span> Включить нижний слайдер{' '}
									</span>
									<IsActiveIndicator
										active={oneStockCreate.bottom_slider_active}
										change={(e) =>
											setOneStockCreate({
												...oneStockCreate,
												bottom_slider_active:
													!oneStockCreate.bottom_slider_active,
											})
										}
									/>
								</div>
							</div>
							<input
								class="admin_field_styles admin_field_styles--short"
								placeholder="Укажите название слайдера"
								type="text"
								defaultValue={oneStockCreate.bottom_slider_name}
								onBlur={updateBottomSliderName}
							/>
							<SearchChildProductArea addProduct={addProductToBottomSlider} />
							<div className={'products_in_slider'}>
								{responseBottomProducts
									? responseBottomProducts.map((item, index) => {
											return (
												<div
													className={'one_slide_product_wrapper'}
													key={item.id}
												>
													<div className={'one_slide_img_wrapper'}>
														<img
															src={
																process.env.REACT_APP_API_URL +
																'uploads/images/' +
																item.product.id +
																'/' +
																item.id +
																'/214_' +
																item.img_preview
															}
															alt=""
														/>
													</div>
													<span>{item.name}</span>
													<span
														onClick={(e) =>
															deleteOneItem(item, 'bottom')
														}
														className={'destroy_one_slide'}
													>
														Удалить
													</span>
												</div>
											);
									  })
									: ''}
							</div>

							{template == 'new' && (
								<AdmManagedSliders
									item_id={oneStockCreate.id}
									uploadImage={uploadImage}
									deleteOneItem={deleteOneItem}
									oneStockCreate={oneStockCreate}
									setOneStockCreate={setOneStockCreate}
									sliders={sliders}
									setSliders={setSliders}
									setDeletedSliders={setDeletedSliders}
								/>
							)}
						</div>

						<div className={'save_wrapper_info'}>
							<div className="save_informer"></div>
							<span onClick={(e) => updateStock()} className={'save_btn'}>
								Сохранить
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddOneStockOrTrend;
