import React from 'react';
import {Link} from "react-router-dom";


const OneMenuItem = (props) => {


    const currentRoute = props.item.slug ? "/catalog/"+props.item.slug+"/" : "/catalog/"


    return (

            <Link
                className=
                    {window.location.pathname == currentRoute ? "menu_item active" : "menu_item"}
                onMouseEnter={e => props.onMouseEnter(props.item)}
                  // onMouseLeave={e => props.onMouseLeave(props.item)}
                style={{fontWeight:props.item.weight}}
                to={props.item.slug ? "/catalog/"+props.item.slug+"/" : "/catalog/"}>
                {props.item.name}
            </Link>

    );
};

export default OneMenuItem;