import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Context } from '../../index';

import './SharesInfo.css';

const SharesInfo = observer(() => {
	const { settings } = useContext(Context);
	const workingList = [
		{
			id: 1,
			number: 1,
			text: 'сформируйте корзину с покупками и на этапе оформления заказа выберите способ оплаты долями',
		},
		{
			id: 2,
			number: 2,
			text: 'укажите номер телефона и данные карты',
		},
		{
			id: 3,
			number: 3,
			text: 'оплатите 25% стоимости покупки картой любого банка',
		},
		{
			id: 4,
			number: 4,
			text: 'оставшиеся 3 части спишутся автоматически с шагом в две недели',
		},
	];
	
	return (
		<>
			<Helmet>
				<title>Оплата долями без комиссий и переплат</title>
				<meta
					name="description"
					content={'Оплатите покупку 4 долями. Заберите сразу, а платите частями каждые две недели. Без комиссий и переплат. YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07.'}
				/>
			</Helmet>
			
			<div
				style={{ paddingTop: settings.headerHeight }}
				className={'shares'}
			>
				<section className="shares__main">
					<div className="shares__container">
						<div className="shares__mainWrapper">
							<div className="shares__mainImage">
								<img
									src="/files/images/shares/logo.png"
									srcSet="/files/images/shares/logo@2x.png 2x"
									width="738"
									height="590"
									alt="Логотип YouWanna"
								/>
							</div>
							<div className="shares__mainContent">
								<div>
									<img
										className="shares__collaboration"
										src="/files/images/shares/collaboration.svg"
										width="345"
										height="41"
										alt="YouWanna и Долями"
									/>
								</div>
								<h1 className="shares__mainTitle">
									<SvgArrowTitle/> Оплачивайте <br/>
									покупку по&nbsp;частям
								</h1>
								<p className="shares__text">
									получайте заказы сразу, а&nbsp;платите за&nbsp;них постепенно без процентов
									и&nbsp;переплат
								</p>
							</div>
						</div>
					</div>
				</section>
				<section className="shares__working">
					<div className="shares__container">
						<h2 className="shares__title">
							<SvgArrowTitle isBlack/> Как это работает
						</h2>
						<div className="shares__workingWrapper">
							{workingList.map(({
								id,
								number,
								text,
							}) => (
								<article key={id} className="shares__workingItem">
									<div className="shares__workingNumber">{number}</div>
									<p className="shares__text">{text}</p>
								</article>
							))}
						</div>
					</div>
				</section>
				<section className="shares__stages">
					<div className="shares__container">
						<div className="shares__stagesList">
							<article className="shares__stagesItem">
								<div className="shares__stagesHead">
									<div className="shares__stagesIcon">
										<svg xmlns="http://www.w3.org/2000/svg"
										     width="16"
										     height="16"
										     viewBox="0 0 16 16"
										     fill="none">
											<path d="M15.5258 8.78524L1.47531 15.8899C0.843034 16.1988 0 15.8281 0 15.1485V0.877484C0 0.19791 0.843034 -0.234553 1.47531 0.136123L15.5258 7.24076C16.1581 7.61143 16.1581 8.47635 15.5258 8.78524Z"
											      fill="white"/>
										</svg>
									</div>
									25%
								</div>
								<h3 className="shares__text shares__stagesTitle">
									списывание первого платежа, отправка заказа
								</h3>
								<p className="shares__text shares__stagesText">
									платёж сегодня
								</p>
							</article>
							<article className="shares__stagesItem">
								<div className="shares__stagesHead">
									<div className="shares__stagesIcon">
										<svg xmlns="http://www.w3.org/2000/svg"
										     width="16"
										     height="16"
										     viewBox="0 0 16 16"
										     fill="none">
											<path d="M14.8449 0.0761719C14.1562 0.0761719 13.6206 0.60951 13.6206 1.29522V8.99047C13.6206 10.0571 12.3197 10.5905 11.5545 9.82857L2.06605 0.380952C1.83649 0.152381 1.53038 0 1.2243 0C0.535622 0 0 0.533315 0 1.21903C0 1.52379 0.15304 1.82856 0.3826 2.05713L9.87111 11.5047C10.6363 12.2666 10.1006 13.5619 9.02936 13.5619H1.2243C0.535622 13.5619 0 14.0952 0 14.7809C0 15.4667 0.535622 16 1.2243 16H14.7684C15.457 16 15.9927 15.4667 15.9927 14.7809V1.29522C16.0692 0.60951 15.5336 0.0761719 14.8449 0.0761719Z"
											      fill="white"/>
										</svg>
									</div>
									25%
								</div>
								<h3 className="shares__text shares__stagesTitle">
									автоматический платёж по&nbsp;реквизитам
								</h3>
								<p className="shares__text shares__stagesText">
									через 2&nbsp;недели
								</p>
							</article>
							<article className="shares__stagesItem">
								<div className="shares__stagesHead">
									<div className="shares__stagesIcon">
										<svg xmlns="http://www.w3.org/2000/svg"
										     width="16"
										     height="16"
										     viewBox="0 0 16 16"
										     fill="none">
											<path d="M14.8449 0.0761719C14.1562 0.0761719 13.6206 0.60951 13.6206 1.29522V8.99047C13.6206 10.0571 12.3197 10.5905 11.5545 9.82857L2.06605 0.380952C1.83649 0.152381 1.53038 0 1.2243 0C0.535622 0 0 0.533315 0 1.21903C0 1.52379 0.15304 1.82856 0.3826 2.05713L9.87111 11.5047C10.6363 12.2666 10.1006 13.5619 9.02936 13.5619H1.2243C0.535622 13.5619 0 14.0952 0 14.7809C0 15.4667 0.535622 16 1.2243 16H14.7684C15.457 16 15.9927 15.4667 15.9927 14.7809V1.29522C16.0692 0.60951 15.5336 0.0761719 14.8449 0.0761719Z"
											      fill="white"/>
										</svg>
									</div>
									25%
								</div>
								<h3 className="shares__text shares__stagesTitle">
									автоматический платёж по&nbsp;реквизитам
								</h3>
								<p className="shares__text shares__stagesText">
									через 4&nbsp;недели
								</p>
							</article>
							<article className="shares__stagesItem">
								<div className="shares__stagesHead">
									<div className="shares__stagesIcon">
										<svg xmlns="http://www.w3.org/2000/svg"
										     width="16"
										     height="16"
										     viewBox="0 0 16 16"
										     fill="none">
											<path d="M8 16C3.55555 16 0 12.381 0 8.00001C0 3.61905 3.61904 0 8 0C12.381 0 16 3.61905 16 8.00001C16 12.381 12.4445 16 8 16ZM8 1.90476C4.63491 1.90476 1.90477 4.63493 1.90477 8.00001C1.90477 11.3651 4.63491 14.0952 8 14.0952C11.3651 14.0952 14.0952 11.3651 14.0952 8.00001C14.0952 4.63493 11.3651 1.90476 8 1.90476Z"
											      fill="white"/>
											<path d="M7.99996 10C9.10453 10 10 9.10458 10 8.00001C10 6.89544 9.10453 6 7.99996 6C6.8954 6 6 6.89544 6 8.00001C6 9.10458 6.8954 10 7.99996 10Z"
											      fill="white"/>
										</svg>
									</div>
									25%
								</div>
								<h3 className="shares__text shares__stagesTitle">
									автоматический платёж по&nbsp;реквизитам
								</h3>
								<p className="shares__text shares__stagesText">
									через 6&nbsp;недели
								</p>
							</article>
						</div>
					</div>
				</section>
				
				<section className="shares__faq">
					<div className="shares__container">
						<h2 className="shares__title">
							Ответы на&nbsp;частые вопросы
						</h2>
						<div className="shares__faqList">
							<article className="shares__faqItem">
								<h3 className="shares__faqItemTitle">Нужна&nbsp;ли регистрация в&nbsp;сервисе?</h3>
								<p className="shares__text shares__faqText">
									Нет, отдельная регистрация не&nbsp;нужна. После совершения покупки вы&nbsp;будете
									автоматически зарегистрированы в&nbsp;приложении сервиса по&nbsp;номеру телефона.
									Далее, по&nbsp;этому номеру вы&nbsp;сможете войти в&nbsp;мобильное приложение, чтобы
									отслеживать следующие платежи.
								</p>
							</article>
							<article className="shares__faqItem">
								<h3 className="shares__faqItemTitle">Как купить товар?</h3>
								<p className="shares__text shares__faqText">
									Выберите товар в&nbsp;каталоге нашего интернет-магазина, добавьте его в&nbsp;корзину
									и&nbsp;переходите к&nbsp;оплате. На&nbsp;странице оформления заказа выберите
									способ &laquo;оплатить долями&raquo; и&nbsp;совершите покупку как при оплате обычной
									картой.
								</p>
							</article>
							<article className="shares__faqItem">
								<h3 className="shares__faqItemTitle">
									Кому задавать вопросы после совершения покупки? Интернет-магазину или сервису?
								</h3>
								<p className="shares__text shares__faqText">
									После совершения оплаты данным способом коммуникация по&nbsp;работе сервиса
									происходит напрямую с&nbsp;клиентским отделом &laquo;Долями&raquo;. В&nbsp;случае
									вопросов по&nbsp;качеству самого товара вы&nbsp;можете обратиться к&nbsp;менеджеру
									нашего интернет-магазина.
								</p>
							</article>
							<article className="shares__faqItem">
								<h3 className="shares__faqItemTitle">
									Есть&nbsp;ли плата за&nbsp;использование сервиса?
								</h3>
								<p className="shares__text shares__faqText">
									Нет, сервис не&nbsp;берет процентов или комиссий. Вы&nbsp;заплатите ровно сумму
									покупки, разделённую на&nbsp;4&nbsp;части с&nbsp;шагом в&nbsp;2&nbsp;недели. Перед
									списанием каждой части вам будет выслано напоминание о&nbsp;дате списания.
								</p>
							</article>
							<article className="shares__faqItem">
								<h3 className="shares__faqItemTitle">
									Это кредит? Проверяете&nbsp;ли вы&nbsp;кредитную историю?
								</h3>
								<p className="shares__text shares__faqText">
									Нет, это не&nbsp;кредит. Сервис не&nbsp;проверяет кредитную историю
									и&nbsp;не&nbsp;заключает кредитный договор с&nbsp;покупателем. В&nbsp;Долями нет
									процентной ставки&nbsp;&mdash; вы&nbsp;платите только сумму покупки, разделенную
									на&nbsp;4&nbsp;части.
								</p>
							</article>
						</div>
						<img
							className="shares__collaboration"
							src="/files/images/shares/collaboration.svg"
							width="345"
							height="41"
							alt="YouWanna и Долями"
						/>
					</div>
				</section>
			</div>
		</>
	);
});

const SvgArrowTitle = ({ isBlack }) => {
	return (
		<svg
			className={isBlack ? 'shares__arrow _black' : 'shares__arrow'}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path d="M22.2674 0.114258C21.2343 0.114258 20.4309 0.914265 20.4309 1.94284V13.4857C20.4309 15.0857 18.4796 15.8857 17.3318 14.7429L3.09901 0.571429C2.75467 0.228571 2.29557 0 1.83645 0C0.803433 0 0 0.799972 0 1.82854C0 2.28569 0.22956 2.74284 0.5739 3.08569L14.8067 17.2571C15.9545 18.4 15.151 20.3428 13.5441 20.3428H1.83645C0.803433 20.3428 0 21.1429 0 22.1714C0 23.2 0.803433 24 1.83645 24H22.1526C23.1856 24 23.9891 23.2 23.9891 22.1714V1.94284C24.1038 0.914265 23.3004 0.114258 22.2674 0.114258Z"/>
		</svg>
	);
};

export default SharesInfo;