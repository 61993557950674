import React, {useEffect, useState} from 'react';
import './oneDeliveryMethod.css';
import IsActiveIndicator from "../../../UI/isActiveIndicator/IsActiveIndicator";
import {
    changeActiveStatusOneDelivery,
    deleteDeliveryMethod,
    saveNewContentDelivery
} from "../../../../../http/settingsApi";
import CompliteRemove from "../../../UI/compliteremove/CompliteRemove";

const OneDeliveryMethod = (props) => {

    const [thisDeliveryContent, setThisDeliveryContent] = useState(null)

    useEffect(() => {
        setThisDeliveryContent(props.item)
    }, [props.allDeliveryMethods])

    const thisDeliveryContentHandler = (item) => {

        if(thisDeliveryContent.payment_type.includes(item.id)) {
            //Есть, удаляем
            let index = thisDeliveryContent.payment_type.lastIndexOf(item.id);
            let reducePayments = thisDeliveryContent.payment_type;
            reducePayments.splice(index,1);
            setThisDeliveryContent({...thisDeliveryContent, paymentMethods:reducePayments})

        } else {
            // Нету, добавляем
            let reducePayments = thisDeliveryContent.payment_type;
            reducePayments.push(item.id);
            setThisDeliveryContent({...thisDeliveryContent, paymentMethods:reducePayments})
        }

    }

    const [activeSaveButton, setActiveSaveButton] = useState(true);
    const [responseServerText, setResponseServerText] = useState("")
    const deliveryMinTerms = [
        {id: 0, key: "В день заказа", value: 0},
        {id: 1, key: "На следующий день",  value: 1},
        {id: 2, key: "Через 2 дня",  value: 2},
        {id: 3, key: "От 3 дней",  value: 3},
    ]
    const deliveryTypes = [
        {id: 0, key: "Есть ПВЗ", value: true},
        {id: 1, key: "Без ПВЗ",  value: false},
    ]
    const deliverySellTypes = [
        {id: 0, key: "Скидка при покупке нескольких товаров", value: "items"},
        {id: 1, key: "Скидка при покупке от суммы",  value: "price"},
        {id: 2, key: "Без скидки",  value: "no"},
    ]

    const saveNewContentDeliveryHandler = (fields) => {
        setActiveSaveButton(false)
        saveNewContentDelivery(fields).then(response => {
            if(response.error) {
                setResponseServerText(response.error)
                setActiveSaveButton(true);
                return false;
            }

            if (response.message) {
                setResponseServerText(response.message)
                setTimeout(() => {
                    setResponseServerText("")
                }, 4000)
            }
            props.setRefreshList(!props.refreshList)
            setActiveSaveButton(true);

        })
    }

    const changeActiveDeliveryHandler = (id, status) => {
        changeActiveStatusOneDelivery(id, status).then(response => {
            if (!response.error) {
                props.setRefreshList(!props.refreshList)
            }
        })
    }

    const [openDelete, setOpenDelete] = useState(false);

    const destroyOneDeliveryHandler = (item) => {
        setOpenDelete(false)
        if(item.id) {
            //sad
            deleteDeliveryMethod(item).then(response => {
                props.setRefreshList(!props.refreshList)
            })
        }
    }

    function listDeliveryTypes() {

        return <select
                className={'status_refund'}
                onChange={e => setThisDeliveryContent({
                    ...thisDeliveryContent,
                    hasPickupLocations: e.target.value
                })}
                value={thisDeliveryContent.hasPickupLocations}>
                {deliveryTypes.map((m, ix) => {
                    return <option
                        key={m.id}
                        value={m.value}
                    >
                        {m.key}
                    </option>
                })};
            </select>
    }

    function listDeliveryMinTerms() {

        return <select
                className={'status_refund'}
                onChange={(e) => setThisDeliveryContent({...thisDeliveryContent, min_delivery_term: e.target.value})}
                value={thisDeliveryContent.min_delivery_term}>
                {deliveryMinTerms.map((m, ix) => {
                    return <option
                        key={m.id}
                        value={m.value}
                    >
                        {m.key}
                    </option>
                })};
            </select>
    }

    function listDeliverySellTypes() {
        return <select
                className={'sell_type'}
                onChange={(e) => setThisDeliveryContent({...thisDeliveryContent, delivery_sell_type: e.target.value})}
                value={thisDeliveryContent.delivery_sell_type}>
                {deliverySellTypes.map((m, ix) => {
                    return <option
                        key={m.id}
                        value={m.value}
                    >
                        {m.key}
                    </option>
                })};
            </select>
    }

    return (
        <div>
            {
                thisDeliveryContent ?
                    <div className={"one_delivery_method_component"}>
                        <CompliteRemove open={openDelete} canselRemove={e => setOpenDelete(false)}  goRemove={ e => destroyOneDeliveryHandler(thisDeliveryContent)}/>
                        <div className={"one_delivery_top_line"}>
                            <div className={"delivery_id_container_info"}>ID: {" " + props.item.id + " "}</div>
                            <div
                                onClick={e => changeActiveDeliveryHandler(thisDeliveryContent.id, !thisDeliveryContent.is_active)}
                                className={"one_delivery_active_controller"}>
                                <IsActiveIndicator active={thisDeliveryContent.is_active}/>
                            </div>
                            <div className={"area_or_city_dilivery"}>
                                Цель: {" " + thisDeliveryContent.target + " "}
                            </div>
                            <div className={"delivery_current_target_name"}>
                                Название: {" " + thisDeliveryContent.target_value + " "}
                            </div>
                            <div className={"delivery_current_target_name"}>
                                ISO: {" " + thisDeliveryContent.country_iso_code + " "}
                            </div>
                        </div>
                        <div className={"one_delivery_method_support_wrapper"}>
                            <div className={"one_delivery_containers_for_content_wrapper"}>
                                <div className={"one_delivery_one_field_wrapper"}>
                                    <span className={"one_delivery_name_field_text"}>Название доставки:</span>
                                    <input
                                        value={thisDeliveryContent.name}
                                        onChange={e => setThisDeliveryContent({
                                            ...thisDeliveryContent,
                                            name: e.target.value
                                        })}
                                        className={"one_delivery_standard_input"} type="text"/>
                                </div>
                                <div className={"one_delivery_one_field_wrapper"}>
                                    <span className={"one_delivery_name_field_text"}>Информ. текст:</span>
                                    <input
                                        value={thisDeliveryContent.delivery_text}
                                        onChange={e => setThisDeliveryContent({
                                            ...thisDeliveryContent,
                                            delivery_text: e.target.value
                                        })}
                                        className={"one_delivery_standard_input"} type="text"/>
                                </div>
                                <div className={"one_delivery_one_field_wrapper"}>
                                    <span className={"one_delivery_name_field_text"}>Доп. цена:</span>
                                    <input
                                        value={thisDeliveryContent.dop_price}
                                        onChange={e => setThisDeliveryContent({
                                            ...thisDeliveryContent,
                                            dop_price: e.target.value
                                        })}
                                        className={"one_delivery_standard_input"} type="text"/>
                                </div>
                                <div className={"one_delivery_one_field_wrapper"}>
                                <span
                                    className={"one_delivery_name_field_text"}>Негатив. доп. прайс (невыкуп и т.д):</span>
                                    <input
                                        value={thisDeliveryContent.negative_dop_price}
                                        onChange={e => setThisDeliveryContent({
                                            ...thisDeliveryContent,
                                            negative_dop_price: e.target.value
                                        })}
                                        className={"one_delivery_standard_input"} type="text"/>
                                </div>
                                <div className={"one_delivery_one_field_wrapper"}>
                                    <span className={"one_delivery_name_field_text"}>Текст после цены:</span>
                                    <input
                                        value={thisDeliveryContent.after_price_text}
                                        onChange={e => setThisDeliveryContent({
                                            ...thisDeliveryContent,
                                            after_price_text: e.target.value
                                        })}
                                        className={"one_delivery_standard_input"} type="text"/>
                                </div>


                                <div className={"one_delivery_one_field_wrapper"}>
                                    <span className={"one_delivery_name_field_text"}>RetailCRM code:</span>
                                    <input
                                        value={thisDeliveryContent.retailCrmCode ? thisDeliveryContent.retailCrmCode : ""}
                                        onChange={e => setThisDeliveryContent({
                                            ...thisDeliveryContent,
                                            retailCrmCode: e.target.value
                                        })}
                                        className={"one_delivery_standard_input"} type="text"/>
                                </div>
                                <div className={"one_delivery_one_field_wrapper"}>
                                    <span className={"one_delivery_name_field_text"}>Тип доставки (imshop)</span>
                                    {listDeliveryTypes()}
                                </div>
                                <div className={"one_delivery_one_field_wrapper"}>
                                    <span className={"one_delivery_name_field_text"}>Минимальный срок доставки (imshop)</span>
                                    {listDeliveryMinTerms()}
                                </div>
                                <div className={"response_server_text_delivery"}>{responseServerText}</div>
                                <div className={"delivery_controll_buttons"}>
                                    <div
                                        onClick={e => saveNewContentDeliveryHandler(thisDeliveryContent)}
                                        className={activeSaveButton ? "delivery_save_controller" : "delivery_save_controller disactive"}>
                                        Сохранить
                                    </div>

                                    <div
                                        onClick={ e => setOpenDelete(true)}

                                        className={"delete_button_one_delivery"}
                                    >Удалить
                                    </div>
                                </div>
                            </div>
                            <div className={"one_delivery_method_settings_wrapper"}>
                                <div className={"add_new_delivery_integration"}>

                                    <span className={"add_new_delivery_integration_heading"}>Область с привязкой интеграций к доставкам.</span>
                                    <div className={"active_delivery_integrations"}>
                                        <div
                                            onClick={e => setThisDeliveryContent({...thisDeliveryContent, delivery_integration: !thisDeliveryContent.delivery_integration, delivery_type: "",delivery_map:false})}
                                            className={thisDeliveryContent.delivery_integration ? "active_delivery_integrations_check active" : "active_delivery_integrations_check" }> </div>
                                        <span className={"active_delivery_integrations_info"}>Включая, у вас появятся доступные интеграции. </span>
                                    </div>
                                    <div className={"delivery_map_controller_check"}>
                                        <div
                                            onClick={e => setThisDeliveryContent({...thisDeliveryContent, delivery_map: !thisDeliveryContent.delivery_map })}
                                            className={thisDeliveryContent.delivery_map ? "active_delivery_integrations_check active" : "active_delivery_integrations_check" }> </div>
                                        <span className={"active_delivery_integrations_info"}>Чтобы убрать поля "куда привозить" и включить "карту" </span>
                                    </div>
                                    {
                                        thisDeliveryContent.delivery_integration && !thisDeliveryContent.show_room ?

                                            <div className={"delivery_itegration_list"}>
                                                <div
                                                    onClick={ e => setThisDeliveryContent({...thisDeliveryContent, delivery_type:"sdek_courier"})}
                                                    className={thisDeliveryContent.delivery_type === "sdek_courier" ?"delivery_one_method_board active" : "delivery_one_method_board"}>
                                                    SDEK ПВЗ-ДВЕРЬ
                                                </div>
                                                <div
                                                    onClick={ e => setThisDeliveryContent({...thisDeliveryContent, delivery_type:"sdek_pvz"})}
                                                    className={thisDeliveryContent.delivery_type === "sdek_pvz" ?"delivery_one_method_board active" : "delivery_one_method_board"}>
                                                    SDEK ПВЗ-ПВЗ
                                                </div>
                                                <div
                                                    onClick={ e => setThisDeliveryContent({...thisDeliveryContent, delivery_type:"yandex_courier"})}
                                                    className={thisDeliveryContent.delivery_type === "yandex_courier" ? "delivery_one_method_board active" : "delivery_one_method_board"}>
                                                    Yandex ПВЗ-ДВЕРЬ
                                                </div>
                                                <div
                                                    onClick={ e => setThisDeliveryContent({...thisDeliveryContent, delivery_type:"yandex_pvz"})}
                                                    className={thisDeliveryContent.delivery_type === "yandex_pvz" ? "delivery_one_method_board active" : "delivery_one_method_board"}>
                                                    Yandex ПВЗ-ПВЗ
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    <span className={"add_new_delivery_integration_heading"}>Самовывоз из Шоу-рума.</span>
                                    <div className={"active_delivery_integrations"}>
                                        <div
                                            onClick={e => setThisDeliveryContent({...thisDeliveryContent, delivery_integration: false, show_room: !thisDeliveryContent.show_room, delivery_type: "",delivery_map:false})}
                                            className={thisDeliveryContent.show_room ? "active_delivery_integrations_check active" : "active_delivery_integrations_check" }> </div>
                                        <span className={"active_delivery_integrations_info"}>Чтобы убрать "куда привозить"</span>
                                    </div>

                                </div>

                                <div className={"wrapper_for_change_payments"}>
                                    { props.paymentMethods ?
                                        <div className={"payment_methods_front_wrap"}>
                                            {
                                                props.paymentMethods.map((item, index) => {
                                                    return (
                                                        <div
                                                            onClick={e => thisDeliveryContentHandler(item)}
                                                            className={ thisDeliveryContent.payment_type.includes(item.id) ? "one_pay_method_button active" : "one_pay_method_button"}
                                                            key={item.id}>
                                                            {item.name}
                                                        </div>)
                                                })
                                            }
                                        </div>

                                        : <div className={"no_payment_methods"}>Вероятно, это ошибка, либо мы не смогли найти методы оплаты.</div>
                                    }
                                </div>

                                <div className={"add_new_delivery_integration"}>

                                    <span className={"add_new_delivery_integration_heading"}>Область c условиями скидки на доставку.</span>
                                    <div className={"one_delivery_one_field_wrapper"}>
                                        <span className={"one_delivery_name_field_text"}>Тип скидки на доставку</span>
                                        {listDeliverySellTypes()}
                                    </div>
                                    <div className={"one_delivery_one_field_wrapper"}>
                                        <span className={"one_delivery_name_field_text"}>Условие скидки на доставку: Цена/Количество товаров</span>
                                        <input
                                            value={thisDeliveryContent.sell_condition}
                                            onChange={e => setThisDeliveryContent({
                                                ...thisDeliveryContent,
                                                sell_condition: e.target.value
                                            })}
                                            className={"one_delivery_sell_input" + (thisDeliveryContent.delivery_sell_type === "no" ? " disactive" : "")} type="text"/>
                                    </div>
                                    <div className={"one_delivery_one_field_wrapper"}>
                                        <span className={"one_delivery_name_field_text"}>Цена доставки со кидкой</span>
                                        <input
                                            value={thisDeliveryContent.sell_price}
                                            onChange={e => setThisDeliveryContent({
                                                ...thisDeliveryContent,
                                                sell_price: e.target.value
                                            })}
                                            className={"one_delivery_sell_input" + (thisDeliveryContent.delivery_sell_type === "no" ? " disactive" : "")} type="text"/>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    : ""
            }
        </div>
    );
};

export default OneDeliveryMethod;