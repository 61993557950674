import React from 'react';
import './AbsoluteShadow.css'

const AbsoluteShadow = (props) => {

    return (
        <div className={props.shadow ? "shadow light" : "shadow"}>

        </div>
    );
};

export default AbsoluteShadow;