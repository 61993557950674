import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Fashions.scss";
import { observer } from "mobx-react-lite";
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import { Context } from "../../../index";
import Helmet from "react-helmet";
import Masonry from "react-masonry-component";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { fetchAllImages } from "../../../http/contentApi";

const masonryOptions = {
  transitionDuration: 0,
};

const COUNT = 8;

const Fashions = observer(() => {
  const location = useLocation();

  const { settings } = useContext(Context);
  const [fashions, setFashions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    count: 0,
    offset: 0,
  });

  useEffect(() => {
    setLoading(true);
    fetchAllImages(COUNT, pagination.offset, undefined, true).then(
      (response) => {
        if (response) {
          setFashions(response.rows);
          setPagination({
            count: response.count,
            offset: pagination.offset + COUNT,
          });
          setLoading(false);
        }
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        !loading &&
        document.querySelector(".js-fashions-list").getBoundingClientRect()
          .bottom <
          window.innerHeight * 1.5
      ) {
        if (fashions.length < pagination.count) {
          setPagination({
            ...pagination,
            offset: pagination.offset + COUNT,
          });
          setLoading(true);
          fetchAllImages(COUNT, pagination.offset, undefined, true).then(
            (response) => {
              if (response) {
                setFashions([...fashions, ...response.rows]);
                setPagination({
                  ...pagination,
                  count: response.count,
                });
                setLoading(false);
              }
            }
          );
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fashions, loading, pagination]);

  const elements = useMemo(() => {
    return fashions
      .filter((item) => {
        const set = JSON.parse(item.set);

        if (!set || !set.length) {
          return false;
        }

        return true;
      })
      .map((item) => (
        <li key={item.id} className="fashions__item _vertical">
          <Link
            className="fashions__link"
            to={`/fashions/${item.id}/`}
            state={{ backgroundLocation: location }}
          >
            <svg
              className="fashions__item-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M24.8003 25.6001H7.20068V9.95786C7.20068 9.09906 7.89941 8.40018 8.75844 8.40018H10.8006V13.4746L16.1963 11.9839L21.2005 13.4644V8.40002H23.2429C24.1016 8.40002 24.8004 9.0989 24.8004 9.9577L24.8003 25.6001ZM8.00076 24.8H24.0006L24.0005 9.95786C24.0005 9.54018 23.6607 9.20026 23.243 9.20026H22.0006V14.5358L16.1889 12.8162L10.0006 14.5258V9.20026H8.75844C8.3406 9.20026 8.00068 9.54018 8.00068 9.95786L8.00076 24.8Z"
                fill="black"
              />
              <path
                d="M11.4887 13.9588C11.1829 13.9588 10.8854 13.864 10.6278 13.6805C10.2286 13.3962 10 12.9522 10 12.4625V7.89546C10 7.07093 10.6709 6.40002 11.4958 6.40002H20.5043C21.329 6.40002 21.9998 7.0709 21.9998 7.89546V12.4265C21.9998 12.9155 21.7605 13.3743 21.359 13.6538C20.9578 13.9332 20.4442 13.999 19.9856 13.8293L16.0013 12.3628L11.994 13.8723C11.8264 13.9303 11.6564 13.9588 11.4887 13.9588ZM11.4957 7.20034C11.1121 7.20034 10.8 7.51228 10.8 7.89576V12.4628C10.8 12.6906 10.9066 12.8969 11.0921 13.0292C11.2777 13.1617 11.5072 13.1951 11.723 13.1202L15.9984 11.5097L20.2624 13.0793C20.48 13.1595 20.7127 13.1299 20.9018 12.9979C21.0914 12.8659 21.1999 12.6579 21.1999 12.427V7.89598C21.1999 7.51252 20.888 7.20056 20.5044 7.20056L11.4957 7.20034Z"
                fill="black"
              />
              <path
                d="M16.0034 12.5808L10.1509 7.9109L10.6497 7.28542L15.9956 11.5512L21.32 7.17102L21.8281 7.78911L16.0034 12.5808Z"
                fill="black"
              />
              <path
                d="M15.6001 12.6727H16.4001V25.3242H15.6001V12.6727Z"
                fill="black"
              />
              <path
                d="M18.7993 17.1984H22.3992V17.9984H18.7993V17.1984Z"
                fill="black"
              />
            </svg>
          </Link>
          <div className="fashions__item-img">
            <img
              className="fashions__item-img"
              src={
                process.env.REACT_APP_API_URL +
                "uploads/images/image/" +
                (item.previewImg ? item.previewImg : item.detailImg)
              }
              alt={"Образ " + (item.title ? item.title : "")}
            />
            {
              item.tag ?
                item.href ? 
                  <a href={item.href} className="fashion__name">
                    @{item.tag}
                  </a>
                : 
                  <span className="fashion__name">
                      @{item.tag}
                  </span>
              : ""
            }
          </div>
        </li>
      ));
  }, [fashions, location]);

  return (
    <div style={{ marginTop: settings.headerHeight }}>
      <Helmet>
        <title>Образы интернет-магазина YOU WANNA</title>
        <meta
          name="description"
          content={
            "Образы в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07"
          }
        />
      </Helmet>
      <ContentBreadcrumbs
        margTop={settings.headerHeight}
        items={[
          { name: "Главная", link: "/" },
          { name: "Образы", link: "/fashions" },
        ]}
      />
      <div className={"page_fashions_wrapper"}>
        <div className="container">
          <h1 className="fashions__title">Образы</h1>

          <div className="">
            <Masonry
              options={masonryOptions}
              className="fashions__list js-fashions-list"
              elementType="ul"
            >
              {elements}
            </Masonry>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Fashions;
