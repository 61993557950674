import React, {useEffect, useState} from 'react';
import './users.css';
import {fetchAllUsers, parserUserCsv} from "../../../http/userAPI";
import OneUserLine from "./oneUserLine/OneUserLine";
import Pagination from "../../../components/Paginstion";
import {useParams} from "react-router-dom";
import RedactOneUser from "./RedactOneUser";

const Users = () => {

    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')
    let limit = 100
    if(!page || page <= 0)
        page = 1

    const [allCount,setAllCount] = useState(0)
    const [usersArray, setUsersArray] = useState(null);

    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();

    const [selectedUser, setSelectedUser] = useState(null);
    const [openUserModal, setOpenUserModal] = useState(false)
    useEffect(() => {
        fetchAllUsers(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setAllCount(response.count)
            setUsersArray(response.rows);
        })
    }, [page,searchRoleParam,searchQuery])




    return (
        <div className={"users_page_wrapper"}>

            <div className="add_new_user_block">

            </div>
            <div className={"wrapper_all_users"}>
                  <span className={"user_list_page_heading"}>
                         Лист пользователей
                  </span>
                <div className={"controll_and_sort_users_list"}>
                   <div>

                       <input
                           className={"search_user_input"}
                           value={searchQuery ? searchQuery : ""}
                              onChange={ e => setSearchQuery(e.target.value)}
                              placeholder={"Введите номер"} type="text"/>
                   </div>
                    <div className={"wrapper_for_role_button"}>
                        <span onClick={e => setSearchRoleParam("ADMIN")} className={searchRoleParam === "ADMIN" ? "role_button_users active" : "role_button_users"}>Администраторы</span>
                        <span onClick={e => setSearchRoleParam("")} className={"role_button_users"}>Сбросить</span>
                    </div>

                </div>
                <div className={"list_all_users"}>
                    {
                        usersArray ?
                            usersArray.map(item =>
                                <OneUserLine
                                    item={item}
                                    key={item.id}
                                    select={setSelectedUser}
                                    setOpen={setOpenUserModal}
                                />)
                            : "Пусто"
                    }
                    {
                        selectedUser && openUserModal ?
                            <RedactOneUser user={selectedUser} setClose={setOpenUserModal} />
                            : ""
                    }
                </div>
                <div className="pagination_adm_wrapper">
                    <Pagination page={page} limit={limit} count={allCount} />
                </div>
            </div>
        </div>
    );
};

export default Users;