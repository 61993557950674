import { reaction, runInAction } from "mobx";
import BasketStore from "./BasketStore";
import DeliveryStore from "./DeliveryStore";
import SettingsStore from "./SettingsStore";
import UserStore from "./UserStore";
import { calculateBasket, yandexGetCostToOffice } from "../http/basketApi";
import LastviewStore from "./LastviewStore";

class mainStore {

    constructor() {
        this.delivery = new DeliveryStore();
        this.user = new UserStore();
        this.basket = new BasketStore(this.delivery, this.user);      
        this.settings = new SettingsStore();
        this.lastviewed = new LastviewStore();
    }
}

const main = new mainStore()

reaction(
    //TODO Попробовать перенести bonusesSpend на сеттер и геттер
    () => [
        main.basket.bonusesOrderAvail
    ],
    () => {
        runInAction(() => {
            main.basket.setBonusesSpend(0);
            main.basket.setBonusesValue('');
            main.basket.bonusesSpendAll = false;
        })
    }
)

reaction(
    () => [
        main.delivery.officePvz,
        main.delivery.type
    ],
    async ([officePvz]) => {
        if (main.delivery.type === 'yandex_pvz' && officePvz) {
            const {pricing_total} = await yandexGetCostToOffice(officePvz.code, main.basket.priceNoSell);
            if (pricing_total) {
                const price = Math.round(+pricing_total.replace(' RUB', ''));
                runInAction(() => {
                    main.delivery.setPrice(parseInt(price));
                })
            }
        }
    }
)

reaction(
    () => [
        main.basket.activeBasketItems,
        main.basket.selectedItems,
        main.delivery.active,
        main.delivery.price,
        main.basket.promocode,
        main.basket.selectPaymentType,
        main.basket.bonusesSpend,
        main.basket.totalCountBasketItems
    ],
    async ([activeBasketItems, selectedItems, active, price, promocode, selectPaymentType, bonusesSpend, totalCountBasketItems]) => {
        const {totalPrice, priceNoSell, deliveryPrice, promocode: promo, loyalty, promotions, promoDiscountSumm, newItems, favoriteBasketProduct} = await calculateBasket(
            activeBasketItems,
            active,
            main.delivery.price,
            main.basket.promocode,
            selectPaymentType?.id || null,
            main.user.user,
            bonusesSpend ?? 0
        )

        runInAction(() => {
            main.basket.totalPrice = totalPrice;
            main.basket.priceNoSell = priceNoSell;
            main.basket.delPrice = deliveryPrice;
            main.basket.setSavoriteItems(favoriteBasketProduct);
            main.basket.promoDiscountSumm = promoDiscountSumm;
            main.basket.setCalculatedItems(newItems);
            // main.basket.setActiveBasketItems(newItems);

            if (loyalty) {
                main.basket.bonusesOrderEarned = loyalty.bonusPointsChanges.earnedAmount;
                main.basket.bonusesOrderAvail = loyalty.totalBonusPointsInfo.availableAmountForCurrentOrder;
                main.basket.bonusesTotal = loyalty.totalBonusPointsInfo.balance.available;
                main.basket.bonusesConfirmed = loyalty.bonusPointsChanges.spentAmount;

                if (main.basket.bonusesConfirmed !== +main.basket.bonusesValue) {
                    main.basket.setBonusesValue(main.basket.bonusesConfirmed);
                }
                main.basket.setNoProductsForBonuses(main.basket.bonusesTotal && !main.basket.bonusesOrderAvail)
                main.basket.isLoyaltyActive = true;
            }

            main.basket.usedPromotions = promotions;

            if (!promo?.error && promo.status && promo.item) {
                main.basket.promocodeItem = promo.item;
                main.basket.promoNitificator = {
                    color: 'green',
                    message: `Применен промокод ${promo.item.promocode_value}`
                }
            }

            if (!promo.item) {
                main.basket.promocodeItem = null;
                main.basket.promoNitificator = {
                    color: 'red',
                    message: ''
                }

                if (promo.error) {
                    main.basket.promoNitificator = {
                        color: 'red',
                        message: promo.error
                    }
                }
            }
        })
    }
)

export default main;