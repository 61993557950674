import React, {useEffect} from 'react';
import './shopCity.css'

const ShopCity = (props) => {



    const activatingCityAndMaps = () => {
        props.SetSwitchSity(props.item.id)
        props.setActiveMapsShop(props.item.map_url)
        props.setActivCardsShop('')
        props.setSwitchCityActive(!props.switchCityActive)
    }

    useEffect(() => {

    }, [props.switchCityActive])



    return (
            <div className={"oblozka_one_sity"}>
                {
                    props.switchCityActive === false ?
                        <div
                            id={props.item.id}
                            onClick = {e => activatingCityAndMaps()}
                            className={"s_button open"}>
                            {props.item.name}
                            <div className={props.switchSity === props.item.id ? "s_button arrows" : "s_button_arrows non_arrows"}></div>
                        </div>

                        :
                        <div
                            id={props.item.id}
                            onClick = {e => activatingCityAndMaps()}
                            className={props.switchSity === props.item.id ? "s_button active" : "s_button"}>
                            {props.item.name}</div>

                }

            </div>
    );
};

export default ShopCity;