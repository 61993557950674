import React, {useContext, useEffect, useRef, useState} from 'react';
import {Helmet} from "react-helmet";

import './vacancies.css'
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../../index";
import VacanciesCards from "../../../components/Vacancies/vacanciesCards";
import VacanciesCity from "../../../components/Vacancies/vacansiesCity";
import {observer} from "mobx-react-lite";
import {getCityAndVacancy, getOnePageContent} from "../../../http/contentApi";
import VacanciesCityMobile from "../../../components/Vacancies/vacanciesCityMobile";


const Vacancies = observer(() => {

    const {settings, user} = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [vacancyArray, setVacancyArray] = useState([])
    const [pageContent, setPageContent] = useState({});
    const [noJson, setNoJsonContent] = useState({})
    const [activeCity, setActiveCity] = useState('')
    const [nameActiveCity, setNameActiveCity] = useState('')
    const [pageProps, setPageProps] = useState(false)


    useEffect(() => {
        getOnePageContent(3).then(response => {
            setPageContent(response)
            if (response.content) {
                setNoJsonContent(JSON.parse(response.content))

                const {title, description} = response
                if(title || description)
                    setPageProps({title, description})
            }
        })
        getCityAndVacancy().then(response => {
            if (response != '') {
                setVacancyArray(response)
                setActiveCity(response[0].id)
                setNameActiveCity(response[0].name)
            }
            return false;
        })

    }, [])


    const [activeModalVacancy, setActiveModalVacancy] = useState(false)

    const goModalVacancies = () => {
        user.setShadowOverlay(true);
        setActiveModalVacancy(true);
    }

    const closeModalVacancies = () => {
        setActiveModalVacancy(false);
        user.setShadowOverlay(false);
    }
    useEffect(() => {
        if (user.shadowOverlay === false) {
            setActiveModalVacancy(false);
        }
    }, [user.shadowOverlay])

    const [heightQuestion, setheightQuestion] = useState(0)
    const [stateActiveCityMob, setStateActiveCityMob] = useState(false)

    useEffect(() => {
        let trackHeightInfo = document.getElementById('height_block').offsetHeight
        let trackTopInfo = document.getElementById('height_block').offsetTop

        setheightQuestion(trackHeightInfo + trackTopInfo)

    }, [activeCity, settings.projectWidth, stateActiveCityMob])

    let reduceArray = vacancyArray.filter(function (Vacancies) {
        return Vacancies.id === activeCity;

    });



    const refVacanciesCity = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (stateActiveCityMob && refVacanciesCity.current && !refVacanciesCity.current.contains(e.target)) {
                setStateActiveCityMob(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [stateActiveCityMob]);




    return (
        <div
            // style={{minHeight:heightQuestion}}
            className={"vacancies_page"}>
            {
                pageProps
                &&
                <Helmet>
                    <title>{pageProps.title}</title>
                    <meta name="description" content={pageProps.description} />
                </Helmet>
            }
            {/*<div className={"vacancies_banner"}>*/}
            {/*{*/}
            {/*    noJson.img ?*/}
            {/*        <img className="banner_vacancies_image" src={*/}
            {/*            process.env.REACT_APP_API_URL+"uploads/images/vacancy/"+ noJson.img*/}
            {/*        } alt=""/> :  ""*/}
            {/*}*/}


            {/*{*/}
            {/*    settings.projectWidth &&  settings.projectWidth > 1024 ?*/}
            {/*        <ContentBreadcrumbs*/}
            {/*            margTop={settings.headerHeight}*/}
            {/*            items={[*/}
            {/*                {name:"Youwanna", link:"/"},*/}
            {/*                {name:"ВАКАНСИИ", link:"/content/vacancies/"}*/}
            {/*            ]}*/}
            {/*        />*/}
            {/*        :*/}
            {/*       ""*/}
            {/*}*/}

            {/*</div>*/}
            <div
                style={{marginTop: settings.headerHeight}}
                id={"height_block"} className={"vacancies_content"}>
                {
                    vacancyArray && vacancyArray != '' ?
                        <div className={"vacancies_abs_div_content"}>
                            <div className={"vacancies_static_div_content"}>
                                <h1 className={"title_page_vacancies"}>Вакансии в YOU WANNA</h1>

                                {/*{settings.projectWidth &&  settings.projectWidth > 1024 ?*/}
                                {/*    <div className={"vacancies_cities_container"}>*/}
                                {/*        <div className={"vacancies_city"}>*/}
                                {/*            { vacancyArray ? vacancyArray.map((item,index) =>*/}
                                {/*                <VacanciesCity*/}
                                {/*                    item={item}*/}
                                {/*                    key={index}*/}
                                {/*                    index={index}*/}
                                {/*                    setActiveCity={e => setActiveCity(e)}*/}
                                {/*                    activeCity={activeCity}*/}
                                {/*                />*/}
                                {/*            ) : ""}*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*:*/}
                                <div className={"vacancies_city_mobile"}>
                                    <div className={"mob_title_city"}>Город:</div>
                                    <div ref={refVacanciesCity}>
                                        <div
                                            onClick={e => setStateActiveCityMob(!stateActiveCityMob)}
                                            className={stateActiveCityMob === false ? "container_city_active open" : "container_city_active close"}>{nameActiveCity}</div>
                                        <div
                                            className={stateActiveCityMob === false ? "mob_list_city" : "mob_list_city active"}>
                                            {stateActiveCityMob === true && vacancyArray ? vacancyArray.map((item, index) =>
                                                <VacanciesCityMobile
                                                    item={item}
                                                    key={index}
                                                    index={index}
                                                    setActiveCity={e => setActiveCity(e)}
                                                    activeCity={activeCity}
                                                    stateActiveCityMob={stateActiveCityMob}
                                                    setStateActiveCityMob={e => setStateActiveCityMob(e)}
                                                    setNameActiveCity={e => setNameActiveCity(e)}
                                                />
                                            ) : ""}
                                        </div>
                                    </div>

                                </div>
                                {/*}*/}


                                <div className={"vacancies_cards_content"}>
                                    {
                                        reduceArray[0] ?
                                            reduceArray[0].vacancies.map((item, index) =>
                                                <VacanciesCards
                                                    key={index}
                                                    vacancyId={item.id}
                                                    item={item}
                                                    openModal={e => goModalVacancies()}
                                                    reduceArray={reduceArray[0].vacancies}
                                                    activeModalVacancy={activeModalVacancy}
                                                    setActiveModalVacancy={setActiveModalVacancy}
                                                    closeModalVacancies={closeModalVacancies}
                                                />
                                            )
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <h3>Свободных вакансий нет</h3>
                }

            </div>
        </div>

    );
});

export default Vacancies;