import React, {useState} from 'react';
import './oneProductLine.css';
import {Link} from "react-router-dom";
import IsActiveIndicator from "../../../UI/isActiveIndicator/IsActiveIndicator";
import {changeProductActive, removeOneProduct} from "../../../../../http/catalogApi";
import TrashCont from "../../../UI/trashICont/TrashCont";
import Popup from './deletePopup/deletePopup';
const OneProductLine = (props) => {

    const [removeState,setRemove] = useState(false);
    const [vendettaState,setVendettaState] = useState(false)

    const changeActive = async (id,status) => {
        const data = await changeProductActive(id,status);
        if(!data.error) {
            props.setProducts([...props.products],[props.products[props.index].active_product = data.status])
        }
    }

    const  date = new Date(props.item.updatedAt);

    const humanDateFormat =  date.toLocaleString();

    const createHumanFormat = new Date(props.item.createdAt).toLocaleString();

    const removeProduct = async (id) => {
        const response = await removeOneProduct(id);
        if(response.data.success) {
            setVendettaState(true);
            setRemove(false)
            setTimeout(() => {
                let reduceArray = [...props.products];
                reduceArray.splice(props.index,1)
                props.setProducts([...reduceArray])
                setVendettaState(false);
            },400)
        }
    }

    return (
        <>
            {
                removeState && <Popup
                    content={<>
                        <b>Удаление товара</b>
                        <p style={{fontWeght: "bold"}}>Вы действительно хотите удалить товар: {props.item.name}, id:  {props.item.id}, артикул: {props.item.articul}?</p>
                        <p>Товар удалится безвозвратно, также вместе с ним удалятся его торговые предложения, {props.item.childProducts.length} шт</p>
                        <button className={"delete_item_button"} onClick={e => removeProduct(props.item.id)}>Удалить</button>
                    </>}
                    handleClose={e => setRemove(false)}
                />
            }
            <div className={props.item.active_product ? (vendettaState ? "one_product_line v" : "one_product_line") : (vendettaState ? "one_product_line v deactive" : "one_product_line deactive")}>
                <Link className={"link_st"} to={"/admindashboard/products/oneitem/"+props.item.id+"/"}  >
                    <div className={"id_block_on_pr_line"}>{props.item.id}</div>
                    <div className={"image_product_or_line"}>
                        {props.item.previev_photo ?
                            <img src={process.env.REACT_APP_API_URL+"uploads/images/"+props.item.id+"/"+props.item.previev_photo} alt=""/>
                            :
                            <img className={"no_image"} src={process.env.REACT_APP_API_URL+"uploads/images/no-image.jpg"} alt=""/>
                        }
                    </div>

                    <div  className={"name_line_product"}> {props.item.name} </div>
                    <div className={"articul_product"}>{props.item.articul}</div>
                    <div className={"child_count"}>{props.item.childProducts.length + " шт"}</div>


                </Link>

                <IsActiveIndicator active={props.item.active_product} change={e => changeActive(props.item.id, !props.item.active_product)}
                />

                <div className={"other_controls_tab"}>
                    <div className={"left_line_bar"}></div>
                    <div className={"right_line_bar"}>
                        <div className={"date_createdat_container"}> {createHumanFormat ? createHumanFormat : ""}</div>
                        <div className={"date_updateat_container"}> {humanDateFormat ? humanDateFormat : ""}</div>
                        <div className={"last_redactor"}> {props.item.redactor} </div>
                        <div  className={"remove_div"}>
                            <TrashCont onClick={e => setRemove(true)}/>
                        </div>
                    </div>
                </div>

            </div>
        </>
        
    );
};

export default OneProductLine;