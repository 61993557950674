import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {BASKET_ROUTE} from "../../../../../utils/consts";
import { Context } from '../../../../../index';

const BasketIcon = observer(() => {
    const {basket} = useContext(Context);

    return (
        <Link to={BASKET_ROUTE} className="bar_icon_wrapper fill basket">
            <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0259 19.618C11.3467 19.618 12.4169 20.6882 12.4169 22.009C12.4169 23.3299 11.3467 24.4 10.0259 24.4C8.70506 24.4 7.63489 23.3299 7.63489 22.009C7.63489 20.6882 8.70788 19.618 10.0259 19.618Z" fill="white"/>
                <path d="M21.3051 19.618C22.6259 19.618 23.6961 20.6882 23.6961 22.009C23.6961 23.3299 22.6259 24.4 21.3051 24.4C19.9842 24.4 18.9141 23.3299 18.9141 22.009C18.9113 20.6882 19.9842 19.618 21.3051 19.618Z" fill="white"/>
                <path d="M27.4528 6.2549C27.3092 6.06058 27.0839 5.94511 26.8417 5.94511H7.82355H6.5L4.78763 0.66182C4.59049 0.256279 4.18214 0 3.73154 0H0.605495C0.27036 0.00281625 0 0.27036 0 0.605495V1.3687C0 1.70102 0.27036 1.97138 0.602678 1.97138H2.8416C2.99649 1.97138 3.13167 2.07558 3.1711 2.22484L6.92235 16.4638C7.28002 17.8269 8.51354 18.7788 9.92448 18.7788H21.5866C22.9468 18.7788 24.1466 17.8945 24.5521 16.5962L27.5627 6.92798C27.6359 6.69987 27.5937 6.44922 27.4528 6.2549ZM22.4202 15.9344C22.3047 16.3005 21.9724 16.5455 21.5894 16.5455H9.9273C9.53302 16.5455 9.18662 16.2779 9.08805 15.8949L7.23777 8.86838C7.19271 8.70504 7.22932 8.53043 7.33353 8.39525C7.43773 8.26007 7.59825 8.18122 7.76723 8.18122H24.0959C24.2677 8.18122 24.4338 8.26289 24.5352 8.4037C24.6366 8.5417 24.6676 8.72475 24.6169 8.8881L22.4202 15.9344Z" fill="white"/>
            </svg>
            <div className={"counter_basket"}>
                {basket.countItems}
            </div>
        </Link>
    );
});

export default BasketIcon;