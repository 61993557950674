import React, { useEffect, useState } from 'react';
import './deliveryManager.css';
import {
    addNewDeliveryMethod,
    fecthPaymentMethods,
    fetchAllDeliveryMethods,
    searchDependencyForDelivery
} from "../../../../http/settingsApi";
import OneDeliveryMethod from "./oneDeliveryMethod/OneDeliveryMethod";

const DeliveryManager = () => {


    const [allDeliveryMethods, setAllDeliveryMethods] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState(null);

    const [refreshList, setRefreshList] = useState(false);

    useEffect(() => {

        fecthPaymentMethods().then(response => {

            if (response.error) {
                return false;
            }

            setPaymentMethods(response);

        })

        fetchAllDeliveryMethods().then(response => {
            if (response[0]) {
                setAllDeliveryMethods(response)
            }
        })
    }, [refreshList])

    const [addNewDeliveryFields, setAddNewDeliveryFields] = useState({
        name: "",
        delivery_text: "",
        dop_price: 0,
        negative_dop_price: 0,
        after_price_text: "",
        target: "city",
        country_iso_code: "",
        target_value: "",
        delivery_integration: false,
        delivery_type: "",
        delivery_map: false,
        payment_type: [],
        retailCrmCode: "",
        hasPickupLocations: false,
        min_delivery_term: 3,
        delivery_sell_type: "no",
        sell_condition: 0,
        sell_price: 0,
    });

    const [searchResult, setSearchResult] = useState([]);

    const [searchText, setSearchText] = useState("")

    const [isActiveSaveButton, setIsActiveSaveButton] = useState(true)

    const deliveryTypes = [
        { id: 0, key: "Есть ПВЗ", value: true },
        { id: 1, key: "Без ПВЗ", value: false },
    ]

    const deliveryMinTerms = [
        { id: 0, key: "В день заказа", value: 0 },
        { id: 1, key: "На следующий день", value: 1 },
        { id: 2, key: "Через 2 дня", value: 2 },
        { id: 3, key: "От 3 дней", value: 3 },
    ]

    const deliverySellTypes = [
        { id: 0, key: "Скидка при покупке нескольких товаров", value: "items" },
        { id: 1, key: "Скидка при покупке от суммы", value: "price" },
        { id: 2, key: "Без скидки", value: "no" },
    ]

    const makeAChoise = (item) => {
        if (!item.name || !item.country_iso_code) {
            return false;
        }

        setAddNewDeliveryFields({ ...addNewDeliveryFields, target_value: item.name, country_iso_code: item.country_iso_code })
    }

    const changeTargetNewDelivery = (parametr) => {
        setAddNewDeliveryFields({ ...addNewDeliveryFields, target: parametr, target_value: "", country_iso_code: "" })
        setSearchText('')

    }

    useEffect(() => {
        searchDependencyForDelivery(addNewDeliveryFields.target, searchText).then(response => {
            setSearchResult(response);
        })
    }, [searchText])

    const [serverResponse, setServerResponse] = useState([])

    const addNewDeliveryHandler = (fields) => {
        setIsActiveSaveButton(false);

        addNewDeliveryMethod(fields).then(response => {

            if (response.error) {
                setServerResponse(response.error)
            }
            if (response.message) {
                setAddNewDeliveryFields({ name: "", delivery_text: "", dop_price: 0, negative_dop_price: 0, after_price_text: "", target: "city", country_iso_code: "", target_value: "", delivery_integration: false, delivery_type: "", delivery_map: false, payment_type: [] })
                setSearchText('')
                setServerResponse(response.message);
                setRefreshList(!refreshList);
            }

            setIsActiveSaveButton(true);
        })
    }

    const addPaymentMethodHandler = (item) => {
        if (addNewDeliveryFields.payment_type.includes(item.id)) {
            //Есть, удаляем
            let index = addNewDeliveryFields.payment_type.lastIndexOf(item.id);

            let reducePayments = addNewDeliveryFields.payment_type;
            reducePayments.splice(index, 1);
            setAddNewDeliveryFields({ ...addNewDeliveryFields, paymentMethods: reducePayments })

        } else {
            // Нету, добавляем
            let reducePayments = addNewDeliveryFields.payment_type;
            reducePayments.push(item.id);
            setAddNewDeliveryFields({ ...addNewDeliveryFields, paymentMethods: reducePayments })
        }
    }


    function listDeliveryTypes() {

        return <select
            className={'status_refund'}
            onChange={(e) => setAddNewDeliveryFields({ ...addNewDeliveryFields, hasPickupLocations: e.target.value })}
            value={addNewDeliveryFields.hasPickupLocations}>
            {deliveryTypes.map((m, ix) => {
                return <option
                    key={m.id}
                    value={m.value}
                >
                    {m.key}
                </option>
            })};
        </select>
    }

    function listDeliveryMinTerms() {

        return <select
            className={'status_refund'}
            onChange={(e) => setAddNewDeliveryFields({ ...addNewDeliveryFields, min_delivery_term: e.target.value })}
            value={addNewDeliveryFields.min_delivery_term ? addNewDeliveryFields.min_delivery_term : 3}>
            {deliveryMinTerms.map((m, ix) => {
                return <option
                    key={m.id}
                    value={m.value}
                >
                    {m.key}
                </option>
            })};
        </select>
    }

    function listDeliverySellTypes() {
        return <select
            className={'sell_type'}
            onChange={(e) => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_sell_type: e.target.value })}
            value={addNewDeliveryFields.delivery_sell_type ? addNewDeliveryFields.delivery_sell_type : "no"}>
            {deliverySellTypes.map((m, ix) => {
                return <option
                    key={m.id}
                    value={m.value}
                >
                    {m.key}
                </option>
            })};
        </select>
    }

    return (
        <div className={"delivery_manage_wrapper"}>
            <span className="city_page_title"> Менеджер способов доставки </span>
            <div className={"delivery_manager_content"}>
                <div className={"delivery_manager_info_content"}>
                    <p>Менеджер способов доставки позволяет добавить способ доставки и указать регион.</p>
                    <p>Во время оформления заказа функционал будет искать сначала способы доставки доступные пользователю по его городу, затем по области, затем по стране.</p>
                    <p>Если совпадения будут найдены по городу - вернутся способы доставки для города, дальше область и т.д. В случае, если нет - доставка для всей России.</p>
                </div>


                {/*id:{type: DataTypes.INTEGER, autoIncrement:true, primaryKey: true},*/}
                {/*name:{type: DataTypes.STRING},*/}
                {/*delivery_text:{type: DataTypes.STRING},*/}
                {/*dop_price:{type: DataTypes.INTEGER, defaultValue: 0},*/}
                <div className={"delivery_adm_page_heading"}>Добавление нового способа доставки</div>
                <div className={"add_new_delivery_block"}>
                    <div className={"add_new_delivery_standard_controll"}>
                        <div className={"standard_delivery_inputs"}>

                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Название</span>
                                <input
                                    placeholder={"Введите название способа доставки"}
                                    value={addNewDeliveryFields.name ? addNewDeliveryFields.name : ""}
                                    onChange={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, name: e.target.value })}
                                    className={"standard_delivery_one_field"} type="text" />
                            </div>
                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Информ. текст</span>
                                <input
                                    placeholder={"Введите дополнительный текст"}
                                    value={addNewDeliveryFields.delivery_text ? addNewDeliveryFields.delivery_text : ""}
                                    onChange={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_text: e.target.value })}
                                    className={"standard_delivery_one_field"} type="text" />
                            </div>
                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Доп. цена</span>
                                <input
                                    placeholder={"Введите надбавку к цене (цифры)"}
                                    value={addNewDeliveryFields.dop_price ? addNewDeliveryFields.dop_price : ""}
                                    onChange={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, dop_price: e.target.value })}
                                    className={"standard_delivery_one_field"} type="text" />
                            </div>
                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Негатив. доп. прайс (невыкуп и т.д)</span>
                                <input
                                    placeholder={"Введите надбавку к цене (цифры)"}
                                    value={addNewDeliveryFields.negative_dop_price ? addNewDeliveryFields.negative_dop_price : ""}
                                    onChange={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, negative_dop_price: e.target.value })}
                                    className={"standard_delivery_one_field"} type="text" />
                            </div>

                            {/*negative_dop_price*/}
                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Текст после цены</span>
                                <input
                                    placeholder={"Введите текст"}
                                    value={addNewDeliveryFields.after_price_text ? addNewDeliveryFields.after_price_text : ""}
                                    onChange={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, after_price_text: e.target.value })}
                                    className={"standard_delivery_one_field"} type="text" />
                            </div>
                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Код для RetailCRM</span>
                                <input
                                    placeholder={"Введите текст"}
                                    value={addNewDeliveryFields.retailCrmCode ? addNewDeliveryFields.retailCrmCode : ""}
                                    onChange={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, retailCrmCode: e.target.value })}
                                    className={"standard_delivery_one_field"} type="text" />
                            </div>
                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Тип доставки (imshop)</span>
                                {listDeliveryTypes()}
                            </div>
                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>Минимальный срок доставки (imshop)</span>
                                {listDeliveryMinTerms()}
                            </div>

                            <div className={"delivery_wrapper_for_input"}>
                                <span className={"delivery_input_title"}>По какому параметру создаем доставку</span>
                                <div className={"delivery_targets_wrapper"}>
                                    <div
                                        onClick={e => changeTargetNewDelivery("city")}
                                        className={addNewDeliveryFields.target === "city" ? "target_delivery_changer active" : "target_delivery_changer"}>Город</div>
                                    <div
                                        onClick={e => changeTargetNewDelivery("area")}
                                        className={addNewDeliveryFields.target === "area" ? "target_delivery_changer active" : "target_delivery_changer"}> Область / край</div>
                                    <div
                                        onClick={e => changeTargetNewDelivery("country")}
                                        className={addNewDeliveryFields.target === "country" ? "target_delivery_changer active" : "target_delivery_changer"}>Страна</div>
                                    <div
                                        onClick={e => changeTargetNewDelivery("default")}
                                        className={addNewDeliveryFields.target === "default" ? "target_delivery_changer active all" : "target_delivery_changer all"}>По умолчанию</div>
                                </div>
                            </div>

                        </div>
                        {
                            addNewDeliveryFields.target !== "default" ?

                                <div className={"delivery_search_wrapper"}>
                                    <div className={"delivery_wrapper_for_input"}>
                                        <span className={"delivery_input_title"}>Поиск по городу / области / стране</span>
                                        <input
                                            placeholder={"Введите название"}
                                            value={searchText ? searchText : ""}
                                            onChange={e => setSearchText(e.target.value)}
                                            className={"standard_delivery_one_field"} type="text" />
                                    </div>
                                    <span className={addNewDeliveryFields.target_value ? "delivery_target_value_style ok_target" : "delivery_target_value_style"}> Выбрано: {addNewDeliveryFields.target_value ? addNewDeliveryFields.target_value : "Не выбрано"} </span>
                                    <div className={"delivery_search_response_container"}>
                                        {
                                            !searchResult[0] ?
                                                <div className={"pre_result_responser"}>Нет результатов, измените запрос</div>
                                                :
                                                searchResult.map((item, index) => {
                                                    return (
                                                        <div
                                                            onClick={e => makeAChoise(item)}
                                                            className={"delivery_search_responser_one_item"}
                                                            key={item.id}>
                                                            {item.name}
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                </div> : ""
                        }

                        <div className={"add_one_product_last_container"}>

                            <span

                                onClick={e => addNewDeliveryHandler(addNewDeliveryFields)}

                                className={isActiveSaveButton ? "add_new_delivery_button" : "add_new_delivery_button "}> Добавить </span>
                            <div className={"add_new_delivery_response_server"}>
                                {
                                    serverResponse[0] ?
                                        serverResponse.map((item, index) => {
                                            return (
                                                <span key={index}>{index + ") " + item}</span>
                                            )
                                        })
                                        : <span>Если что-то пойдет не так,
                                            вы увидите ответ от сервера.</span>
                                }

                            </div>
                        </div>
                    </div>

                    <div className={"add_new_delivery_integration"}>

                        <span className={"add_new_delivery_integration_heading"}>Область с привязкой интеграций к доставкам.</span>
                        <div className={"active_delivery_integrations"}>
                            <div
                                onClick={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_integration: !addNewDeliveryFields.delivery_integration, delivery_type: "", delivery_map: false })}
                                className={addNewDeliveryFields.delivery_integration ? "active_delivery_integrations_check active" : "active_delivery_integrations_check"}> </div>
                            <span className={"active_delivery_integrations_info"}>Включая, у вас появятся доступные интеграции. </span>
                        </div>
                        <div className={"delivery_map_controller_check"}>
                            <div
                                onClick={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_map: !addNewDeliveryFields.delivery_map })}
                                className={addNewDeliveryFields.delivery_map ? "active_delivery_integrations_check active" : "active_delivery_integrations_check"}> </div>
                            <span className={"active_delivery_integrations_info"}>Чтобы убрать поля "куда привозить" и включить "карту" </span>
                        </div>
                        {
                            addNewDeliveryFields.delivery_integration ?

                                <div className={"delivery_itegration_list"}>
                                    <div
                                        onClick={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_type: "sdek_courier" })}
                                        className={addNewDeliveryFields.delivery_type === "sdek_courier" ? "delivery_one_method_board active" : "delivery_one_method_board"}>
                                        SDEK ПВЗ-ДВЕРЬ
                                    </div>
                                    <div
                                        onClick={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_type: "sdek_pvz" })}
                                        className={addNewDeliveryFields.delivery_type === "sdek_pvz" ? "delivery_one_method_board active" : "delivery_one_method_board"}>
                                        SDEK ПВЗ-ПВЗ
                                    </div>
                                    <div
                                        onClick={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_type: "yandex_courier" })}
                                        className={addNewDeliveryFields.delivery_type === "yandex_courier" ? "delivery_one_method_board active" : "delivery_one_method_board"}>
                                        Yandex ПВЗ-ДВЕРЬ
                                    </div>
                                    <div
                                        onClick={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_type: "yandex_pvz" })}
                                        className={addNewDeliveryFields.delivery_type === "yandex_pvz" ? "delivery_one_method_board active" : "delivery_one_method_board"}>
                                        Yandex ПВЗ-ПВЗ
                                    </div>
                                </div>
                                : ""
                        }
                        <span className={"add_new_delivery_integration_heading"}>Самовывоз из Шоу-рума.</span>
                        <div className={"active_delivery_integrations"}>
                            <div
                                onClick={e => setAddNewDeliveryFields({ ...addNewDeliveryFields, delivery_integration: false, show_room: !addNewDeliveryFields.show_room, delivery_type: "", delivery_map: false })}
                                className={addNewDeliveryFields.show_room ? "active_delivery_integrations_check active" : "active_delivery_integrations_check"}> </div>
                            <span className={"active_delivery_integrations_info"}>Чтобы убрать "куда привозить"</span>
                        </div>

                    </div>

                    <div className={"delivery_payment_methods_list"}>
                        <span className={"delivery_payment_methods_list_heading"}>Выберите доступные способы оплаты для этой доставки</span>
                        {paymentMethods ?
                            <div className={"payment_methods_front_wrap"}>
                                {
                                    paymentMethods.map((item, index) => {
                                        return (
                                            <div
                                                onClick={e => addPaymentMethodHandler(item)}
                                                className={addNewDeliveryFields.payment_type.includes(item.id) ? "one_pay_method_button active" : "one_pay_method_button"}
                                                key={item.id}>
                                                {item.name}
                                            </div>)
                                    })
                                }
                            </div>

                            : <div className={"no_payment_methods"}>Вероятно, это ошибка, либо мы не смогли найти методы оплаты.</div>
                        }
                    </div>
                    <div className={"add_new_delivery_integration"}>

                        <span className={"add_new_delivery_integration_heading"}>Область c условиями скидки на доставку.</span>
                        <div className={"one_delivery_one_field_wrapper"}>
                            <span className={"one_delivery_name_field_text"}>Тип скидки на доставку</span>
                            {listDeliverySellTypes()}
                        </div>
                        <div className={"one_delivery_one_field_wrapper"}>
                            <span className={"one_delivery_name_field_text"}>Условие скидки на доставку: Цена/Количество товаров</span>
                            <input
                                value={addNewDeliveryFields.sell_condition}
                                onChange={e => setAddNewDeliveryFields({
                                    ...addNewDeliveryFields,
                                    sell_condition: e.target.value
                                })}
                                className={"one_delivery_sell_input" + (addNewDeliveryFields.delivery_sell_type === "no" ? " disactive" : "")} type="text" />
                        </div>
                        <div className={"one_delivery_one_field_wrapper"}>
                            <span className={"one_delivery_name_field_text"}>Цена доставки со кидкой</span>
                            <input
                                value={addNewDeliveryFields.sell_price}
                                onChange={e => setAddNewDeliveryFields({
                                    ...addNewDeliveryFields,
                                    sell_price: e.target.value
                                })}
                                className={"one_delivery_sell_input" + (addNewDeliveryFields.delivery_sell_type === "no" ? " disactive" : "")} type="text" />
                        </div>
                    </div>
                </div>


                <div className={"all_delivery_methods_wrapper"}>
                    <div className={"all_delivery_list_head"}>Список существующих доставок</div>
                    {
                        allDeliveryMethods[0] ?
                            allDeliveryMethods.map((item, index) =>
                                <OneDeliveryMethod key={item.id} item={item} index={index}
                                    refreshList={refreshList}
                                    setRefreshList={setRefreshList}
                                    allDeliveryMethods={allDeliveryMethods}
                                    paymentMethods={paymentMethods}
                                />
                            )
                            : ""
                    }
                </div>

            </div>
        </div>
    );
};

export default DeliveryManager;