import React from 'react';
import './PolicyAndOffer.css';

const PolicyAndOffer = (props) => {

    return (
            <div>
                <p className={"policy_subline"}>{props.item.title}</p>
                {
                    props.item.text ? props.item.text.map((item, index) =>
                        <p key={index} className={"policy_text"}>{item.p}</p>
                    ) : ''
                }
            </div>
    );
};

export default PolicyAndOffer;