import React, {useState} from 'react';
import ReturnsList from "./ReturnsList";
import ReturnContext from '../lib/returnsContext'
import Error from "./Error";

const Returns = () => {

    const [error, setError] = useState(false)

    return (
        <ReturnContext.Provider
            value={{
                setError
            }}>

            {error ? <Error error={error} /> : <ReturnsList />}
        </ReturnContext.Provider>
    );
};

export default Returns;