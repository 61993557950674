import React from 'react';

const UserItemControllBar = (props) => {

    return (

        <div>
            {
                props.index === 3 ?

                        <a rel="noopener noreferrer" className={"user_item_controll_bar chat"} target="_blank" href="https://api.whatsapp.com/send/?phone=79268877007&text&app_absent=0">
                        {props.oneItem.nameButton}
                        </a>


                    :
                    <div
                        id={"controll_tab_"+props.index}
                        className={"user_item_controll_bar " + props.disabled + " " + props.isActive + " " + props.paddingClass}
                        onClick={e => props.trigerAnimate(props.index)}
                    >
                        {props.oneItem.nameButton}
                    </div>
            }
        </div>

    );
};

export default UserItemControllBar;