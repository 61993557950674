import React, {useEffect, useMemo, useRef, useState} from 'react';
import './css/ReturnsItemPage.css'
import ReturnsApi from "../../../../http/returnsApi";
import {Link, Navigate, useParams} from "react-router-dom";
import Error from "./Error";
import {formatDate} from "../../../../utils/date";

const fields = {
    id: {
        type: 'text',
        label: 'ID',
        edit: false
    },
    createdAt: {
        type: 'text',
        label: 'Дата оформления заявления',
        edit: false
    },
    lastName: {type: 'text', label: 'Фамилия'},
    firstName: {type: 'text', label: 'Имя'},
    middleName: {type: 'text', label: 'Отчество'},
    phone: {type: 'text', label: 'Номер телефона'},
    email: {type: 'email', label: 'E-mail'},
    article: {type: 'text', label: 'Артикул'},
    size: {type: 'text', label: 'Размер'},
    color: {type: 'text', label: 'Цвет'},
    passportSeriesAndNumber: {type: 'text', label: 'Серия и номер паспорта'},
    issuedByWhom: {type: 'text', label: 'Кем выдан'},
    dateOfIssue: {type: 'text', label: 'Дата выдачи'},
    reasonForReturn: {type: 'text', label: 'Причина возврата'},
    orderNumber: {type: 'number', label: 'Номер заказа'},
    orderPlacingDate: {type: 'text', label: 'Дата оформления заказа'},
    type: {type: 'text', label: 'Тип оплаты'},
    bankName: {type: 'text', label: 'Наименование банка'},
    bik: {type: 'number', label: 'Бик'},
    correspondentAccount: {type: 'number', label: 'Кор.Счёт'},
    settlementAccount: {type: 'number', label: 'Расчётный счет банка'},
    account: {type: 'number', label: 'Счет получателя'},
    cardNumber: {type: 'text', label: 'Номер карты'}
}

const ReturnItemPage = () => {

    const [returnItem, setReturnItem] = useState(false)
    const [updatedReturnItem, setUpdatedReturnItem] = useState(false)
    const [error, setError] = useState(false)
    const [isDeleted, setIsDeleted] = useState(false)
    const [articles, setArticles] = useState(false)

    const {id} = useParams()

    const getReturnItem = async () => {
        const response = await ReturnsApi.get(id)

        if (!response.success)
            return setError(response.message)

        if (!response.data)
            setError('Элемент не найден')
        setReturnItem(response.data)
        setUpdatedReturnItem(response.data)

        let articles;
        try{
            articles = JSON.parse(response.data.articles)
        } catch (e) {
            articles = [];
        }
        setArticles(articles)
    }

    useEffect(() => {
        getReturnItem()
    }, [])

    // console.log(returnItem)

    const dataChanged = useMemo(() => {
        return JSON.stringify(updatedReturnItem) !== JSON.stringify(returnItem)
    }, [returnItem, updatedReturnItem])

    const changeField = (key, value) => {
        setUpdatedReturnItem({...updatedReturnItem, [key]: value})
    }

    const save = async () => {
        const response = await ReturnsApi.update(returnItem)

        if (!response.success)
            return setError(response.message)

        setReturnItem(response.data)
        setUpdatedReturnItem(response.data)

        let articles;
        try{
            articles = JSON.parse(response.data.articles)
        } catch (e) {
            articles = [];
        }
        setArticles(articles)
    }

    const deleteItem = async () => {
        const response = await ReturnsApi.delete(returnItem.id)

        if (!response.success)
            return setError(response.message)

        setIsDeleted(true)
    }

    if (error)
        return <Error error={error}/>

    if (!returnItem)
        return (
            <div className="spin_wrapper ">
                <div className="spinner"></div>
            </div>
        )


    const returnFields = (e, fieldName) => {
        setReturnItem({...returnItem, [fieldName]: e.target.value})
    }

    const setArticleField = (e, id, fieldName) => {
        let articles = JSON.parse(returnItem.articles)
        articles.forEach( (el) => {
            if(el.id == id){
                el[fieldName] = e.target.value
                console.log(el)
            }
        })
        returnItem.articles = JSON.stringify(articles)
        setArticles(articles)
        setReturnItem(returnItem)
    }

    //Причина возврата
    const reasonForReturn = [
        {id: 1, name:'1-Заказала на выбор'},
        {id: 2, name:'2-Не подошел размер'},
        {id: 3, name:'3-Не устраивает качество'},
        {id: 4, name:'4-Брак товара'},
        {id: 5, name:'5-Не нравится стиль'},
        {id: 6, name:'6-Другое '},
    ]
    //Причина возврата
    const paymentTypes = [
        {id: 1, name:'Наличные'},
        {id: 2, name:'Картой на сайте'},
        {id: 3, name:'Безналичный расчет'},
        {id: 4, name:'Терминал'},
        {id: 5, name:'Долями'},
        {id: 6, name:'Яндекс Сплит'},
    ]

    //Самостоятельный возврат, выбор ПВЗ
    const deliveryServices = [
        {id: 1, img:'/files/images/deliveryRefound/sdek.png', name: 'CDEK'},
        {id: 2, img:'/files/images/deliveryRefound/pochta.png', name: 'Почта России'},
    ]

    //Статус
    const statusRefund = [
        {id: 1, name: 'Новый'},
        {id: 2, name: 'В обработке'},
        {id: 3, name: 'Выполнен'},
    ]

    const reasonReturnHandler = (item, fieldName) => {
        setReturnItem({...returnItem, [fieldName]: item.name})
    }

    return (
        <div className="returns-item-page">
            {
                isDeleted && <Navigate to="/admindashboard/returns" replace={true} />
            }
            <div className={"return_and_status_button"}>
                <Link  to="/admindashboard/returns" className={"return_refunds_page"}>
                    <svg width="37" height="8" viewBox="0 0 37 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.646446 3.64645C0.451183 3.84171 0.451183 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646446 3.64645ZM37 3.5L1 3.5V4.5L37 4.5V3.5Z" fill="black"/>
                    </svg>
                    <span>
                     Возвраты интенет магазина
                </span>
                </Link>
                <select className={"status_refund"} name="category" value={returnItem.status ? returnItem.status : 'не выбрано'} onChange={e => returnFields(e,"status" )}>
                    {
                        statusRefund ? statusRefund.map((item, index) =>
                            <option key={index} id={index}>{item.name}</option>
                        ) :''
                    }
                </select>
            </div>


            <div className="product_information">

                {/*{*/}
                {/*    Object.entries(fields).map(([key, {type, label, edit}]) => (*/}
                {/*        <div className="returns-item-page__field" key={key}>*/}
                {/*            <div className="admin_inputs_wrapper"><span className="field_info">*/}
                {/*                <span*/}
                {/*                    className="red_sup">*</span> {label}: {edit === false ? updatedReturnItem[key] : ''}</span>*/}
                {/*                {*/}
                {/*                    edit !== false &&*/}
                {/*                    <input className="admin_field_styles"*/}
                {/*                           placeholder={label}*/}
                {/*                           type={type}*/}
                {/*                           name=""*/}
                {/*                           id=""*/}
                {/*                           onChange={({target}) => changeField(key, target.value)}*/}
                {/*                           value={updatedReturnItem[key]}/>*/}
                {/*                }*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    ))*/}
                {/*}*/}
                {
                    returnItem ?
                        <div className={"fields_refund_wrapper"}>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund"}>Тип</span>
                                <select
                                    className={'status_refund'}
                                    value={returnItem.issued_type}
                                    fieldName={"issued_type"}
                                    onChange={e => returnFields(e,'issued_type')}
                                >
                                    <option value={"Возврат"}>Возврат</option>
                                    <option value={"Обмен"}>Обмен</option>
                                </select>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Фамилия</span>
                                <input
                                    value={returnItem.lastName ? returnItem.lastName : ""}
                                    onChange={e => returnFields(e,'lastName')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Имя</span>
                                <input
                                    value={returnItem.firstName ? returnItem.firstName : ""}
                                    onChange={e => returnFields(e,'firstName')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Отчество</span>
                                <input
                                    value={returnItem.middleName ? returnItem.middleName : ""}
                                    onChange={e => returnFields(e,'middleName')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Телефон</span>
                                <input
                                    value={returnItem.phone ? returnItem.phone : ""}
                                    onChange={e => returnFields(e,'phone')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>E-mail</span>
                                <input
                                    value={returnItem.email ? returnItem.email : ""}
                                    onChange={e => returnFields(e,'email')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            {
                                articles.length > 0 ?
                                    articles.map((item, index) => {
                                        return (
                                            <div key={item.id} className={"gorizontal_fields_wrapper"}>
                                                <div className="one_field_refund_wrapper">
                                                    <span className={"one_field_refund mandatory"}>Артикул</span>
                                                    <input
                                                        value={item.article ? item.article : ""}
                                                        onChange={e => setArticleField(e,item.id,'article')}
                                                        className={"refund_field"} type="name"/>
                                                </div>
                                                <div className="one_field_refund_wrapper">
                                                    <span className={"one_field_refund mandatory"}>Размер</span>
                                                    <input
                                                        value={item.size ? item.size : ""}
                                                        onChange={e => setArticleField(e,item.id,'size')}
                                                        className={"refund_field"} type="name"/>
                                                </div>
                                                <div className="one_field_refund_wrapper">
                                                    <span className={"one_field_refund mandatory"}>Цвет</span>
                                                    <input
                                                        value={item.color ? item.color : ""}
                                                        onChange={e => setArticleField(e, item.id,'color')}
                                                        className={"refund_field"} type="name"/>
                                                </div>
                                            </div>
                                        )
                                    }):
                                    <div className={"gorizontal_fields_wrapper"}>
                                        <div className="one_field_refund_wrapper">
                                            <span className={"one_field_refund mandatory"}>Артикул</span>
                                            <input
                                                value={returnItem.article ? returnItem.article : ""}
                                                onChange={e => returnFields(e, 'article')}
                                                className={"refund_field"} type="name"/>
                                        </div>
                                        <div className="one_field_refund_wrapper">
                                            <span className={"one_field_refund mandatory"}>Размер</span>
                                            <input
                                                value={returnItem.size ? returnItem.size : ""}
                                                onChange={e => returnFields(e, 'size')}
                                                className={"refund_field"} type="name"/>
                                        </div>
                                        <div className="one_field_refund_wrapper">
                                            <span className={"one_field_refund mandatory"}>Цвет</span>
                                            <input
                                                value={returnItem.color ? returnItem.color : ""}
                                                onChange={e => returnFields(e, 'color')}
                                                className={"refund_field"} type="name"/>
                                        </div>
                                    </div>

                            }
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>E-mail</span>
                                <input
                                    value={returnItem.email ? returnItem.email : ""}
                                    onChange={e => returnFields(e,'email')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            <div className={"gorizontal_fields_wrapper"}>
                                <div className="one_field_refund_wrapper">
                                    <span className={"one_field_refund mandatory"}>Серия и номер паспорта</span>
                                    <input
                                        value={returnItem.passportSeriesAndNumber ? returnItem.passportSeriesAndNumber : ""}
                                        onChange={e => returnFields(e,'passportSeriesAndNumber')}
                                        className={"refund_field"} type="name"/>
                                </div>
                                <div className="one_field_refund_wrapper">
                                    <span className={"one_field_refund mandatory"}>Дата выдачи</span>
                                    <input
                                        value={returnItem.dateOfIssue ? returnItem.dateOfIssue : ""}
                                        onChange={e => returnFields(e,'dateOfIssue')}
                                        className={"refund_field"} type="name"/>
                                </div>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Кем выдан</span>
                                <input
                                    value={returnItem.issuedByWhom ? returnItem.issuedByWhom : ""}
                                    onChange={e => returnFields(e,'issuedByWhom')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Номер заказа</span>
                                <input
                                    value={returnItem.orderNumber ? returnItem.orderNumber : ""}
                                    onChange={e => returnFields(e,'orderNumber')}
                                    className={"refund_field"} type="name"/>
                            </div>
                            <div className={"gorizontal_fields_wrapper"}>
                                <div className="one_field_refund_wrapper">
                                    <span className={"one_field_refund mandatory"}>Дата оформления заказа</span>
                                    <input
                                        value={returnItem.orderPlacingDate ? returnItem.orderPlacingDate : ""}
                                        onChange={e => returnFields(e,'orderPlacingDate')}
                                        className={"refund_field"} type="name"/>
                                </div>
                                <div className="one_field_refund_wrapper">
                                    <span className={"one_field_refund mandatory"}>Дата оформления заявления</span>
                                    <input
                                        value=  {formatDate(new Date(returnItem.createdAt ? returnItem.createdAt : ""))}
                                        className={"refund_field"} type="name"/>
                                </div>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Причина возврата</span>
                                <div className={"types_of_payment"}>
                                    {
                                        reasonForReturn ? reasonForReturn.map((item, index) =>
                                            <div key={index}
                                                 onClick={e=> reasonReturnHandler(item, 'reasonForReturn')}
                                                 className={"one_type_payment"}>
                                                <div className={returnItem.reasonForReturn  === item.name ? "selection_checkbox active" : "selection_checkbox"}></div>
                                                <div className={"name_type_payment"}>{item.name ? item.name : ''}</div>
                                            </div>
                                        ) : ''
                                    }
                                </div>
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund mandatory"}>Тип оплаты</span>
                                <div className={"types_of_payment"}>
                                    {
                                        paymentTypes ? paymentTypes.map((item, index) =>
                                            <div key={index}
                                                 onClick={e=> reasonReturnHandler(item, 'type')}
                                                 className={"one_type_payment"}>
                                                <div className={returnItem.type === item.name ? "selection_checkbox active" : "selection_checkbox"}></div>
                                                <div className={"name_type_payment"}>{item.name ? item.name : ''}</div>
                                            </div>
                                        ) : ''
                                    }
                                </div>
                                {
                                    returnItem.type == "Безналичный расчет" || returnItem.type == "Наличные"?
                                        <div className={"card_data_wrapper"}>
                                            <div className={"description_field_card"}>
                                                <div className={"one_field_card"}>Наименование банка</div>
                                                <div className={"one_field_card"}>Кор.Счёт</div>
                                                <div className={"one_field_card"}>Расчётный счет банка</div>
                                                <div className={"one_field_card"}>Счет получателя</div>
                                                <div className={"one_field_card"}>Номер карты</div>
                                            </div>
                                            <div className={"card_value_wrapper"}>
                                                <div className={"card_value_child"}>
                                                    <input
                                                        value={returnItem.bankName ? returnItem.bankName : ""}
                                                        onChange={e => returnFields(e,'bankName')}
                                                        placeholder={'AO ”Тинькофф Банк”'}
                                                        className={"one_value_card"} type="text"/>
                                                    <input
                                                        value={returnItem.bik ? returnItem.bik : ""}
                                                        onChange={e => returnFields(e,'bik')}
                                                        placeholder={'Бик: 234565214'}
                                                        className={"one_value_card non_decorate"} type="text"/>
                                                </div>
                                                <input
                                                    value={returnItem.correspondentAccount? returnItem.correspondentAccount : ""}
                                                    onChange={e => returnFields(e,'correspondentAccount')}
                                                    placeholder={'32154865452321456521'}
                                                    className={"one_value_card"} type="text"/>
                                                <input
                                                    value={returnItem.settlementAccount ? returnItem.settlementAccount : ""}
                                                    onChange={e => returnFields(e,'settlementAccount')}
                                                    placeholder={'6516847611114'}
                                                    className={"one_value_card"} type="text"/>
                                                <input
                                                    value={returnItem.account? returnItem.account : ""}
                                                    onChange={e => returnFields(e,'account')}
                                                    placeholder={'4848461156468451'}
                                                    className={"one_value_card"} type="text"/>
                                                <input
                                                    value={returnItem.cardNumber ? returnItem.cardNumber : ""}
                                                    onChange={e => returnFields(e,'cardNumber')}
                                                    placeholder={'3562 6448 9985 5632'}
                                                    className={"one_value_card"} type="text"/>
                                            </div>
                                        </div>
                                        : ''
                                }

                                {
                                    returnItem.returnType == 2 ?
                                        <div className={"self-refund"}>
                                            <div className={"subtitle_return_form no_restrictions"}>
                                                Выберете курьерскую службу (СДэк или Почта России)
                                            </div>
                                            <div className={"delivery_services_wrapper"}>
                                                <div className={"pvz_variant"}>
                                                    {
                                                        deliveryServices ? deliveryServices.map((item, index) =>
                                                            <div key={index}
                                                                 onClick={e=> reasonReturnHandler(item, 'courierService')}
                                                                 className={"one_pvz"}>
                                                                <div className={returnItem.courierService === item.name ? "selection_checkbox active" : "selection_checkbox"}></div>
                                                                {item.img ? <img src={item.img} alt="ПВЗ"/> : ''}
                                                            </div>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className={"treck_number_wrap"}>
                                                    <span className={"one_field_refund mandatory"}>Вставьте трек номер посылки</span>
                                                    <input
                                                        value={returnItem.trackNumber ? returnItem.trackNumber : ""}
                                                        onChange={e => returnFields(e,'trackNumber')}
                                                        className={"refund_field"} type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                            </div>
                            <div className="one_field_refund_wrapper">
                                <span className={"one_field_refund"}>Дополнителые поля</span>
                                <textarea
                                    value={returnItem.additionalFields ? returnItem.additionalFields : ""}
                                    onChange={e => returnFields(e,'additionalFields')}
                                    className={"refund_field textarea"} type="name"/>
                            </div>

                        </div>
                        : ''
                }


                <div className={"control_buttons_refund"}>
                    <button
                        onClick={save}
                        className={`save_category_button ${dataChanged ? 'active' : ''}`}>
                        Сохранить
                    </button>
                    <button
                        onClick={deleteItem}
                        className="delete_category_button">
                        Удалить
                    </button>
                </div>
            </div>


        </div>
    );
};

export default ReturnItemPage;
