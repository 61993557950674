import {makeAutoObservable} from "mobx";

export default class SettingsStore {
    constructor() {
       this._headerHeight = false;
       this._bodySize = '';
       this._resultModalActive = false;
       this._resultModalImage = "success";
       this._resultModalTitle = "Данные сохранены";
       this._resultModalTextOne = "Спасибо за заказ!";
       this._resultModalTextTwo = "Ожидайте звонка менеджера, чтобы уточнить подробности.";
       this._resultModalButtonText = "КО ВСЕМ ЗАКАЗАМ";
       this._offHeader = false;
       this._projectWidth = 0;
       this._projecHeight = 0;
       this._searchQuery = '';
       this._favoriteArray = null;
       this._countingPages = '';
       this._activeModalNonAuth = false;
       this._checkingUserOrder = false;
       this._checkingUrl = '';
       this._scrollStatusCatalog = 0;
       this._refundOption = null;
       this._stateFilerCatalog = "default";

       makeAutoObservable(this);
    }

    setOffHeader(bool) {
        this._offHeader = bool;
    }
    setProjectWidth(number) {
        this._projectWidth = number;
    }
    setProjecHeight(number) {
        this._projecHeight = number;
    }
    setHeaderHeight(string) {
        this._headerHeight = string;
    }
    setBodySize(number) {
        this._bodySize = number;
    }
    setResultModalActive(bool) {
        this._resultModalActive = bool;
    }
    setResultModalImage(string) {
        this._resultModalImage = string;
    }
    setResultModalTitle(string) {
        this._resultModalTitle = string;
    }
    setResultModalTextOne(string) {
        this._resultModalTextOne = string;
    }
    setResultModalTextTwo(string) {
        this._resultModalTextTwo = string;
    }
    setResultModalButtonText(string) {
        this._resultModalButtonText = string;
    }
    setSearchQuery(string) {
        this._searchQuery = string;
    }
    setFavoriteArray(array) {
        this._favoriteArray = array;
    }
    setCountingPages(string) {
        this._countingPages = string;
    }
    setActiveModalNonAuth(bool) {
        this._activeModalNonAuth = bool;
    }
    setCheckingUserOrder(bool) {
        this._checkingUserOrder = bool;
    }
    setCheckingUrl(string) {
        this._checkingUrl = string;
    }
    setScrollStatusCatalog(number) {
        this._scrollStatusCatalog = number;
    }
    setRefundOption(number) {
        this._refundOption = number;
    }
    setStateFilerCatalog(object) {
        this._stateFilerCatalog = object;
    }

    clearResultModal() {
        this.setResultModalActive(false);
        this.setResultModalImage("unset");
        this.setResultModalTitle("");
        this.setResultModalTextOne("");
        this.setResultModalTextTwo("");
        this.setResultModalButtonText("");
    }
    showUserResultModal(image, title, oneText, twoText, buttonText) {
        this.setResultModalActive(true);
        this.setResultModalImage(image);
        this.setResultModalTitle(title);
        this.setResultModalTextOne(oneText);
        this.setResultModalTextTwo(twoText);
        this.setResultModalButtonText(buttonText);
    }

    get projectWidth() {
        return this._projectWidth;
    }
    get projectHeight() {
        return this._projecHeight;
    }
    get offHeader() {
        return this._offHeader;
    }
    get headerHeight() {
        return this._headerHeight;
    }
    get bodySize() {
        return this._bodySize;
    }
    get resultModalActive() {
        return this._resultModalActive;
    }
    get resultModalImage() {
        return this._resultModalImage;
    }
    get resultModalTitle() {
        return this._resultModalTitle;
    }
    get resultModalTextOne() {
        return this._resultModalTextOne;
    }
    get resultModalTextTwo() {
        return this._resultModalTextTwo;
    }
    get resultModalButtonText() {
        return this._resultModalButtonText;
    }
    get searchQuery() {
        return this._searchQuery;
    }
    get favoriteArray() {
        return this._favoriteArray;
    }
    get countingPages() {
        return this._countingPages;
    }
    get activeModalNonAuth() {
        return this._activeModalNonAuth;
    }
    get checkingUserOrder() {
        return this._checkingUserOrder;
    }
    get checkingUrl() {
        return this._checkingUrl;
    }
    get scrollStatusCatalog() {
        return this._scrollStatusCatalog;
    }
    get refundOption() {
        return this._refundOption;
    }
    get stateFilerCatalog() {
        return this._stateFilerCatalog;
    }

}