import React, { memo, useState } from 'react';
import { formatAmount } from "../../../utils/format";
import {getTrackBackground, Range} from "react-range";

import styles from './FiltersForm.module.css';


const FiltersForm = memo(({filters, watchPriceChange, handlePriceChange, onChange, handleAcceptFilters, findProductsCount, disabledBtn }) => {
    return (
        <form className={styles.content}>
            <div>
                {filters.colors && filters.colors.length && (
                    <div className={`${styles.section} ${styles.sectionColors}`}>
                        <h3 className={styles.sectionTitle}>Цвет</h3>

                        <ul className={styles.colors}>
                            {filters.colors.map(({ id, color_name, hex, img, checked }) => (
                                <li key={id}>
                                    <label className={styles.colorLabel}>
                                        <input
                                            className={styles.colorInput}
                                            type="checkbox"
                                            name="colors"
                                            value={id}
                                            checked={checked}
                                            onChange={onChange}
                                        />
                                        <div
                                            className={styles.colorControl}
                                            style={img ? {['--background']: `url(${process.env.REACT_APP_API_URL}uploads/images/colors/${id}/${img})`} : { ['--background']: hex }}
                                        ></div>
                                        <div className={styles.colorContent}>{color_name}</div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {filters.sizes && filters.sizes.length && (
                    <div className={styles.section}>
                        <h3 className={styles.sectionTitle}>Размер</h3>

                        <ul className={styles.sizes}>
                            {filters.sizes.map(({ id, size_name, checked }) => (
                                <li key={id}>
                                    <label className={styles.sizesLabel}>
                                        <input
                                            className={styles.sizesInput}
                                            type="checkbox"
                                            name="sizes"
                                            value={id}
                                            checked={checked}
                                            onChange={onChange}
                                        />
                                        <div className={styles.sizesControl}>{size_name}</div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {filters.price_min && filters.price_max && filters.price_min < filters.price_max && (
                    <div className={styles.section}>
                        <h3 className={styles.sectionTitle}>Цена</h3>

                        <div className={styles.price}>
                            <Range
                                step={1}
                                min={filters.price_min}
                                max={filters.price_max}
                                onChange={watchPriceChange}
                                onFinalChange={handlePriceChange}
                                values={filters.filter_price}
                                renderTrack={({ props, children }) => (
                                    <div
                                        {...props}
                                        style={{
                                            ...props.style,
                                            background: getTrackBackground({
                                                values: filters.filter_price,
                                                colors: ['#B2AFAF', '#1A1A1A', '#B2AFAF'],
                                                min: filters.price_min,
                                                max: filters.price_max,
                                                rtl: false,
                                            }),
                                        }}
                                        className={styles.priceTrack}
                                    >
                                        {children}
                                    </div>
                                )}
                                renderThumb={({ props, index }) => (
                                    <div
                                        {...props}
                                        style={props.style}
                                        className={styles.priceThumb}
                                    >
                                        <div
                                            className={styles.priceLabel}
                                            style={{
                                                [index === 0 ? 'left' : 'right']: -15
                                            }}
                                        >
                                            {formatAmount(filters.filter_price[index])} руб
                                        </div>
                                    </div>
                                )}
                            />
                        </div>

                        <div className={styles.priceInputs}>
                            <div className={styles.priceInput}>
                                <span className={styles.priceInputLabel}>Мин. цена</span>
                                <input className={styles.priceInputField} disabled name="min_price" type="text" value={filters.filter_price[0]}/>
                            </div>
                            <div className={styles.priceInputsDeliver}>—</div>
                            <div className={styles.priceInput}>
                                <span className={styles.priceInputLabel}>Макс. цена</span>
                                <input className={styles.priceInputField} disabled name="max_price" type="text" value={filters.filter_price[1]}/>
                            </div>
                        </div>
                    </div>
                )}

                {filters.trends && filters.trends.length ? 
                    
                    <div className={styles.section}>
                        <h3 className={styles.sectionTitle}>Тренд</h3>

                        <ul className={styles.trends}>
                            {filters.trends.map(({ id, name, checked }) => (
                                <li key={id}>
                                    <label className={styles.trendsLabel}>
                                        <input
                                            className={styles.trendsInput}
                                            type="checkbox"
                                            name="trends"
                                            value={id}
                                            checked={checked}
                                            onChange={onChange}
                                        />
                                        <div className={styles.trendsControl}>{name}</div>
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                    : ""
                }
            </div>

            <button onClick={handleAcceptFilters} disabled={disabledBtn || findProductsCount === 0} className={styles.acceptBtn} type="button">Показать {findProductsCount} товаров</button>
        </form>
    );
});

export default FiltersForm;