import React from 'react';
import {Route, Routes} from "react-router-dom";
import AllProducts from "./allproducts/AllProducts";
import AddOneProduct from "./addOneProduct/AddOneProduct";
import './products.css';
const Products = () => {
    return (
        <div className={"homy_products_page"}>
            <Routes>
                <Route path="/oneitem/:productid/" element={<AddOneProduct/>} />
                <Route path="/*"  element={<AllProducts/>} />
            </Routes>
        </div>
    );
};

export default Products;