import React from 'react';
import './breadcrumbsCatalog.css';
import {Link, useParams} from "react-router-dom";

const BreadcrumbsCatalog = (props) => {
    const params = useParams()

    return (
        <div className={"breadcrumbs_block_catalog"}>
            <Link to={"/"} className={"breadcrumbs_item"}>Главная</Link>
            <Link to={"/catalog/"} className={"breadcrumbs_item"}>Каталог</Link>
            {
                props.breadcrumbsParams && props.breadcrumbsParams.parrent ?
                    <Link to={"/catalog/"+params.parrentCategory+"/"} className={"breadcrumbs_item"}>{props.breadcrumbsParams.parrent}</Link>
                    : ""

            }
            {
                props.breadcrumbsParams && props.breadcrumbsParams.child ?
                    <Link to={"/catalog/"+params.childCategory+"/"} className={"breadcrumbs_item"}>{props.breadcrumbsParams.child}</Link>
                    : ""

            }
            {
                props.breadcrumbsParams && props.breadcrumbsParams.third ?
                    <Link to={"/catalog/"+params.thirdCategory+"/"} className={"breadcrumbs_item"}>{props.breadcrumbsParams.third}</Link>
                    : ""

            }
            {
                props.breadcrumbsParams && props.breadcrumbsParams.fourth ?
                    <Link to={"/catalog/"+params.fourthCategory+"/"} className={"breadcrumbs_item"}>{props.breadcrumbsParams.fourth}</Link>
                    : ""

            }

        </div>
    );
};

export default BreadcrumbsCatalog;