import React, {useState} from 'react';
import './oneShop.css';
import CityChanger from "../../../UI/cityChanger/CityChanger";
import IsActiveIndicator from "../../../UI/isActiveIndicator/IsActiveIndicator";
import OneImageField from "../../../UI/oneImageField/OneImageField";
import {catalogProductSizePreset, contentShopImage} from "../../../../../utils/consts";
import {addImages, deleteImage} from "../../../../../http/imagesApi";
import CompliteRemove from "../../../UI/compliteremove/CompliteRemove";
import {deleteOneMarket, updateOneMarket} from "../../../../../http/contentApi";


const OneShop = (props) => {

    const [saveTrigerShop, setSaveTrigerShop] = useState(false);
    const [stateDeletedShop, setStateDeletedShop] = useState(false)

    const setCity = (value) => {
        props.setShopArray([...props.shopArray],[props.shopArray[props.index].city = value,
            props.shopArray[props.index].cityId = value.id])
        setSaveTrigerShop(true);
    }

    const fieldsHanlerShop = (e,fieldName) => {
        props.setShopArray([...props.shopArray],[props.shopArray[props.index][fieldName] = e.target.value])
        setSaveTrigerShop(true)
    }

    const changeActiveShopChild = () => {
        props.setShopArray([...props.shopArray],[props.shopArray[props.index].is_active = !props.item.is_active])
        setSaveTrigerShop(true)
    }


    const uploadShopChildtImage = (e,id) => {


        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (props.item.img_shop){
            deleteImage(props.item.img_shop, "shop", null).then(response  => {
                props.setShopArray([...props.shopArray],[props.shopArray[props.index].img_shop = ""])
                setSaveTrigerShop(true)
            })
        }

        addImages(formData,'shop',null, contentShopImage).then(response => {
            if(response.message) {
                props.setShopArray([...props.shopArray],[props.shopArray[props.index].img_shop = response.fileName])
                setSaveTrigerShop(true)
            }
        })

    }

    const deleteImageShopChildOnClick = (filename) => {
        deleteImage(filename, "shop", null).then(response  => {
            props.setShopArray([...props.shopArray],[props.shopArray[props.index].img_shop = ""])
            setSaveTrigerShop(true)
        })
    }


    const deleteOneShopHandler = (id) => {
        deleteOneMarket(id).then(response => {
            if(response.message){
                props.setRefreshDbShop(!props.refreshDbShop)
                setStateDeletedShop(!stateDeletedShop)
            }
        } )
    }
    const controllDeleteState = (bool) => {
        setStateDeletedShop(bool)
    }


    const saveOneShopHandler = (fields) => {
        updateOneMarket(fields).then(response => {
            if(response.message) {
                setSaveTrigerShop(false)
            }
        } )
    }


    return (
        <div className={"one_shop_adm"}>

            <CompliteRemove open={stateDeletedShop} canselRemove={ e => controllDeleteState(false)} goRemove={e => deleteOneShopHandler(props.item.id)} />
            <div className={"delete_cars_shop_relative"}>
                <div
                    onClick={e => controllDeleteState(true)}
                    className={"delete_cars_shop"}></div>
            </div>
            <div className={"shop_city_container"}>
                <div className={"preview_img_shop"}>
                    <OneImageField text={"Превью магазина"}
                                   image={props.item.img_shop ?
                                       process.env.REACT_APP_API_URL+"uploads/images/shop/"+ props.item.img_shop : null }
                                   onChange={ e => uploadShopChildtImage(e,'img_shop',catalogProductSizePreset)}
                                   deleteImage={e => deleteImageShopChildOnClick(props.item.img)}
                    />
                </div>
                <div className={"wrap_create_shop"}>

                    <div className="left_container_shop">
                        <span className="shop_field_label_name">Наименование магазина</span>
                        <input
                            value={props.item.name_market ? props.item.name_market : ""}
                            onChange={e => fieldsHanlerShop(e,"name_market") }
                            className="input_field_shop" type="text"/>
                    </div>
                    <div className="left_container_shop">
                        <span className="shop_field_label_name">Адрес</span>
                        <textarea
                            value={props.item.address ? props.item.address : ""}
                            onChange={e => fieldsHanlerShop(e,"address") }
                            className="input_field_shop textarea" type="text" />
                    </div>
                    <div className="left_container_shop">
                        <span className="shop_field_label_name">Режим работы</span>
                        <textarea
                            value={props.item.work_schedule ? props.item.work_schedule : ""}
                            onChange={e => fieldsHanlerShop(e,"work_schedule") }
                            className="input_field_shop textarea" type="text"/>
                    </div>
                    <div className="left_container_shop">
                        <span className="shop_field_label_name">Номер телефона (виден пользователям)</span>
                        <input
                            value={props.item.front_number ? props.item.front_number : ""}
                            onChange={e => fieldsHanlerShop(e,"front_number") }
                            className="input_field_shop" type="text" />
                    </div>
                    <div className="left_container_shop">
                        <span className="shop_field_label_name">Номер телефона для кнопки (без пробелов, скобок и символов)</span>
                        <input
                            value={props.item.back_number ? props.item.back_number : ""}
                            onChange={e => fieldsHanlerShop(e,"back_number") }
                            className="input_field_shop" type="text"/>
                    </div>
                    <div className="left_container_shop">
                        <span className="shop_field_label_name">Ссылка на карту магазина</span>
                        <textarea
                            value={props.item.map_url ? props.item.map_url : ""}
                            onChange={e => fieldsHanlerShop(e,"map_url") }
                            className="input_field_shop textarea" type="text" />
                    </div>
                </div>
                <div className={"right_container_city"}>
                    <div
                        onClick={e => changeActiveShopChild()}
                        className={"is_active_shop"}>
                        <IsActiveIndicator active={props.item.is_active}  />
                    </div>
                    <div className={"add_city_shop"}>
                        {
                            props.shopArray ?
                                <CityChanger
                                    targetCity={props.item.city}
                                    setCity={e => setCity(e)}
                                    cityName={props.item.city.city}
                                    isBottom={true}
                                />
                                :
                                ""
                        }
                    </div>
                </div>
            </div>

            <div className={"wrap_btn_one_shop"}>
                <div
                    onClick={e => saveOneShopHandler(props.item)}
                    className={ saveTrigerShop ? "save_btn_one_shop trig" : "save_btn_one_shop"}>Сохранить</div>
            </div>
        </div>
    );
};

export default OneShop;