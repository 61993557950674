
import {$authHost,$host} from "./index";
import {getCategories, getSizeIdByName} from "./catalogApi";
import { getClientID } from "../components/YM/YM";

export const createNewOrder = async (orderData) => {
    const clientid = getClientID();
    const {data} = await $host.post('/api/order/createneworder', {orderData, clientid})

    if(data.status === 'success') {
        const basketItems = JSON.parse(localStorage.getItem('basketStorage'))
        if(basketItems) {
            const categoryIds = []
            basketItems.forEach((item) => {
                const {product} = item.item
                categoryIds.push(...product.product_category)
            })

            getCategories(categoryIds).then(async categoriesResponse => {
                let products = [];
                for(let oneProduct of basketItems) {
                    let category = ''
                    if (categoriesResponse.success) {
                        category = oneProduct.item.product.product_category.map((id) => categoriesResponse.data[id]).join('/')
                    }
                    let findSize = null;
                    if (oneProduct.item.child_product_size) {
                        findSize = oneProduct.item.child_product_size.find(element =>  element.size_name === oneProduct.currentSize);
                    }
                    if (!findSize) {
                        const sizeData = await getSizeIdByName(oneProduct.currentSize);
                        if (sizeData.success) {
                            findSize = sizeData.data
                        }
                    }
                    if (category && findSize) {
                        products.push(
                            {
                                id: String(oneProduct.item.id)+String(oneProduct.item.color.id)+findSize.id,
                                name: oneProduct.item.product.name,
                                price: oneProduct.item.sell_price ? oneProduct.item.sell_price : oneProduct.item.parrent_price,
                                category,
                                variant: oneProduct.item.color.color_name,
                                quantity: oneProduct.count,
                            }
                        )
                    }
                }
                if (products.length) {
                    window.dataLayer.push({
                        event: "ym-ecom",
                        eCategory: "ecommerce",
                        eAction: "purchase",
                        eLabel: "cart",
                        ecommerce: {
                            currencyCode: "RUB",
                            purchase: {
                                actionField: {
                                    id: data.order.custom_order_id,
                                    revenue: data.order.result_price - data.order.deliveryPrice,
                                    shipping: data.order.deliveryPrice
                                },
                                products
                            }
                        }
                    })
                }
            })
        }
    }
    return data;
}


export const getOneOrder = async (id) => {
    // console.log(id)
    const {data} = await $authHost.post('/api/order/getoneorder', {id})
    return  data;
}

export const getAllOrders = async (limit,offset,getCount, pattern='') => {
    const {data} = await  $authHost.post("/api/order/getallorders",{limit,offset, getCount, pattern});
    return  data;
}
export const getAllOrderStatus = async () => {
    const {data} = await  $authHost.post("/api/order/getallstatus");
    return  data;
}
export const getAllPayments = async () => {
    const {data} = await  $authHost.post("/api/order/getallpayments");
    return  data;
}

export const changeOrderStatus = async (orderId,statusOrderId) => {
    const {data} = await  $authHost.post("/api/order/changeorderstatus",{orderId, statusOrderId});
    return  data;
}
export const changeDataAfterPayOrder = async (options) => {
    const {data} = await  $authHost.post("/api/order/changedataafterpay",{options});
    return  data;
}
export const changeOrderPayment = async (orderId,paymentId) => {
    const {data} = await  $authHost.post("/api/order/changeorderpayment",{orderId, paymentId});
    return  data;
}

export const changeOrderDelivery = async (orderId,deliveryId) => {
    const {data} = await  $authHost.post("/api/order/changeorderdelivery",{orderId, deliveryId});
    return  data;
}

export const checkActive = async () => {
    const {data} = await  $authHost.post("/api/order/checkactive");
    return  data;
}

export const createUserCmp = async (identificator,number) => {
    const {data} = await  $authHost.post("/api/order/createcmpuser",{identificator,number});
    return  data;

}

export const changeComment = async (orderId,commentInfo) => {
    const {data} = await  $authHost.post("/api/order/changeordercomment",{orderId, commentInfo});
    return  data;
}

export const dolyamiMethodCreate = async (order) => {
    const {data} = await  $authHost.post("/api/order/dolyamicreate",{order});
    return  data;
}

export const yandexMethodCreate = async (order) => {
    const {data} = await  $authHost.post("/api/order/ysplitcreate",{order});
    return  data;
}

export const yandexMethodFind = async (orderId) => {
    const {data} = await  $authHost.post("/api/order/ysplitfindone",{orderId});
    return  data;
}

export const refoundDolyami = async (order,items) => {
    const {data} = await $authHost.post("/api/order/rejectdolyami", {order,items})
    return data;
}

export const refoundYSplit = async (order,items) => {
    const {data} = await $authHost.post("/api/order/refoundysplit", {order,items})
    return data;
}

export const refoundDolyamiCh = async (order,items) => {
    const {data} = await $authHost.post("/api/order/rejectdolyamich", {order,items})
    return data;
}

export const refoundPartYSplit = async (order,items) => {
    const {data} = await $authHost.post("/api/order/refoundpartysplit", {order,items})
    return data;
}

export const refoundCP = async (order,items) => {
    const {data} = await $authHost.post("/api/order/rejectcp", {order,items})
    return data;
}
export const refoundCPCh = async (order,items) => {
    const {data} = await $authHost.post("/api/order/rejectcpch", {order,items})
    return data;
}

export const saveChangeDeliveryAddressFields = async (order) => {
    const {data} = await  $authHost.post("/api/order/savedeliveryaddressfields",{order});
    return  data;
}

export const createPreOrder = async (orderData,orderUserFields,UserAccountFields,deviceUUID,userAgent) => {
    const clientid = getClientID();
    const {data} = await $host.post('/api/order/createpreorder', {orderData,orderUserFields,UserAccountFields,clientid,deviceUUID,userAgent})

    const {product} = orderData.item
    getCategories(product.product_category).then(categoriesResponse => {
        let category = ''
        if (categoriesResponse.success) {
            category = product.product_category.map((id) => categoriesResponse.data[id]).join('/')
            let findSize = null;
            if (orderData.item.child_product_size) {
                findSize = orderData.item.child_product_size.find(item => item.size_name === orderData.currentSize)
            }
            if (findSize) {
                const products = [{
                    id: String(product.id)+String(orderData.colorId)+findSize.id,
                    name: product.name,
                    price: orderData.item.sell_price ? orderData.item.sell_price : orderData.item.parrent_price,
                    category,
                    variant: orderData.item.color.color_name,
                    quantity: orderData.count,
                }]

                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: "add",
                    ecommerce: {
                        currencyCode: "RUB",
                        add: {products}
                    }
                })

                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: "purchase",
                    eLabel: "preorder",
                    ecommerce: {
                        currencyCode: "RUB",
                        purchase: {
                            actionField: {
                                id: data.order.custom_order_id,
                                revenue: product.price,
                                shipping: 0
                            },
                            products
                        }
                    }
                })
            }

        }

    })

    return data;
}

export const createNotifyOfReceiptOrder = async (orderData, orderUserFields, UserAccountFields, cityInfo = null, isFastOrder = false) => {
    const clientid = getClientID();
    let api = '/api/order/createnotificationorder';
    if (isFastOrder) {
        api = '/api/order/createfastorder'
    }
    const {data} = await $host.post(api, {orderData,orderUserFields,UserAccountFields,clientid,cityInfo})
    const {product} = orderData.item

    getCategories(product.product_category).then(categoriesResponse => {
        let category = ''
        if (categoriesResponse.success) {
            category = product.product_category.map((id) => categoriesResponse.data[id]).join('/')
            let findSize = null;
            if (orderData.item.child_product_size) {
                findSize = orderData.item.child_product_size.find(item => item.size_name === orderData.currentSize)
            }
            if (findSize) {
                const products = [{
                    id: String(product.id)+String(orderData.colorId)+findSize.id,
                    name: product.name,
                    price: orderData.item.sell_price ? orderData.item.sell_price : orderData.item.parrent_price,
                    category,
                    variant: orderData.item.color.color_name,
                    quantity: orderData.count,
                }]
                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: "add",
                    ecommerce: {
                        currencyCode: "RUB",
                        add: {products}
                    }
                })

                window.dataLayer.push({
                    event: "ym-ecom",
                    eCategory: "ecommerce",
                    eAction: "purchase",
                    eLabel: isFastOrder ? "buy_one_click" : "notification",
                    ecommerce: {
                        currencyCode: "RUB",
                        purchase: {
                            actionField: {
                                id: data.order.custom_order_id,
                                revenue: orderData.item.sell_price ? orderData.item.sell_price : orderData.item.parrent_price,
                                shipping: 0
                            },
                            products
                        }
                    }
                })
            }
        }
    })

    return data;
}
export const сreateSertificateOrder = async (preorderInfo,userData,UserAccountFields) => {
    const clientid = getClientID();
    const {data} = await $host.post('/api/order/createsertificateorder', {preorderInfo,userData,UserAccountFields,clientid})

    return data;
}

export const findSertificate = async (sertificateCode,orderInfo) => {
    const {data} = await $host.post('/api/order/findsertificate', {sertificateCode,orderInfo})
    return data;
}
export const payOrderSertificate = async (certificates, orderInfo) => {
    const {data} = await $host.post('/api/order/payordersertificate', {certificates, orderInfo})
    return data;
}
export const changeOrderUserFields = async (currentOrder) => {
    const {data} = await $authHost.post('/api/order/changeorderuserfield', {currentOrder})
    return data;
}
export const saveOrderCity = async (orderId,object) => {
    const {data} = await $authHost.post("/api/order/changeordercityinfo", {orderId,object})
    return data;
}


export const fetchChildProductsForOrder = async (childIdArray) => {
    const {data} = await $authHost.post('/api/order/fetchchildproductsfororder', {childIdArray})
    return data;
}

export const searchProductsBySearchValue = async (value) => {
    const {data} = await $authHost.post('/api/order/searchproductsbysearchvalue', {value})
    return data;
}

export const addNewItemToOrder = async (item,orderId) => {
    const {data} = await $authHost.post('/api/order/addnewitemtoorder', {item,orderId});
    return data;
}

export const saveChangesOrderBasketItems = async (items,orderId) => {
    const {data} = await $authHost.post('/api/order/savechangesorderbasketitems', {items,orderId})
    return data;
}
