import React, {useContext, useEffect, useState} from 'react';
import './like.css';
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import {  mindboxAddToWishList, mindboxRemoveFromWishList } from "../../../http/catalogApi";
import {  addFavorite, deleteFavorite } from "../../../http/favoriteApi";


const Like = observer((props) => {

    const {settings} = useContext(Context)
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')
    const {user} = useContext(Context)

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })

    const checkExistFavoriteProduct = () => {

        let favoriteJson = settings.favoriteArray;
        if(!favoriteJson) {
            return false;
        }

        for(const oneFavorite of favoriteJson ) {
            if(props.itemProduct.id === oneFavorite.id) {
                return true;
            }
        }

        return false;

    }

    const addProductFavorite = (object) => {
       //Пустая переменная для хранения товаров в фаворитах
        let currentFavoriteArray = [];
        // Мы получаем с локального стора товары для фаворитов.
        let currentFavoriteStorage = localStorage.getItem('favoriteStorage');

        if(!currentFavoriteStorage) {
            // Если в сторе нету товаров, мы сразу пушим 1 товар, конвертируем его в JSON и записываем в стор.
            currentFavoriteArray.push(object);
            let jsonCurrentFavoriteArray = JSON.stringify(currentFavoriteArray)
            if(user.isAuth) {
                addFavorite(object.id, user.user.id)
            }
            localStorage.setItem("favoriteStorage", jsonCurrentFavoriteArray)
        } else {
            // Если в сторе уже что-то есть, мы в пустой массиф сохраняем результат получения и депарсинга JSON
            currentFavoriteArray = JSON.parse(currentFavoriteStorage);

            let indexItem = 0;
            let isFind = false;

            for(const oneFavoriteItem of currentFavoriteArray) {
                if(oneFavoriteItem.id === object.id) {
                    currentFavoriteArray.splice(indexItem,1);
                    if(user.user.id) {
                        deleteFavorite(object.id, user.user.id)
                    }
                    isFind = true;
                }
                indexItem++
            }

            if(!isFind) {
                if(user.isAuth) {
                    addFavorite(object.id, user.user.id)
                }
                currentFavoriteArray.push(object);
                props.onAddInFavorite && props.onAddInFavorite()
            }

            let jsonCurrentFavoriteArray = JSON.stringify(currentFavoriteArray)
            localStorage.setItem("favoriteStorage",jsonCurrentFavoriteArray)

            settings.setFavoriteArray(currentFavoriteArray)
        }
    }

    return (
        <div
            onClick={e=> {
                e.stopPropagation();
                addProductFavorite(props.itemProduct)
                const sizeId = (props.itemProduct.child_product_size && props.itemProduct.child_product_size.length)
                    ? props.itemProduct.child_product_size[0].id
                    : props.itemProduct?.child_product_size_field[0].sizeId ? props.itemProduct.child_product_size_field[0].sizeId : null;

                const currentSize = props.itemProduct.child_product_size?.find(item => item.id === sizeId);
                const guid = currentSize?.childProduct_Size?.guid ?? props.itemProduct.id;

                if (sizeId) {
                    if (checkExistFavoriteProduct()) {
                        mindboxAddToWishList(deviceUUID, userAgent, guid, props.itemProduct.parrent_price)
                    } else {
                        mindboxRemoveFromWishList(deviceUUID, userAgent, guid, props.itemProduct.parrent_price)
                    }
                }
            }}
            className={ checkExistFavoriteProduct() ? "like_wrapper active" :  "like_wrapper"}>
            <svg width="30" height="27" viewBox="0 0 30 27"  xmlns="http://www.w3.org/2000/svg">
                <path d="M14.0764 5.14371L14.7905 5.87065L15.5039 5.14291L16.6725 3.95064C16.6726 3.95059 16.6726 3.95054 16.6727 3.95048C19.2773 1.29933 23.486 1.29938 26.0905 3.95063C28.6981 6.60517 28.6966 10.905 26.0916 13.5726C26.0912 13.573 26.0909 13.5733 26.0905 13.5737L14.8338 25.0259L14.8336 25.0261C14.8247 25.0351 14.8174 25.0392 14.8118 25.0415C14.8056 25.044 14.7986 25.0455 14.7912 25.0455C14.7838 25.0455 14.7768 25.044 14.7706 25.0415C14.7651 25.0392 14.7577 25.0351 14.7489 25.0261L3.49863 13.5739C3.49863 13.5739 3.49862 13.5739 3.49862 13.5739C0.889777 10.9182 0.889789 6.60522 3.49862 3.94956L3.49862 3.94956C6.09772 1.3038 10.3042 1.30381 12.9032 3.94955L12.9033 3.94958L14.0764 5.14371Z" stroke="#1A1A1A" strokeWidth="2"/>
            </svg>
        </div>
    );
});

export default Like;
