import React, {useContext, useEffect, useState} from 'react';
import './SubscribeForm.css';
import FormInput from "../UI/SubFormComponents/FormInput/FormInput";
import SubscribeButton from "../UI/SubFormComponents/SubscribeButton/SubscribeButton";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import ModalSubscriptions from "../homepage/ModalSubscriptions/ModalSubscriptions";
import ResultReviwsModal from "../ReviewsPage/reviewsModal/ResultReviwsModal";
import {subscribeNewsletter} from "../../http/settingsApi";

const SubscribeForm = observer( (props) => {

    const {user,settings} = useContext(Context)

    const [email, setEmail] = useState('');

    const [stateSubsHome, setStateSubsHome] = useState(false);
    const [cheсkBoxApprovalHom, setCheсkBoxApprovalHom] = useState(false)
    const [errSoglasieHome, setErrSoglasieHome] = useState('')
    const [errSubscribeHome, setErrSubscribeHome] = useState('')
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')

    const openModalSubscriptions = () => {
        setStateSubsHome(true)
        user.setShadowOverlay(true);
    }

    const closeModalSubscriptions = () => {
        setStateSubsHome(false);
        user.setShadowOverlay(false);
    }

    useEffect(() => {
        if (user.shadowOverlay === false) {
            setStateSubsHome(false);
        }
    }, [user.shadowOverlay])

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })


    const subscribeHome = (onclick) => {
        if(cheсkBoxApprovalHom != false) {
            subscribeNewsletter(email, 4, deviceUUID, userAgent, 'subscriptionInFooter').then(res => {
                if(res.message) {
                    setEmail('')
                    openModalSubscriptions()
                    setErrSubscribeHome('')
                    setErrSoglasieHome('')
                    setCheсkBoxApprovalHom(false)

                    window.dataLayer.push({
                        event: 'subscription_success', // название события, не меняется
                    })

                    return false;
                }
                setErrSubscribeHome(res.error)
                // console.log(res.error)

            } )

            return false

        }
        setErrSoglasieHome('Примите соглашение')

    }

    // console.log(email)

    return (
        <div className="subWrapper">
            <div className="Sub_Form_Container">
                <div className="left_Sub_Container">
                    <span className="heading_SubForm">{props.titleSubscriptionForm ? props.titleSubscriptionForm : ''}</span>
                    <form className="sub_form_home" action="">
                        <FormInput
                            type="text"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                        />
                        <div className={"error_mess_sub_home"}>{errSubscribeHome}</div>
                        <SubscribeButton
                            openModalSubscriptions = {e => subscribeHome()}
                            stateCheсkBox={cheсkBoxApprovalHom}
                        />

                        <ModalSubscriptions
                            className={stateSubsHome === true ? "active" : "hide"}
                            closeClick={() => closeModalSubscriptions()}
                        />

                    </form>

                    <div className="politic_sub_home_wrap">
                        <div className={"policy_form"}>
                            <div
                                onClick={e=> setCheсkBoxApprovalHom(!cheсkBoxApprovalHom)}
                                className={cheсkBoxApprovalHom === true ? "confirm_selector_home active" : "confirm_selector_home"}>
                                <div className="child_selector_home">
                                </div>
                            </div>

                            <span> <noindex>Нажимая кнопку “ПОДПИСАТЬСЯ”, вы соглашаетесь с нашей  <a rel="noreferrer"  target={"_blank"} href="/personal/privacy-policy/">политикой конфиденциальности</a></noindex> </span>
                        </div>
                        <div className={"error_mess_sub_home"}>{errSoglasieHome }</div>
                    </div>
                </div>
                {/*<div className="right_Sub_Container">*/}
                {/*    <img src={'/files/images/1921.jpg'} alt=""/>*/}
                {/*    {*/}
                {/*        settings.projectWidth &&  settings.projectWidth < 1024 ?*/}
                {/*            <span className="heading_SubForm">Подпишитесь и узнайте о новинках первым!</span>*/}
                {/*            :*/}
                {/*            ""*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        </div>
    );
});

export default SubscribeForm;