import React, {useContext} from 'react';
import './basketStep.css';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../../index";
import {priceToUserString} from "../../../../../../http/basketApi";
import TrashBasket from "../../../../../UI/trashBasket/TrashBasket";
import {useNavigate} from "react-router";

const BasketStep = observer((props) => {

    const {user, basket} = useContext(Context);
    const navigate = useNavigate();
    const placeAnOrderFlyBasket = () => {

        if(user.isAuth) {
            navigate('/personal/basket/')
            user.setOpenAuthModal(0);
            user.setShadowOverlay(false)
            basket.setStartBasketNum(2);
            return false
        }

        basket.setRegistrationOnFlyBasket(true)
        user.setStepModals('orderRegistrationOrNot')
    }

    const changeCountAuthFlyController = (modifier, item) => {
        basket.chenageCountItem(modifier, item)
    }

    const deleteOneItemAuthControllerFly = (item) => {
        basket.removeItem(item);
    }

    const deleteAuthAllItemsControllerFly = () => {
        basket.removeAllItems('all')
    }
    const deleteAll = () => {
        deleteAuthAllItemsControllerFly(user.user.id);
        props.deleteAll()
    }

    return (
        <div className={"fly_basket_step_modal"}>
            <div className={"count_items_and_clear_basket_wrap"}>
               <div className={"count_items_line"}><span>Всего товаров: <span className={"counter_weight"}>{basket.countItems}</span></span>


                </div>
                <span
                    onClick={ e => deleteAll() }
                    className={"delete_all_products"}> Очистить все </span>
            </div>
            <div className={"fly_basket_items_container"}>
                {
                    basket.basketItems[0] ?
                        <div className={
                            basket.basketItems && basket.basketItems.length > 1 ? "for_items_wrapper" : "for_items_wrapper_non"}>
                            {
                                basket.basketItems.map((item,index) => {
                                    return(
                                        <div key={index} className={"one_fly_basket_item"}>
                                            <div className={"fly_basket_image_wrapper"}>
                                                <img src={process.env.REACT_APP_API_URL+"uploads/images/"+item.item.product.id+"/"+item.item.id+"/"+"214_"+item.item.img_preview}
                                                    alt={'YouWanna - ' +(item.item.name ? item.item.name : '')}
                                                />
                                            </div>
                                            <div className={"fly_basket_content_container_one"}>
                                                <div className={"fly_basket_one_container_heading"}>
                                                    {item.item.name}
                                                </div>
                                                <div className={"fly_basket_content_artikul"}>
                                                    <span>Артикул: {" "+item.item.product.articul}</span>
                                                </div>
                                                <div className={"fly_basket_count_price"}>
                                                    <span className={"fly_basket_sell_price"}>{item.item.sell_price ? priceToUserString(item.item.sell_price)+" ₽" : "" }</span>
                                                    <span className={item.item.sell_price ? "fly_basket_price_one old_price" : "fly_basket_price_one"}>{priceToUserString(item.item.product.price)+" ₽"}</span>
                                                </div>
                                                <div className={"fly_basket_content_line_size"}>
                                                    <span className={"name_line_fly_basket"}>Цвет:</span>
                                                    <span className={"props_line_fly_basket_color"}>{item.item.color.color_name}</span>
                                                </div>
                                                <div className={"fly_basket_content_line_size"}>
                                                    <span className={"name_line_fly_basket"}>Размер:</span>
                                                    <span className={"props_line_fly_basket"}>{item.currentSize}</span>
                                                </div>
                                                <div className={"fly_basket_content_line_size counter"}>
                                                    <span className={"name_line_fly_basket"}>Кол-во:</span>
                                                        <div className={"count_one_product_controller"}>
                                                            <div
                                                                onClick={e => changeCountAuthFlyController("decrease", item)}
                                                                className={"count_one_item_controller min"}>

                                                            </div>
                                                            <div className={"count_one_item_controller count"}>{item.count}</div>
                                                            <div
                                                                onClick={e => changeCountAuthFlyController("increase", item)}
                                                                className={"count_one_item_controller max"}>

                                                            </div>
                                                        </div>
                                                    <div
                                                        onClick={e => deleteOneItemAuthControllerFly(item)}
                                                        className={"fly_basket_trash_wrapper"}>
                                                        <TrashBasket/>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    : 
                        <div className={"no_basket_items_modal"}>Товаров не найдено</div>
                }
            </div>
            {
                basket.basketItems.length ?
                    <div className={"wrap_promo_and_price"}>

                        <div className={"result_price_fly_basket"}>
                            <span className={"fly_basket_non_sell_price"}>{basket.priceNoSell !== basket.totalPrice ? priceToUserString(basket.priceNoSell)+'₽' : ''}</span>
                            <div className={"fly_basket_final_price"}>
                                <span className={"fly_basket_result_text"}>Итого:</span>
                                <span className={"fly_basket_result_price"}>{basket.allPrice} ₽</span>
                            </div>
                        </div>

                        <div
                            onClick={e => placeAnOrderFlyBasket()}
                            className={"fly_basket_place_an_order"}>
                            Оформить заказ
                        </div>

                    </div>

                : ""
            }
        </div>
    );
});

export default BasketStep;
