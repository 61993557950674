import React, {useEffect, useState} from 'react';
import './adminContactsPage.css'
import OneImageHorizontalField from "../../UI/OneImageHorizontalField/OneImageHorizontalField";
import {getOnePageContent, updateContentPage} from "../../../../http/contentApi";
import OneCardsContact from "./contactCards/OneCardsContact";
import SocialIcons from "./socialIcons/SocialIcons";
import {fetchAllSocial} from "../../../../http/settingsApi";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import {contentBackgroundImage} from "../../../../utils/consts";
import ActiveSocialIcons from "./activeSocialIcons/ActiveSocialIcons";

const AdminContactsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])

    const [pageContent,setPageContent] = useState({});
    const [noJson,setNoJsonContent] = useState({});
    const [iconsSocialArray, setIconsSocialArray] = useState([]);
    const [updateSocialSaveTriger, setUpdateSocialSaveTriger] = useState(false);
    const oneCart =  {title:"", text_cards: "", name_buttom:"", link: "", active_target_blank: false };

    const addNewCartHandler = (object) => {
            let reduceNoJson = {...noJson};
            reduceNoJson.cardsInfo.push(object)
        setNoJsonContent(reduceNoJson)
        setUpdateSocialSaveTriger(true)
    }

    const deleteOneCards = (id) => {
            let deleteNoJson = {...noJson};
            deleteNoJson.cardsInfo.splice(id,1)
        setNoJsonContent(deleteNoJson)
        setUpdateSocialSaveTriger(true)

    }

    useEffect(() => {
        getOnePageContent(5).then(response => {
            setPageContent(response)

            if(response.content) {
                setNoJsonContent(JSON.parse(response.content))
                return false;
            }
                setNoJsonContent({

                    cardsInfo: [

                    ],
                    socialNetwork:{

                    },
                    activSocial: [

                    ],
                    requisitesBlock: {

                    }
                })
        } )

    },[])


    useEffect((fields) => {
        fetchAllSocial(fields).then(response => {
            setIconsSocialArray(response)
        })
    },[])


    useEffect(() => {
        setPageContent({...pageContent, content:JSON.stringify(noJson)})
    }, [noJson])




    const fieldsHanler = (e,objectName, fieldName) => {
        setNoJsonContent({...noJson}, [noJson[objectName][fieldName] = e.target.value])
        setUpdateSocialSaveTriger(true)
    }


    const uploadContentImageSocial = (e,id) => {


        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (noJson.img){
            deleteImage(noJson.img, "social", null).then(response  => {
                setNoJsonContent({...noJson,img: ""})            })
            setUpdateSocialSaveTriger(true)

        }
        addImages(formData,'social',null, contentBackgroundImage).then(response => {
            if(response.message) {
                setNoJsonContent({...noJson,img:response.fileName})
                setUpdateSocialSaveTriger(true)
            }
        })

    }

    const deleteImageSocialOnClick = (filename) => {
        deleteImage(filename, "social", null).then(response  => {
            setNoJsonContent({...noJson,img: ""})
            setUpdateSocialSaveTriger(true)
        })
    }


    const updateInformationSocial = (pageContent) => {
        updateContentPage(pageContent).then(response => {
            setUpdateSocialSaveTriger(false)
        } )
    }


    return (
        <div className={"wrapper_page_contacts"}>
            {
                pageContent ?
                    <div className={"contacts_wrapper"}>
                        <div className={"contacts_adm_title"}>Менеджер контактов</div>
                        <div className={"workspace_area_contacts"}>
                            <div className={"top_container_contacts"}>
                                <div className={"content_manager_contacts"}>
                                    <div className={"left_wrapper_contacts"}>
                                        <div className={"left_fields_container"}>
                                            <span className="contacts_field_label_name">Название страницы</span>
                                            <input
                                                value={pageContent.name ? pageContent.name : ""}
                                                onChange={e => (setPageContent({...pageContent, name: e.target.value})
                                                        ,setUpdateSocialSaveTriger(true)
                                                )}
                                                className="contacts_field" type="text"/>
                                        </div>
                                        <div className={"left_fields_container"}>
                                            <span className="contacts_field_label_name">title</span>
                                            <input
                                                value={pageContent.title ? pageContent.title : ""}
                                                onChange={e => (setPageContent({...pageContent, title: e.target.value})
                                                        ,setUpdateSocialSaveTriger(true)
                                                )}
                                                className="contacts_field" type="text" />
                                        </div>
                                        <div className={"left_fields_container"}>
                                            <span className="contacts_field_label_name">descriprion</span>
                                            <input
                                                value={pageContent.description ? pageContent.description : ""}
                                                onChange={e => (setPageContent({...pageContent, description: e.target.value})
                                                        ,setUpdateSocialSaveTriger(true)
                                                )}
                                                className="contacts_field" type="text" />
                                        </div>
                                    </div>
                                    <div className={"right_wrapper_contacts"}>
                                        <OneImageHorizontalField
                                            onChange={ e => uploadContentImageSocial(e,pageContent.id) }
                                            image={noJson.img ?
                                                process.env.REACT_APP_API_URL+"uploads/images/social/"+ noJson.img : null }
                                            deleteImage={e => deleteImageSocialOnClick(noJson.img)}

                                        />
                                    </div>
                                </div>
                                <div className={"bottom_container_contacts"}>
                                    <div
                                        onClick={e => addNewCartHandler(Object.assign({}, oneCart ))}
                                        className={"button_new_cards"}>Добавить блок</div>
                                    <div className={"container_cards_contact"}>
                                        {
                                            noJson.cardsInfo ? noJson.cardsInfo.map((item,index) =>
                                                    <OneCardsContact
                                                        item={item}
                                                        index={index}
                                                        key={index}
                                                        noJson = {noJson}
                                                        setNoJsonContent = {setNoJsonContent}
                                                        deleteOneCards = {e => deleteOneCards(e)}
                                                        setUpdateSocialSaveTriger = {setUpdateSocialSaveTriger}
                                                    />
                                                )
                                                :
                                                ""
                                        }
                                    </div>

                                </div>
                                <div className={"content_manager_contacts"}>
                                    <div className={"left_wrapper_contacts"}>
                                        <div className={"left_fields_container"}>
                                            <span className="contacts_field_label_name">Заголовок блока соц.сетей</span>
                                            <input
                                                value={noJson.socialNetwork ? noJson.socialNetwork.title_social : ""}
                                                onChange={e => fieldsHanler(e,"socialNetwork","title_social")
                                                }
                                                className="contacts_field" type="text"/>
                                        </div>
                                        <div className={"left_fields_container"}>
                                            <span className="contacts_field_label_name">Текст под заголовком</span>
                                            <input
                                                value={noJson.socialNetwork ? noJson.socialNetwork.text_social : ""}
                                                onChange={e => fieldsHanler(e,"socialNetwork","text_social")}
                                                className="contacts_field" type="text" />
                                        </div>
                                    </div>
                                    <div className={"contacts_social_block"}>
                                        <div className={"subtitle_social_active"}>Для добавления и удаления элемента со страницы, необходимо нажать на нужный элемент</div>
                                        <div className={"social_right_block"}>
                                            {
                                                iconsSocialArray ? iconsSocialArray.map((item, index) =>
                                                        <SocialIcons
                                                            key = {index}
                                                            item = {item}
                                                            index = {index}
                                                            noJson = {noJson}
                                                            setNoJsonContent = {setNoJsonContent}
                                                            setUpdateSocialSaveTriger = {setUpdateSocialSaveTriger}
                                                            activeCheck = {noJson.activSocial}
                                                            nameRecordingObject = {'activSocial'}

                                                        />
                                                    )
                                                    :
                                                    "Заполните менеджер соц.сетей"
                                            }
                                        </div>
                                        <div className={"social_right_block"}>
                                            {
                                                noJson.activSocial ? noJson.activSocial.map ((item, index) =>
                                                        <ActiveSocialIcons
                                                            key={index}
                                                            item={item}
                                                            index={index}
                                                            // deleteJsonIcons={e => deleteJsonIcons(e)}
                                                            activSocial={noJson.activSocial}
                                                            setNoJsonContent = {setNoJsonContent}
                                                            noJson={noJson}
                                                            setUpdateSocialSaveTriger = {setUpdateSocialSaveTriger}
                                                            nameRecordingObject = {'activSocial'}
                                                        />
                                                    )
                                                    :
                                                    ""
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className={"content_requisites_contacts"}>
                                    <div className={"left_fields_container"}>
                                        <span className="contacts_field_label_name">Заголовок блока</span>
                                        <input
                                            value={noJson.requisitesBlock ? noJson.requisitesBlock.title_requisites : ""}
                                            onChange={e => fieldsHanler(e,"requisitesBlock", "title_requisites")}
                                            className="contacts_field" type="text"/>
                                    </div>
                                    <div className={"left_fields_container"}>
                                        <span className="contacts_field_label_name">Первый абзац текста</span>
                                        <textarea
                                            value={noJson.requisitesBlock ? noJson.requisitesBlock.one_paragraph : ""}
                                            onChange={e => fieldsHanler(e,"requisitesBlock", "one_paragraph")}
                                            className="contacts_field" type="text"/>
                                    </div>
                                    <div className={"left_fields_container"}>
                                        <span className="contacts_field_label_name">Второй абзац</span>
                                        <input
                                            value={noJson.requisitesBlock ? noJson.requisitesBlock.two_paragraph : ""}
                                            onChange={e => fieldsHanler(e,"requisitesBlock", "two_paragraph")}
                                            className="contacts_field" type="text" />
                                    </div>
                                    <div className={"left_fields_container"}>
                                        <span className="contacts_field_label_name">Третий абзац</span>
                                        <textarea
                                            value={noJson.requisitesBlock ? noJson.requisitesBlock.three_paragraph : ""}
                                            onChange={e => fieldsHanler(e,"requisitesBlock", "three_paragraph")}
                                            className="contacts_field textarea" type="text" />
                                    </div>
                                    <div className={"left_fields_container"}>
                                        <span className="contacts_field_label_name">Подзаголовк абзаца</span>
                                        <input
                                            value={noJson.requisitesBlock ? noJson.requisitesBlock.subtitle_paragraph : ""}
                                            onChange={e => fieldsHanler(e,"requisitesBlock", "subtitle_paragraph")}
                                            className="contacts_field" type="text" />
                                    </div>
                                    <div className={"left_fields_container"}>
                                        <span className="contacts_field_label_name">Четвертый абзац</span>
                                        <textarea
                                            value={noJson.requisitesBlock ? noJson.requisitesBlock.four_paragraph : ""}
                                            onChange={e => fieldsHanler(e,"requisitesBlock", "four_paragraph")}
                                            className="contacts_field textarea" type="text" />
                                    </div>
                                </div>
                            </div>
                            <span
                                onClick={e => updateInformationSocial(pageContent)}
                                className={ updateSocialSaveTriger === true ? "save_button_contacts_content active" : "save_button_contacts_content" }>Сохранить</span>
                        </div>
                    </div>
                    :
                    ""
            }
        </div>
    );
};

export default AdminContactsPage;