import React, {useEffect, useState} from 'react';
import './adminReviewsPage.css';
import {
    addNewPage,
    addReviews,
    fetchAllReviews,
    getOnePageContent,
    updateContentPage
} from "../../../../http/contentApi";
import OneReviews from "./oneReviews/OneReviews";
import OneImageHorizontalField from "../../UI/OneImageHorizontalField/OneImageHorizontalField";
import {catalogHorizontalSizePreset, contentBackgroundImage} from "../../../../utils/consts";
import {addImages, deleteImage} from "../../../../http/imagesApi";


const AdminReviewsPage = () => {

    const [fechParams,setFetchParams] = useState({
        moderate:null,
        field:'createdAt',
        sort:'DESC',
    })


  const [contentFields, setContentFields] = useState({})

  const [contentPage, setContentPage] = useState({text:"", img:""});

    useEffect(() => {
        setContentFields({...contentFields,content: JSON.stringify(contentPage)  })
    },[contentPage])



    useEffect( () => {

        getOnePageContent(1).then(response => {
            setContentFields(response)
            if(response.content) {
                setContentPage(JSON.parse(response.content))
            }

        })


        fetchAllReviews(fechParams.moderate,fechParams.field,fechParams.sort).then(data => {
            if(data.error) {
                return false;
            }
            if(data.data) {
                setReviews(data.data)
                return false;
            }
            setReviews(data)
        } )
    },[fechParams])


    const [reviews,setReviews] = useState([]);

    const addNewReviewsAdmin = async () => {
       const data = await addReviews();
       if(data) {
           setFetchParams({...fechParams, field:"createdAt",sort:"DESC",moderate:null })
       }

    }

    const deleteMe = () => {
        addNewPage().then(r => {
            console.log(r)
        })
    }

    const uploadContentImage = (e,id) => {


        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (contentPage.img){
            deleteImage(contentPage.img, "reviews", null).then(response  => {
                setContentPage({...contentPage,img: ""})            })
        }

        addImages(formData,'reviews',null, contentBackgroundImage).then(response => {
            if(response.message) {
                setContentPage({...contentPage,img:response.fileName})
            }
        })

    }

    const deleteImageOnClick = (filename) => {
       deleteImage(filename, "reviews", null).then(response  => {
            setContentPage({...contentPage,img: ""})            })
    }

    const saveFieldsReviewsContent = (contentFields) => {
        updateContentPage(contentFields).then(response => {
        } )
    }

    return (
        <div className={"wrapper_page_reviews"}>
            <div className={"delete_me_button"} onClick={e => deleteMe(e)} >НЕ НАЖИМАТЬ</div>
            <div className={"reviews_wrapper"}>
                <div className="reviews_title">
                    Менеджер отзывов
                </div>
                <div className={"workspace_area_reviews"}>

                    <div className={"content_manager_reviews"}>

                        <div className={"left_wrapper_reviews"}>
                            <div className={"left_fields_container"}>
                            <span className={"reviews_field_label_name"}>
                                Название страницы
                            </span>
                                <input
                                    value={contentFields.name ? contentFields.name : ""}
                                    onChange={e => setContentFields({...contentFields, name:e.target.value})}
                                    className={"reviews_field"} type="text"/>
                            </div>
                            <div className={"left_fields_container"}>
                            <span className={"reviews_field_label_name"}>
                                title
                            </span>
                                <input
                                    value={contentFields.title ? contentFields.title : "" }
                                    onChange={e => setContentFields({...contentFields, title:e.target.value})}
                                    className={"reviews_field"} type="text"/>
                            </div>
                            <div className={"left_fields_container"}>
                            <span className={"reviews_field_label_name"}>
                                descriprion
                            </span>
                                <input
                                    value={contentFields.description ? contentFields.description : ""}
                                    onChange={e => setContentFields({...contentFields, description:e.target.value})}
                                    className={"reviews_field"} type="text"/>
                            </div>
                            <div className={"left_fields_container"}>
                            <span className={"reviews_field_label_name"}>
                                Текст
                            </span>
                                <input
                                    value={contentPage.text ? contentPage.text : ""}
                                    onChange={e =>  setContentPage({...contentPage,text:e.target.value})}
                                    className={"reviews_field"} type="text"/>
                            </div>
                        </div>
                        <div className={"right_wrapper_riviews"}>
                            <OneImageHorizontalField

                                onChange={ e => uploadContentImage(e,contentFields.id) }
                                image={contentPage.img ?
                                    process.env.REACT_APP_API_URL+"uploads/images/reviews/"+ contentPage.img : null }
                                deleteImage={e => deleteImageOnClick(contentPage.img)}

                            />
                        </div>
                    </div>

                    <span
                        onClick={e => saveFieldsReviewsContent(contentFields) }
                        className={"save_button_reviews_content"}>Сохранить</span>

                    <div className="workspace_area_content">
                        <div className={"controll_btn_area_reviews"}>
                            <div className={"left_div"}>
                                <span  onClick={e => setFetchParams({...fechParams, field:"stars",sort:"ASC", moderate:null }) } className={"sort_btn"}> Оцен. MIN</span>
                                <span  onClick={e => setFetchParams({...fechParams, field:"stars",sort:"DESC", moderate:null }) } className={"sort_btn"}> Оцен. MAX</span>
                                <span  onClick={e => setFetchParams({...fechParams, field:"createdAt",sort:"DESC", moderate:null }) } className={"sort_btn"}> Новые </span>
                                <span  onClick={e => setFetchParams({...fechParams, field:"createdAt",sort:"ASC", moderate:null }) } className={"sort_btn"}> Старые </span>
                                <span  onClick={e => setFetchParams({...fechParams, field:"updatedAt",sort:"DESC", moderate:null }) } className={"sort_btn"}> посл. изм. </span>
                                <span  onClick={e => setFetchParams({...fechParams, field:"createdAt",sort:"DESC", moderate:true }) } className={"sort_btn"}> Активные </span>
                                <span  onClick={e => setFetchParams({...fechParams, field:"createdAt",sort:"DESC", moderate:false }) } className={"sort_btn"}> Деактивиров. </span>
                            </div>
                            <div className="right_div">
                                <span onClick={e => addNewReviewsAdmin()} className={"add_reviews"}>Добавить отзыв</span>
                            </div>
                        </div>

                        <div className={"reviews_area_cont"}>
                            {
                                reviews[0] ?
                                    reviews.map((item,index) =>
                                        <OneReviews key={item.id} item={item} index={index} reviews={reviews} setReviews={setReviews} />
                                    )
                                    : <div> Отзывов нет </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AdminReviewsPage;