import React, { useContext } from 'react';
import './bonusesBlock.css'
import IsActiveCheckbox from '../isActiveCheckbox/isActiveCheckbox';
import ToolTipComponent from '../ToolTip/toolTipComponent';
import { observer } from "mobx-react-lite";
import { Context } from '../../..';

const BonusesBlock = observer(() => {

    const {basket} = useContext(Context);

    const toolTipContent = () => {
        return (
            <div style={{width: '100%'}}>
                <div style={{fontWeight: 600, fontSize: '16px', marginBottom: '20px'}}>
                    {`Ваш баланс ${basket.bonusesTotal} бонусов`}
                </div>
                {
                    basket.noProductsForBonuses ? 
                    <div>К сожалению, по текущему заказу нельзя списать бонусы</div>
                    :
                    <div>Оплата бонусами не может превышать 30% от стоимости заказа</div>
                }
            </div>
        )
    }

    const handleSpendAll = () => {
        if (!basket.bonusesSpendAll) {
            basket.setBonusesSpend(basket.bonusesOrderAvail);
        } else {
            basket.setBonusesSpend(0);
        }
        basket.bonusesSpendAll = !basket.bonusesSpendAll
    }

    const handleManualSpend = (value) => {
        const replaced = value.replace(/^0+|\D/g, "");
        const number = Number(replaced)
        if (number > basket.bonusesOrderAvail) {
            basket.setBonusesInfo({
                color: 'red',
                message: 'Оплата бонусами не может превышать 30% от стоимости заказа'
            })
        } else if (number > basket.bonusesTotal) {
            basket.setBonusesInfo({
                color: 'red',
                message: 'К сожалению, сейчас у вас нет столько бонусов'
            })
        } else {
            basket.clearBonusesInfo()
        }
        basket.setBonusesValue(replaced);
    }

    const handleManulaConfirm = () => {
        if (!basket.bonusesInfo.message) {
            basket.setBonusesSpend(+basket.bonusesValue);
        }
    }

    return (
        <div className={'bonuses_wrapper'}>
            <div className={'basket_bonuses_info'}>
                <div className={'amount'}><span>{`Доступно для списания ${basket.bonusesOrderAvail} бонусов`}</span>
                <ToolTipComponent text={toolTipContent()} customClass={'basket_tooltip_custom'}>
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="8" cy="8.5" r="8" fill="#D9D9D9"/>
                        <path d="M8.56818 5.26203C8.30303 5.26203 8.11742 5.21212 8.01136 5.1123C7.9053 5.00535 7.85227 4.87344 7.85227 4.71658V4.54545C7.85227 4.38859 7.9053 4.26025 8.01136 4.16043C8.11742 4.05348 8.30303 4 8.56818 4C8.83333 4 9.01894 4.05348 9.125 4.16043C9.23106 4.26025 9.28409 4.38859 9.28409 4.54545V4.71658C9.28409 4.87344 9.23106 5.00535 9.125 5.1123C9.01894 5.21212 8.83333 5.26203 8.56818 5.26203ZM6 11.2727H8.11364V7.20856H6V6.48128H9.02273V11.2727H11V12H6V11.2727Z" fill="black"/>
                    </svg>
                </ToolTipComponent>

                </div>
                
                <div className={'small_text'}>1 бонус = 1 ₽</div>
            </div>
            {
                !basket.noProductsForBonuses ? 
                    <>
                        <div className={'input_wrapper'}>
                            <div className={'basket_bonuses_info'}>{`Списать все  ${basket.bonusesOrderAvail} бонусов`}</div>
                            <IsActiveCheckbox
                                active={basket.bonusesSpendAll}
                                change={() => handleSpendAll()}
                            />
                        </div>
                        <div className={'input_wrapper_summ'}>
                            {
                                basket.bonusesSpendAll ? 
                                    <>
                                        <div className={'basket_bonuses_info'}>
                                            {`Будет списано  ${basket.bonusesValue} бонусов`}
                                        </div>
                                        <div className={'basket_bonuses_info change'}
                                            onClick={() =>  basket.bonusesSpendAll = false}>
                                            Изменить количество бонусов
                                        </div>
                                    </>
                                :
                                    <div className={'main_manual'}>
                                        <div className={'manual'}>
                                            <div className={'basket_bonuses_info'}>
                                                Сумма для списания
                                            </div>
                                            <div className={"wrapper_bonuses_input"}>
                                                <input
                                                    value={basket.bonusesValue}
                                                    onChange={(e) => handleManualSpend(e.target.value)}
                                                    className={"bonuses_input"} 
                                                    type="text"/>
                                                <div
                                                    onClick={e => handleManulaConfirm()}
                                                    className={"bonuses_button"}>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{color: basket.bonusesInfo.color}} className={"bonuses_notification"}><span>{basket.bonusesInfo.message}</span></div>
                                    </div>
                            }
                        </div>
                    </>
                : ''
            }
        </div>
    );
});

export default BonusesBlock;