import React from 'react';

const SupportLine = () => {
    return (
        <div className="SuportLine">

        </div>
    );
};

export default SupportLine;