import React, { memo } from 'react';
import styles from './FiltersHeader.module.css';

const FiltersHeader = memo(({selectedCount, onReset}) => {
    return (
        <div className={styles.head}>
            <h2 className={styles.title}>
                Фильтры
                <span className={styles.count}>{selectedCount}</span>
            </h2>
            <button
                className={styles.reset}
                onClick={onReset}
                disabled={!selectedCount}
            >
                Очистить всё
            </button>
        </div>
    );
});

export default FiltersHeader;