import React, {useState, useEffect} from 'react'

import {getSitemapUrls, deleteSitemapUrl, updateSitemapUrl, addSitemapUrl} from "../../../../http/settingsApi";
import SitemapUrl from "./SitemapUrl/SitemapUrl";

const Sitemap = () => {

    const [urls, setUrls] = useState([])
    const [addUrl, setAddUrl] = useState('')
    const [error, setError] = useState(false)

    const getUrls = async () => {
        const response = await getSitemapUrls()

        if(!response.success)
            return setError(response.message)

        setUrls(response.data)
    }

    const deleteUrl = async (id) => {
        const response = await deleteSitemapUrl(id)

        if(!response.success)
            return setError(response.message)

        getUrls()
        return true
    }

    const updateUrl = async (id, url) => {
        const response = await updateSitemapUrl(id, url)

        if(!response.success)
            return setError(response.message)

        return true
    }

    const createUrl = async (url) => {
        if(!addUrl)
            return false

        const response = await addSitemapUrl(url)

        if(!response.success)
            return setError(response.message)

        getUrls()
        return true
    }

    useEffect(() => {
        getUrls()
    }, [])

    if(error)
        return (<span className={"error_category_message"} >{error}</span>)

    return (
        <div className={"category_wrapper_page"}>
            <div className={"page_container_category"}>
                <div className={"first_category_controller"}>
                       <span className={"heading_page_category"}>
                        Sitemap Urls
                       </span>
                    <div className={"left_wrapper_category_controller"}>
                        <div className={"catalog_manager_field_wrap"}>
                            <span className={"category_field_label"}>* URL страницы.  Пример:   /about/contacts/</span>
                            <input
                                className={"category_fields_vac"}
                                placeholder={"Ввести url..."}
                                onChange={({target}) => {setAddUrl(target.value)}}
                                value={addUrl}
                                type="text"/>
                        </div>
                    </div>
                    <div className={"save_and_error"}>
                        <span className={"add_new_category_button"} onClick={() => createUrl(addUrl)}> Добавить </span>
                        <span className={"error_category_message"} >  </span>
                    </div>
                </div>

                <div className={"all_category_redaction_wrapper"}>
                    {
                        urls.map((url) => <SitemapUrl {...url} updateUrl={updateUrl} deleteUrl={deleteUrl} />)
                    }
                </div>

            </div>
        </div>
    )
}

export default Sitemap