import React, {useEffect, useState} from 'react';
import './admPageNotFound.css'
import {getOnePageContent, updateContentPage} from "../../../../http/contentApi";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import OneImageField from "../../UI/oneImageField/OneImageField";

const AdmPageNotFound = () => {

    const [contentNotFound, setContentNotFound] = useState('')
    const [noJsonNotFound, seNoJsonNotFound] = useState('')
    const [triggerNoJson, setTriggerNoJson] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)

        getOnePageContent(14).then(response => {
            if (response) {
                setContentNotFound(response)
            }
            if(response.content) {
                // console.log(JSON.parse(response.content))
                seNoJsonNotFound(JSON.parse(response.content))
                return false;
            }
            seNoJsonNotFound('')
        } )

    },[])

    const fieldsNotFound = (e, fieldName) => {
        setContentNotFound({...contentNotFound , [fieldName]:e.target.value})
        setTriggerNoJson(true)
    }

    const fieldsNoJsonNotFound = (e, fieldName) => {
        seNoJsonNotFound({...noJsonNotFound, [fieldName]: e.target.value})
        setTriggerNoJson(true)
    }



    const uploadImgNotFound = (e,fieldName) => {

        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (noJsonNotFound[fieldName]){
            deleteImage(noJsonNotFound[fieldName], 'notfound', null).then(response  => {
                seNoJsonNotFound({...noJsonNotFound,[fieldName]: ""})      })
            setTriggerNoJson(true)

        }
        addImages(formData,'notfound',null).then(response => {
            if(response.message) {
                seNoJsonNotFound({...noJsonNotFound,[fieldName]: response.fileName})
                setTriggerNoJson(true)
            }
        })

    }

    const deleteImgNotFound = (filename, fieldName) => {
        deleteImage(noJsonNotFound[fieldName], 'notfound', null).then(response  => {
            seNoJsonNotFound({...noJsonNotFound,[fieldName]: ""})
            setTriggerNoJson(true)
        })
    }

    useEffect(() => {
        setContentNotFound({...contentNotFound, content:JSON.stringify(noJsonNotFound)})
    }, [noJsonNotFound])



    const saveNotFoundContent = (date) => {
        updateContentPage(date).then(response => {
            setTriggerNoJson(false)
        } )
    }


    return (
        <div className={"adm_page_not_found"}>
            <div className={"title_not_found"}>Менеджер страницы 404</div>
            <div className={"content_not_found"}>
                <div className={"fields_container_nf"}>
                    <div className={"field_not_found_wrap"}>
                        <span className="name_field_not_found">Имя страницы</span>
                        <input
                            value={contentNotFound.name ? contentNotFound.name : ""}
                            onChange={e => fieldsNotFound(e,'name')}
                            className="input_not_found" type="text" />
                    </div>
                    <div className={"field_not_found_wrap"}>
                        <span className="name_field_not_found">Title</span>
                        <input
                            value={contentNotFound.title ? contentNotFound.title : ""}
                            onChange={e => fieldsNotFound(e,'title')}
                            className="input_not_found" type="text" />
                    </div>
                    <div className={"field_not_found_wrap"}>
                        <span className="name_field_not_found">Description</span>
                        <input
                            value={contentNotFound.description ? contentNotFound.description : ""}
                            onChange={e => fieldsNotFound(e,'description')}
                            className="input_not_found" type="text" />
                    </div>
                    <div className={"field_not_found_wrap"}>
                        <span className="name_field_not_found">Текст крупным шрифтом</span>
                        <input
                            value={noJsonNotFound.titlePageError ? noJsonNotFound.titlePageError : ""}
                            onChange={e => fieldsNoJsonNotFound(e,'titlePageError')}
                            className="input_not_found" type="text" />
                    </div>
                    <div className={"field_not_found_wrap"}>
                        <span className="name_field_not_found">Остальной текст</span>
                        <textarea
                            value={noJsonNotFound.textPageError ? noJsonNotFound.textPageError : ""}
                            onChange={e => fieldsNoJsonNotFound(e,'textPageError')}
                            className="input_not_found textarea" type="text" />
                    </div>
                    <div className={"field_not_found_wrap"}>
                        <span className="name_field_not_found">Текст кнопки</span>
                        <input
                            value={noJsonNotFound.buttonNotFound ? noJsonNotFound.buttonNotFound : ""}
                            onChange={e => fieldsNoJsonNotFound(e,'buttonNotFound')}
                            className="input_not_found" type="text" />
                    </div>
                    <div className={"field_not_found_wrap"}>
                        <span className="name_field_not_found">Ссылка кнопки</span>
                        <input
                            value={noJsonNotFound.linkNotFound ? noJsonNotFound.linkNotFound : ""}
                            onChange={e => fieldsNoJsonNotFound(e,'linkNotFound')}
                            className="input_not_found" type="text" />
                    </div>
                </div>

                <div className={"img_not_found"}>
                    <div className={"one_img_not_found"}>
                        <OneImageField
                            text={"ПК (1920x1080)"}
                            image={ noJsonNotFound.imgPC ?
                                process.env.REACT_APP_API_URL+"uploads/images/notfound/"+noJsonNotFound.imgPC  : null }
                            onChange={ e => uploadImgNotFound(e, 'imgPC')}
                            deleteImage={ e => deleteImgNotFound(e,'imgPC')}
                        />
                    </div>
                    <div className={"one_img_not_found"}>
                        <OneImageField
                            text={"Планшет (768*1024)"}
                            image={ noJsonNotFound.imgTablet ?
                                process.env.REACT_APP_API_URL+"uploads/images/notfound/"+noJsonNotFound.imgTablet  : null }
                            onChange={ e => uploadImgNotFound(e, 'imgTablet')}
                            deleteImage={ e => deleteImgNotFound(e,'imgTablet')}
                        />
                    </div>
                    <div className={"one_img_not_found"}>
                        <OneImageField
                            text={"Смартфон (320*568"}
                            image={ noJsonNotFound.imgMobile ?
                                process.env.REACT_APP_API_URL+"uploads/images/notfound/"+noJsonNotFound.imgMobile  : null }
                            onChange={ e => uploadImgNotFound(e, 'imgMobile')}
                            deleteImage={ e => deleteImgNotFound(e,'imgMobile')}
                        />
                    </div>
                </div>
            </div>

            <span
                onClick={e => saveNotFoundContent(contentNotFound)}
                className={triggerNoJson ? "save_not_found trig" : "save_not_found"}>
                            Сохранить
            </span>
        </div>
    );
};

export default AdmPageNotFound;