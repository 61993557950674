import React from 'react';
import './TexUnderFooter.css';
import {PRIVACY_POLICY} from "../../../utils/consts";

const TextUnderFooter = (props) => {
    return (
        <div className={"container_text_policy"}>
            {
                props.dopTextFooter.datesExistence ?
                    <div className={"footer_span_info_shop"}>
                        <span>{props.dopTextFooter.datesExistence}</span>
                    </div>
                    : ''
            }
            {
                props.dopTextFooter.textToPolicy || props.dopTextFooter.textClickable  ?
                    <div className={"footer_span_policy"}>
                        <span>{props.dopTextFooter.textToPolicy ? props.dopTextFooter.textToPolicy :''} <a rel="noreferrer"  className={"footer_style_policy"} href={props.dopTextFooter.linkToPolicy ? props.dopTextFooter.linkToPolicy :''} target={"_blank"}>{props.dopTextFooter.textClickable ? props.dopTextFooter.textClickable : ''}</a></span>
                    </div>
                    : ''
            }


        </div>
    );
};

export default TextUnderFooter;