
import {$authHost} from "./index";

export const getAllCerts = async (limit, offset, getCount, pattern='') => {
    const {data} = await  $authHost.post("/api/cert/getallcerts", {limit, offset, getCount, pattern});
    return  data;
}

export const activateCert = async (nominal) => {
    const {data} = await  $authHost.post("/api/cert/activate", {nominal});
    return  data;
}