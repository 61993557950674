import React from "react";
import styles from "./Modal.module.css";
import { memo, useEffect } from "react";
import { createPortal } from "react-dom";
import ModalOverlay from "../ModalOverlay/ModalOverlay";
import { scrollbar } from "../../utils/scrollbar";

const Modal = memo((props) => {
  const {
    children,
    onClose,
    extraClass = "",
    modalOverlayClassName = "",
    modalClassName = "",
  } = props;

  const handleEsc = (e) => {
    if (e.code === "Escape") {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEsc);
    document.body.style.paddingRight = `${
      window.innerWidth - document.documentElement.clientWidth
    }px`;
    document.body.style.overflowY = "hidden";

    return () => {
      document.removeEventListener("keydown", handleEsc);
      document.body.style.overflowY = "";
      document.body.style.paddingRight = "0px";
    };
  }, []);

  const modal = (
    <div className={`${styles.root} ${modalClassName}`}>
      <ModalOverlay className={modalOverlayClassName} onClose={onClose} />
      <div className={`${styles.wrapper} ${extraClass}`}>{children}</div>
    </div>
  );

  return createPortal(modal, document.getElementById("modals"));
});

export default Modal;
