import React, {useContext, useState} from 'react';
import "./changeNumberModal.css"

import ChangeNumber from "./ChangeNumber";
import CompleteChangeNumber from "./CompleteChangeNumber";
import {Context} from "../../../../../index";

const ChangeNumberModal = (props) => {
    const {user} = useContext(Context)
    const [stateChangeModals,setStateChangeModals] = useState(1)
    const [buttonGetCodeStatus, setButtonGetCodeStatus] = useState(2);
    const [newNumber, setNewNumber] = useState("");
    const [newNumberNoValid, setNewNumberNoValid] = useState('');
    const [smsCodeChangeNumber, setSmsCodeChangeNumber] = useState('');
    const [selectCountryUser, setSelectCountryUser] = useState(user.user.country);

    const codeNumberValidator = (number) => {
        let replacenumber = number.replace(/[^\d]/g, '');
        setSmsCodeChangeNumber(replacenumber)
    }

    const clearCodeNumber = (number) => {
        setSmsCodeChangeNumber("");
    }


    return (
        <div className={"change_number_modal " + props.controllClass }>
            <ChangeNumber
                controllClass = {stateChangeModals === 1 ? "active" : "hide" }
                goToStateTwo = {e => setStateChangeModals(2)}
                buttonStatuActive = {e => setButtonGetCodeStatus(1)}
                buttonStatuDisactive = {e => setButtonGetCodeStatus(2)}
                controllButtonClass = {buttonGetCodeStatus === 1 ? "active" : "disable"}
                newNumber={newNumber}
                setNewNumber={setNewNumber}
                newNumberNoValid={newNumberNoValid}
                setNewNumberNoValid={setNewNumberNoValid}
                SelectCountryUser={selectCountryUser}
                setSelectCountryUser={setSelectCountryUser}
                closeModalChangeNumber={e => props.closeModalChangeNumber()}
            />
            <CompleteChangeNumber
                controllClass = {stateChangeModals === 2 ? "active" : "hide" }
                goToStateOne = {e => setStateChangeModals(1)}
                newNumber={newNumber}
                newNumberNoValid={newNumberNoValid}
                smsCodeChangeNumber={smsCodeChangeNumber}
                SelectCountryUser={selectCountryUser}
                codeNumberValidator={e => codeNumberValidator(e.target.value)}
                controllClassConfirm={smsCodeChangeNumber.length === 4 ? "active" : "disabled"}
                clearCode={ e => clearCodeNumber("")}
                SelectCountryUser={selectCountryUser}
                closeModalChangeNumber={e => props.closeModalChangeNumber()}
            />
        </div>
    );
};

export default ChangeNumberModal;