import React, {useEffect, useState} from 'react';
import './admDelivery.css';
import {getCommonContent, getOnePageContent, updateCommonContent, updateContentPage} from "../../../../http/contentApi";
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import {addPdfFile, deletePdfFile} from "../../../../http/imagesApi";

const AdmDelivery = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    },[])


    const [pageContentDelivery,setPageContentDelivery] = useState({});
    const [noJsonDelivery,setNoJsonContentDelivery] = useState({})
    const [trigerSeoDelivery,setTrigerSeoDelivery] = useState(false)
    const [triggerContentDelivery,setTriggerContentDelivery] = useState(false)
    const [triggerCommonDeliveryContent, setTriggerCommonDeliveryContent] = useState(false)
    const [deliveryContent, setDeliveryContent] = useState("");

    useEffect(() => {
        getOnePageContent(10).then(response => {
            setPageContentDelivery(response)
            if(response.content) {
                setNoJsonContentDelivery(JSON.parse(response.content))
                return false;
            }
            setNoJsonContentDelivery({
                titleDelivery: '',
                arrayDeliveryRegion:[],
                arrayDeliveryMoscow:[],
                titleRefund: '',
                arrayRefund:[],
                refundTitleInfo: '',
                refundTextInfo: '',
                refundDopInfoTitle: '',
                refundDopInfoText: '',
            })
        } )

        getCommonContent('delivery').then(response => {
            if(response.success) {
                setDeliveryContent(response.content);
            }
        })

    },[])

    const saveSeoDeliveryContent = (date) => {
        updateContentPage(date).then(response => {
            setTrigerSeoDelivery(false)
        } )
    }

    const updateDeliveryCommonContent = (data) => {
        updateCommonContent('delivery', data).then(response => {
            if(response.success) {
                setDeliveryContent(response.content);
                setTriggerCommonDeliveryContent(false);
            }
        })

    }

    useEffect(() => {
        setPageContentDelivery({...pageContentDelivery, content:JSON.stringify(noJsonDelivery)})
    }, [noJsonDelivery])


    const fieldsDeliveryRefundContent = (e,fieldName) => {
        setNoJsonContentDelivery({...noJsonDelivery, [fieldName]: e.target.value})
        setTriggerContentDelivery(true)
    }


    const oneBlockDeliveryRegion =  {titleBlock:"", textArrayBlock: [{name:"",value:""}], buttonName: '', linkButton: '' };
    const textFieldsDelivery = {name:"",value:""};

    const addNewOneDeliveryRegion = (object) => {
        let reduceNoJsonDeliveryRegion = {...noJsonDelivery}
        reduceNoJsonDeliveryRegion.arrayDeliveryRegion.push(object)
        setNoJsonContentDelivery(reduceNoJsonDeliveryRegion)
        setTriggerContentDelivery(true)
    }

    const fieldsDeliveryRegion = (e,fieldName, index) => {
        setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryRegion[index][fieldName] = e.target.value])
        setTriggerContentDelivery(true)
    }
    const deleteOneDeliveryRegion = (id) => {
        let deleteNoJsonBlockRegion = {...noJsonDelivery};
        deleteNoJsonBlockRegion.arrayDeliveryRegion.splice(id,1)
        setNoJsonContentDelivery(deleteNoJsonBlockRegion)
        setTriggerContentDelivery(true)
    }


    const addDopTextDeliveryRegion = (object, index) => {
        let reduceNoJsonTextRegion = {...noJsonDelivery}
        reduceNoJsonTextRegion.arrayDeliveryRegion[index].textArrayBlock.push(object)
        setNoJsonContentDelivery(reduceNoJsonTextRegion)
        setTriggerContentDelivery(true)
    }



    const oneBlockDeliveryMsk =  {titleBlock:"",conditions: [{subtitle:"",text: [{child_subtitle:"",text_delivery:""}]}]};
    const newBlockDelChild = {subtitle:"",text: [{child_subtitle:"",text_delivery:""}]}
    const textDeliveryMsk = {child_subtitle:"",text_delivery:""};

    const addNewOneBlockMsk = (object) => {
        let reduceNoJsonDeliveryRegion = {...noJsonDelivery}
        reduceNoJsonDeliveryRegion.arrayDeliveryMoscow.push(object)
        setNoJsonContentDelivery(reduceNoJsonDeliveryRegion)
        setTriggerContentDelivery(true)
    }

    const addDopTextMoscow = (object, index) => {
        let reduceNoJsonTextMsk = {...noJsonDelivery}
        reduceNoJsonTextMsk.arrayDeliveryMoscow[index].conditions.push(object)
        setNoJsonContentDelivery(reduceNoJsonTextMsk)
        setTriggerContentDelivery(true)
    }
    const addTextChildMoscow = (object, index, indexChild) => {
        let reduceNoJsonTextMsk = {...noJsonDelivery}
        reduceNoJsonTextMsk.arrayDeliveryMoscow[index].conditions[indexChild].text.push(object)
        setNoJsonContentDelivery(reduceNoJsonTextMsk)
        setTriggerContentDelivery(true)
    }

    const deleteOneDeliveryMsk = (id) => {
        let deleteNoJsonBlockMsk = {...noJsonDelivery};
        deleteNoJsonBlockMsk.arrayDeliveryMoscow.splice(id,1)
        setNoJsonContentDelivery(deleteNoJsonBlockMsk)
        setTriggerContentDelivery(true)
    }

    const fieldsDeliveryMsk = (e,fieldName, index) => {
        setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryMoscow[index][fieldName] = e.target.value])
        setTriggerContentDelivery(true)
    }





    const oneBlockRefund =  {titleBlock:"", textArrayBlock: [{name:"",value:""}], uploadSsampleApplication: '',  purchaseOnlineStore: false, selfRefund: false};
    const textFieldsRefund = {name:"",value:"",  application: ''};

    const addNewOneRefund = (object) => {
        let reduceNoJsonRefund = {...noJsonDelivery}
        reduceNoJsonRefund.arrayRefund.push(object)
        setNoJsonContentDelivery(reduceNoJsonRefund)
        setTriggerContentDelivery(true)
    }

    const fieldsRefund = (e,fieldName, index) => {
        setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayRefund[index][fieldName] = e.target.value])
        setTriggerContentDelivery(true)
    }

    const deleteOneRefund = (id) => {
        let deleteNoJsonBlockRefund = {...noJsonDelivery};
        deleteNoJsonBlockRefund.arrayRefund.splice(id,1)
        setNoJsonContentDelivery(deleteNoJsonBlockRefund)
        setTriggerContentDelivery(true)
    }

    const addDopTextRefund = (object, index) => {
        let reduceNoJsonTextRefund = {...noJsonDelivery}
        reduceNoJsonTextRefund.arrayRefund[index].textArrayBlock.push(object)
        setNoJsonContentDelivery(reduceNoJsonTextRefund)
        setTriggerContentDelivery(true)
    }


    const activationButtonReturn = (fildName, objectName, index) => {
        setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][fildName]= !noJsonDelivery[objectName][index][fildName]])
        setTriggerContentDelivery(true)
    }

    const formWithoutDelivery = (fildName, objectName, index) => {
        setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][fildName]= !noJsonDelivery[objectName][index][fildName]])
        setTriggerContentDelivery(true)
    } 


    function upLoadReturnForm(e,nameField, objectName, index) {
        let newFile = e.target.files[0];
        let formData = new FormData();
        formData.append("file", newFile);

        if (noJsonDelivery[objectName][index][nameField]){
            deletePdfFile(noJsonDelivery[objectName][index][nameField], "refund", [objectName]).then(response  => {
                setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][nameField] = ''])
            })
        }

        addPdfFile(formData,'refund',[objectName], null).then(response => {
            if(response.message) {
                setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][nameField]= response.fileName])

            }
        })

    }

    const deleteReturnForm = (filename,nameField, objectName, index) => {
        deletePdfFile(filename, "refund", [objectName]).then(response  => {
            setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][nameField]= ''])
        })
    }

    const updatePageDeliveryAndRefund = (pageContentDelivery) => {
        updateContentPage(pageContentDelivery).then(response => {
            setTriggerContentDelivery(false)
        } )
    }



    function upLoadReturnFormChild (e,nameField, objectName, childObject, index, indexChild) {
        let newFile = e.target.files[0];
        let formData = new FormData();
        formData.append("file", newFile);

        if (noJsonDelivery[objectName][index][childObject][indexChild][nameField]){
            deletePdfFile(noJsonDelivery[objectName][index][nameField], "refund", [objectName]).then(response  => {
                setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][childObject][indexChild][nameField] = ''])
            })
        }

        addPdfFile(formData,'refund',[objectName], null).then(response => {
            if(response.message) {
                setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][childObject][indexChild][nameField]= response.fileName])

            }
        })
        setTriggerContentDelivery(true)

    }

    const deleteReturnFormChild = (filename,nameField, objectName, childObject, index, indexChild) => {
        deletePdfFile(filename, "refund", [objectName]).then(response  => {
            setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery[objectName][index][childObject][indexChild][nameField]= ''])
        })
        setTriggerContentDelivery(true)
    }

    return (
        <div className={"adm_page_delivery"}>
            <div className={"adm_title_delivery"}>
                Доставка и Возврат
            </div>
            <div className={"content_delivery_adm"}>
                <div className={"adm_title_delivery"}>
                    Доставка
                </div>
                <div className={"delivery_block_content"}>
                    <div className="one_field_delivery_wrapper">
                        <span className={"one_field_delivery"}>Имя страницы</span>
                        <input
                            value={noJsonDelivery.nameDelivery ? noJsonDelivery.nameDelivery : ""}
                            onChange={e => fieldsDeliveryRefundContent(e, "nameDelivery")}
                            className={"delivery_field_adm"} type="text"/>
                    </div>
                    <div className="one_field_delivery_wrapper">
                        <span className={"one_field_delivery"}>title</span>
                        <input
                            value={noJsonDelivery.titleDelivery ? noJsonDelivery.titleDelivery : ""}
                            onChange={e => fieldsDeliveryRefundContent(e, "titleDelivery")}
                            className={"delivery_field_adm"} type="text"/>
                    </div>
                    <div className="one_field_delivery_wrapper">
                        <span className={"one_field_delivery"}>description</span>
                        <input
                            value={noJsonDelivery.descriptionDelivery ? noJsonDelivery.descriptionDelivery : ""}
                            onChange={e => fieldsDeliveryRefundContent(e, "descriptionDelivery")}
                            className={"delivery_field_adm"} type="text"/>
                    </div>
                    <div className={"adm_title_delivery"}>
                        Информация о доставке в товарах
                    </div>
                    <div className={"parents_delivery_region"}>
                        <div className={"textarea_wrapper_f_vysev"}>
                            <textarea
                            value={deliveryContent}
                            onChange={e => {setDeliveryContent(e.target.value); setTriggerCommonDeliveryContent(true)}}
                            >

                            </textarea>
                        </div>
                        <span
                            onClick={e => updateDeliveryCommonContent(deliveryContent)}
                            className={ triggerCommonDeliveryContent === true ? "save_fields_delivery trig" : "save_fields_delivery"}
                            >Сохранить
                        </span>
                    </div>
                    <div className={"adm_title_delivery"}>
                        Доставка по регионам
                    </div>
                    <div className={"parents_delivery_region"}>
                        <div
                            onClick={e => addNewOneDeliveryRegion(Object.assign({}, oneBlockDeliveryRegion ))}
                            className={"button_new_block_del_and_ref"}>Добавить блок доставки</div>
                        <div className={"delivery_region_wrapper"}>
                            {noJsonDelivery.arrayDeliveryRegion ? noJsonDelivery.arrayDeliveryRegion.map((itemDeliveryRegion, indexDeliveryRegion) =>
                                <div key={indexDeliveryRegion}
                                    className={"one_delivery_block"}>
                                    <div className={"delete_block_delivery"}>
                                        <div
                                            onClick={e => deleteOneDeliveryRegion(indexDeliveryRegion)}
                                            className={"del_delivery_child"}></div>
                                    </div>
                                    <div className="one_field_delivery_wrapper">
                                        <span className={"one_field_delivery"}>Заголовок блока</span>
                                        <input
                                            value={itemDeliveryRegion.titleBlock ? itemDeliveryRegion.titleBlock : ""}
                                            onChange={e => fieldsDeliveryRegion(e, "titleBlock", indexDeliveryRegion)}
                                            className={"delivery_field_adm"} type="text"/>
                                    </div>
                                    <div className={"block_and_fields_text"}>
                                        <div className={"text_repeater"}>
                                            {

                                                itemDeliveryRegion.textArrayBlock ?
                                                    itemDeliveryRegion.textArrayBlock .map((itemFields,indexFields) => {
                                                        return(
                                                            <div key={indexFields} className={"dop_text_parrent_wrapper"}>
                                                                <div className={"delete_block_delivery"}>
                                                                    <div
                                                                        onClick={e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryRegion[indexDeliveryRegion].textArrayBlock.splice(indexFields,1)]) }
                                                                        className={"del_delivery_child"}></div>
                                                                </div>
                                                                <div className={"one_field_delivery_wrapper"}>
                                                                    <span className={"one_field_delivery"}>Подзаголовок</span>
                                                                    <input
                                                                        value={itemFields.name ? itemFields.name : ""}
                                                                        onChange={ e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryRegion[indexDeliveryRegion].textArrayBlock[indexFields].name = e.target.value ]) }
                                                                        // onChange={ e => mestoToJson(e.target.value,indexFields) }
                                                                        className={"delivery_field_adm"} type="text" name="" id=""/>

                                                                </div>
                                                                <div className={"one_field_delivery_wrapper"}>
                                                                    <span className={"one_field_delivery"}>Текст</span>
                                                                    <textarea
                                                                        value={itemFields.value ? itemFields.value : ""}
                                                                        onChange={ e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryRegion[indexDeliveryRegion].textArrayBlock[indexFields].value = e.target.value ]) }
                                                                        // onChange={ e => valueToJson(e.target.value, indexFields)}
                                                                        className={"delivery_field_adm textarea"} type="text" name="" id=""/>
                                                                </div>

                                                            </div>

                                                        )
                                                    }): ""
                                            }
                                        </div>
                                        <div
                                            onClick={e => addDopTextDeliveryRegion(Object.assign({}, textFieldsDelivery), indexDeliveryRegion)}
                                            className={"add_fields_delivery"}
                                        >+</div>
                                    </div>
                                    <div className={"custom_button_wrapper"}>
                                        <div className="one_field_delivery_wrapper">
                                            <span className={"one_field_delivery"}>Текст кнопки</span>
                                            <input
                                                value={itemDeliveryRegion.buttonName ? itemDeliveryRegion.buttonName : ""}
                                                onChange={e => fieldsDeliveryRegion(e, "buttonName", indexDeliveryRegion)}
                                                className={"delivery_field_adm"} type="text"/>
                                        </div>
                                        <div className="one_field_delivery_wrapper">
                                            <span className={"one_field_delivery"}>Ссылка кнопки (ссылка внутри сайта)</span>
                                            <input
                                                value={itemDeliveryRegion.linkButton  ? itemDeliveryRegion.linkButton : ""}
                                                onChange={e => fieldsDeliveryRegion(e, "linkButton", indexDeliveryRegion)}
                                                className={"delivery_field_adm"} type="text"/>
                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                        </div>
                    </div>












                    <div className={"delivery_msk"}>
                        <div className={"adm_title_delivery"}>
                            Доставка Москве
                        </div>
                        <div className={"parent_delivery_msc"}>
                            <div
                                onClick={e => addNewOneBlockMsk(Object.assign({}, oneBlockDeliveryMsk ))}
                                className={"button_new_block_del_and_ref"}>Добавить блок доставки</div>
                        </div>
                        <div className={"delivery_moscow_wrapper"}>
                            {
                                noJsonDelivery.arrayDeliveryMoscow ? noJsonDelivery.arrayDeliveryMoscow.map((itemMsk, indexMsk) =>
                                    <div key={indexMsk}
                                         className={"one_delivery_block"}>
                                        <div className={"delete_block_delivery"}>
                                            <div
                                                onClick={e => deleteOneDeliveryMsk(indexMsk)}
                                                className={"del_delivery_child"}></div>
                                        </div>
                                        <div className="one_field_delivery_wrapper">
                                            <span className={"one_field_delivery"}>Заголовок блока</span>
                                            <input
                                                value={itemMsk.titleBlock ? itemMsk.titleBlock : ""}
                                                onChange={e => fieldsDeliveryMsk(e, "titleBlock", indexMsk)}
                                                className={"delivery_field_adm"} type="text"/>
                                        </div>
                                        <div className={"msk_fields_text_wrap"}>
                                            <div className={"text_repeater"}>
                                                {

                                                    itemMsk.conditions ?
                                                        itemMsk.conditions.map((itemChildMsk,indexChildMck) => {
                                                            return(
                                                                <div className={"conditions_array"}>
                                                                    <div key={indexChildMck} className={"dop_text_msk_wrapper"}>

                                                                        <div className={"one_field_delivery_wrapper"}>
                                                                            <span className={"one_field_delivery"}>Подзаголовок блока</span>
                                                                            <input
                                                                                value={itemChildMsk.subtitle ? itemChildMsk.subtitle : ""}
                                                                                onChange={ e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryMoscow[indexMsk].conditions[indexChildMck].subtitle = e.target.value ]) }
                                                                                className={"delivery_field_adm"} type="text" name="" id=""/>

                                                                        </div>
                                                                        <div className={"dop_text_msk"}>
                                                                            {itemChildMsk.text ? itemChildMsk.text.map((itemChildTextMsk,indexChildTextMck) =>
                                                                                <div className={"text_and_del_msk"}>
                                                                                    <div className={"text_msk_child"}>
                                                                                        <div className={"one_field_delivery_wrapper"}>
                                                                                            <span className={"one_field_delivery"}>Заголовк текста</span>
                                                                                            <input
                                                                                                value={itemChildTextMsk.child_subtitle ? itemChildTextMsk.child_subtitle : ""}
                                                                                                onChange={ e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryMoscow[indexMsk].conditions[indexChildMck].text[indexChildTextMck].child_subtitle = e.target.value ]) }
                                                                                                className={"delivery_field_adm"} type="text" name="" id=""/>

                                                                                        </div>
                                                                                        <div className={"one_field_delivery_wrapper"}>
                                                                                            <span className={"one_field_delivery"}>Текст</span>
                                                                                            <textarea
                                                                                                value={itemChildTextMsk.text_delivery ? itemChildTextMsk.text_delivery : ""}
                                                                                                onChange={ e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryMoscow[indexMsk].conditions[indexChildMck].text[indexChildTextMck].text_delivery = e.target.value ]) }
                                                                                                className={"delivery_field_adm textarea"} type="text" name="" id=""/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={"delete_block_delivery"}>
                                                                                        <div
                                                                                            onClick={e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryMoscow[indexMsk].conditions[indexChildMck].text.splice(indexChildTextMck,1)]) }
                                                                                            className={"del_delivery_child"}></div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : '' }
                                                                        </div>

                                                                        <div
                                                                            onClick={e => addTextChildMoscow(Object.assign({}, textDeliveryMsk), indexMsk, indexChildMck)}
                                                                            className={"add_fields_delivery"}
                                                                        >+</div>
                                                                    </div>
                                                                    <div className={"delete_block_delivery"}>
                                                                        <div
                                                                            onClick={e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayDeliveryMoscow[indexMsk].conditions.splice(indexChildMck,1)]) }
                                                                            className={"del_text_delivery"}>Удалить</div>
                                                                    </div>
                                                                </div>


                                                            )
                                                        }): ""
                                                }
                                            </div>
                                            <div className={"add_block_delivery"}>
                                                <div
                                                    onClick={e => addDopTextMoscow(Object.assign({}, newBlockDelChild), indexMsk)}
                                                    className={"add_fields_delivery"}
                                                >+</div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ''
                            }
                        </div>
                    </div>



                    <div className={"refund_wrapper"}>
                        <div className={"adm_title_delivery"}>
                            Возврат
                        </div>
                        <div className="one_field_delivery_wrapper">
                            <span className={"one_field_delivery"}>Имя страницы</span>
                            <input
                                value={noJsonDelivery.nameRefound ? noJsonDelivery.nameRefound : ""}
                                onChange={e => fieldsDeliveryRefundContent(e, "nameRefound")}
                                className={"delivery_field_adm"} type="text"/>
                        </div>
                        <div className="one_field_delivery_wrapper">
                            <span className={"one_field_delivery"}>title</span>
                            <input
                                value={noJsonDelivery.titleRefound ? noJsonDelivery.titleRefound : ""}
                                onChange={e => fieldsDeliveryRefundContent(e, "titleRefound")}
                                className={"delivery_field_adm"} type="text"/>
                        </div>
                        <div className="one_field_delivery_wrapper">
                            <span className={"one_field_delivery"}>description</span>
                            <input
                                value={noJsonDelivery.descriptionRefound ? noJsonDelivery.descriptionRefound : ""}
                                onChange={e => fieldsDeliveryRefundContent(e, "descriptionRefound")}
                                className={"delivery_field_adm"} type="text"/>
                        </div>

                        <div className={"parents_delivery_region"}>
                            <div
                                onClick={e => addNewOneRefund(Object.assign({}, oneBlockRefund ))}
                                className={"button_new_block_del_and_ref"}>Добавить блок доставки</div>
                            <div className={"delivery_region_wrapper"}>
                                {noJsonDelivery.arrayRefund ? noJsonDelivery.arrayRefund.map((itemRefund, indexRefund) =>
                                    <div key={indexRefund}
                                         className={"one_delivery_block"}>
                                        <div className={"delete_block_delivery"}>
                                            <div
                                                onClick={e => deleteOneRefund(indexRefund)}
                                                className={"del_delivery_child"}></div>
                                        </div>
                                        <div className="one_field_delivery_wrapper">
                                            <span className={"one_field_delivery"}>Заголовок блока</span>
                                            <input
                                                value={itemRefund.titleBlock ? itemRefund.titleBlock : ""}
                                                onChange={e => fieldsRefund(e, "titleBlock", indexRefund)}
                                                className={"delivery_field_adm"} type="text"/>
                                        </div>
                                        <div className={"block_and_fields_text"}>
                                            <div className={"text_repeater"}>
                                                {

                                                    itemRefund.textArrayBlock ?
                                                        itemRefund.textArrayBlock .map((itemFields,indexFields) => {
                                                            return(
                                                                <div key={indexFields} className={"dop_text_parrent_wrapper"}>
                                                                    <div className={"delete_block_delivery"}>
                                                                        <div
                                                                            onClick={e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayRefund[indexRefund].textArrayBlock.splice(indexFields,1)]) }
                                                                            className={"del_delivery_child"}></div>
                                                                    </div>
                                                                    <div className={"one_field_delivery_wrapper"}>
                                                                        <span className={"one_field_delivery"}>Подзаголовок</span>
                                                                        <input
                                                                            value={itemFields.name ? itemFields.name : ""}
                                                                            onChange={ e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayRefund[indexRefund].textArrayBlock[indexFields].name = e.target.value ]) }
                                                                            className={"delivery_field_adm"} type="text" name="" id=""/>

                                                                    </div>
                                                                    <div className={"one_field_delivery_wrapper"}>
                                                                        <span className={"one_field_delivery"}>Текст</span>
                                                                        <textarea
                                                                            value={itemFields.value ? itemFields.value : ""}
                                                                            onChange={ e => setNoJsonContentDelivery({...noJsonDelivery}, [noJsonDelivery.arrayRefund[indexRefund].textArrayBlock[indexFields].value = e.target.value ]) }
                                                                            className={"delivery_field_adm textarea"} type="text" name="" id=""/>
                                                                    </div>
                                                                    <div className={"file_return_wrapper"}>
                                                                        <input type="file"
                                                                               onChange={e => upLoadReturnFormChild(e, 'application', "arrayRefund", "textArrayBlock", indexRefund, indexFields)}/>
                                                                        {
                                                                            itemFields.application ?
                                                                                <div className={"loaded_pdf_file"}>
                                                                                    <div className={"name_file_return"}>
                                                                                        <div>✔ загруженный файл: {itemFields.application}</div>
                                                                                    </div>
                                                                                    <div className={"opportunities_upload_file"}>
                                                                                        <a className={"button_view_file"} download href={itemFields.application ? process.env.REACT_APP_API_URL+"uploads/images/refund/arrayRefund/"+ itemFields.application : ""} without rel="noopener noreferrer" target="_blank">
                                                                                            Просмотреть файл PDF
                                                                                        </a>
                                                                                        <div
                                                                                            className={"deleted_file_return"}
                                                                                            onClick={e => deleteReturnFormChild(itemFields.application,"application", "arrayRefund", "textArrayBlock", indexRefund, indexFields)}
                                                                                        >

                                                                                            удалить файл
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div className={"not_loaded_file"}>файл не загружен</div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            )
                                                        }): ""
                                                }
                                            </div>
                                            <div
                                                onClick={e => addDopTextRefund(Object.assign({}, textFieldsRefund), indexRefund)}
                                                className={"add_fields_delivery"}
                                            >+</div>
                                        </div>
                                        <div className={"return_buttons_wrapper"}>
                                            <div className={"file_return_wrapper"}>
                                                <input type="file"
                                                       onChange={e => upLoadReturnForm(e, 'uploadSsampleApplication', "arrayRefund", indexRefund)}/>
                                                {
                                                    itemRefund.uploadSsampleApplication ?
                                                        <div className={"loaded_pdf_file"}>
                                                            <div className={"name_file_return"}>
                                                                <div>✔ загруженный файл: </div>
                                                                <div> {itemRefund.uploadSsampleApplication}</div>
                                                            </div>
                                                            <div className={"opportunities_upload_file"}>
                                                                <a className={"button_view_file"} download href={itemRefund.uploadSsampleApplication ? process.env.REACT_APP_API_URL+"uploads/images/refund/arrayRefund/"+ itemRefund.uploadSsampleApplication : ""} without rel="noopener noreferrer" target="_blank">
                                                                    Просмотреть файл PDF
                                                                </a>
                                                                <div
                                                                    className={"deleted_file_return"}
                                                                    onClick={e => deleteReturnForm(itemRefund.uploadSsampleApplication,"uploadSsampleApplication", "arrayRefund", indexRefund)}
                                                                >

                                                                    удалить файл
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={"not_loaded_file"}>файл не загружен</div>
                                                }
                                            </div>
                                            <div className={"activation_return_buttons"}>
                                                <div className={"one_field_delivery"}>Активировать кнопку "Оформить возврат"</div>
                                                <div
                                                    onClick={e=> activationButtonReturn("purchaseOnlineStore", "arrayRefund", indexRefund)}
                                                    className={""}>
                                                    <IsActiveIndicator active={itemRefund.purchaseOnlineStore} />
                                                </div>
                                            </div>
                                            <div className={"activation_return_buttons"}>
                                                <div className={"one_field_delivery"}>Форма без служб доставки</div>
                                                <div
                                                    onClick={e=> formWithoutDelivery("withoutDelivery", "arrayRefund", indexRefund)}
                                                    className={""}>
                                                    <IsActiveIndicator active={itemRefund.withoutDelivery} />
                                                </div>
                                            </div>
                                            <div className={"activation_return_buttons"}>
                                                <div className={"one_field_delivery"}>Активировать кнопку "Отправить заявление на возврат"</div>
                                                <div
                                                    onClick={e=> activationButtonReturn("selfRefund", "arrayRefund", indexRefund)}
                                                    className={""}>
                                                    <IsActiveIndicator active={itemRefund.selfRefund} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        </div>


                    </div>

                    <div className={"refund_text_info"}>
                        <div className={"adm_title_delivery"}>
                            Описание блока, текстовая информация
                        </div>
                        <div className="one_field_delivery_wrapper">
                            <span className={"one_field_delivery"}>Заголовок текста</span>
                            <input
                                value={noJsonDelivery.refundTitleInfo ? noJsonDelivery.refundTitleInfo : ""}
                                onChange={e => fieldsDeliveryRefundContent(e, "refundTitleInfo")}
                                className={"delivery_field_adm"} type="text"/>
                        </div>
                        <div className="one_field_delivery_wrapper">
                            <span className={"one_field_delivery"}>Текстовая информация (черный текст)</span>
                            <textarea
                                value={noJsonDelivery.refundTextInfo ? noJsonDelivery.refundTextInfo : ""}
                                onChange={e => fieldsDeliveryRefundContent(e, "refundTextInfo")}
                                className={"delivery_field_adm textarea_big"} type="text"/>
                        </div>
                        <div className="one_field_delivery_wrapper">
                            <span className={"one_field_delivery"}>Подзаголовок</span>
                            <input
                                value={noJsonDelivery.refundDopInfoTitle ? noJsonDelivery.refundDopInfoTitle : ""}
                                onChange={e => fieldsDeliveryRefundContent(e, "refundDopInfoTitle")}
                                className={"delivery_field_adm"} type="text"/>
                        </div>
                        <div className="one_field_delivery_wrapper">
                            <span className={"one_field_delivery"}>Текстовая информация (серый текст)</span>
                            <textarea
                                value={noJsonDelivery.refundDopInfoText ? noJsonDelivery.refundDopInfoText : ""}
                                onChange={e => fieldsDeliveryRefundContent(e, "refundDopInfoText")}
                                className={"delivery_field_adm textarea_big"} type="text"/>
                        </div>
                    </div>


                </div>
            </div>

            <span
                onClick={e => updatePageDeliveryAndRefund(pageContentDelivery)}
                className={ triggerContentDelivery === true ? "save_fields_delivery trig" : "save_fields_delivery"}
            >Сохранить</span>
        </div>
    );
};

export default AdmDelivery;