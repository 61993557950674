import React, {useEffect, useState} from 'react';

const OneLineCert = (props) => {

    const [oneLine, setOneLine] = useState(null)
    const [createdAt, setCreatedAt] = useState(null)

    useEffect( () => {
        setOneLine(props.item)
        let data = new Date(props.item.updatedAt);
        setCreatedAt(data);
    },[])

    return (
        <div className={"wrapper_container_for_one_line"}>
            {
                oneLine ?
                <>
                    <div className={"order_one_line_item"}>
                        {oneLine.id}
                    </div>
                    <div className={"order_one_line_item"}> {oneLine.code} </div>
                    <div className={"order_one_line_item"}> {oneLine.price} </div>

                    <div className={"order_one_line_item"}>
                        {oneLine.isActive ? 'Активирован' : oneLine.used ? 'Использован' : ""}
                    </div>
                    <div className={"order_one_line_item"}>
                        {oneLine.order_id_pay ? oneLine.order_id_pay : ""}
                    </div>
                    <div className={"order_one_line_item"}>
                        {oneLine.sertificate_reserv ? 'Да' : 'Нет'}
                    </div>
                    <div className={"order_one_line_item"}> {createdAt ? ""+createdAt.toLocaleString()+"" : "" } </div>
                </>
                : ""
            }
        </div>
    );
};


export default OneLineCert;