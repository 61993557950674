import React from 'react';
import "./doly.css";

const DolyamiSettingsPage = () => {
    return (
        <div className={"dolyami_settings_page"}>
            <div className={"dolyami_settings_page_content_wrap"}>
                asdas
            </div>
        </div>
    );
};

export default DolyamiSettingsPage;
//