import React, {useState} from 'react';
import './SubscribeButton.css';

const SubscribeButton = (props) => {



    return (
        <span
            onClick={ e=> props.openModalSubscriptions()}
            className={props.stateCheсkBox === true ? "sub_Form_button" : "sub_Form_button unactive"}>
             Подписаться
        </span>
    );
};

export default SubscribeButton;