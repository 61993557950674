import React, {useEffect, useState} from 'react';

import './ordersList.css';
import {getAllOrders} from "../../../http/orderApi";
import {Link, useParams} from "react-router-dom";
import OneLineOrder from "./oneLineOrder/OneLineOrder";
import Pagination from "../../../components/Paginstion";
import {generateExcelExport} from "../../../http/settingsApi";



const OrdersList = () => {

    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')

    if(!page || page <= 0)
        page = 1

    const [searchText, setSearchText] = useState('');
    const [searchTextConfirmed, setSearchTextConfirmed] = useState('');
    const [orderList,setOrderList] = useState(null);
    const [listWidth, setListWidth] = useState(0);
    const [listHeight, setListHeight] = useState(0)

    const limit = 100;
    const [allCount,setAllCount] = useState(0)

    useEffect(() => {
        const offset = searchTextConfirmed ? 0 : ~~((page - 1)*limit)

        setListWidth(window.innerWidth - 270);
        setListHeight(window.innerHeight - 350);

        getAllOrders(limit, offset, true, searchTextConfirmed).then(response =>  {
            setOrderList(response.rows);
            setAllCount(response.count)
        })
    },[page, searchTextConfirmed])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchTextConfirmed(searchText)
        }
    }


    return (
        <div className={"orders_page_wrapper"}>
            <div className={"heading_order_list_page"}>
                <span>Все заказы</span>
            </div>
            <div className="orders_panel_wrapper">
                <div style={{width: listWidth, height: listHeight }} className={"orders_container"}>

                    <div className={"container_for_search_field"}>
                        <input className={"search_input_products_page"} type="text"
                            value={searchText}
                            onKeyDown={e => handleKeyDown(e)}
                            onChange={e => setSearchText(e.target.value)}
                            placeholder={"ID заказа..."}
                            style={{width:"15%", marginBottom:"10px", marginLeft:"10px"}}
                        />  
                    </div>

                    <div className={"all_order_fields_name_container"}>
                        <div className={"order_system_id order_ogr"}> system id </div>
                        <div className={"order_number"}> Номер заказа </div>
                        <div className={"order_id"}> ID заказа </div>
                        <div className={"order_user_info"}> Покупатель </div>
                        <div className={"order_status"}>Статус</div>
                        <div className={"order_pay_status"}>Статус оплаты</div>
                        <div className={"order_to_pay_summ"}>Сумма к оплате </div>
                        <div className={"order_basket_items"}> Товары </div>
                        <div className={"order_address_inf"}>Адресс</div>
                        <div className={"order_email_address"}> Email покупателя </div>
                        <div className={"order_mobile_number"}> Телефон </div>
                        <div className={"order_time_to_create"}> Дата создания </div>
                    </div>
                    <div style={{height: listHeight}} className={"wrapper_for_one_order"}>
                        {
                            orderList ?
                                orderList.map((item,index) => {
                                   return(
                                       <OneLineOrder className={"order_one_line"} key={item.id} item={item}/>


                                   )
                                })
                                : ""
                        }
                    </div>
                </div>
            </div>
            <div className={"pagination_adm_wrapper"}>
                <Pagination page={page} limit={limit} count={allCount}/>
            </div>
        </div>
    );
};

export default OrdersList;