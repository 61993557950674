import React, {useEffect, useState} from 'react';
import './landry.css';
import {addLandry, fetchLandry, removeLandry} from "../../../http/catalogApi";
import OneLandry from "./oneLandry/OneLandry";
import Products from "../products/Products";

const Landry = () => {


    const [landryAll,setLandryAll] = useState([]);
    const [refreshDb, setRefreshDb] = useState(true);

    useEffect(() => {
        fetchLandry().then(data => {
            setLandryAll(data);
        })
    },[refreshDb])

    const addnewitem = async () => {
        const data = await addLandry();
        if(data.message) {
            setRefreshDb(!refreshDb);
        }
    }




    return (
        <div className={"landry_wrapper"}>

            <div className="landry_parrent_wrapper">
                <span className={"landry_title"}>Вкладыши ухода за одеждой</span>
                <div className="landry_tables">
                    <div className="landrys_item_wrapper">
                        {
                            landryAll[0] ?
                                landryAll.map((item,index) =>
                                    <OneLandry
                                        landryAll={landryAll}
                                        setLandryAll={setLandryAll}
                                        key={item.id} index={index} item={item}
                                        deleteClass={""}
                                    />
                                )
                                : <div className='no_landry'>На данный момент нет ни одного вкладыша</div>

                        }
                    </div>
                    <div onClick={e => addnewitem()} className="add_new_landry">
                        Добавить новый
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landry;