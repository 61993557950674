import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './HeaderSearchResult.module.css';
import { Context } from "../../../index";
import { useNavigate } from "react-router";
import { searchTips } from '../../../http/catalogApi';

const HeaderSearchResult = ({setStateSearcht}) => {
    const {settings} = useContext(Context);
    const [data, setData] = useState([]);
    let navigate = useNavigate();

    const listRef = useRef();

    const fetchQuery = useCallback(async () => {
        const res = await searchTips(settings.searchQuery).then(response => {
            if (!response.error && response.length > 1) {
                return response;
            }
            return []
        });

        setData(res);

        handleWindowResize();
        if (listRef && listRef.current) {
            document.documentElement.style.setProperty('--search-result-height', `${listRef.current.offsetHeight}px`);
        }
    }, []);

    useEffect(() => {
        if (settings.searchQuery.trim().length > 2) {
            fetchQuery();
        } else {
            setData([]);
            document.documentElement.style.removeProperty('--search-result-height');
        }
    }, [settings.searchQuery]);

    const handleWindowResize = useCallback(() => {
        const search = document.querySelector('.input_search_product');

        if (listRef.current) {
            listRef.current.style.marginLeft = `${search.getBoundingClientRect().left}px`;
            listRef.current.style.maxWidth = `${search.getBoundingClientRect().width + 10 + 18}px`;
        }
    }, [listRef.current])

    const onClickResult = (query) => {
        setStateSearcht(false);

        settings.setSearchQuery(query)
        navigate({pathname: '/search', search: `?query=${settings.searchQuery}`});
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [])

    const ucFirst = (str) => {
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    }

    return (
        <>
            {!!settings.searchQuery && (
                <div className={styles.root}>
                    <ul className={styles.list} ref={listRef}>
                        {!!data.length && data.map((item, i) => (
                            <li className={styles.item} key={item.id} onClick={() => onClickResult(item.name)}>
                                {ucFirst(item.name)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default HeaderSearchResult;