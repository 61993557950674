import React from 'react';
import './resultReviewsModal.css'

const ResultReviwsModal = (props) => {

    return (
        <div className={"result_modal_reviews " + props.className}>
                <div className={"result_modal_reviews_wrapper"}>
                    <div className={"result_modal_reviews_close"}>
                        <div
                            onClick={props.closeClick}
                            className={"reviews_modal_exit_div"}></div>
                    </div>
                        <div className={"reviews_result_content"}>
                            <div className="reviews_result_content_chilld">

                                <div className="success_check_mark"></div>
                                <span className="successfully_sent">Отзыв успешно отправлен!</span>
                                <div
                                    onClick={props.closeClick}
                                    className="successfully_sent_button">
                                    Ко всем отзывам
                                </div>

                            </div>
                        </div>

                </div>
            </div>
    );
};

export default ResultReviwsModal;