import React, {useState, useEffect} from 'react'

const RedirectItem = ({id, from, to, save, deleteItem}) => {
    const [fromValue, setFromValue] = useState(from)
    const [toValue, setToValue] = useState(to)
    const [isValid, setIsValid] = useState(true)

    useEffect(() => {
        setIsValid(fromValue && toValue && (toValue !== to || fromValue !== from) && fromValue !== toValue)
    }, [fromValue, toValue])

    return (
        <div className="one_category_redaction_wrapper">
            <div className={"one_category_wrap_field"}>
                <span className={"one_category_heading_item"}>* откуда:</span>
                <input className={"one_category_list_input"} onChange={({target}) => setFromValue(target.value)} value={fromValue}
                       type="text"/>
            </div>
            <div className={"one_category_wrap_field"}>
                <span className={"one_category_heading_item"}>* куда:</span>
                <input className={"one_category_list_input"} onChange={({target}) => setToValue(target.value)} value={toValue}
                       type="text"/>
            </div>
            <div className={`save_category_button ${!isValid ? 'disabled' : ''}`} onClick={() => {isValid && save(id, fromValue, toValue)}}>
                Сохранить
            </div>

            <div className={"delete_category_button"} onClick={() => deleteItem(id)}> Удалить </div>
        </div>
    )
}

export default RedirectItem