import React, {useEffect, useState} from 'react';
import {
    deleteOneCategory,
    fetchChangeableCategories,
    fetchClearCategory,
    fetchColors,
    saveOneCategory
} from "../../../../http/catalogApi";
import CompliteRemove from "../../UI/compliteremove/CompliteRemove";
import OneImageField from '../../UI/oneImageField/OneImageField';
import { addImages, deleteImage } from '../../../../http/imagesApi';
import IsActiveIndicator from '../../UI/isActiveIndicator/IsActiveIndicator';


const OneCategoryItem = (props) => {

    const [errorTextState, setErrorTextState] = useState("");

    const saveCategory = (category) => {
        saveOneCategory(category).then(response => {
            if(response.error) {
                setErrorTextState(response.error)
                return false;
            }
            setErrorTextState("")
            setErrorTextState(false)

        } )
    }
    const deleteOneCategoryHandler = (id) => {
        deleteOneCategory(id).then(response => {
            if(response.message) {
                props.setRefreshCategories(!props.refreshCategories)
            }
        })
    }
    const uploadImageCategory = (e, objectName, nameField) => {

        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);
        addImages(formData, 'categories', null, null).then(response => {
            if(response.message) {
                fieldController(nameField, response.fileName);
                saveCategory(props.item)
            }
        })

    }
    const deleteImageCategory = (filename, objectName, nameField) => {
        deleteImage(filename, "categories", null).then(response  => {
            fieldController(nameField, null);
            saveCategory(props.item);
        })
    }

    const showParentCategories = (fieldName, value) => {
        props.setClearCategoryList([...props.clearCategoryList], [props.clearCategoryList[props.index][fieldName] = value])
    }

    const fieldController = (fieldName, value) => {
        props.setClearCategoryList([...props.clearCategoryList], [props.clearCategoryList[props.index][fieldName] = value])
        setErrorTextState(true)
    }

    const fieldControllerName = (fieldName, value) => {
        props.setClearCategoryList([...props.clearCategoryList], [props.clearCategoryList[props.index][fieldName] = value])
        setErrorTextState(true)
    }
    const [deleteState,setDeleteState] = useState(false);

    const [searchCategoryQuery,setSearchCategoryQuery] = useState('');
    const [allCategories, setAllCategories] = useState([]);
    function categoryAfterSearchArray() {
        if(searchCategoryQuery) {
            return [...allCategories].filter(item => item.name.toLowerCase().includes(searchCategoryQuery.toLowerCase()))
        } else {
            return allCategories;
        }
    }

    useEffect(()=> {
        setAllCategories(props.clearCategoryList)
        if (!!props.clearCategoryList[props.index].parentId) {
            setSelectCategoryId([props.clearCategoryList[props.index].parentId])
        }

    }, [])

    const [selectCategoryId,setSelectCategoryId] = useState([]);
    const categoryProductHandler = (id) =>  {
        setSelectCategoryId([id]);
        fieldController("parentId", id);
    }

    return (
        <div key={props.id} className={"one_category_redaction_wrapper"}>
            <CompliteRemove open={deleteState} goRemove={e => deleteOneCategoryHandler(props.item.id) } canselRemove={e =>  setDeleteState(false)} />
            <div className={"one_category_wrap_field id"}>
                <span className={"one_category_heading_item"}>ID </span>
                <span className={"id_category_one_item"}>{props.clearCategoryList[props.index].id}</span>
            </div>

            <div className={"one_category_wrap_field"}>
                <span className={"one_category_heading_item"}>* Название</span>
                <input className={"one_category_list_input"} value={props.clearCategoryList[props.index].name ? props.clearCategoryList[props.index].name : "" }
                        onChange={e =>  fieldControllerName("name",e.target.value)}
                       type="text"/>
            </div>
            <div className={"one_category_wrap_field"}>
                <span className={"one_category_heading_item"}>* URL категории.  Пример:   ubki   , без цифр и слешей.</span>
                <input disabled={props.clearCategoryList[props.index].only_parent } className={"one_category_list_input"} value={props.clearCategoryList[props.index].slug ? props.clearCategoryList[props.index].slug : "" } type="text"
                       onChange={e =>  fieldController("slug",e.target.value)}
                />
            </div>

            <div className={"one_category_wrap_field"}>
                <span className={"one_category_heading_item"}>Родительский каталог</span>
                <div className={"one_category_wrap_field"}>
                    <div className={"is_secret_checkbox"}>
                            <span className={"field_info"}>
                                Показать категории
                            </span>
                        <IsActiveIndicator active={props.clearCategoryList[props.index].showCategories}
                                           change={e => showParentCategories("showCategories", !props.clearCategoryList[props.index].showCategories)}
                        />
                    </div>
                </div>
                { props.clearCategoryList[props.index].showCategories ?
                    <div className={"category_filter_input_container"}>
                        <input value={searchCategoryQuery ? searchCategoryQuery : ""}
                               onChange={e => setSearchCategoryQuery(e.target.value)}
                               placeholder={"Поиск по категориям..."}
                               className={"input_filter_category"}
                               type="text"/>
                    </div>
                    : ''
                }

                {
                    props.clearCategoryList[props.index].showCategories ?
                        <div className={"category_setter_wrap"}>
                            {
                                categoryAfterSearchArray()[0] ?
                                    categoryAfterSearchArray().map((item,index) => {
                                        return(
                                            <div

                                                className={"one_item_category_setter active"}
                                                onClick={e => categoryProductHandler(item.id)}
                                                key={item.id}>

                                                <div className={ selectCategoryId && selectCategoryId.includes(item.id) ? "category_check_box active" : "category_check_box"}> </div>
                                                <div className={"category_check_name"}>{item.name}</div>
                                            </div>
                                        )
                                    })
                                    : <div> Не найдено </div>
                            }
                        </div>
                        : ''
                }
            </div>

            <div
                onClick={e => saveCategory(props.item)}
                className={errorTextState ?  "save_category_button active" : "save_category_button"}>
                Сохранить
            </div>
            <div className={"error_category_text"}>
                {errorTextState}
            </div>

            <div
                onClick={e => setDeleteState(true)}
                className={"delete_category_button"}> Удалить </div>
            <div className={"description_and_image_category_wrapper"}>
                <div className={"title_and_description_category_wrapper"}>
                    <div className={"one_category_wrap_field"}>
                        <span className={"one_category_heading_item"}>* Title для категории, отображается в каталоге.</span>
                        <input className={"one_category_list_input"} value={props.clearCategoryList[props.index].title ? props.clearCategoryList[props.index].title : "" } type="text"
                                onChange={e =>  fieldController("title",e.target.value)}
                        />
                    </div>
                    <div className={"one_category_wrap_field"}>
                        <span className={"one_category_heading_item"}>* Description для категории, отображается в каталоге.</span>
                        <input className={"one_category_list_input"} value={props.clearCategoryList[props.index].description ? props.clearCategoryList[props.index].description : "" } type="text"
                                onChange={e =>  fieldController("description",e.target.value)}
                        />
                    </div>
                    <div className={"one_category_wrap_field"}>
                        <span className={"one_category_heading_item"}>SEO текст.</span>
                        <textarea className={"one_category_list_input"} value={props.clearCategoryList[props.index].seo_text ? props.clearCategoryList[props.index].seo_text : "" } type="text"
                                    onChange={e =>  fieldController("seo_text",e.target.value)}></textarea>
                    </div>
                    <div className={"one_category_wrap_field"}>
                        <div className={"is_secret_checkbox"}>
                            <span className={"field_info"}>
                                <span className={"red_sup"}>*</span> Секретный раздел </span>
                            <IsActiveIndicator active={props.clearCategoryList[props.index].is_secret} change={e => fieldController("is_secret", !props.clearCategoryList[props.index].is_secret)} />
                        </div>
                        <span className={"fields_text_aft"}>При включении, убедителсь что категория не находится в меню хедера</span>
                    </div>

                    <div className={"one_category_wrap_field"}>
                        <div className={"is_secret_checkbox"}>
                            <span className={"field_info"}>
                                <span className={"red_sup"}>*</span>
                                Используется в фидах
                            </span>
                            <IsActiveIndicator active={props.clearCategoryList[props.index].used_in_feeds}
                                               change={e => fieldController("used_in_feeds", !props.clearCategoryList[props.index].used_in_feeds)}
                            />
                        </div>
                    </div>

                </div>
                <div className={"one_img_category_block"}>
                    <OneImageField
                        text={"Мобильно приложение"}
                        image={props.clearCategoryList[props.index].image ?
                            process.env.REACT_APP_API_URL+"uploads/images/categories/"+props.clearCategoryList[props.index].image  : null }
                        onChange={ e => uploadImageCategory(e, props.clearCategoryList[props.index].id, 'image')}
                        deleteImage={ e => deleteImageCategory(props.clearCategoryList[props.index].image, props.item.id, 'image')}
                    />
                </div>
                <div className={"one_img_category_block"}>
                    <OneImageField
                        text={"Баннер в разделе"}
                        image={props.clearCategoryList[props.index].banner ?
                            process.env.REACT_APP_API_URL+"uploads/images/categories/"+props.clearCategoryList[props.index].banner  : null }
                        onChange={ e => uploadImageCategory(e, props.clearCategoryList[props.index].id, 'banner')}
                        deleteImage={ e => deleteImageCategory(props.clearCategoryList[props.index].banner, props.item.id, 'banner')}
                    />

                </div>
            </div>
        </div>
    );
};

export default OneCategoryItem;