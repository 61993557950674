import React, {useEffect, useState} from 'react';
import './admFqa.css'
import OneImageHorizontalField from "../../UI/OneImageHorizontalField/OneImageHorizontalField";
import {getOnePageContent, updateContentPage} from "../../../../http/contentApi";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import {contentBackgroundImage} from "../../../../utils/consts";

const AdmFqa = () => {


    const [fqaPageContent, setFqaPageContent] = useState('')
    const [noJsonFqa, setNoJsonFqa] = useState('')
    const [trigerSaveFqa, setTrigerSaveFqa] = useState(false)
    const oneQuestion =  {titleFqa:"", textFqa: "", activeFqa: false };

    const addNewQuestion = (object) => {
        let reduceQuestion = {...noJsonFqa};
        reduceQuestion.questions.push(object)
        setNoJsonFqa(reduceQuestion)
        setTrigerSaveFqa(true)
    }

    const deleteQuestion = (id) => {
        let deleteOneQuestion = {...noJsonFqa};
        deleteOneQuestion.questions.splice(id,1)
        setNoJsonFqa(deleteOneQuestion)
        setTrigerSaveFqa(true)

    }

    useEffect(() => {
        getOnePageContent(11).then(response => {
            if (response) {
                setFqaPageContent(response)
            }
            if(response.content) {
                setNoJsonFqa(JSON.parse(response.content))
                return false;
            }
            setNoJsonFqa({

                questions: [

                ],
                feedback:{
                    titleFeedback: '',
                    textFeedback: '',
                    buttonFeedback: '',
                    FeedbackLink: ''
                },

            })
        } )

    },[])

    useEffect(() => {
        setFqaPageContent({...fqaPageContent, content:JSON.stringify(noJsonFqa)})
    }, [noJsonFqa])


    const fieldsSeoFqa = (e, fieldName) => {
        setFqaPageContent({...fqaPageContent, [fieldName]: e.target.value})
        setTrigerSaveFqa(true)
    }


    const uploadBackgroundFqa = (e,id) => {


        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (noJsonFqa.img){
            deleteImage(noJsonFqa.img, "fqa", null).then(response  => {
                setNoJsonFqa({...noJsonFqa,img: ""})            })
            setTrigerSaveFqa(true)

        }
        addImages(formData,'fqa',null, contentBackgroundImage).then(response => {
            if(response.message) {
                setNoJsonFqa({...noJsonFqa,img:response.fileName})
                setTrigerSaveFqa(true)
            }
        })

    }

    const deleteBackgroundFqa = (filename) => {
        deleteImage(filename, "fqa", null).then(response  => {
            setNoJsonFqa({...noJsonFqa,img: ""})
            setTrigerSaveFqa(true)
        })
    }



    const fieldsQuestion = (e, fieldName, objectName, index) => {
        setNoJsonFqa({...noJsonFqa}, [noJsonFqa[objectName][index][fieldName] = e.target.value])
        setTrigerSaveFqa(true)
    }

    const fieldsFeedback = (e, fieldName, objectName) => {
        setNoJsonFqa({...noJsonFqa}, [noJsonFqa[objectName][fieldName] = e.target.value])
        setTrigerSaveFqa(true)
    }


    const updatePageFqa = (fqaPageContent) => {
        updateContentPage(fqaPageContent).then(response => {
            setTrigerSaveFqa(false)
        } )
    }

    return (
        <div className={"adm_page_fqa"}>
            <div className={"contacts_fqa_title"}>Менеджер часто задаваемых вопросов</div>
            <div className={"content_manager_fqa"}>
                <div className={"left_block_seo_fqa"}>
                    <div className={"fields_seo_fqa"}>
                        <span className="fqa_field_name">Название страницы</span>
                        <input
                            value={fqaPageContent.name ? fqaPageContent.name : ""}
                            onChange={e => fieldsSeoFqa(e,'name')}
                            className="fqa_field" type="text"/>
                    </div>
                    <div className={"fields_seo_fqa"}>
                        <span className="fqa_field_name">title</span>
                        <input
                            value={fqaPageContent.title ? fqaPageContent.title : ""}
                            onChange={e => fieldsSeoFqa(e,'title')}
                            className="fqa_field" type="text" />
                    </div>
                    <div className={"fields_seo_fqa"}>
                        <span className="fqa_field_name">descriprion</span>
                        <input
                            value={fqaPageContent.description ? fqaPageContent.description : ""}
                            onChange={e => fieldsSeoFqa(e,'description')}
                            className="fqa_field" type="text" />
                    </div>
                </div>
                <div className={"image_adm_fqa"}>
                    <OneImageHorizontalField

                        onChange={ e => uploadBackgroundFqa(e,fqaPageContent.id) }
                        image={noJsonFqa.img ?
                            process.env.REACT_APP_API_URL+"uploads/images/fqa/"+ noJsonFqa.img : null }
                        deleteImage={e => deleteBackgroundFqa(noJsonFqa.img)}

                    />
                </div>
            </div>
            <div className={"questions_wrapper"}>
                <div
                    onClick={e => addNewQuestion(Object.assign({}, oneQuestion ))}
                      className={"add_new_question"}>Добавить вопрос</div>
                <div className={"one_question_fqa"}>
                    {noJsonFqa.questions ? noJsonFqa.questions.map((itemQuestion, indexQuestion) =>
                        <div key={indexQuestion} className={"fields_fqa_wrap"}>
                            <div className={"fields_fqa"}>
                                <span className="label_fqa">Вопрос</span>
                                <input
                                    value={itemQuestion.titleFqa ? itemQuestion.titleFqa : ""}
                                    onChange={e => fieldsQuestion(e,"titleFqa", "questions", indexQuestion)}
                                    className="field_text_fqa" type="text"/>
                                <span className="label_fqa">Ответ</span>
                                <textarea
                                    value={itemQuestion.textFqa ? itemQuestion.textFqa : ""}
                                    onChange={e => fieldsQuestion(e,"textFqa","questions", indexQuestion)}
                                    className="field_text_fqa textarea" type="text"/>
                            </div>
                            <div
                                onClick={e => deleteQuestion(indexQuestion)}
                                className={"del_fqa"}>✖</div>
                        </div>
                    ) : ""}
                </div>
            </div>
            {
                noJsonFqa.feedback ?
                    <div className={"feedback_block"}>
                        <div className={"contacts_fqa_title"}>Блок под вопросами</div>
                        <div className={"fields_fqa"}>
                            <span className="label_fqa">Заголовок</span>
                            <input
                                value={noJsonFqa.feedback.titleFeedback ? noJsonFqa.feedback.titleFeedback : ""}
                                onChange={e => fieldsFeedback(e,"titleFeedback", 'feedback')}
                                className="field_text_fqa" type="text"/>
                            <span className="label_fqa">Текст блока</span>
                            <textarea
                                value={noJsonFqa.feedback.textFeedback ? noJsonFqa.feedback.textFeedback : ""}
                                onChange={e => fieldsFeedback(e,"textFeedback", "feedback")}
                                className="field_text_fqa textarea" type="text"/>
                            <span className="label_fqa">Текст кнопки</span>
                            <input
                                value={noJsonFqa.feedback.buttonFeedback ? noJsonFqa.feedback.buttonFeedback : ""}
                                onChange={e => fieldsFeedback(e,"buttonFeedback", "feedback")}
                                className="field_text_fqa" type="text"/>
                            <span className="label_fqa">Ссылка кнопки</span>
                            <input
                                value={noJsonFqa.feedback.FeedbackLink ? noJsonFqa.feedback.FeedbackLink : ""}
                                onChange={e => fieldsFeedback(e,"FeedbackLink", "feedback")}
                                className="field_text_fqa" type="text"/>
                        </div>
                    </div>
                    : ''
            }


            <span
                onClick={e => updatePageFqa(fqaPageContent)}
                className={ trigerSaveFqa === true ? "save_page_fqa active" : "save_page_fqa" }>Сохранить</span>
        </div>
    );
};

export default AdmFqa;