import React, { useEffect, useMemo, useState } from 'react';
import { getchildsforslider } from '../../http/catalogApi';
import SearchChildProductArea from '../../pages/administrator/products/addOneProduct/searchChildProductsComponent/SearchChildProductArea';
import OneImageField from '../../pages/administrator/UI/oneImageField/OneImageField';
import { transliterate } from '../../utils/format';
import IsActiveIndicator from '../../pages/administrator/UI/isActiveIndicator/IsActiveIndicator';
import './admManagedSliders.css';

const imageTemplate = { id: null, image: '' };

const AdmManagedSliders = ({
	uploadImage,
	oneStockCreate,
	setOneStockCreate,
	sliders,
	setSliders,
	setDeletedSliders,
}) => {
	const [productSliders, setProductSliders] = useState([]);
	const [imageSliders, setImageSliders] = useState([]);

	const toggleSliderActivity = (e, slider) => {
		const updatedSlider = sliders.find((locSlider) => slider.id == locSlider.id);
		updatedSlider.is_active = !updatedSlider.is_active;
		let slidersKey = '';

		if (updatedSlider.type == 'products') {
			slidersKey = 'productSliders';
		} else if (updatedSlider.type == 'images') {
			slidersKey = 'imageSliders';
		}

		setOneStockCreate((prev) => {
			return {
				...prev,
				[slidersKey]: [
					updatedSlider,
					...prev[slidersKey].filter((slider) => slider.id != updatedSlider.id),
				],
			};
		});
	};

	useMemo(() => {
		const presentIds =
			oneStockCreate.productSliders && oneStockCreate.productSliders.length
				? oneStockCreate.productSliders.map((item) => !!item && item.id)
				: [];

		if (!oneStockCreate.productSliders || oneStockCreate.productSliders.length == 0) {
			setProductSliders([]);
		}

		if (oneStockCreate.productSliders && oneStockCreate.productSliders.length) {
			oneStockCreate.productSliders.forEach((prodSlider, index) => {
				if (!prodSlider || prodSlider.type !== 'products') return;
				const ids = prodSlider.items.filter((item) => typeof item == 'number');
				if (prodSlider && prodSlider.item_id && ids.length) {
					getchildsforslider(ids).then((response) => {
						if (!response.length) return;
						const updatedSlider = oneStockCreate.productSliders.find(
							(slider) => prodSlider && prodSlider.id == slider.id
						);
						setProductSliders((prev) => {
							return [
								{ ...updatedSlider, items: response },
								...prev.filter(
									(prevItem) =>
									prevItem && presentIds.includes(prevItem.id) &&
										prevItem.id != prodSlider.id
								),
							];
						});
					});
				} else {
					setProductSliders(oneStockCreate.productSliders);
				}
			});
		}
	}, [oneStockCreate.productSliders]);

	useMemo(() => {
		const presentIds =
			oneStockCreate.imageSliders &&
			oneStockCreate.imageSliders.length &&
			oneStockCreate.imageSliders.map((item) => item && item.id);

		if (oneStockCreate.imageSliders && oneStockCreate.imageSliders.length == 0) {
			setImageSliders([]);
		}
		if (oneStockCreate.imageSliders && oneStockCreate.imageSliders.length) {
			oneStockCreate.imageSliders.forEach((imageSlider) => {
				if (!imageSlider || imageSlider.type !== 'images') return;

				if (imageSlider && imageSlider.item_id) {
					let updateSliderIndex;
					const updatedSlider = oneStockCreate.imageSliders.find((slider, index) => {
						if (imageSlider && slider && imageSlider.id == slider.id) {
							updateSliderIndex = index;
							return slider;
						}
					});
					updatedSlider.items = addBlankSliderToImageSlider(updatedSlider.items);

					setImageSliders((prev) => {
						return [
							{ ...updatedSlider },
							...prev.filter((prevItem) => {
								return (
									presentIds.includes(prevItem.id) &&
									prevItem.id != imageSlider.id
								);
							}),
						];
					});
				} else {
					setImageSliders(oneStockCreate.imageSliders);
				}
			});
		}
	}, [oneStockCreate.imageSliders]);

	const addProductToProductsSlider = (data, sliderId) => {
		//добавление в слайдер, который получили при первоначальной загрузкe страницы
		const slider = oneStockCreate.productSliders.find((slider) => slider.id == sliderId);

		if (slider && slider.items.filter((item) => item == data.id).length == 0) {
			const newProductSliders = oneStockCreate.productSliders.map((prodSlider) => {
				if (prodSlider && prodSlider.id == slider.id) {
					return { ...slider, items: [...slider.items, data.id] };
				}
				return prodSlider;
			});

			setOneStockCreate({ ...oneStockCreate, productSliders: newProductSliders });
		}

		//добавление в новодобавленный слайдер
		if (slider) return;
		const stateSlider = productSliders.find((slider) => slider.id == sliderId);
		if (stateSlider && stateSlider.items.filter((item) => item == data.id).length == 0) {
			stateSlider.items.push(data.id);
			const newProductSliders = productSliders.map((prodSlider) => {
				if (prodSlider && prodSlider.id == stateSlider.id) {
					return stateSlider;
				}
				return prodSlider;
			});

			setOneStockCreate({ ...oneStockCreate, productSliders: newProductSliders });
		}
	};

	const deleteProductFromProductSlider = (data, sliderId) => {
		//добавление в слайдер, который получили при первоначальной загрузкe страницы
		const slider = oneStockCreate.productSliders.find((slider) => slider.id == sliderId);
		if (slider && slider.items.filter((item) => item == data.id).length !== 0) {
			const newProductSliders = oneStockCreate.productSliders.map((prodSlider) => {
				if (prodSlider && prodSlider.id == slider.id) {
					return { ...slider, items: [...slider.items.filter((id) => id != data.id)] };
				}
				return prodSlider;
			});

			setOneStockCreate({ ...oneStockCreate, productSliders: newProductSliders });
		}

		//удаление из новодобавленного слайдер
		if (slider) return;
		const stateSlider = productSliders.find((slider) => slider.id == sliderId);
		if (stateSlider && stateSlider.items.filter((item) => item == data.id).length !== 0) {
			stateSlider.items = stateSlider.items.filter((item) => item != data.id);
			const newProductSliders = productSliders.map((prodSlider) => {
				if (prodSlider.id == stateSlider.id) {
					return stateSlider;
				}
				return prodSlider;
			});

			setOneStockCreate({ ...oneStockCreate, productSliders: newProductSliders });
		}
	};

	const deleteProductSlider = (id, isNew) => {
		setOneStockCreate((prev) => {
			return {
				...prev,
				productSliders: prev.productSliders.filter((item) => {
					return item && item.id != id;
				}),
			};
		});
		!isNew && setDeletedSliders((prev) => [...prev, id]);
	};

	function addBlankSliderToImageSlider(items) {
		const is = items.find((item) => !item.image && item.id !== null);
		if (is) return [...items];
		let reduceOtherImages = [];
		if (items !== null) {
			reduceOtherImages = [...items];
			if (items.length) {
				let maxId = items.reduce((acc, curr) => (acc.b > curr.b ? acc : curr)).id;
				reduceOtherImages.push({ ...imageTemplate, id: maxId + 1 });
			} else {
				reduceOtherImages.push({ ...imageTemplate, id: 1 });
			}
		}
		return reduceOtherImages;
	}

	const deleteCustomImageSlider = (id, isNew) => {
		setOneStockCreate((prev) => {
			return {
				...prev,
				imageSliders: prev.imageSliders.filter((item) => {
					return !!item && item.id != id;
				}),
			};
		});
		!isNew && setDeletedSliders((prev) => [...prev, id]);
	};

	useEffect(() => {
		setSliders([...productSliders, ...imageSliders]);
	}, [productSliders, imageSliders]);

	const updateSliderName = (e, changingSlider) => {
		const value = e.target.value;
		if (!value) return;
		let sliderIndex;
		let slidersKey;

		if (changingSlider.type == 'images') {
			slidersKey = 'imageSliders';
		} else if (changingSlider.type == 'products') {
			slidersKey = 'productSliders';
		}
		setOneStockCreate((prev) => {
			const updatedSlider = prev[slidersKey].find((slider, index) => {
				if (slider && slider.id == changingSlider.id) {
					sliderIndex = index;
					return slider;
				}
			});
			updatedSlider.name = e.target.value;
			updatedSlider.slug = transliterate(value).toLocaleLowerCase();
			return {
				...prev,
				[slidersKey]: prev[slidersKey].map((slider, index) => {
					if (slider && slider.id == updatedSlider.id && sliderIndex == index) {
						return updatedSlider;
					} else if (slider && slider.id != updatedSlider.id) {
						return slider;
					}
				}),
			};
		});
	};

	const addImageSlider = () => {
		let newSlider = {
			id: Date.now(), //temporary id for finding slider
			is_active: true,
			type: 'images',
			name: 'Новый слайдер с картинками',
			slug: 'novii_slaider_s_kartinkami',
			items: [Object.assign({}, { ...imageTemplate, id: 1 })],
			createdAt: new Date(),
			updatedAt: new Date(),
			item_id: oneStockCreate.id,
			is_new: true,
		};

		setOneStockCreate((prev) => ({
			...prev,
			imageSliders: [newSlider, ...prev.imageSliders],
		}));
	};
	const addProductSlider = () => {
		const newSlider = {
			id: Date.now(), //temporary id for finding slider
			is_active: true,
			type: 'products',
			name: 'Новый продуктовый слайдер',
			slug: 'Novii_produktovii_slaider',
			items: [],
			createdAt: new Date(),
			updatedAt: new Date(),
			item_id: oneStockCreate.id,
			table_code: 'products',
			is_new: true,
		};

		setOneStockCreate({
			...oneStockCreate,
			productSliders: [newSlider, ...oneStockCreate.productSliders],
		});
	};

	const deleteImageSlide = (imageId, sliderId) => {
		if (!sliderId && sliderId !== 0) {
			console.error('Не получилось удалить слайд, не передан sliderId', sliderId);
		}

		setImageSliders((prev) => {
			const updatedSlider = prev.find((slider) => slider && slider.id == sliderId);
			updatedSlider.items = updatedSlider.items.filter((item) => item && item.id != imageId);
			return [updatedSlider, ...prev.filter((item) => item && item.id != updatedSlider.id)];
		});
	};

	return (
		<div className={'admin_inputs_wrapper'}>
			<div className={'wrap_for_row_field wrap_for_row_field--sliders'}>
				<span className={'field_info field_info--sliders'}>
					<span className={'red_sup'}>*</span>Слайдеры
				</span>
				<div className="add_stock_and_trends__add-sliders-buttons">
					<div
						onClick={addProductSlider}
						class="one_order_page_ident_container_top_line_item_right"
					>
						<span class="one_order_page_ident_add_new_product">
							Добавить слайдер с товарами
						</span>
					</div>
					<div
						onClick={addImageSlider}
						class="one_order_page_ident_container_top_line_item_right"
					>
						<span class="one_order_page_ident_add_new_product">
							Добавить слайдер с картинками
						</span>
					</div>
				</div>
			</div>
			{sliders.map((slider) => {
				if (slider && slider.type == 'products') {
					return (
						<>
							<div
								class="admin_inputs_wrapper admin_inputs_wrapper--slider"
								data-slider-id={slider.id}
							>
								<div className={'wrap_for_row_field'}>
									<h2 class="add_one_stock_and_trends__slider-title">
										<b>{slider.name}</b> (Слайдер товаров)
									</h2>
									<IsActiveIndicator
										active={slider.is_active}
										change={(e) => toggleSliderActivity(e, slider)}
									/>
									<button
										onClick={() => deleteProductSlider(slider.id, slider.isNew)}
										className="add_one_stock_and_trends__delete-slider"
									>
										Удалить слайдер
									</button>
								</div>

								<h3 className="stock_slider_insert_in_text_hint stock_slider_insert_in_text_hint--big">
									##slider_{slider.slug}##{' '}
									<span className="stock_slider__inline-hint">
										(Используйте для вставки в текст)
									</span>
								</h3>

								<input
									class="admin_field_styles admin_field_styles--short"
									placeholder="Укажите название слайдера"
									type="text"
									onBlur={(e) => updateSliderName(e, slider)}
								/>
							</div>
							<SearchChildProductArea
								addProduct={addProductToProductsSlider}
								sliderId={slider.id}
							/>

							<div className={'products_in_slider'}>
								{slider
									? slider.items.map((item, index) => {
											if (typeof item == 'number') return;
											return (
												<div
													className={'one_slide_product_wrapper'}
													key={item.id}
												>
													<div className={'one_slide_img_wrapper'}>
														<img
															src={
																process.env.REACT_APP_API_URL +
																'uploads/images/' +
																item.product.id +
																'/' +
																item.id +
																'/214_' +
																item.img_preview
															}
															alt=""
														/>
													</div>
													<span>{item.name}</span>
													<span
														onClick={(e) =>
															deleteProductFromProductSlider(
																item,
																slider.id
															)
														}
														className={'destroy_one_slide'}
													>
														Удалить
													</span>
												</div>
											);
									  })
									: ''}
							</div>
						</>
					);
				} else if (slider && slider.type == 'images') {
					return (
						<div className={'admin_inputs_wrapper'} data-slider-id={slider.id}>
							<div class="admin_inputs_wrapper admin_inputs_wrapper--slider">
								<div className={'wrap_for_row_field'}>
									<h2 class="add_one_stock_and_trends__slider-title">
										<b>{slider.name}</b> (Слайдер картинок)
									</h2>
									<IsActiveIndicator
										active={slider.is_active}
										change={(e) => toggleSliderActivity(e, slider)}
									/>
									<button
										onClick={() =>
											deleteCustomImageSlider(slider.id, slider.isNew)
										}
										className="add_one_stock_and_trends__delete-slider"
									>
										Удалить слайдер
									</button>
								</div>

								<h3 className="stock_slider_insert_in_text_hint stock_slider_insert_in_text_hint--big">
									##slider_{slider.slug}##{' '}
									<span className="stock_slider__inline-hint">
										(Используйте для вставки в текст)
									</span>
								</h3>

								<input
									class="admin_field_styles admin_field_styles--short"
									placeholder="Укажите название слайдера"
									type="text"
									name=""
									id=""
									onBlur={(e) => updateSliderName(e, slider)}
								/>
							</div>
							<div className="stock_slider_images">
								{slider.items.map((item, index) => {
									return (
										<div className="stock_slider_one_image">
											<OneImageField
												text={'Изображение'}
												image={
													item.image
														? process.env.REACT_APP_API_URL +
														  'uploads/images/stockAndTrends/' +
														  oneStockCreate.id +
														  '/' +
														  item.image
														: null
												}
												onChange={(e) =>
													uploadImage(
														e,
														oneStockCreate.id,
														'image',
														'custom_image_slider',
														index,
														slider.id,
														item.id
													)
												}
												deleteImage={(e) =>
													deleteImageSlide(item.id, slider.id)
												}
											/>
										</div>
									);
								})}
							</div>
						</div>
					);
				}
			})}
		</div>
	);
};

export default AdmManagedSliders;
