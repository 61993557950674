import React, { useMemo, useRef } from "react";
import "./sliderFashion.css";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar } from "swiper";

SwiperCore.use([Scrollbar]);

const SliderFashion = (props) => {
  const sliderRef = useRef();
  const location = useLocation();

  const buttonLeftNew = () => {
    sliderRef.current.swiper.slidePrev();
  };
  const buttonRightNew = () => {
    sliderRef.current.swiper.slideNext();
  };

  const slides = useMemo(() => {
    return props.fashionsHome.map((item, index) => (
      <SwiperSlide key={item.id}>
        <Link
          id={"one_look_link"}
          className={"one_look_link"}
          to={`/fashions/${item.id}/`}
          state={{ backgroundLocation: location }}
        >
          <div className={"wrap_components_look"}>
            <div className={"image_fashion"}>
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  "uploads/images/image/" +
                  (item.previewImg ? item.previewImg : item.detailImg)
                }
                alt={"Образ " + (item.title ? item.title : "")}
              />
              {
                  item.tag ?
                  item.href ? 
                        <a href={item.href} className="image_fashion_name">
                          @{item.tag}
                        </a>
                      : 
                      <span className="image_fashion_name">
                          @{item.tag}
                      </span>
                  : ""
                }
            </div>
          </div>
        </Link>
      </SwiperSlide>
    ));
  }, [props.fashionsHome, location]);

  return (
    <div className={"fashion_home"}>
      {props.titleSlider ? (
        <div className={"name_block_look"}>
          {props.titleSlider ? (
            <Link to="/fashions">{props.titleSlider}</Link>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <div className={"button_and_arrows"}>
        <div className={"arrows_look_block"}>
          <div onClick={(e) => buttonLeftNew()} className={"one_arrows_look"}>
            <svg
              width="20"
              height="10"
              viewBox="0 0 20 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83 6L6.41 8.59L5 10L0 5L5 0L6.41 1.41L3.83 4L20 4V6L3.83 6Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
          <div onClick={(e) => buttonRightNew()} className={"one_arrows_look"}>
            <svg
              width="20"
              height="10"
              viewBox="0 0 20 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.17 6L13.59 8.59L15 10L20 5L15 0L13.59 1.41L16.17 4L0 4L0 6L16.17 6Z"
                fill="#1A1A1A"
              />
            </svg>
          </div>
        </div>
        <div className={"go_all_lookbook"}>
          <Link className={"button_go_lookbook"} to={"/fashions/"}>
            Cмотреть все
          </Link>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.17 13L15.59 15.59L17 17L22 12L17 7L15.59 8.41L18.17 11H2V13H18.17Z"
              fill="#1A1A1A"
            />
          </svg>
        </div>
      </div>
      {!!slides && (
        <Swiper
          className={"fashions-slider"}
          slidesPerView="auto"
          watchSlidesProgress={true}
          ref={sliderRef}
          scrollbar={{ draggable: true }}
          observer={true}
          onObserverUpdate={(swiper) => {
            setTimeout(() => {
              swiper.update();
              swiper.emit("resize");
              swiper.updateProgress();
            }, 300);
          }}
        >
          {slides}
        </Swiper>
      )}
    </div>
  );
};

export default SliderFashion;
