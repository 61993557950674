import React from "react";
import styles from './ModalOverlay.module.css';
import { memo } from "react";

const ModalOverlay = memo(({ children, className, onClose }) => {
    return (
        <div
            className={`${styles.root} ${className}`}
            onClick={onClose}
        >
            {children}
        </div>
    );
});

export default ModalOverlay;