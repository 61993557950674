import React, {useLayoutEffect, useRef, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';

import './sliderBigBlock.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import {LazyLoadImage} from "react-lazy-load-image-component";

const SliderBigBlock = (props) => {
    const [containerWidth, setContainerWidth] = useState(0);
    const targetRef = useRef();
    SwiperCore.use([Autoplay]);
    useLayoutEffect(() => {
        if (targetRef.current) {
            if (window.outerWidth <= 1920 && window.outerWidth >= 1080) {
                setContainerWidth(targetRef.current.offsetWidth)
            } else if (window.outerWidth < 1080){
                setContainerWidth(1080)
            }
        }
    }, []);

    return (
        <div className={"slick_wrapper_home"}>
                <Swiper
                    loop={true}
                    speed={1000}
                    centeredSlides={true}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    {
                        props.sliderImages[props.sliderBannerName] ? props.sliderImages[props.sliderBannerName].map((item, index) =>
                            <SwiperSlide ref={targetRef} className={'home_slider_item'}>
                                <LazyLoadImage className={'home_slider_image'}
                                     src={process.env.REACT_APP_API_URL+"uploads/images/homepage/"+props.sliderBannerName+"/"+item.image}
                                     alt="image"
                                />
                            </SwiperSlide>
                        ) : ''
                    }
                </Swiper>
        </div>
    );
};

export default SliderBigBlock;
