import React, {useContext, useEffect, useState} from 'react';
import './modalPopup.css';
import {Context} from "../../../index";
import {getOneModal, subscribeNewsletter} from "../../../http/settingsApi";
import ModalSubscriptions from "../../homepage/ModalSubscriptions/ModalSubscriptions";
import {observer} from "mobx-react-lite";

const PopupOneVariant = observer(() => {


    const {user, settings} = useContext(Context)

    const [activeModalComponetns, setActiveModalComponetns ] = useState(false)
    const [modalPopupContent, setModalPopupContent] = useState('')
    const [activePhotoModal, setActivePhotoModal] = useState('')
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')

    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        getOneModal(1).then(response => {
            // setContentPage(response)
            // console.log("response")
            // console.log(response)
            if (response.isActive === true) {
                setActiveModalComponetns(true)
            }
            if (response) {
                setModalPopupContent(response)
                if (response.image_desctop && settings.projectWidth > 768) {
                    setActivePhotoModal(response.image_desctop)
                }
                if (response.image_mobile && settings.projectWidth < 769) {
                    setActivePhotoModal(response.image_mobile)
                }
            }
        return false;
        })
    }, [])

    const [modalContentPopup, setModalContentPopup] = useState({
        email:"",
    })

    const [stateCheсkBox, setStateCheсkBox] = useState(false)
    const [errorSubscribe, setErrorSubscribe] = useState('')
    const [errorSoglasie, setErrorSoglasie] = useState('')
    const [openModalPopUp, setOpenModalPopUp] = useState(false)
    const [activeModalResult, setActiveModalResult] = useState(false)

    const goModalPopup = () => {
        user.setShadowOverlay(true);
        setOpenModalPopUp(true);
        user.setStateOpenModalWindow(true)
    }

    const closeModalPopup = () => {
        setOpenModalPopUp(false);
        user.setShadowOverlay(false);
        user.setStateOpenModalWindow(false)
    }

    const openResultModal = () => {
        setOpenModalPopUp(false);
        setActiveModalResult(true)
    }

    const closeModalResult = () => {
        setActiveModalResult(false);
        user.setShadowOverlay(false);
        user.setStateOpenModalWindow(false)
    }

    const subscribeModalPopUp = (onclick) => {
        if(stateCheсkBox != false) {
            subscribeNewsletter(modalContentPopup.email, 1, deviceUUID, userAgent, 'subscriptionInPopUp10').then(res => {
                if(res.message) {
                    setModalContentPopup({...modalContentPopup, email:""})
                    openResultModal()
                    setErrorSubscribe('')
                    // console.log(res.message)
                    return false;
                }
                setErrorSubscribe(res.error)
                // console.log(res.error)

            } )

            return false

        }
        setErrorSoglasie('Примите соглашение')

    }

    useEffect(() => {
        if (user.shadowOverlay === false) {
            setOpenModalPopUp(false);
        }
    }, [user.shadowOverlay])


    useEffect(() => {

        if (activeModalComponetns === true) {
            let currentTime = Date.now();
            let currentTimeStorage = localStorage.getItem('popupTimeStorage');
            let timeLocalStorage = ''
            // тут проверяешь локалстор, если он пустой
            // if (activeModalComponetns != true) {
            //     return false;
            // }
            if(!currentTimeStorage) {
                let jsonCurrentTimeStorageArray = JSON.stringify(currentTime)
                localStorage.setItem("popupTimeStorage",jsonCurrentTimeStorageArray)
                setTimeout(() => goModalPopup(), 60000);
            } else {
                // return false;
                timeLocalStorage = JSON.parse(currentTimeStorage);
                let timeHasPassed = currentTime - timeLocalStorage;
                let passedHours = Math.floor((timeHasPassed / (1000 * 60 * 60)) % 24);
                // console.log('ЧАСОВ ПРОШЛО')
                // console.log(passedHours)

                if (passedHours >= 24) {
                    let jsonCurrentNewTime = JSON.stringify(currentTime)
                    localStorage.setItem("popupTimeStorage",jsonCurrentNewTime)
                    setTimeout(() => goModalPopup(), 60000);
                }
                return false;
            }
            return false;
        }

    },[activeModalComponetns])

    useEffect(() => {
        if (modalPopupContent.file_modal != null && activeModalResult === true) {
            if (settings.projectWidth > 768) {
                const url = process.env.REACT_APP_API_URL+"uploads/images/modal/"+ modalPopupContent.file_modal;
                window.open(url, '_blank');
            }
            else {
                const url = process.env.REACT_APP_API_URL+"uploads/images/modal/"+ modalPopupContent.file_modal;
                if (      window.location.assign(url)) {
                    window.open(url, '_blank');
                }
            }
        }
    }, [activeModalResult])


    return (
        <div>
            { activeModalComponetns === true ?
                <div
                    className={openModalPopUp === true ? "modal_popup_wrapper active" : "modal_popup_wrapper" }
                    // className={"modal_popup_wrapper active"}
                >
                    <div className={"popup_child_wrap"}>
                        <div
                            onClick={e => closeModalPopup()}
                            className={"close_popup"}></div>
                        <div className={"popup_content_wrap"}>
                            <div className={"container_banner_popup"}>
                                <img className={"banner_popup"} src={activePhotoModal ? process.env.REACT_APP_API_URL+"uploads/images/modal/"+ activePhotoModal : null}
                                     alt={'YouWanna - '+ (modalPopupContent.modal_fooText ? modalPopupContent.modal_fooText : 'интернет-магазин одежды и аксессуаров')}
                                />
                                <div className={"info_text_banner_popup"}>
                                    <div className={"text_banner_bold_line"}>{modalPopupContent.modal_oneText ? modalPopupContent.modal_oneText : ''}</div>
                                    <div className={"text_banner_one_line"}>{modalPopupContent.modal_twoText ? modalPopupContent.modal_twoText : ''}</div>
                                    <div className={"text_promo_and_copy"}>
                                        <div
                                            className={"popup_promocod"}>{modalPopupContent.modal_threeText ? modalPopupContent.modal_threeText : ''}</div>
                                        {modalPopupContent.modal_threeText ?
                                            <div
                                                onClick={e => navigator.clipboard.writeText(modalPopupContent.modal_threeText)}
                                                className={"button_copy_promocod"}>Копировать</div>
                                            :
                                            ''
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className={"container_fields_popup"}>
                                <div className={"child_fields_info"}>
                                    <div className={"title_popup_fields"}>{modalPopupContent.modal_fooText ? modalPopupContent.modal_fooText : ''}</div>
                                    <div className={"fields_and_sending"}>
                                        <div className={"container_email_fields"}>
                                            <span>E-mail</span>
                                            <input
                                                value={modalContentPopup.email}
                                                onChange={e =>  setModalContentPopup({...modalContentPopup,email:e.target.value})}
                                                className={"email_fields_input_popup"} type="text"/>
                                            <div className={"error_massage_popup"}>{errorSubscribe}</div>
                                        </div>
                                        <div
                                            onClick={e=> subscribeModalPopUp()}
                                            className={stateCheсkBox === true ? "button_sending_popup" : "button_sending_popup unactive"}><noindex>Подписаться</noindex></div>
                                        <div
                                            onClick={e=> closeModalPopup()}
                                            className={"button_close_popup"}><noindex>Закрыть</noindex></div>
                                    </div>
                                    <div className="politic_popup_wrapper">
                                        <div className={"link_politic_popup"}>
                                            <div
                                                onClick={e=> setStateCheсkBox(!stateCheсkBox)}
                                                className={stateCheсkBox === true ? "confirm_selector_popup active" : "confirm_selector_popup"}>
                                                <div className="child_confirm_selector_popup">
                                                </div>
                                            </div>
                                            <span> Согласен с условиями  <a rel="noreferrer"  target={"_blank"} href="/personal/oferta/">договора-оферты</a> и <a rel="noreferrer"  target={"_blank"} href="/personal/privacy-policy/">политикой конфиденциальности</a> </span>
                                        </div>
                                        <div className={"error_massage_popup"}>{errorSoglasie}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ""
            }

            <ModalSubscriptions
                className={activeModalResult === true ? "active" : "hide"}
                closeClick={() => closeModalResult()}
            />
        </div>

    );
});

export default PopupOneVariant;