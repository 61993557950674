import React, {useEffect, useState} from 'react';
import {updateStatus} from "../../../../http/settingsApi";

const OneStatusUpd = (props) => {

    const [item,setItem] = useState({});
    const [responseServer, setResponseServer] = useState(null);

    useEffect(() => {
        setItem(props.item);
    },[])

    const updateHandler = (status) => {
        updateStatus(status).then(response =>  {
            if(response.error) {
                setResponseServer(response.error);
            }
            if(response.message) {
                setResponseServer(response.message);
            }
        })
    }

    return (
        <div  className={"status_adm_page_work_area"}>
            <span>Изменить текущий статус заказа:</span>
            <div className={"input_wrapper_status"}>
                <span className={"status_page_heading_input"}>Название</span>
                <input type="text"
                       className={"input_status_page"}
                       placeholder={"Введите название"}
                       value={item.name ? item.name : ""}
                       onChange={e => setItem({...item, name: e.target.value})}
                />
            </div>
            <div className={"input_wrapper_status"}>
                <span className={"status_page_heading_input"}>Код в RetailCRM</span>
                <input type="text"
                       className={"input_status_page"}
                       placeholder={"Введите код"}
                       value={item.retailCode ? item.retailCode : ""}
                       onChange={e => setItem({...item, retailCode: e.target.value})}
                />
            </div>
            <div className={"server_status_response"}>
                {responseServer}
            </div>
            <div
                onClick={ e => updateHandler(item)}
                className={"save_status_page_btn"}>сохранить</div>
        </div>
    );
};

export default OneStatusUpd;