import React, {useContext, useEffect, useState} from 'react';
import './DoubleBlock.css';
import AbsoluteShadow from "../../UI/AbsoluteShadow/AbsoluteShadow";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import {LazyLoadImage} from "react-lazy-load-image-component";

const RightBlockHome = observer((props) => {


    const {settings} = useContext(Context);

    const [imgSuitableResolutionRightBlock, setImgSuitableResolutionRightBlock] = useState('')

    useEffect(() => {
        if (settings.projectWidth > 1024) {
            setImgSuitableResolutionRightBlock(props.item.img_pc)
            return false;
        }
        if (1025 > settings.projectWidth && settings.projectWidth > 428) {
            setImgSuitableResolutionRightBlock(props.item.img_tablet)
            return false;
        }
        if (settings.projectWidth < 429) {
            setImgSuitableResolutionRightBlock(props.item.img_mobile)
            return false;
        }
    }, [settings.projectWidth])



    return (
        <a
            href={props.item.linkButton ? props.item.linkButton : ''}
            className="right double_block_st">
            <AbsoluteShadow
                shadow={props.item.shadowright ? props.item.shadowright : ''}
            />

            {
                imgSuitableResolutionRightBlock ?
                    <LazyLoadImage src={
                        process.env.REACT_APP_API_URL + "uploads/images/homepage/" +props.objectName+"/" +imgSuitableResolutionRightBlock
                    } alt="YouWanna - интернет-магазин одежды и аксессуаров"/>
                    :
                    ''
            }

            <div className="labelInfo">
                <div className="supportLabelWrap">
                    <span className="labelTitleBlock">
                        {props.item.buttonName ? props.item.buttonName : ''}
                    </span>
                    <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.17 6L13.59 8.59L15 10L20 5L15 0L13.59 1.41L16.17 4L0 4L0 6L16.17 6Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </a>
    );
});

export default RightBlockHome;