import React, {useContext, useEffect, useRef, useState} from 'react';
import {Helmet} from "react-helmet";

import {Context} from "../../../index";
import BreadCrumbsBlack from "../../../components/Breadcrumbs/breadCrumbsBlack/BreadCrumbsBlack";
import StoreList from "../../../components/shop/storeList";
import './Shop.css';
import {observer} from "mobx-react-lite";
import ShopCity from "../../../components/shop/ShopCity";
import {fetchAllMarkets, getOnePageContent} from "../../../http/contentApi";
import ShopMaps from "../../../components/shop/ShopMaps";



const Shop = observer(() => {

    const {user} = useContext(Context)
    const {settings} = useContext(Context)
    const margTop = settings.headerHeight
    const [cityArray, setCityArray] = useState([]);
    const [activCardsShop, setActivCardsShop] = useState("");
    const [activMapsShop, setActiveMapsShop] = useState("");
    const [switchSity, SetSwitchSity] = useState('');
    const [switchCityActive, setSwitchCityActive] = useState(false);
    const [nonMapsMessage, setNonMapsMessage] = useState('')

    let oneCityPreviews =  cityArray.filter((filterCity) =>  filterCity.id === switchSity);
    let reduceArray =  cityArray.filter((filterMarkets) =>  filterMarkets.id === switchSity)[0];


    useEffect(() => {
        window.scrollTo(0, 0)
        getOnePageContent(6).then(response => {
            // setContentPageShop(response)
            // console.log("getOnePageContent")
            // console.log(response)
            if(response.error) {
                // console.log("error")
                // console.log(response)
                // setErrorShop(response.error)
                return false;
            }
            if(response.message) {
                // console.log("message")
                // console.log(response)
                // setErrorShop(response.message)
                //добавить тригер
            }

            const {title, description} = response
            if(title || description)
                setPageProps({title, description})
        })
        fetchAllMarkets().then(response => {
            setCityArray(response.dataCity)
            SetSwitchSity(response.dataCity[0].id)
            // TODO надо допилить проверку на наличие общей карты иначе вывод карты магазина
            setActiveMapsShop(response.dataCity[0].map_url)
            if (!response.dataCity[0].map_url) {
                setNonMapsMessage('Магазины на карте не отмечены.')
            }

        })

    },[])


    useEffect (() => {
        setActivCardsShop("")
    }, [switchSity])


    const [openModalMaps, setOpenModalMaps] = useState(false)
    const [pageProps, setPageProps] = useState(false)


    const goModalMaps = () => {
        user.setShadowOverlay(true);
        setOpenModalMaps(true);
        // setActiveMapsShop(mapStores)
    }

    const closeModalMaps = () => {
        setOpenModalMaps(false);
        user.setShadowOverlay(false);
    }

    useEffect( () => {
        if(user.shadowOverlay === false) {
            setOpenModalMaps(false);
        }
    },[user.shadowOverlay])


    const refPageShop = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (switchCityActive && refPageShop.current && !refPageShop.current.contains(e.target)) {
                setSwitchCityActive(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [switchCityActive]);


    return (
        <div className={"shop"}>
            {
                pageProps
                &&
                <Helmet>
                    <title>{pageProps.title}</title>
                    <meta name="description" content={pageProps.description} />
                </Helmet>
            }
            {
                cityArray ?
                    <div style={{marginTop:margTop}}  className={"s_conteiner"}>
                        <div>

                            <h1 className={"shop_title"}>Магазины</h1>
                            <div  className={"map_and_cities"}>
                                <span className={"select_city"}>Город:</span>
                                <div ref={refPageShop} className={"parents_button_city"}>
                                        <div className={switchCityActive === false ? "button_sity" : "button_sity active"}>
                                            {
                                                switchCityActive === false && oneCityPreviews ?
                                                    oneCityPreviews.map((item, index) =>
                                                        <ShopCity
                                                            key = {index}
                                                            index = {index}
                                                            item = {item}
                                                            switchSity = {switchSity}
                                                            SetSwitchSity = {e => SetSwitchSity(e)}
                                                            setActiveMapsShop = {e => setActiveMapsShop(e)}
                                                            setActivCardsShop = {e => setActivCardsShop(e)}
                                                            setSwitchCityActive = {e => setSwitchCityActive(e)}
                                                            switchCityActive = {switchCityActive}
                                                        />
                                                    )
                                                    :
                                                    cityArray.map((item, index) =>
                                                        <ShopCity
                                                            key = {index}
                                                            index = {index}
                                                            item = {item}
                                                            switchSity = {switchSity}
                                                            SetSwitchSity = {e => SetSwitchSity(e)}
                                                            setActiveMapsShop = {e => setActiveMapsShop(e)}
                                                            setActivCardsShop = {e => setActivCardsShop(e)}
                                                            setSwitchCityActive = {e => setSwitchCityActive(e)}
                                                            switchCityActive = {switchCityActive}
                                                        />
                                                    )
                                            }
                                        </div>

                                </div>
                            </div>
                        </div>


                        <div className={"maps_shop"}>
                                <div className={"s_maps"}>
                                    {
                                        cityArray && activMapsShop ?
                                            <iframe
                                                title={"Карта магазинов"}
                                                src={activMapsShop}
                                                width="100%" height="100%" frameBorder="0"></iframe>
                                            :
                                            <div className={"map_error"}>
                                                {nonMapsMessage}
                                            </div>
                                    }
                                </div>
                            <div  className={"s_content"}>

                                {/*{settings.projectWidth && settings.projectWidth > 1024 ?*/}
                                {/*    <div className={"s_bread_crumbs"}>*/}
                                {/*    <BreadCrumbsBlack*/}
                                {/*        items={[*/}
                                {/*            {name:"Youwanna", link:"/"},*/}
                                {/*            {name:"МАГАЗИНЫ", link:"/content/shop/"}*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*    </div>*/}
                                {/*    :*/}
                                {/*    <div className={"one_line_shop"}>*/}
                                {/*        <h1 className={"page_shop_title"}>Магазины</h1>*/}
                                {/*    </div>*/}



                                {/*}*/}

                                <ShopMaps
                                    className = { openModalMaps === true ?  "active" : "hide"  }
                                    closeClick = {e => closeModalMaps(e)}
                                    cityArray = {cityArray}
                                    activMapsShop = {activMapsShop}
                                    reduceArray = {reduceArray}
                                />

                                <div className={"store_list"}>
                                    <div className={
                                        reduceArray && reduceArray.markets.length > 1 ? "s_store_child" : "s_store_child_non"
                                    }>
                                        {
                                            reduceArray ?
                                                reduceArray.markets.map( (item, index)=> item.is_active && 
                                                    <StoreList
                                                        key={index}
                                                        index = {index}
                                                        item={item}
                                                        activCardsShop = {activCardsShop}
                                                        setActivCardsShop = {e => setActivCardsShop(e)}
                                                        setActiveMapsShop = {e => setActiveMapsShop(e)}
                                                        goModalMaps = {e => goModalMaps(e)}
                                                        closeClick = {e => closeModalMaps(e)}
                                                        reduceArray = {reduceArray}
                                                        switchCityActive = {switchCityActive}
                                                        setSwitchCityActive = {e => setSwitchCityActive(e)}
                                                    />
                                                )
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>








                </div>
                :
                    <div className={"map_error"}>
                        На данный момент розничных магазинов нет, попробуйте проверить информацию позже.
                    </div>
            }

        </div>
    );
});

export default Shop;