import React, {useContext, useEffect, useState} from 'react';
import './searchProductSite.css'
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import OneProductCart from "../catalog/OneProductCart";
import {productSearcht} from "../../http/catalogApi";
import {useLocation, useParams} from "react-router-dom";
import Spiner from "../administrator/spiner/Spiner";


const SearchProductSite = observer(() => {

    // const params = useParams()
    const {settings} = useContext(Context)
    const [reduceArraySerch, setReduceArraySerch] = useState([])
    const [arraySearch, setArraySearch] = useState([]);
    const [spinerSearch, setSpinerSearch] = useState(true);

    const limit = 21;
    const [offsetSearch, setOffsetSearch] = useState(0);
    const [getNewArraySearch, setGetNewArraySearch] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search)
    let query = queryParams.get('query');
    const params = new URLSearchParams(location.search);
    if (location.search) {
        query = params.get("query");
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setSpinerSearch(true);
        productSearcht(query, limit, offsetSearch).then(response => {
            setSpinerSearch(false)
            if (!response.error) {
                if (response.count >= 21) {
                    setGetNewArraySearch(true)
                } else {
                    setGetNewArraySearch(false)
                }
                setReduceArraySerch(response.rows)
            }else {
                settings.setSearchQuery('')
            }
            if (response.error) {
                setReduceArraySerch([])
            }
            settings.setSearchQuery('');
        }).catch(response => {
            setSpinerSearch(false)
        })
    }, [query]);

    const newArrayProductSearch = () => {
        productSearcht(query, limit, offsetSearch + limit).then(response => {
            if (!response.error) {
                setOffsetSearch(offsetSearch + limit)
                if (response.rows.length < limit) {
                    setGetNewArraySearch(false)
                }
                let reduceProductsArray = [...reduceArraySerch];
                let finishProductsArray = reduceProductsArray.concat(response.rows);

                setReduceArraySerch(finishProductsArray);
            }
        })
    }

    // определяем высоту при переходе, чтобы при возврате проскролить на это же место
    const handleScroll = () => {
        settings.setScrollStatusCatalog(window.scrollY);
    };



    return (
        <div style={{marginTop:settings.headerHeight}} className={"search_page"}>
            <div className={"search_product_list"}>
                {
                    spinerSearch ?
                        <div className={"products_wrapper standart"}>
                            <Spiner/>
                        </div>
                        :
                        <div className={"products_wrapper standart"}>
                            {
                                reduceArraySerch.length != 0 && reduceArraySerch ? reduceArraySerch.map((item, index) =>
                                        <OneProductCart
                                            item={item}
                                            index={index}
                                            key={item.id}
                                            handleScroll = {e => handleScroll()}
                                            // parrent={parrent}
                                            // child={child}
                                        />
                                    )
                                    :
                                    <div className={"non_result_search"}>По вашему запросу ничего не найдено</div>
                            }
                        </div>
                }

                {
                    getNewArraySearch ?
                        <div
                            onClick={e => newArrayProductSearch()}
                            className={"download_more_products"}>Загрузить ещё</div>
                        : ""
                }
            </div>

        </div>
    );
});

export default SearchProductSite;