import React, {useContext} from 'react';
import {Context} from "../../../../../index";
import AuthUser from "./AuthUser/AuthUser";
import UserIcon from "./NoUser/UserIcon";
import {observer} from "mobx-react-lite";

const SwitchUserIcon = observer((props) => {
    
    const {user} = useContext(Context)

    return (
        <div className={"bar_icon_user_position"}>
            {
                user.isAuth
                    ? <AuthUser
                        openMenuLk={props.openMenuLk}
                        setOpenMenuLk = {e => props.setOpenMenuLk(e)}
                    />
                    : <UserIcon onClick={props.onClick} />


            }
        </div>


    );
});

export default SwitchUserIcon;