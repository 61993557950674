import React from 'react';
import './productbreadcrumbs.css';
import {Link} from "react-router-dom";

const ProductBreadcrumbs = (props) => {

    //
    // console.log('BREADCRUMBS PROPS');
    // console.log(props)
    return (
        <div
            className={"breadcrumbs_for_url_params"}
            itemScope=""
            itemType="http://schema.org/BreadcrumbList">

            <span
                className={"url_breadcrumbs_element_wrapper"}
                itemProp="itemListElement"
                itemScope=""
                itemType="http://schema.org/ListItem">
                <Link
                    to={"/"}
                    className={"url_breadcrumbs_element"}
                    itemProp="item"
                  >
                    <meta itemProp="position" content="1"/>
                    <span itemProp="name">Главная</span>
                </Link>
            </span>

            <span
                className={"url_breadcrumbs_element_wrapper"}
                itemProp="itemListElement"
                itemScope=""
                itemType="http://schema.org/ListItem">
                <Link
                    to={"/catalog/"}
                    itemProp="item"
                    className={"url_breadcrumbs_element"}>
                    <meta itemProp="position" content="2"/>
                    <span itemProp="name">Каталог</span>
                </Link>
            </span>
            {
                props.params.parrent
                    ?
                    <span
                        className={"url_breadcrumbs_element_wrapper"}
                        itemProp="itemListElement"
                        itemScope=""
                        itemType="http://schema.org/ListItem">
                        <Link
                            to={"/catalog/" + props.parrent_url + "/"}
                            itemProp="item"
                            className={"url_breadcrumbs_element"}>
                            <meta itemProp="position" content="3"/>
                            <span itemProp="name">{props.params.parrent}</span>
                        </Link>
                    </span>
                    :
                    ""
            }
            {
                props.params.child
                    ?
                    <span
                        className={"url_breadcrumbs_element_wrapper"}
                        itemProp="itemListElement"
                        itemScope=""
                        itemType="http://schema.org/ListItem">
                        <Link
                            to={"/catalog/" + props.parrent_url + "/" + props.child_url + "/"}
                            itemProp="item"
                            className={"url_breadcrumbs_element"}>
                            <meta itemProp="position" content="4"/>
                            <span itemProp="name">{props.params.child}</span>
                        </Link>
                    </span>
                    :
                    ""
            }
            {
                props.product_name
                    ?
                    <span
                        className={"url_breadcrumbs_element_wrapper"}
                        itemProp="itemListElement"
                        itemScope=""
                        itemType="http://schema.org/ListItem">
                        <span
                            className={"url_breadcrumbs_element"}>
                            <meta itemProp="position" content="5"/>
                            <span itemProp="name">{props.product_name}</span>
                        </span>
                    </span>
                    :
                    ""
            }

        </div>
    );
};

export default ProductBreadcrumbs;
