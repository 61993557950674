import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import OptionCountry from "./RegistrationForm/OptionCountry";
import InputMask from 'react-input-mask';
import { Context } from "../../../../../index";
import { observer } from "mobx-react-lite";
import { countryArr } from "../../../../../utils/consts";
import CheckBoxPolitic from "../../../../UI/checkBoxPolitic/CheckBoxPolitic";
import {InvisibleSmartCaptcha} from '@yandex/smart-captcha';

const RegistrateStep = observer((props) => {

    const captchaRef = useRef(null);
    const { user } = useContext(Context);
    const [childKey, setChildKey] = React.useState(1);


    const [stateCheсkBoxRegistrate, setStateCheсkBoxRegistrate] = useState(false)
    const [captchaCheck, setCaptchaCheck] = useState(false)
    const [visible, setVisible] = useState(false);
    const [gdprError, setGdprError] = useState("")

    // открыто ли окно для выбора стран
    const [searchStateShow, setSearchStateShow] = useState('unactive');

    //  Тут хранится маска для вывода маски в поля
    const [maskState, setMaskState] = useState(countryArr[0]);

    const handleChallengeHidden = useCallback(() => setVisible(false), []);
    const onSuccessHandle = (token) => {
        setCaptcha(token);
        sendForm();
        setVisible(false);
    }

    const onTokenExpired = () => {
        setVisible(true);
    }

    const handleCombinedClick = () => {
        resetChildComponent();
        setVisible(true);
    };

    //Устанавливаем значение для инпута и проверяем на соответствие кол-ву цифр.
    const setTelephoneCheckButton = (number) => {
        user.setPhoneNumber(number);
        let replacenumber = number.replace(/[^\d]/g, '');
        //устанавливаем кликабельность кнопки "получить код"
        if (Number(replacenumber.length) === Number(maskState.number)) {
            user.setGetMeCodeButton('active');
        } else {
            user.setGetMeCodeButton('unactive');
        }
    }

    // selected инпут - значения для отображения или скрытия окна.
    const ToggleShowContry = () => {
        if (searchStateShow === "unactive") {
            setSearchStateShow('active')
        } else {
            setSearchStateShow('unactive')
        }
    }

    // после выбора страны, устанавливаем состояние визуального инпута, затем обнуляем значение инпута с номером.
    const changeModelInput = (object) => {
        setMaskState(object);
        setTelephoneCheckButton('');
        ToggleShowContry();
        user.setCountry(object.name);
    }

    const [searchQuery, setSearchQuery] = useState('');

    const getSortedCountry = () => {
        if (searchQuery) {
            return [...countryArr].filter(country => country.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
        }
        return countryArr;
    }

    const setCaptcha = (token) => {
        if (token) {
            setCaptchaCheck(true);
            user.token = token;
        }
    }

    const resetChildComponent = () => {
        setChildKey(childKey + 1);
    };

    const sendForm = () => {
        if (stateCheсkBoxRegistrate) {
            props.registrationFunc()
        }
    }

    useMemo(() => {
        if (!stateCheсkBoxRegistrate) {
            setGdprError("Необходимо принять соглашение")
        } else {
            setGdprError("");
        }
    }, [stateCheсkBoxRegistrate])

    const sortedArr = getSortedCountry();

    return (
        <div className={"registration_form " + props.controllStateClass}>
            <div className={"registration_title_wrapper"}>
                <span className={"big_registration_text"}>Войти или зарегистрироваться</span>
            </div>

            <div className={"fields_register_and_politic"}>
                <span className={"small_text_registration"}>Мы отправим вам SMS на указанный номер с кодом подтверждения</span>
                <div className={"fields_register_wrapper"}>
                    <span className={"title_fields_phone"}>Введите номер телефона</span>
                    <div className={"registration_select_class"}>
                        <div className={"phone_and_city_wrap"}>
                            <div onClick={e => ToggleShowContry()} className={"selected_input_wrapper"} >
                                <img src={maskState.imgPath} alt="" />
                            </div>
                            <div className={"searchResults " + searchStateShow}>
                                <div className={"wrapSearchInput"}>
                                    <input type="text" className={"searchInput"} placeholder={"Введите название страны"} value={searchQuery} onChange={e => setSearchQuery(e.target.value)} />
                                </div>

                                {
                                    sortedArr.length
                                        ?
                                        sortedArr.map((country, name) =>
                                            <OptionCountry onClick={e => changeModelInput(country)} key={name} country={country} />)
                                        :
                                        <p>К сожалению, такой страны нет. Свяжитесь с менеджером. </p>
                                }

                            </div>

                        </div>

                        <InputMask
                            className={"phoneInput"}
                            placeholder={maskState.placeholder}
                            mask={maskState.mask}
                            value={user.phoneNumber}
                            onChange={e => setTelephoneCheckButton(e.target.value)}
                        />
                    </div>

                    <span
                        className={stateCheсkBoxRegistrate === true && user.getMeCodeButton === "active" ? "send_code active" : "send_code unactive"}
                        onClick={handleCombinedClick}> Получить код </span>
                    <div style={{display: "none"}} className='captcha'>
                        <InvisibleSmartCaptcha
                            key={childKey}
                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                            onSuccess={onSuccessHandle}
                            onTokenExpired={onTokenExpired}
                            onChallengeHidden={handleChallengeHidden}
                            visible={visible}
                            hideShield={true}
                        />
                    </div>
                    <div className={"select_politic_type_error"}>{props.codeLimitErrorText}</div>
                </div>
                <div>
                    <CheckBoxPolitic
                        setStateCheсkBox={e => setStateCheсkBoxRegistrate(e)}
                        stateCheсkBox={stateCheсkBoxRegistrate}
                    />
                    <div className={"select_politic_type_error"}>{gdprError}</div>
                </div>
            </div>
        </div>
    );
});

export default RegistrateStep;