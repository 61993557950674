import {getCategories} from "../http/catalogApi";

export const getGoogleAnalyticProductItem = (product, customProps = {}) => {
    return {
        item_id: product.product.id,
        item_name: product.product.name,
        price: product.sell_price ? product.sell_price : product.parrent_price,
        categories: product.product.product_category,
        item_variant: product.color.color_name,
        ...customProps
    }
}

export const pushGoogleAnalyticProductAction = async (action, products, ecommerceProps = {}) => {
    if(!products || !products.length || !action)
        return false
    let items = [];
    for(let oneProduct of products) {
        if (oneProduct.child_product_size) {
            if (oneProduct.currentSize) {
                let findSize = oneProduct.child_product_size.find(element => element.size_name === oneProduct.currentSize);
                if (findSize) {
                    items.push({
                        item_id: String(oneProduct.id)+String(oneProduct.color.id)+findSize.id,
                        item_name: oneProduct.product.name,
                        price: oneProduct.sell_price ? oneProduct.sell_price : oneProduct.parrent_price,
                        categories: oneProduct.product.product_category,
                        item_variant: oneProduct.color.color_name,
                        ...oneProduct.customProps
                    })
                }
                continue;
            }
            for(let oneSize of oneProduct.child_product_size_field) {
                let findSize = null; 
                if (oneSize.isActive) {
                    findSize = oneProduct.child_product_size.find(element => element.size_name === oneSize.size_name);
                }
                if (findSize) {
                    items.push({
                        item_id: String(oneProduct.id)+String(oneProduct.color.id)+findSize.id,
                        item_name: oneProduct.product.name,
                        price: oneProduct.sell_price ? oneProduct.sell_price : oneProduct.parrent_price,
                        categories: oneProduct.product.product_category,
                        item_variant: oneProduct.color.color_name,
                        ...oneProduct.customProps
                    })
                }
            }
        }
    }

    const categoryIds = []

    items.forEach(({categories}) => {
        categoryIds.push(...categories)
    })

    const response = await getCategories(categoryIds)

    if(!response.success)
        return false

    const categories = response.data

    items = items.map((item) => {
        const itemCategories = {}
        item.categories.forEach((itemCategoryId, index) => {
            if (!categories[itemCategoryId])
                return false

            if (!index)
                itemCategories.item_category = categories[itemCategoryId]
            else
                itemCategories[`item_category${index + 1}`] = categories[itemCategoryId]
        })

        delete item.categories

        return {...item, ...itemCategories}
    })

    window.dataLayer.push({
        event: action,
        ecommerce: {items, ...ecommerceProps}
    })
}