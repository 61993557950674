import React, {useContext, useEffect, useState} from 'react';
import {addImages, deleteImage} from "../../../../http/imagesApi";
import OneImageField from "../../UI/oneImageField/OneImageField";
import OneImageHorizontalField from "../../UI/OneImageHorizontalField/OneImageHorizontalField";
import {LookBookHorizontalSizePreset, LookBookMiniSizePreset, LookBookPreviewsSizePreset, LookBookVerticalSizePreset
} from "../../../../utils/consts";
import {fetchOneLookBook, updateLookBook} from "../../../../http/contentApi";
import {useParams} from "react-router";
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";


const OneLook = () => {

    let {id} = useParams();

    const [contentOneLook, setContentOneLook] = useState('')
    const [arrayImgLook, setArrayImgLook] = useState('')
    const [messageLookBook, setMessageLookBook] = useState('')

    useEffect(()=>{
        window.scrollTo(0, 0)
        fetchOneLookBook(id, true).then(data => {
            if (data) {
                setContentOneLook(data)
            }
            if (data.content && data.content != '') {
                setArrayImgLook(data.content)
            }

        }  );
    },[])

    const [triggerContentLookbook,setTriggerContentLookbook] = useState(false)

    const uploadImgPreviewsLook = (e ,nameField, id, LookSizePreset) => {
        // console.log(id)
        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);

        if (contentOneLook[nameField]){
            deleteImage(contentOneLook[nameField], "lookbook", id).then(response  => {
                setContentOneLook({...contentOneLook, [nameField]: ""})
            })
        }

        addImages(formData,'lookbook',id, LookSizePreset).then(response => {
            if(response.message) {
                setContentOneLook({...contentOneLook, [nameField]:response.fileName})
                setTriggerContentLookbook(true)
            }
        })

    }

    const deletePreviewsLook = (filename, nameField, id) => {
        deleteImage(contentOneLook[nameField], "lookbook", id).then(response  => {
            setContentOneLook({...contentOneLook, [nameField]: ""})
            setTriggerContentLookbook(true)
        })
    }

    const fieldsOneLook = (e,fieldName) => {
        setContentOneLook({...contentOneLook,[fieldName]:e.target.value})
        setTriggerContentLookbook(true)
    }

    const fieldsSort = (e,fieldName) => {
        let newNumber = e.target.value.replace(/\D/g, '');
        let textboxText= newNumber.replace(/^0+/, '')
        setContentOneLook({...contentOneLook,[fieldName]:textboxText})
        setTriggerContentLookbook(true)
    }

    const horizontalImgLook = {type:"horizontal", photoLook:"", linkLook:"", id:""}
    const doubleImgLook = {type:"double", one_photoLook:"", two_photoLook: "", linkLookleft:"", linkLookRight:"", id:""}

    const lookClickAddNewObject = (object) => {
        let reducePhotoLook = [...arrayImgLook]
        reducePhotoLook.push(object)
        setArrayImgLook(reducePhotoLook)
        setTriggerContentLookbook(true)
    }

    const childsFieldsOneLook = (e, fieldName, index) => {
        setArrayImgLook([...arrayImgLook], [arrayImgLook[index][fieldName]= e.target.value])
        setTriggerContentLookbook(true)
    }


    const uploadImgLook = (e,nameField, index, id, LookSizePreset) => {

        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);

        if (arrayImgLook[index][nameField]){
            deleteImage(arrayImgLook[index][nameField], "lookbook", id).then(response  => {
                setArrayImgLook([...arrayImgLook], [arrayImgLook[index][nameField] = ""])
            })
        }

        addImages(formData,'lookbook',id, LookSizePreset).then(response => {
            if(response.message) {
                setArrayImgLook([...arrayImgLook], [arrayImgLook[index][nameField] = response.fileName])
                setTriggerContentLookbook(true)
            }
        })

    }


    const deleteImgLook = (filename, nameField,index, id) => {
        deleteImage(arrayImgLook[index][nameField], "lookbook", id).then(response  => {
            setArrayImgLook([...arrayImgLook], [arrayImgLook[index][nameField] = ""])
            setTriggerContentLookbook(true)
        })
    }





    const deleteOneLookBlock = (item,index) => {

        if(item.type === horizontalImgLook.type) {
            deleteImage(item.photoLook, item.id).then(response  => {
                // console.log(response)
                let reduceItems = [...arrayImgLook];
                reduceItems.splice(index,1);
                reduceItems = reduceItems.map((item,index) => {
                    return {...item, id: index+1};
                })

                setArrayImgLook(reduceItems)
                setTriggerContentLookbook(true)
            })

        }

        if(item.type === doubleImgLook.type) {

            deleteImage(item.one_photoLook, item.linkLookleft, item.id ).then(response  => {
                deleteImage(item.two_photoLook, item.linkLookRight, item.id).then(response  => {
                    // console.log(response)
                    let reduceItems = [...arrayImgLook];
                    reduceItems.splice(index,1);
                    reduceItems = reduceItems.map((item,index) => {
                        return {...item, id: index+1};
                    })
                    setArrayImgLook(reduceItems)
                    setTriggerContentLookbook(true)
                })
            })
        }

    }


    const changeActiveThumbnail = (fildName) => {
        setContentOneLook({...contentOneLook, [fildName]: !contentOneLook[fildName]})
        setTriggerContentLookbook(true)
    }

    useEffect(() => {
        setContentOneLook({...contentOneLook, content:JSON.stringify(arrayImgLook)})
    }, [arrayImgLook])

    useEffect(() => {

        if (triggerContentLookbook === true) {
            setMessageLookBook('')
        }
    }, [triggerContentLookbook])

    const saveOneLook = (data) => {
        if (!contentOneLook.sort) {
            setMessageLookBook("Заполните обязательное поле сортировка!")
            return false;
        }
        updateLookBook(data).then(response => {
                if (response.message) {
                    setTriggerContentLookbook(false)
                    setMessageLookBook("Сохраненно!")
                }
                if (response.error)
                    setMessageLookBook(response.error)
            }
        )

    }

    // console.log(contentOneLook)

    return (
       <div className={"one_look"}>
           {/*<div className={"delete_block_look"}>*/}
           {/*    <div*/}
           {/*        onClick={e => props.deleteOneLook(props.index)}*/}
           {/*        className={"del_delivery_child"}></div>*/}
           {/*</div>*/}
           <div className={"look_previews_photo"}>
               <div className={"container_img_look"}>
                   <OneImageField
                       text={"Превью highlights"}
                       image={contentOneLook.img_mini ?
                           process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+id+"/"+contentOneLook.img_mini  : null }
                         onChange={ e => uploadImgPreviewsLook(e,'img_mini', id, LookBookMiniSizePreset)}
                          deleteImage={ e => deletePreviewsLook(e,'img_mini', id)}
                   />
                   <h4>(разрешение фото 90х90)</h4>
               </div>
               <div className={"container_img_look"}>
                   <OneImageField
                       text={"Баннер лука"}
                       image={contentOneLook.img_preview ?
                           process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+id+"/"+contentOneLook.img_preview  : null }
                       onChange={ e => uploadImgPreviewsLook(e,'img_preview', id, LookBookPreviewsSizePreset)}
                       deleteImage={ e => deletePreviewsLook(e, 'img_preview', id)}
                   />
                   <h4>(разрешение фото 1300х866)</h4>
               </div>
               <div className={"field_lookbook"}>
                   <div className={"thumbnail_active"}>
                       <span className={"one_field_lookbook"}>Активировать запись</span>
                       <div
                           onClick={e => changeActiveThumbnail("isActive")}
                           className={"is_active_shop"}>
                           <IsActiveIndicator active={contentOneLook.isActive} />
                       </div>
                   </div>
                   <div className={"thumbnail_active"}>
                       <span className={"one_field_lookbook"}>Выводить в миниатюры</span>
                       <div
                           onClick={e => changeActiveThumbnail("thumbnail")}
                           className={"is_active_shop"}>
                           <IsActiveIndicator active={contentOneLook.thumbnail} />
                       </div>
                   </div>
                   <div className="one_field_lookbook_wrapper">
                       <span className={"one_field_lookbook"}>Сортировка. Только положительные цифры.* (обязательное поле)</span>
                       <input
                           value={contentOneLook.sort ? contentOneLook.sort : ""}
                           onChange={e => fieldsSort(e,"sort") }
                           className={"lookbook_field_adm"} type="text"

                           min="1" step="1"/>
                   </div>
                   <div className="one_field_lookbook_wrapper">
                       <span className={"one_field_lookbook"}>Заголовок лука</span>
                       <input
                           value={contentOneLook.name ? contentOneLook.name : ""}
                           onChange={e => fieldsOneLook(e,"name") }
                           className={"lookbook_field_adm"} type="text"/>
                   </div>
                   <div className="one_field_lookbook_wrapper">
                       <span className={"one_field_lookbook"}>Описание</span>
                       <textarea
                           value={contentOneLook.text ? contentOneLook.text : ""}
                           onChange={e => fieldsOneLook(e,"text") }
                           className={"lookbook_field_adm textarea "} type="text"/>
                   </div>
               </div>
           </div>
           <div>
               <div className={"field_selection_new_look"}>
                        <span className={"heading_new_look"}>
                            Чтобы добавить блок - нажмите, на одну из двух моделей
                        </span>
                        <span className={"block_selection_text"}>
                           Рекомендуем в целях оптимизации не создавать больше 6 блоков. Максимум - до 8 блоков.
                        </span>

                   <div className={"wrapper_block_selection"}>
                       <div
                           onClick={ e => lookClickAddNewObject(Object.assign({}, horizontalImgLook))}
                           className={"image_block_look_choosing"}>
                           <div className="one_img_look">

                           </div>
                       </div>
                       <div
                           onClick={ e => lookClickAddNewObject(Object.assign({}, doubleImgLook))}
                           className={"image_block_look_choosing"}>
                           <div className="two_img_look">

                           </div>
                           <div className="two_img_look">

                           </div>
                       </div>
                   </div>
                   <div className={"block_with_photo_look"}>
                       {
                           arrayImgLook ?
                               arrayImgLook.map((item,index) => {
                                   if(item.type === "horizontal") {
                                       return( <div key={index} className={"big_image_child_look"}>
                                           <div className={"display_selection_type"}>
                                               <div
                                                   onClick={e => deleteOneLookBlock(item,index)}
                                                   className={"delete_look_img all_chose_look"}></div>
                                           </div>
                                           <div className={"wrap_img_and_link"}>
                                               <h2>(разрешение фото 1500х1000)</h2>
                                               <OneImageHorizontalField
                                                   image={ item.photoLook ?
                                                       process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+id+"/"+item.photoLook  : null }
                                                   onChange={ e => uploadImgLook(e, 'photoLook', index, id, LookBookHorizontalSizePreset)}
                                                   deleteImage={ e => deleteImgLook(e,'photoLook',index, id)}
                                               />
                                               <div className="one_field_lookbook_wrapper">
                                                   <span className={"one_field_lookbook"}>Ссылка на товар</span>
                                                   <input
                                                       value={item.linkLook ? item.linkLook : ""}
                                                       onChange={e => childsFieldsOneLook(e,"linkLook", index) }
                                                       className={"lookbook_field_adm"} type="text"/>
                                               </div>
                                           </div>
                                       </div>)
                                   }
                                   if(item.type === "double") {
                                       return(
                                               <div className={"double_img_look_wrapper"} key={index}>

                                                   <div className={"display_selection_type"}>
                                                       <div
                                                           onClick={e => deleteOneLookBlock(item,index)}
                                                           className={"delete_look_img all_chose_look"}></div>
                                                   </div>

                                                   <h2>(разрешение фото 750х1125)</h2>
                                                   <div className={"double_img_block"}>
                                                       <div className={"wrap_img_and_link"}>
                                                           <OneImageField text={"Вертикальная картинка (Левая)"}
                                                                          image={ item.one_photoLook ?
                                                                              process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+id+"/"+item.one_photoLook  : null }
                                                                          onChange={ e => uploadImgLook(e,'one_photoLook', index, id , LookBookVerticalSizePreset)}
                                                                          deleteImage={ e => deleteImgLook(e, 'one_photoLook' ,index, id)}
                                                           />
                                                           <div className="one_field_lookbook_wrapper">
                                                               <span className={"one_field_lookbook"}>Ссылка на товар</span>
                                                               <input
                                                                   value={item.linkLookleft ? item.linkLookleft : ""}
                                                                   onChange={e => childsFieldsOneLook(e,"linkLookleft", index) }
                                                                   className={"lookbook_field_adm"} type="text"/>
                                                           </div>
                                                       </div>
                                                       <div className={"wrap_img_and_link"}>
                                                           <OneImageField text={"Вертикальная картинка (Правая)"}
                                                                          image={ item.two_photoLook ? process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+id+"/"+item.two_photoLook  : null }
                                                                          onChange={ e => uploadImgLook(e,'two_photoLook',index, id, LookBookVerticalSizePreset)}
                                                                          deleteImage={ e => deleteImgLook(e, 'two_photoLook',index, id)}
                                                           />
                                                           <div className="one_field_lookbook_wrapper">
                                                               <span className={"one_field_lookbook"}>Ссылка на товар</span>
                                                               <input
                                                                   value={item.linkLookRight ? item.linkLookRight : ""}
                                                                   onChange={e => childsFieldsOneLook(e,"linkLookRight", index) }
                                                                   className={"lookbook_field_adm"} type="text"/>
                                                           </div>
                                                       </div>
                                                   </div>

                                               </div>
                                           )
                                   }
                               })
                               : ""
                       }
                   </div>
               </div>
               <div
                   onClick={e => saveOneLook(contentOneLook)}
                   className={triggerContentLookbook ? "save_fields_lookbook trig" : "save_fields_lookbook"}>
                    Сохранить
               </div>
               <div className={"message_lookbook"}>{messageLookBook ? messageLookBook : ''}</div>
           </div>

       </div>
    );
};

export default OneLook;