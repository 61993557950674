import React, {useCallback, useContext, useState} from 'react';
import './BlockQuestion.css';
import ButtonShow from "./ButtonShow";
import ButtonHide from "./ButtonHide";
import TextBlock from "./TextBlock";
import {Context} from "../../index";

const BlockQuestion = (props) => {


    const {settings} = useContext(Context)
    const [activeComponent, setActiveComponent] = useState("button_hide");

    const modifyActiveComponent = useCallback(
        (newActiveComponent) => {
            setActiveComponent(newActiveComponent);
        },
        [setActiveComponent]
    );

    let hide = () => modifyActiveComponent("button_hide")
    let show = () => modifyActiveComponent("button_show")

    // const [openTextFqa, setOpenTextFqa] = useState(false)
    //
    // const activeFqa = () => {
    //     setOpenTextFqa(!openTextFqa)
    // }
    const [openTextFqa, setOpenTextFqa] = useState(false)
    const [fullDropdownBlock, setDropdownBlock] = useState(false)
    const controlDropdownBlock = () => {
        setDropdownBlock(!fullDropdownBlock);
        props.setDropdownBlock(!props.DropdownBlock);
        setOpenTextFqa(!openTextFqa);

    }

    // console.log(activeComponent)

    return (
        <div id={'q_conteiner'} className={"q_conteiner"}>
            <div  className={"q_conteine_child"}>
                <div className={fullDropdownBlock === false ? "q_title" : "q_title open"}>
                    <div>
                        <span className={"q_subline"}>{props.item.titleFqa ? props.item.titleFqa : ''}</span>
                        {fullDropdownBlock === false ? "" : <TextBlock answer={props.item.textFqa}  />}
                    </div>


                            <div onClick={controlDropdownBlock}  className={"q_wrap_batton"}>
                                <div className="q_batton">

                                        <ButtonHide
                                            // OnclikHihe={hide}
                                            // className={""}
                                            openTextFqa={openTextFqa}
                                            setOpenTextFqa={e=> setOpenTextFqa(e)}
                                        />

                                    {/*{activeComponent === "button_show" ?*/}
                                    {/*    <ButtonHide*/}
                                    {/*        OnclikHihe={hide}*/}
                                    {/*        className={""}*/}
                                    {/*        openTextFqa={openTextFqa}*/}
                                    {/*        setOpenTextFqa={e=> setOpenTextFqa(e)}*/}
                                    {/*    />*/}
                                    {/*    :*/}
                                    {/*    <ButtonShow*/}
                                    {/*        OnclickShow={show}*/}
                                    {/*    />}*/}
                                </div>
                            </div>


                </div>

                {/*{*/}
                {/*    settings.projectWidth &&  settings.projectWidth < 429 ?*/}
                {/*        <div className={""}>*/}
                {/*            <div className={"q_border_button"}></div>*/}
                {/*            <div className={"q_wrap_batton"}>*/}

                {/*                <div*/}
                {/*                    onClick={controlDropdownBlock} className="q_batton">*/}

                {/*                    {activeComponent === "button_show" ?*/}
                {/*                        <ButtonHide*/}
                {/*                            OnclikHihe={hide}*/}
                {/*                        />*/}
                {/*                        :*/}
                {/*                        <ButtonShow*/}
                {/*                            OnclickShow={show}*/}
                {/*                        />}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}


                {/*        :*/}
                {/*        ""*/}
                {/*}*/}
                {/*{activeComponent === "button_show" ? <TextBlock answer={props.item.answer}  /> : ""}*/}
            </div>
        </div>
    );
};

export default BlockQuestion;