import React, {useContext, useEffect, useRef, useState} from 'react';
import "./flyBasketNotification.css";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";

const FlyBasketNotification = observer((props) => {

    const {user, basket} = useContext(Context)
    const isActiveNotification = basket.activeNotification;


    const [timer, setTimer] = useState(0)

    const countRef = useRef(null)

    const handleStart = () => {

        countRef.current = setInterval(() => {
            setTimer((timer) => timer + 1)
        }, 1000)
    }





    const onHoverHandler = () => {
        clearInterval(countRef.current)
        setTimer(0)
    }

    const onMouseLeaveHandler = () => {
            if(!isActiveNotification) {
                return false;
            }

            handleStart()
    }


    useEffect(() => {
        if(isActiveNotification === true) {
            handleStart()
        }

    },[isActiveNotification]);

    useEffect(() => {
        if(timer >= 5) {
            clearInterval(countRef.current)
            setTimer(0);
            basket.setActiveNotification(false)
        }
    },[timer])

    const changeModalStep = () => {
        clearInterval(countRef.current)
        setTimer(0);
        basket.setActiveNotification(false)

        setTimeout(() => {
            user.setStepModals("basketStep")
            user.setShadowOverlay(true)
            user.setOpenAuthModal(1)
        },400)

    }

    return (
        <div
            onMouseEnter={e => onHoverHandler()}
            onMouseLeave={e => setTimeout(() => {onMouseLeaveHandler()},1000)}
            className={ isActiveNotification ? "fly_basket_notification active" : "fly_basket_notification" }>

            {
                basket.notificationContent.item ?
                    <div
                        onClick={ e => changeModalStep()}
                        className={"notification_all_wrapper_content"}>
                        <div className={"notification_image_container"}>
                            <img src={process.env.REACT_APP_API_URL+"uploads/images/"+basket.notificationContent.item.product.id+"/"+basket.notificationContent.item.id+"/"+"214_"+basket.notificationContent.item.img_preview}
                                 alt={'YouWanna - ' +(basket.notificationContent.item.name ? basket.notificationContent.item.name : '')}
                            />
                        </div>
                        <div className={"notification_content_area"}>
                            <div className={"notification_heading_line"}>
                                {basket.notificationContent.item.name}
                            </div>
                            <div className={"notification_counter_container"}>
                                {basket.notificationContent.count}
                                <span className={"notification_prefix"}>шт</span>
                            </div>
                            <div className={"notification_size_and_color_container"}>
                                <div className={"notification_size"}>Размер: {basket.notificationContent.currentSize}</div>
                                <div className={"notification_color"}>
                                    <div
                                        style={basket.notificationContent.item.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+basket.notificationContent.item.color.id +"/"+basket.notificationContent.item.color.img +")"} : {backgroundColor: basket.notificationContent.item.color.hex }}

                                        className={"notification_colorpicker"}></div>
                                    <div className={"notification_sizename"}>{basket.notificationContent.item.color.color_name}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ""
            }



        </div>
    );
});

export default FlyBasketNotification;