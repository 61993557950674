import React, {useContext, useEffect} from 'react';
import './ favoritesNotAuthorized.css'
import Product from "../../../UI/Product/Product";
import BreadCrumbsBlack from "../../../Breadcrumbs/breadCrumbsBlack/BreadCrumbsBlack";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";

const FavoritesNotAuthorized = observer(() => {

    const favoritesNonArray = [
        {name: "ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА", price: "18 950"},
        {name: "ДВОЙНОЙ ТОП YOU WANNA X VELVETIN", price: "2 950 "},
        {name: "ПЛАТЬЕ МИНИ С АКЦЕНТНЫМИ ВЫРЕЗАМИ НА ГРУДИ YOU WANNA X VELVETIN", price: "5 770"},
        {name: "ПЛАТЬЕ С ОТКРЫТОЙ СПИНОЙ ИЗ ТРИКОТАЖА", price: "6 965"},
    ]



    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const {settings, user} = useContext(Context);

    const margTop = settings.headerHeight

    const openAuthModalFavorite = () =>  {
        user.setOpenAuthModal(1);
        user.setShadowOverlay(true);
    }



    return (
        <div className={"page_favorites_non_auth"}>
            <div style={{marginTop:margTop}} className={"page_favorites_indent"}>
                {/*<div className={"favorites_not_breadcrumbs"}>*/}
                {/*    <BreadCrumbsBlack*/}
                {/*        items={[*/}
                {/*            {name:"Youwanna", link:"/"},*/}
                {/*            {name:"ИЗБРАННОЕ", link:"/content/favorites/"}*/}
                {/*        ]}*/}
                {/*    />*/}
                {/*</div>*/}
                <div  className={"favorites_not_authorized_container"}>
                    <div
                        onClick={ () => openAuthModalFavorite()}
                        className={"favorites_not_authorized"}>
                        <div className={"favorites_container_svg"}>
                            <span className="favorites_text_usercabinet">Войти в аккаунт</span>
                            {/*<div className={"favorites_not_user_block"}>*/}
                            {/*    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4618 21.0133H2.86543C2.33818 21.0133 1.9103 20.5854 1.9103 20.0582V2.86548C1.9103 2.33823 2.33823 1.91035 2.86543 1.91035H11.4618C11.99 1.91035 12.4169 1.48341 12.4169 0.955219C12.4169 0.427027 11.99 0 11.4618 0H2.86543C1.28562 0 0 1.28566 0 2.86548V20.0582C0 21.638 1.28562 22.9236 2.86543 22.9236H11.4618C11.99 22.9236 12.4169 22.4966 12.4169 21.9685C12.4169 21.4403 11.99 21.0133 11.4618 21.0133Z" fill="#1A1A1A"></path><path d="M22.7148 10.0059L16.9074 4.27498C16.533 3.90438 15.9275 3.90918 15.5569 4.28455C15.1863 4.65992 15.1901 5.26452 15.5664 5.63513L19.7165 9.73079H8.59575C8.06756 9.73079 7.64062 10.1577 7.64062 10.6859C7.64062 11.2141 8.06756 11.6411 8.59575 11.6411H19.7165L15.5664 15.7368C15.1901 16.1074 15.1873 16.712 15.5569 17.0873C15.7441 17.2764 15.9905 17.372 16.2369 17.372C16.4796 17.372 16.7221 17.2803 16.9074 17.0969L22.7148 11.3659C22.8962 11.1864 22.9994 10.9418 22.9994 10.6859C22.9994 10.43 22.8972 10.1864 22.7148 10.0059Z" fill="#1A1A1A"></path></svg>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className={"product_favorites_not_auth"}>
                        {
                            favoritesNonArray ? favoritesNonArray.map ((item, index) =>
                                    <Product
                                        key={index}
                                        item={item}
                                    />
                                )
                                : "У вас нет избранных товаров"
                        }
                    </div>
                </div>
            </div>
        </div>

    );
});

export default FavoritesNotAuthorized;