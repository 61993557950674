import React, {useContext, useEffect, useState} from 'react'
import {Context} from "../../index";
import BasketPromokod from '../UI/basketPromokod/BasketPromokod';
import { Link } from 'react-router-dom';
import IsActiveCheckbox from '../UI/isActiveCheckbox/isActiveCheckbox';

const Checkout = () => {
       const { user, basket} = useContext(Context)
    const [promoActivateCode, setPromoActiveteCode] = useState(basket.promocode??"");
    const [bonusSpendActive, setBonusSpendActive] = useState(basket.bonusesSpend > 0);


    const handlerTargetValue = (e) => {
        let newStringTrim = e.trim()
        setPromoActiveteCode(newStringTrim);
    }
    const handleSpendAll = () => {
        if (!basket.bonusesSpendAll) {
            basket.setBonusesSpend(basket.bonusesOrderAvail);
        } else {
            basket.setBonusesSpend(0);
        }
        basket.bonusesSpendAll = !basket.bonusesSpendAll
        setBonusSpendActive(basket.bonusesSpend > 0)
        console.log(basket.bonusesSpend)
    }

    useEffect(() => {
        setBonusSpendActive(basket.bonusesSpend > 0)
    }, [basket.bonusesSpend]);

    const isPromoHandler = (value) => {
        basket.setPromocode(value);
    }
  return (
    <div className={"checkout_basket_page_body"}>
        <div className={"checkout_basket_page_bonus"}>
            <span>Вернем бонусами:</span>
                <div className={"checkout_basket_page_bonus_block"}>
                <span>5%</span>
                <img src='/files/images/logo-green.png' />    
                   </div>
                      </div>
                      <div className={"checkout_basket_page_login"}>
                        {
                             user.isAuth ?
                                 basket.bonusesOrderAvail > 0 ?
                               <div className={"checkout_basket_page_bonuses"}>
                                 <span>Списать {basket.bonusesOrderAvail} бонусов из {basket.bonusesTotal}</span>
                                    <label className={"checkout_basket_page_checkbox"}>
                                        <input type='checkbox' />
                                        <span className={"checkout_basket_page_fake"}></span>
                                    </label>
                                    <IsActiveCheckbox
                                        active={bonusSpendActive}
                                        change={() => handleSpendAll()}
                                    />
                                 </div> : ""
                                    :
                                    <>
                                        <Link onClick={ e => {
                                            user.setOpenAuthModal(1);
                                            user.setShadowOverlay(true);
                                            user.setStepModals("registration")
                                        }} >Авторизируйтесь</Link>, чтобы получать и списывать</>
                        }
                    </div>
                    <BasketPromokod
                            color={basket.promoNitificator.color}
                            notification={basket.promoNitificator.message}
                            value={promoActivateCode}
                            onChange={e => handlerTargetValue(e.target.value)}
                            onClick={e => isPromoHandler(promoActivateCode)}
                        />
                </div>
  )
}

export default Checkout