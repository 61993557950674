import React, {useContext, useEffect, useState} from 'react';
import './styles/App.css';
import Header from "./components/header/header";
import Footer from "./components/footer/Footer";
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import {observer} from "mobx-react-lite";
import NoodlesRain from './components/NoodlesRain/NoodlesRain';
import {Context} from "./index";
import {check, getСheckingUserOrder} from "./http/userAPI";
import ResultModal from "./components/UI/ResultModal/ResultModal";
import {fetchHeaderAndFooter} from "./http/settingsApi";
import {getAndMergeBasketItems, mergeBasketItemsNoUser} from "./http/basketApi";
import {checkActive} from "./http/orderApi";

const App = observer(() => {
    const {user, settings, basket} = useContext(Context)
    const [headerState,setHeaderState] = useState({});
    const [runLine,setRunLine] = useState(null)
    const [active,setActive] = useState(true);
    const [stateSearcht, setStateSearcht] = useState(false)

    useEffect(() => {
        checkActive().then(response => {
            if(response) {
                setActive(response.active)
            }
        })

        window.dataLayer = window.dataLayer || [];

        check().then(data => {
          
            let favoriteJson = localStorage.getItem('favoriteStorage')
            let fakeArray = [];
            const basketJson = localStorage.getItem('basketStorage');
            if(!favoriteJson){
                localStorage.setItem('favoriteStorage', JSON.stringify(fakeArray))
            } else {
                settings.setFavoriteArray(JSON.parse(favoriteJson))
            }

            if(!data) {

                user.setIsAuth(false)
                settings.setActiveModalNonAuth(true)

                mergeBasketItemsNoUser(basketJson).then(response => {
                    if(response.items) {
                        basket.setBasket(response.items);
                    }
                })
            } else {
                user.setUser(data);
                user.setIsAuth(true);

                getСheckingUserOrder(user.user.id).then(response => {
                    if (response) {
                        settings.setCheckingUserOrder(response.res)
                    }
                    return false;
                })

                getAndMergeBasketItems(user.user.id, basketJson).then(response => {
                    if(response.items) {
                        basket.setBasket(response.items);
                    }
                })
            }

            fetchHeaderAndFooter(data ? data.id : "").then(response => {
                if(response.data.header) {
                    setHeaderState(JSON.parse(response.data.header))
                }

                if(response.data) {
                    setRunLine(response.data);
                }
            })
        })

    },[])

  return (
    <div className="App">
        {(
            window.location.pathname.includes("catalog/collab/youwannarollton")
        ) && (
            <NoodlesRain/>
        )}
        {
            active ?
                <BrowserRouter>
                    <Header
                        headerMenu={headerState}
                        runline={runLine}
                        stateSearcht={stateSearcht}
                        setStateSearcht={setStateSearcht}
                    />
                    <AppRouter/>
                    <ResultModal
                        controllClass={settings.resultModalActive ? "active" : "" }
                    />
                    <Footer/>
                </BrowserRouter>

            : "Отключено."
        }
    </div>
  );
})

export default App;
