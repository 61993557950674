import React, {useContext, useEffect} from 'react';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import './catalogLanding.css';
import BreadcrmbsAndFilterLineLanding
    from "../../components/CatalogLanding/BrcrmbAndFilterLandingCatalog/BreadcrmbsAndFilterLineLanding";

const CatalogLanding = observer(() => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const {settings, user} = useContext(Context)

    return (
        <div style={{marginTop:settings.headerHeight}} >
            <BreadcrmbsAndFilterLineLanding />

            {/*Начало враппера каталога*/}
            <div className={"wrapper_content_catalog "+"style_view_catalog"}>


                <div className="double_cart">
                    <div className={"wrapper_one_item_product_small"}>
                        <div className="one_item_product_small">
                            <div className="one_item_product_img_container">
                                <img className={"one_item_product_img_small"} src={"/files/images/smallPhoto.png"} alt=""/>
                            </div>
                            <div className={"title_and_price_product_small"}>
                            <span className={"title_product_small"}>
                                ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                            </span>
                                <span className={"price_one_item_product_small"}>18 950 ₽</span>
                            </div>
                        </div>
                    </div>

                    <div className={"wrapper_one_item_product_small"}>
                        <div className="one_item_product_small">
                            <div className="one_item_product_img_container">
                                <img className={"one_item_product_img_small"} src={"/files/images/smallPhoto.png"} alt=""/>
                            </div>
                            <div className={"title_and_price_product_small"}>
                            <span className={"title_product_small"}>
                                ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                            </span>
                                <span className={"price_one_item_product_small"}>18 950 ₽</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="one_cart_item">
                    <div className={"wrapper_one_item_product_small"}>
                        <div className="one_item_product_small">
                            <div className="one_item_product_img_container">
                                <img className={"one_item_product_img_small"} src={"/files/images/smallPhoto.png"} alt=""/>
                            </div>
                            <div className={"title_and_price_product_small"}>
                                <span className={"title_product_small"}>
                                    ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                                </span>
                                    <span className={"price_one_item_product_small"}>18 950 ₽</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="one_medium_cart_item">
                    <div className="wrapper_one_item_product_medium">
                        <div className="one_item_product_medium">
                            <div className="one_item_product_medium_img_container">
                                <img className={"one_item_product_img_medium"} src={"/files/images/mediumPhoto.png"} alt=""/>
                            </div>
                            <div className={"title_and_price_product_medium"}>
                                <span className={"title_product_medium"}>
                                    ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                                </span>
                                <span className={"price_one_item_product_medium"}>18 950 ₽</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="group_small_products">
                    <div className="one_product_item_small_group">
                        <div className="one_product_item_small_group_img_container">
                            <img className={"small_group_img"} src={"/files/images/small_group_img.png"} alt=""/>
                        </div>
                        <div className="title_and_price_product_group">
                               <span className={"title_product_group_small"}>
                                    ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                                </span>
                            <span className={"price_one_item_product_group_small"}>18 950 ₽</span>
                        </div>

                    </div>
                    <div className="one_product_item_small_group">
                        <div className="one_product_item_small_group_img_container">
                            <img className={"small_group_img"} src={"/files/images/small_group_img.png"} alt=""/>
                        </div>
                        <div className="title_and_price_product_group">
                               <span className={"title_product_group_small"}>
                                    ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                                </span>
                            <span className={"price_one_item_product_group_small"}>18 950 ₽</span>
                        </div>

                    </div>
                    <div className="one_product_item_small_group">
                        <div className="one_product_item_small_group_img_container">
                            <img className={"small_group_img"} src={"/files/images/small_group_img.png"} alt=""/>
                        </div>
                        <div className="title_and_price_product_group">
                               <span className={"title_product_group_small"}>
                                    ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                                </span>
                            <span className={"price_one_item_product_group_small"}>18 950 ₽</span>
                        </div>

                    </div>
                    <div className="one_product_item_small_group">
                        <div className="one_product_item_small_group_img_container">
                            <img className={"small_group_img"} src={"/files/images/small_group_img.png"} alt=""/>
                        </div>
                        <div className="title_and_price_product_group">
                               <span className={"title_product_group_small"}>
                                    ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                                </span>
                            <span className={"price_one_item_product_group_small"}>18 950 ₽</span>
                        </div>

                    </div>
                </div>

                <div className="fullsize_one_item">
                    <div className="one_item_product_fullsize">
                        <div className="one_item_image_wrapper_fullsize">
                            <img className={"image_full_size"} src={"/files/images/fullsizeFoto.png"} alt=""/>
                        </div>
                        <div className="title_and_price_fullsize">
                            <span className={"title_fullsize"} >ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА</span>
                            <span className={"price_one_item_product_group_small"}>18 950 ₽</span>
                        </div>
                    </div>
                </div>


                <div className="unreverse_container">
                        <div className="wrapper_one_item_unreverse">

                            <div className="one_item_product_small">
                                <div className="one_item_product_img_container">
                                    <img className={"one_item_product_img_small"} src={"/files/images/smallPhoto.png"} alt=""/>
                                </div>
                                <div className={"title_and_price_product_small"}>
                            <span className={"title_product_small"}>
                                ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                            </span>
                                    <span className={"price_one_item_product_small"}>18 950 ₽</span>
                                </div>
                            </div>


                            <div className="unreverse_img_container">
                                <img className={"unreverse_img"} src={"/files/images/smallAdd.png"} alt=""/>
                            </div>

                        </div>
                </div>



                <div className="reverse_container">
                    <div className="wrapper_one_item_reverse">

                        <div className="one_item_product_small">
                            <div className="one_item_product_img_container">
                                <img className={"one_item_product_img_small"} src={"/files/images/smallPhoto.png"} alt=""/>
                            </div>
                            <div className={"title_and_price_product_small"}>
                            <span className={"title_product_small"}>
                                ВЕЧЕРНЕЕ ПЛАТЬЕ СО ВСТАВКОЙ ИЗ СТРУЯЩЕГОСЯ АТЛАСА
                            </span>
                                <span className={"price_one_item_product_small"}>18 950 ₽</span>
                            </div>
                        </div>


                        <div className="unreverse_img_container">
                            <img className={"unreverse_img"} src={"/files/images/smallAdd.png"} alt=""/>
                        </div>

                    </div>
                </div>





            </div>



        </div>
    );
});

export default CatalogLanding;