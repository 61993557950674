import React, {useEffect, useState} from 'react';
import './colorManager.css';
import {addNewColor, fetchColors, removeOneColor} from "../../../http/catalogApi";
import OneColor from "./oneColor/OneColor";
import {login} from "../../../http/userAPI";
const ColorManager = () => {


    const [colorsArray, setCollorsArray] = useState([])

    useEffect(() => {
        fetchColors().then(data => {
            setCollorsArray(data);
        } )
    },[])


    const addNewColorHandler = async () => {
        const {data} = await addNewColor();
        if(data){
            fetchColors().then(data => {
                setCollorsArray(data);
            } )
        }
    }
    const [errorDelete,setErrorDelete] = useState([]);


    return (
        <div className={"homy_color_manager"}>
            <div className="colors_manager_title">
                Менеджер цветов
            </div>
            <div className="colors_panel_wrapper">
                <div className="table_colors_adm">
                    { colorsArray ?
                        colorsArray.map((item,index) =>
                            <OneColor
                                setCollorsArray={setCollorsArray}
                                colorsArray={colorsArray}
                                index={index}
                                // onClickRemove={removeColorHandler}
                                error={errorDelete}
                                key={item.id} item={item}   />
                        ) : ""
                    }

                   <div

                       onClick={event => addNewColorHandler()}
                       className={"add_new_color_btn"}>Добавить новый</div>

                </div>
            </div>
        </div>
    );
};

export default ColorManager;