import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet";

import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../../index";
import './faq.css'
import BlockQuestion from "../../../components/faq/BlockQuestion";
import {getOnePageContent} from "../../../http/contentApi";


const faq = observer(() => {

    const {settings} = useContext(Context)

    const [contentFqa, setContentFqa] = useState('')
    const [fqaNoJson, setFqaNoJson] = useState('')
    const [seoFqa, setSeoFqa] = useState('')
    const [DropdownBlock, setDropdownBlock] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
            getOnePageContent(11).then(response => {
                setSeoFqa(response)
                if (response) {
                    setContentFqa(response)
                }
                if (response.content) {
                    setFqaNoJson(JSON.parse(response.content))
                }
            })
        }, [])


    return (
        <div  style={{marginTop:settings.headerHeight}} className={"faq_conteiner"}>
            <Helmet>
                <title>{contentFqa.title ? contentFqa.title : ''}</title>
                <meta name="description" content={contentFqa.description ? contentFqa.description : ''} />
            </Helmet>
            <ContentBreadcrumbs
                margTop={settings.headerHeight}
                items={[
                    {name:"Главная", link:"/"},
                    {name:"Вопросы", link:"/faq/"}
                ]}
            />
            <div className={"f_indent"}>

                <div className={"faq_banner"}>
                    {
                        settings.projectWidth && settings.projectWidth > 768 && fqaNoJson.img ?
                            <img className={"faq_background_img"} src={process.env.REACT_APP_API_URL+"uploads/images/fqa/"+ fqaNoJson.img} alt="YouWanna - часто задаваемые вопросы"/>
                            :
                            ""
                    }


                    <div className={"faq_content_wrapper"}>
                        <div id={'q_parents_content'} className={"faq_content"}>
                            <h1 className={"faq_title"}>{contentFqa.name ? contentFqa.name : ''}</h1>
                            <div   className={"q_parents_content"}>
                                {
                                    fqaNoJson.questions ?
                                        fqaNoJson.questions.map( (item, index)=>
                                            <BlockQuestion
                                                key={index}
                                                item={item}
                                                DropdownBlock={DropdownBlock}
                                                setDropdownBlock={setDropdownBlock}
                                            />
                                        )
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                    {
                        fqaNoJson.feedback  ?
                            <div className={"f_info_content"}>
                                <div className={"q_border_block"}></div>
                                <div className={"f_info"}>
                                    {
                                        fqaNoJson.feedback.titleFeedback ?
                                            <span className={"f_title_info"}>{fqaNoJson.feedback.titleFeedback ? fqaNoJson.feedback.titleFeedback : ''}</span>
                                            : ''
                                    }
                                    {
                                        fqaNoJson.feedback.textFeedback ?  <span className={"f_text_info"}>{fqaNoJson.feedback.textFeedback ? fqaNoJson.feedback.textFeedback : ''}</span>
                                            : ''
                                    }
                                </div>
                                {
                                    fqaNoJson.feedback.FeedbackLink ?
                                        <a className={"f_button_info"} target="_blank" href={fqaNoJson.feedback.FeedbackLink ? fqaNoJson.feedback.FeedbackLink : ''}>
                                            {fqaNoJson.feedback.buttonFeedback ? fqaNoJson.feedback.buttonFeedback : ''}
                                        </a>
                                        : ''
                                }


                            </div>
                            : ''
                    }

                </div>
            </div>

        </div>
    );
});

export default faq;