import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './BreadcrumbsAndFilterLine.css';
import BreadcrumbsCatalog from "./breadcrumbsCatalog/BreadcrumbsCatalog";
import CatalogView from "./FilterAndCatalogView/CatalogView/CatalogView";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import SliderView from "./FilterAndCatalogView/sliderView/SliderView";
import {useLocation} from "react-router";
import styles from './BreadcrmbsAndFilterLine.module.css';
import { createPortal } from "react-dom";
import Modal from "../../Modal/Modal";
import { useModal } from "../../../hooks/useModal";
import Filters from "../../Filters/Filters";

const BreadcrmbsAndFilterLine = observer((props) => {
    const {isModalOpen, closeModal, openModal} = useModal();
    const {settings} = useContext(Context);

    const [stateSotCatalog, setStateSotCatalog] = useState(false)
    const sortingFunction = () => {
        setStateSotCatalog(!stateSotCatalog)
    }
    const arrayStateSort = {
        default: 'по умолчанию',
        price_down: 'сначала дешевле',
        price_up: 'сначала дороже',
        new_products: 'сначала новые'
    }

    const getH1 = () => {

        if(!props.category && !props.parrentCategory && !props.parrent || !props.category && !props.parrent) {
            return (<h1>Каталог</h1>)
        }
        if(props.fourthCategory && props.thirdCategory) {
            return (<h1>{props.fourthCategory.name}</h1>)
        }
        if(props.thirdCategory && props.category) {
            return (<h1>{props.thirdCategory.name}</h1>)
        }
        if(props.category && props.parrentCategory) {
            return (<h1>{props.category.name}</h1>)
        }
        if(props.category && !props.parrent) {
            return (<h1>{props.category.name}</h1>)
        }
        if(props.parrentCategory) {
            return (<h1>{props.parrentCategory.name}</h1>)
        }
        if(!props.parrentCategory && props.parrent && !props.category) {
            if(props.parrent === "bestseller") {
                return (<h1>Бестселлеры</h1>)
            }
            if(props.parrent === "new-collection") {
                return (<h1>Новинки</h1>)
            }
            if(props.parrent === "coming_soon") {
                return (<h1>Скоро в наличии</h1>)
            }
            if(props.parrent === "sale") {
                return (<h1>Скидки на женскую одежду</h1>)
            }
        }
        if(!props.parrentCategory && props.parrent && props.category) {
            if(props.parrent === "bestseller") {
                return (<h1>Бестселлеры - {props.category.name} </h1>)
            }
            if(props.parrent === "new-collection") {
                return (<h1>Новинки - {props.category.name} </h1>)
            }
            if(props.parrent === "coming_soon") {
                return (<h1>Скоро в наличии - {props.category.name} </h1>)
            }
            if(props.parrent === "sale") {
                return (<h1>Распродажа - {props.category.name}</h1>)
            }
        }
    }


    const refCatalogSort = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (stateSotCatalog && refCatalogSort.current && !refCatalogSort.current.contains(e.target)) {
                setStateSotCatalog(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [stateSotCatalog]);

    const handleFilterClick = useCallback((e) => {
        e.preventDefault();

        openModal();
    }, [])

    const handleCloseFilters = useCallback((e) => {
        // e.preventDefault();

        closeModal();
    }, []);

    return (
        <>
            <div className={props.catalogModelView === 'small' ? "filter_and_breadcrumbs" : "filter_and_breadcrumbs active"}>
                <div className={"h1_catalog_breadcrmbs"}>
                    {
                        getH1()
                    }
                </div>

                <div ref={refCatalogSort} className={"sort_catalog"}>
                    {
                        props.showFilters && 
                        <button className={styles.filterBtn} onClick={handleFilterClick}>
                            <span>Фильтры</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 0.5C6.16462 0.5 6.32249 0.565394 6.43889 0.681796C6.5553 0.798198 6.62069 0.956073 6.62069 1.12069V5.87931H11.3793C11.5439 5.87931 11.7018 5.9447 11.8182 6.06111C11.9346 6.17751 12 6.33538 12 6.5C12 6.66462 11.9346 6.82249 11.8182 6.93889C11.7018 7.0553 11.5439 7.12069 11.3793 7.12069H6.62069V11.8793C6.62069 12.0439 6.5553 12.2018 6.43889 12.3182C6.32249 12.4346 6.16462 12.5 6 12.5C5.83538 12.5 5.67751 12.4346 5.56111 12.3182C5.4447 12.2018 5.37931 12.0439 5.37931 11.8793V7.12069H0.62069C0.456073 7.12069 0.298198 7.0553 0.181796 6.93889C0.0653938 6.82249 0 6.66462 0 6.5C0 6.33538 0.0653938 6.17751 0.181796 6.06111C0.298198 5.9447 0.456073 5.87931 0.62069 5.87931H5.37931V1.12069C5.37931 0.956073 5.4447 0.798198 5.56111 0.681796C5.67751 0.565394 5.83538 0.5 6 0.5Z" fill="black"/>
                            </svg>
                        </button>
                    }
                   
                    <div onClick={e => sortingFunction()} className={stateSotCatalog === true ? "title_sort active" :  "title_sort"}>Сортировать: {arrayStateSort[props.filterParam] ? arrayStateSort[props.filterParam] : ""}</div>
                    <div className={stateSotCatalog === true ? "list_sorting_options active" : "list_sorting_options"}>
                        {
                            Object.keys(arrayStateSort).map((key, index) => {
                                return (<div key={key}
                                    onClick={e => (props.setFilterParam(key),sortingFunction())}
                                    className={"one_sort"}>{arrayStateSort[key]}</div>)
                            })
                        }
                    </div>
                </div>
                {/*{*/}
                {/*    settings.projectWidth && settings.projectWidth > 1024 ?*/}
                {/*        <BreadcrumbsCatalog*/}
                {/*            breadcrumbsParams={props.breadcrumbsParams}*/}
                {/*        />*/}
                {/*        :*/}
                {/*        ""*/}
                {/*}*/}


                {/*<div className={"wrapper_control_catalog_bar"}>*/}
                {/*    <CatalogView*/}
                {/*        setCatalogModelView={props.setCatalogModelView}*/}
                {/*        currentStateCatalogModelView={props.currentStateCatalogModelView}*/}
                {/*        projectWidth={settings.projectWidth}*/}
                {/*    />*/}
                {/*    <SliderView*/}
                {/*        setCatalogModelView={props.setCatalogModelView}*/}
                {/*        currentStateCatalogModelView={props.currentStateCatalogModelView}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>

            {!!isModalOpen && (
                <Modal extraClass={styles.filters} onClose={handleCloseFilters}>
                    <Filters onClose={handleCloseFilters}/>
                </Modal>
            )}
        </>
    );
});

export default BreadcrmbsAndFilterLine;