import React, {useContext, useEffect, useRef, useState} from 'react';
import './header.scss';
import RunningLine from "./RunningLine/RunningLine";
import MainHeaderLine from "./MainHeaderLine/MainHeaderLine";
import {useLocation} from "react-router";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet";
import ShadowOverlay from "../UI/ShadowOverlay/ShadowOwerlay";
import PopupTwoVariant from "../UI/ModalPopup/PopupTwoVariant";
import PopupOneVariant from "../UI/ModalPopup/PopupOneVariant";
import PopupThreeVariant from "../UI/ModalPopup/PopupThreeVariant";
import { SmartBannerJSX } from './SmartBanner/SmartBanner';

const Header = observer(({stateSearcht, setStateSearcht, ...props}) => {

    const {settings, user} = useContext(Context)
    const headerRef = useRef();
//Получаем роуты пользовательские и сравниваем с текущим. Забирая от туда цвет хедера.
    let location = useLocation();

    useEffect(() => {
        const elHeader = document.getElementById('header').clientHeight
        const elFooter = document.getElementById('footer').clientHeight
        const projectWidth = window.innerWidth
        const projectHeight = window.innerHeight
        // const projectWidth = document.documentElement.clientWidth
        // const projectHeight = document.documentElement.clientHeight

        // const projectHeightWindows = window.clientWidth

        settings.setProjecHeight(projectHeight);
        settings.setProjectWidth(projectWidth);

        const screenHeight = window.screen.height
        const bodySize = screenHeight * 1 - elHeader * 1 - elFooter * 1;

        const currentPath = location.pathname;
        if (currentPath.includes('admindashboard') && user.user.role === "ADMIN") {
            settings.setOffHeader(true);
        } else {
            settings.setOffHeader(false);
        }
        settings.setHeaderHeight(elHeader);
        settings.setBodySize(bodySize);

    }, [<Header/>], [settings.projectWidth])

    window.onresize = windowResizeController;
    function windowResizeController() {
        settings.setProjectWidth(window.innerWidth)
        settings.setProjecHeight(window.innerHeight);

    }

    const [menuState, setMenuState] = useState(false);
    const [childItemsMenu, setChildItemsMenu] = useState([])

    const onHoverHandler = (item) => {
        if (item.child && item.child?.length > 0) {

            setChildItemsMenu(item)
            setMenuState(true)
        } else {
            setMenuState(false)

        }
    }
    const onMouseLeaveHandler = () => {
        if (menuState === false) {
            return false;
        }
        setMenuState(false)
    }


    const closeAuthModalFunction = () => {
        user.setShadowOverlay(false);
        user.setOpenAuthModal(0);
        settings.setResultModalActive(false)


        if (user.user.telephone !== "" && user.user.telephone !== undefined) {
            user.setStepModals("unset");
        }

    }


    // Показывает полоску уведомления для открытия 3-ей модалки
    const [bottomLineNotificator,setBottomLineNotificator] = useState(false);

    const [openModalThreeImportant,setBottomModalThreeImportant] = useState(false);

    useEffect(() => {
        let timestamLocalstorage = localStorage.getItem("lineTimestamp");
        let timestamp = new Date().getTime();
        if(timestamLocalstorage) {
            let secondsInLocalstorage = timestamLocalstorage/1000;
            let currentSeconds = timestamp/1000;
            let seconds = currentSeconds - secondsInLocalstorage;
            if(seconds > 86400) {
                setBottomLineNotificator(true)
            }
        } else {
            setBottomLineNotificator(true)
        }
    },[]);

    useEffect(() => {
        if (headerRef.current && !headerRef.current.classList.contains('scroll')) {
        createScrollStopListener(window, function(scrollStopListener) {
            
            if (headerRef.current && headerRef.current.classList.contains('new-info')) {
                    if (!headerRef.current.classList.contains('scroll') && window.scrollY > 100) {
                        headerRef.current.classList.add('scroll');
                    }

                    if (headerRef.current.classList.contains('scroll') && window.scrollY === 0) {
                        headerRef.current.classList.remove('scroll');
                    }
                } else {
                    scrollStopListener();
                }
            });
        }
    },[location.pathname]);


    //меняем цвет хедера при скролле
    const [scrollPage, setScrollPage]=useState('')

    function createScrollStopListener(element, callback, timeout) {
        var handle = null;
        var onScroll = function() {
            if (handle) {
                clearTimeout(handle);
            }
            handle = setTimeout(callback, timeout || 500, stop); // default 1500 ms
            setScrollPage("scroll")
        };
        var stop = function() {
            element.removeEventListener('scroll', onScroll);
        };

        element.addEventListener('scroll', onScroll);

        return stop;
    }
    
    // Остановка скролла


    const styleHeader = () => {
        let currentPage = window.location.pathname

        if (settings.offHeader) {
            return "header admin";
        } else if (["/shares", "/shares/", "/bonus-program", "/bonus-program/"].includes(currentPage)) {
            return "header is-black "+scrollPage
        } else if (user.shadowOverlay === true) {
            return "header home scroll"
        } else if (currentPage.includes("/about/info/61")) {
            return "header home new-info"   
        } else {
            return "header home scroll"
        }
    }

    var mySession = window.sessionStorage;
    var pageCount = mySession.getItem("pageCount");
    useEffect(()=> {
        setScrollPage("")
    }, [pageCount])

    return (
        <div>
            {stateSearcht === true && <span className="header-bg"></span>}
            <header
                onMouseLeave={e => onMouseLeaveHandler(e)}
                id={"header"}
                ref={headerRef}
                // className={settings.offHeader ? "header admin" : "header"}
                className={styleHeader() + `${stateSearcht ? ' _search-active' : ''}`}
            >
                <SmartBannerJSX headerRef={headerRef} />
                <Helmet>
                    {/*<title>Новинки женской одежды - купить в интернет-магазине YOU WANNA</title>*/}
                    <meta name="description" content="Скоро в наличии в интернет-магазине YOU WANNA. По имеющимся вопросам звоните: ☎ +7 (926) 887-70-07." />
                </Helmet>

                {
                    location.pathname === "/" && props.runline && props.runline.header_line_active ?  <RunningLine runline={props.runline}/> : ""
                }

                <MainHeaderLine
                    onMouseEnter={e => onHoverHandler(e)}
                    // onMouseLeave={ e => onMouseLeaveHandler(e)}
                    headerMenu={props.headerMenu}
                    menuState={menuState}
                    childItemsMenu={childItemsMenu}
                    stateSearcht={stateSearcht}
                    setStateSearcht={setStateSearcht}
                />
                <ShadowOverlay className={user.shadowOverlay === true ? "active" : "hide"}
                    closeClick={() => closeAuthModalFunction()}/>
            </header>
            <div className={"other_header_elements"}>
                <PopupOneVariant/>
                <PopupTwoVariant/>
                <PopupThreeVariant open={openModalThreeImportant} setBottomModalThreeImportant={setBottomModalThreeImportant} />
                {/* {
                    bottomLineNotificator && <BottomLineForModal  closeLine={setBottomLineNotificator} openModal={setBottomModalThreeImportant}/>
                } */}

            </div>

        </div>

    );
});

export default Header;
