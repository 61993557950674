import React, {useState} from 'react';
import OneMenuItem from "./OneMenuItem/OneMenuItem";
import {Link} from "react-router-dom";

const NavBar = (props) => {

    const [menuState,setMenuState] = useState(false);
    const onHoverHandler = (item) => {
        if (item.child) {
            setMenuState(true)
        } else {
            setMenuState(false)
        }
    }
    const onMouseLeaveHandler = () => {

    }

    return (
        <div className="nav_bar_desc">
            {
                props.headerMenu[0] ?
                    props.headerMenu.map((item, index) =>
                        <OneMenuItem
                            // setCurrentRoute={e => setCurrentRoute(e)}
                            onMouseEnter={props.onMouseEnter}
                            // onMouseLeave={props.onMouseLeave}
                            key={index} item={item} />
                        )
                : ""
            }

            <div className={props.menuState ? "hover_menu active" : "hover_menu"}>
                {
                    props.childItemsMenu.child ?
                    props.childItemsMenu.child.map((item,index) => {

                        if(props.childItemsMenu.skip === true) {
                            return(
                                <div key={index} className={"one_column_menu"}>
                                    <Link to={"/catalog/"+item.slug+"/"} key={index}>{item.name}</Link>
                                    {
                                        item.child_elements ?
                                            item.child_elements.map((childItem,indexItem) => {
                                                return(<Link key={childItem.slug} to={"/catalog/"+item.slug+"/"+childItem.slug+"/"}>{childItem.name}</Link>)
                                            }) : ""
                                    }
                                </div>
                            )
                        } else {
                            return(
                                <div key={index} className={"one_column_menu"}>
                                    <Link className={"child_menu_link"} to={"/catalog/"+props.childItemsMenu.slug+"/"+item.slug+"/"} key={index}>{item.name}</Link>
                                    {
                                        item.child_elements ?
                                            item.child_elements.map((childItem,indexItem) => {
                                                return(<Link  key={indexItem} to={"/catalog/"+props.childItemsMenu.slug+"/"+childItem.slug+"/"}>{childItem.name}</Link>)
                                            }) : ""
                                    }
                                </div>
                            )
                        }
                    }) : ""
                }
            </div>
        </div>
    );
};

export default NavBar;