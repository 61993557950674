import React, {useState} from 'react';
import './oneImagehorizontalfield.css';
import CompliteRemove from "../compliteremove/CompliteRemove";
const OneImageHorizontalField = (props) => {

    const [delState,setDelState] = useState(false);

    const deleteImage = (e) => {
        props.deleteImage(e)
        setDelState(false)
    }

    return (
        <div className={"horizontal_upload_image"}>
            <CompliteRemove open={delState}  goRemove={e => deleteImage(e)  } canselRemove={e => setDelState(false)} />
            <span className={"for_manager_horizontal_info"}>
                Изображение
            </span>
            <div className={"image_container_horizontal"}>
                {
                    props.image ?
                        <div>
                            <img className={"horizontal_image_child_product"} src={props.image} alt=""/>
                            <div onClick={ e => setDelState(true)} className={"remove_horizontal_image"}>Удалить изображение </div>
                        </div>

                        :
                        <div className={"pre_info_upload_horizontal"}> Перетащи или кликни, чтобы загрузить </div>
                }
                <input className={"horizontal_uploader"} onChange={props.onChange} type="file"/>
            </div>
        </div>
    );
};

export default OneImageHorizontalField;