import React, { useContext, useEffect, useState } from 'react';
import Logotype from "./Logotype/Logotype";
import NavBar from "./NavBar/NavBar";
import IconsBar from "./IconsBar/IconsBar";
import { Context } from "../../../index";
import IconsBurger from "./BurgerMenu/IconsBurger";
import MobileNavBar from "./BurgerMenu/MobileNavBar";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import { SeoMenuContent } from "../SeoMenuContent";

const MainHeaderLine = observer(({ stateSearcht, setStateSearcht, ...props }) => {

    const { settings, user } = useContext(Context)
    const [activeMobMenu, setActiveMobMenu] = useState(false)
    const [stateRunningLine, setStateRunningLine] = useState('')


    const goBurger = () => {
        setActiveMobMenu(!activeMobMenu)
        if (settings.projectWidth && settings.projectWidth > 768) {
            user.setShadowOverlay(!user.shadowOverlay)
        }
        user.setStateOpenModalWindow(false)
    }

    let locationPage = useLocation();

    useEffect(() => {
        let trackHeightMenu = document.getElementById('main_header_line').offsetHeight
        if (locationPage.pathname.length < 2) {
            setStateRunningLine(trackHeightMenu + 30)
        } else {
            setStateRunningLine(trackHeightMenu)
        }

    }, [locationPage, settings.projectWidth])

    const openAuthModalFunction = () => {
        user.setOpenAuthModal(1);
        user.setShadowOverlay(true);
        user.setStepModals("registration")
        setActiveMobMenu(false)
    }

    useEffect(() => {
        if (activeMobMenu === true) {
            document.body.style.overflowY = "hidden";
            document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
        } else {
            document.body.style.overflowY = "scroll";
            document.body.style.paddingRight = "0px";
        }
    }, [activeMobMenu])

    return (
        <div id={"main_header_line"} className={activeMobMenu === true ? "main_header_line active" : "main_header_line"}>
            {settings.projectWidth && settings.projectWidth < 1025 ?
                stateSearcht ? '' : (
                    <IconsBurger
                        setActiveMobMenu={e => setActiveMobMenu(e)}
                        activeMobMenu={activeMobMenu}
                        goBurger={e => goBurger(e)}
                    />
                )
                :
                ""
            }
            {window.innerWidth <= 1024 ?
                stateSearcht ? '' : (
                    <Logotype
                        activeMobMenu={activeMobMenu}
                        stateSearcht={stateSearcht}
                    />
                )
                : (
                    <Logotype
                        activeMobMenu={activeMobMenu}
                        stateSearcht={stateSearcht}
                    />
                )
            }
            {stateSearcht === false && settings.projectWidth && settings.projectWidth > 1024 ?
                <>
                    <NavBar
                        onMouseEnter={props.onMouseEnter}
                        // onMouseLeave={props.onMouseLeave}
                        headerMenu={props.headerMenu}
                        menuState={props.menuState}
                        childItemsMenu={props.childItemsMenu}
                    />
                    <SeoMenuContent headerMenu={props.headerMenu} />
                </>
                :
                ""
            }
            <IconsBar
                activeMobMenu={activeMobMenu}
                stateSearcht={stateSearcht}
                setStateSearcht={setStateSearcht}
            />
            {settings.projectWidth && settings.projectWidth < 1025 ?
                <MobileNavBar
                    className={activeMobMenu === true ? "active" : "hide"}
                    headerMenu={props.headerMenu}
                    childItemsMenu={props.childItemsMenu}
                    stateRunningLine={stateRunningLine}
                    openAuthModalFunction={e => openAuthModalFunction(e)}
                />
                :
                ""
            }


        </div>
    );
});

export default MainHeaderLine;