import React, {useContext, useEffect, useState} from 'react';
import './payment.css'
import {observer} from "mobx-react-lite";
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../../index";
import {Helmet} from "react-helmet";
import {getOnePageContent} from "../../../http/contentApi";

const Payment = observer(() => {

    const {settings} = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const [pagePaymentContent, setPagePaymentContent] = useState({})
    const [paymentNoJson, setPaymentNoJson] = useState({})
    const [pageSeoPropsPayment, setPageSeoPropsPayment] = useState(false)


    useEffect(() => {
        getOnePageContent(9).then(response => {
            setPagePaymentContent(response)

            if (response.content) {

                setPaymentNoJson(JSON.parse(response.content))


                const {title, description} = response
                if(title || description)
                    setPageSeoPropsPayment({title, description})
                return false;
            }
            // setPageSeoPropsPayment('')
        })


    }, [])



    // const [heightPayment, setHeightPayment] = useState(0);
    // const restText = contentPayment[0].text
    // const counterText = restText.length
    //
    // let fullTextPayment = restText
    // let shortText = fullTextPayment.substr(0, 406);
    //
    // const [paymentTextState, setPaymentTextState] = useState(false)
    // const controllerTextPayment = () => {
    //     setPaymentTextState(!paymentTextState);
    //     if (paymentTextState === true) {
    //         window.scrollTo({
    //             top: 0,
    //             behavior: 'smooth'
    //         });
    //     }
    // }


    // useEffect(() => {
    //     let trackHeightPayment  = document.getElementById('static_div_content').offsetHeight;
    //     let trackHeight  = document.getElementById('payment_banner_height').offsetHeight;
    //     let trackTopPayment = document.getElementById('height_payment_abs_div').offsetTop;
    //     setHeightPayment(trackHeightPayment+trackTopPayment-trackHeight)
    //
    // }, [settings.projectWidth, trekerUpdateWindows])
    // дописать параметр при загрузке контента


    return (
        <div style={{marginTop: settings.headerHeight}} className={"payment_page"}>
            <Helmet>
                <title>{pageSeoPropsPayment.title ? pageSeoPropsPayment.title : ""}</title>
                <meta name="description" content={pageSeoPropsPayment.description ? pageSeoPropsPayment.description : ""}/>
            </Helmet>
            <div className={"payment_page_child"}>
                <div
                    id={"payment_banner_height"}
                    className={"payment_banner"}>
                    {/*<img className={"banner_content_image"} src={"/files/images/paymentPage/payment_banner.jpg"} alt=""/>*/}
                    {/*{*/}
                    {/*    settings.projectWidth &&  settings.projectWidth > 1024 ?*/}
                    {/*        <ContentBreadcrumbs*/}
                    {/*            margTop={settings.headerHeight}*/}
                    {/*            items={[*/}
                    {/*                {name:"Youwanna", link:"/"},*/}
                    {/*                {name:"ОПЛАТА", link:"/content/payment/"}*/}
                    {/*            ]}*/}
                    {/*        />*/}
                    {/*        :*/}
                    {/*        ""*/}
                    {/*}*/}

                    <div
                        id={"height_payment_abs_div"}
                        className={"payment_abs_div_content"}>
                        <div id={"static_div_content"} className={"static_div_content"}>
                            <h1 className={"content_page_name_sp"}>{pagePaymentContent.title ? pagePaymentContent.title : ''}</h1>
                            <div className={"white_block_steps"}>
                                <h2 className={"white_block_steps_title"}>{paymentNoJson.titleStep ? paymentNoJson.titleStep : ''}</h2>
                                <div className={"steps_wrapper_white_block"}>
                                    <div className={"line_abs_steps_support"}>
                                        <div className="line_abs_steps_line">

                                        </div>
                                    </div>
                                    <div className={"steps_items_wrapper"}>
                                        {
                                            paymentNoJson.arrayStepPayment ? paymentNoJson.arrayStepPayment.map((item, index) =>

                                                <div
                                                    key={index}
                                                    className={"one_step_item"}>
                                                    <div className="one_step_counter">
                                                        {item.number ? item.number : ''}
                                                    </div>
                                                    <span className={"step_title_one_step"}>{item.title ? item.title : ''}</span>
                                                    <div className={"one_step_item_text"}>
                                                        {item.text ? item.text : ''}
                                                    </div>
                                                </div>

                                            ) : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div
                    // style={{marginTop:heightPayment}}
                    className={"payment_page_content_aft"}>
                    {/*<div className="content_aft_line"></div>*/}
                    <h3 className={"aft_heading_text"}> {paymentNoJson.titleTextPayment ? paymentNoJson.titleTextPayment : ''} </h3>
                    <div className={"payment_page_methods"}>

                        {
                            paymentNoJson.arrayImgPayment ?  paymentNoJson.arrayImgPayment.map((item, index) =>
                                <div className="payment_page_methods_item_wrapper">
                                    <div className="payment_page_methods_item">
                                        <div className="img_method_wrapper">
                                            {
                                                item.img ?
                                                    <img src={process.env.REACT_APP_API_URL+"uploads/images/payment/"+item.img} alt={'Способ оплаты '+(item.name ? item.name : '')+' в магазине YouWanna' }/>
                                                    : ''
                                            }

                                        </div>
                                        <div className="text_method_wrapper">
                                            {item.name ? item.name : ''}
                                        </div>
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>

                    {/*<div className={"two_line_br"}></div>*/}

                    {/*{*/}
                    {/*    settings.projectWidth &&  settings.projectWidth < 1024 ?*/}
                    {/*        <div className={"opening_block_text"}>*/}
                    {/*            <div className={"payment_shadow_text"}>*/}
                    {/*                {paymentTextState === false ? shortText : fullTextPayment}*/}
                    {/*            </div>*/}
                    {/*            <div*/}
                    {/*                onClick={e => controllerTextPayment()}*/}
                    {/*                className={"button_add_text"}>*/}
                    {/*                {paymentTextState === false ? "Показать все" : "Скрыть"}*/}

                    {/*                </div>*/}
                    {/*        </div>*/}
                    {/*        :*/}
                    {/*        <div className={"payment_shadow_text"}>*/}
                    {/*            {fullTextPayment}*/}
                    {/*        </div>*/}

                    {/*}*/}

                    <div className={"payment_shadow_text"}>
                          <span className={"forced_transfer"}>
                              {paymentNoJson.textPayment ? paymentNoJson.textPayment : ''}
                          </span>
                    </div>

                </div>
            </div>

        </div>
    );
});

export default Payment;