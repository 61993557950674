import React, {useContext, useEffect, useMemo, useState} from 'react';
import {priceToUserString} from "../../../http/basketApi";
import CheckBoxPolitic from "../../../components/UI/checkBoxPolitic/CheckBoxPolitic";
import {createNotifyOfReceiptOrder} from "../../../http/orderApi";
import {Context} from "../../../index";
import './receiptNotification.css';

const FastOrderPopup = (props) => {

    const {user} = useContext(Context)

    const [userOrderFields, setUserOrderFields] = useState({name: "", telephone: "", email: ""})
    const [cityInfo, setCityInfo] = useState({value: ""});
    const [orderButtonDisable, setOrderButtonDisable] = useState(false);
    const [stateCheсkBoxPreorder, setStateCheсkBoxPreorder] = useState(false)

    const countChangeHandler = (param) => {
        if(param === "min") {
            if(props.orderModalInfo.count === 1) {
                return false;
            }
            props.setOrderModalInfo({...props.orderModalInfo, count: props.orderModalInfo.count - 1})
        }
        if(param === "max") {
            props.setOrderModalInfo({...props.orderModalInfo, count: props.orderModalInfo.count + 1})
        }
        return  false;
    }

    useMemo(() => {
        if (stateCheсkBoxPreorder) {
            setOrderButtonDisable(false)
        }
    }, [stateCheсkBoxPreorder])

    const userPreOrderChangeNameHandler = (value) => {
        let total = 200;
        setOrderButtonDisable(false);
        if (value.length > total) {
            return false;
        }
        setUserOrderFields({...userOrderFields, name: value})
    }
    const userPreOrderChangeEmailHandler = (value) => {
        setOrderButtonDisable(false);
        setUserOrderFields({...userOrderFields, email: value})
    }

    const userPreOrderChangeCityHandler = (value) => {
        value = value.replace(/[^a-zA-Zа-яА-Я ]/, '', value)
        if (value.length <= 64) {
            setOrderButtonDisable(false);
            setCityInfo({value});
        }
    }

    const userPreOrderChangeTelephoneHandler = (value) => {
        var reg = /^\d+$/;
        if(!value) {
            setUserOrderFields({...userOrderFields, telephone: value})
//
        }
        if(!reg.test(value) || value.length > 25) {
            return false;
        }
        setUserOrderFields({...userOrderFields, telephone: value})
        setOrderButtonDisable(false);
    }


    const [errorPreOrderText,setErrorPreOrderText] = useState(null)

    const onClickNotifyOfReceiptOrderHandler = () => {
        // console.log("CLICH")
        if (orderButtonDisable) {
            return false;
        }
        if (stateCheсkBoxPreorder === false) {
            setErrorPreOrderText('Примите соглашение')
            setOrderButtonDisable(true);
            return false;
        }
        let namePattern = /^[ а-яА-Яa-zA-Z]+$/
        if(!namePattern.test(userOrderFields.name) || userOrderFields.name.length < 2 ) {
            setErrorPreOrderText("Укажите настоящее имя");
            setOrderButtonDisable(true);
            return false;
        }
        if(userOrderFields.telephone.length < 2 ) {
            setErrorPreOrderText("Укажите настоящий телефон");
            setOrderButtonDisable(true);
            return false;
        }
        let emailPattern = /^[-a-z-A-Z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z-A-Z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/;
        if(!emailPattern.test(userOrderFields.email) || !userOrderFields.email){
            setErrorPreOrderText("Укажите настоящий Email");
            setOrderButtonDisable(true);
            return false;
        }
        setErrorPreOrderText("");
        setOrderButtonDisable(true);

        createNotifyOfReceiptOrder(props.orderModalInfo, userOrderFields, user.user, cityInfo, true).then(response => {
            ///
            if(response.error) {
                return false;
            }

            window.dataLayer.push({
                event: 'in_stock_notification',
                product_name: props.orderModalInfo.item.name,
                product_url: document.location.href
            })

            props.setSuccessStateModal(true);
            props.setOrderModalInfo(null)
            setOrderButtonDisable(false);

        })
    }

    const closeNotificationModal = () => {
        props.setOpenFastOrderModal(false);
        user.setShadowOverlay(false);
        props.setOrderModalInfo(null)
    }

    useEffect(() => {
        if(user.shadowOverlay !== true) {
            props.setOrderModalInfo(null)
            props.setOpenFastOrderModal(false);
        }

    },[user.shadowOverlay])

    return (
        <div>
            <div
                className={ props.openFastOrderModal ? "notification_modal_for_new_order active" : "notification_modal_for_new_order"
                }>
                <div className={props.successStateModal ? "notification_modal_wrapper_for_content non_border" : "notification_modal_wrapper_for_content"}>
                    <div className={"notification_modal_heading_line_and_exit"}>
                        { props.orderModalInfo && !props.successStateModal ? <span className={"notification_modal_heading_text"}> Быстрый заказ</span> : "" }
                        <div onClick={ e => closeNotificationModal() } className={"notification_modal_exit_div"}></div>
                    </div>
                    <div
                        className={props.successStateModal ? "relative_container_for_notification non_border" :  "relative_container_for_notification"}
                    >
                        {
                            props.orderModalInfo && !props.successStateModal ?
                                <div className={"modal_content_notification"}>
                                    <div className="modal_content_notification_heading_line">
                                        Оставьте свои контактные данные, чтобы менеджер мог связаться с вами для уточнения деталей заказа
                                    </div>
                                    <div className={"modal_content_notification_wrapper_for_row"}>
                                        <div

                                            className={"modal_content_notification_img_container"}>
                                            <img src={props.orderModalInfo.item.productId ? process.env.REACT_APP_API_URL+"uploads/images/"+props.orderModalInfo.item.productId+"/"+props.orderModalInfo.item.id+"/" + "214_"+props.orderModalInfo.item.img_preview : ''}
                                                 alt={ (props.orderModalInfo.item.name ? props.orderModalInfo.item.name : '')+'; цвет: '+(props.orderModalInfo.item.color.color_name ? props.orderModalInfo.item.color.color_name : '')}
                                            />
                                        </div>
                                        <div className={"modal_content_notification_wrapper_for_column_content"}>
                                            <div className="modal_content_notification_name_line">
                                                {props.orderModalInfo.item.name}
                                            </div>
                                            <div className={"modal_content_notification_color_name"}>
                                                <span className={"modal_content_notification_black_span"}>Цвет: </span>
                                                <span>{props.orderModalInfo.item.color.color_name}</span>
                                            </div>
                                            <div className={"modal_content_notification_color_name"}>
                                                <span className={"modal_content_notification_black_span"}>Размер: </span>
                                                <span>{props.orderModalInfo.currentSize}</span>
                                            </div>
                                            <div className={"modal_content_notification_color_name"}>
                                                <span className={"modal_content_notification_black_span"}>Количество: </span>

                                                <div className={"wrapper_for_mini_count_controller_wp"}>
                                                    <div className={"wrapper_for_mini_count_controller min"} onClick={e => countChangeHandler("min")}></div>
                                                    <div className={"wrapper_for_mini_count_controller center"}>
                                                        {props.orderModalInfo.count}
                                                    </div>
                                                    <div className={"wrapper_for_mini_count_controller max"} onClick={e => countChangeHandler("max")}></div>
                                                </div>

                                            </div>
                                            {!props.orderModalInfo.item.price_hidden ?
                                                <div className={"modal_content_notification_color_name"}>
                                                    <span className={"modal_content_notification_black_span"}>Цена: </span>
                                                    <span className={"one_product_page_price"}>
                                                        {
                                                        (props.orderModalInfo.item.sell_price && props.orderModalInfo.item.sell_price > 0) ?
                                                        priceToUserString(props.orderModalInfo.item.sell_price*props.orderModalInfo.count)+" ₽" 
                                                        : 
                                                        priceToUserString(props.orderModalInfo.item.parrent_price*props.orderModalInfo.count)+" ₽"
                                                        }</span>
                                                    <span className={(props.orderModalInfo.item.sell_price && props.orderModalInfo.item.sell_price > 0) ? "standard_price old_price" : "standard_price" }></span>
                                                </div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div className={"notification_fields_modal"}>
                                        <div className={"notification_fields_wrapper"}>
                                            <span>Имя*</span>
                                            <input
                                                value={userOrderFields.name ? userOrderFields.name : "" }
                                                onChange={ e => userPreOrderChangeNameHandler(e.target.value)}
                                                type="text"/>
                                        </div>
                                        <div className={"notification_fields_wrapper"}>
                                            <span>Телефон*</span>
                                            <input
                                                onChange={ e => userPreOrderChangeTelephoneHandler(e.target.value)}
                                                value={userOrderFields.telephone ? userOrderFields.telephone : "" }
                                                type="text"/>
                                        </div>
                                        <div className={"notification_fields_wrapper"}>
                                            <span>Email*</span>
                                            <input
                                                onChange={e => userPreOrderChangeEmailHandler(e.target.value)}
                                                value={userOrderFields.email ? userOrderFields.email : "" }
                                                type="text"/>
                                        </div>
                                        <div className={"notification_fields_wrapper"}>
                                            <span>Город*</span>
                                            <input
                                                onChange={e => userPreOrderChangeCityHandler(e.target.value)}
                                                value={cityInfo.value}
                                                type="text"/>
                                        </div>
                                    </div>
                                    <span className={"notification_finally_valid_text"}>
                                                    {errorPreOrderText ?  errorPreOrderText : ""}
                                                </span>
                                    <span
                                        onClick={ e => onClickNotifyOfReceiptOrderHandler()}
                                        className={orderButtonDisable ? "notification_finally_button disable" : "notification_finally_button"}>
                                                   Оформить заказ
                                                </span>
                                    <CheckBoxPolitic
                                        setStateCheсkBox = {e=> setStateCheсkBoxPreorder(e)}
                                        stateCheсkBox ={stateCheсkBoxPreorder}
                                    />
                                </div>
                            : ""
                        }

                        {
                            !props.orderModalInfo && props.successStateModal ?
                                <div className={"notification_success"}>
                                    <div className="wrapper_notificator">
                                        <span className="thank_you_notification">Спасибо за заказ!</span>
                                        <div className="thank_text_container">
                                            <span className="thank_text_child">Менеджер свяжется с Вами в ближайшее время.</span>
                                        </div>
                                        <span
                                            onClick={ e => closeNotificationModal()}
                                            className={"notification_finally_button close"}>
                                                Закрыть
                                        </span>
                                    </div>
                                </div>
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FastOrderPopup;
