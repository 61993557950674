import React, { useContext, useEffect, useState } from 'react';
import './CardsRefound.css'
import TextCardsShop from "./TextCardsShop";
import { Context } from "../../../index";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom"
import { is } from "immutable";



const CardsRefound = observer((props) => {

    const { settings } = useContext(Context)

    const [tracerOpenRefound, setTracerOpenRefound] = useState(false)
    const [indexRefoundCards, setIndexRefoundCards] = useState(false)
    const [isActive, setIsActive] = useState(false);

    const clickOpenDelivery = () => {
        setTracerOpenRefound(!tracerOpenRefound)
        setIndexRefoundCards(props.index)
        props.setHheightRefoundBlock(!props.heightRefoundBlock)
        setIsActive(!isActive);
    }

    const setRefoundOptions = () => {
        if (props.item.withoutDelivery) {
            settings.setRefundOption(1)
        } else {
            settings.setRefundOption(2)
        }
    }

    const content = (
        <div className={"refund_content_wrapper"}>
            <div className={settings.projectWidth && settings.projectWidth < 429 && tracerOpenRefound === true && indexRefoundCards === props.index ? "style_content active" : "style_content"}>
                {
                    props.item.textArrayBlock ? props.item.textArrayBlock.map((item, index) =>
                            <TextCardsShop
                                item={item}
                                key={index}
                                index={index}
                                useeffChange={props.useeffChange}
                                setUseffChange={e => props.setUseffChange(e)}
                            />
                        )
                        : ""
                }
            </div>
            <div className={"return_button_wrapper"}>
                {
                    props.item.uploadSsampleApplication ?
                        <div className={""}>
                            <a className={"return_button"} download href={process.env.REACT_APP_API_URL + "uploads/images/refund/arrayRefund/" + props.item.uploadSsampleApplication} without rel="noopener noreferrer" target="_blank">
                                Заявление на возврат
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 17.5H15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 2.5L10 14.1667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M14.1667 10L10 14.1667L5.83337 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </a>
                        </div>
                        : ''
                }
                {
                    props.item && props.item.purchaseOnlineStore && props.item.purchaseOnlineStore === true ?
                        <div className={""}>
                            <Link onClick={e => setRefoundOptions()} to={'/about/refund/making-refund/'} className={"return_button"} >
                                Оформить возврат
                            </Link>
                        </div>
                        : ''
                }
                {
                    props.item.selfRefund && props.item.selfRefund === true ?
                        <div className={"self-refund"}>
                            <Link onClick={e => setRefoundOptions()} to={'/about/refund/making-refund/'} className={"return_button"} >
                                Отправить заявление на возврат
                            </Link>
                        </div>
                        : ''
                }
            </div>
        </div>
    )

    const className = settings.projectWidth && settings.projectWidth < 428 && tracerOpenRefound === true && indexRefoundCards === props.index ? "child_cards" : "child_cards none";

    return (
        <div className={"conteiner_cards_refound"}>
            {
                props.item ?
                    <div className={"background_ref"}>
                        <div className={className + (isActive ? ' _active' : '')}>
                            <div
                                onClick={e => clickOpenDelivery()}
                                className={ tracerOpenRefound === true && indexRefoundCards === props.index ?
                                    "style_title close" : "style_title" }>
                                <span className={"refound_arrows_mob"}>{props.item.titleBlock}</span>
                            </div>
                        </div>
                        {window.innerWidth <= 1024 ?
                            isActive ? content : ''
                            : content
                        }
                    </div>
                : ''
            }
        </div>
    );
});

export default CardsRefound;