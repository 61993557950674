import React, {useContext, useState} from 'react';
import InputMask from "react-input-mask";
import {isComparePassword} from "../../../../../http/userAPI";
import {Context} from "../../../../../index";

const CompleteChangeNumber = (props) => {
    const [codeLimitErrorText, setCodeLimitErrorText] = useState('');
    const {user} = useContext(Context);

    const compareCode = async (code, userId, newNumber, newCountry) => {
        props.clearCode();
        let data = await isComparePassword(code, userId, newNumber, newCountry);
        if (data.error.length > 0) {
            setCodeLimitErrorText(data.error);
        } else if (data.jwt !== false) {
            user.setUser(data.jwt);
            user.setIsAuth(true);
            props.goToStateOne();
            user.setStepModals("unset");
            user.setShadowOverlay(false)
            setCodeLimitErrorText('');
        }
    };


    return (
        <div className={"complete_change_number " + props.controllClass}>

            <div className={"contain_change_phone_close"}>
                <span className={"change_heading"}>Смена номера телефона</span>
                <div
                    onClick={e => props.closeModalChangeNumber()}
                    className={"close_modal_change_phone"}>

                </div>
            </div>
            <div className={"text_change_wrapper"}>
                <div className="text_change_container">

                    <p className={"text_change_p"}>Мы отправили Вам СМС на номер {props.newNumberNoValid}</p>
                    <p className={"text_change_p"}>Для подтверждения смены номера телефона - введите четырехзначный код в поле ниже. </p>
                    <div className={"container_change_mask"}>
                        <span className={"title_change_input"}>Введите код из SMS</span>
                        <InputMask
                            className={"auth_sms_code"}
                            placeholder={"Код из SMS"}
                            mask={"9999"}
                            autoComplete={"one-time-code"}
                            value={props.smsCodeChangeNumber}
                            onChange={props.codeNumberValidator}
                            alwaysShowMask={false}
                            maskChar={"X"}
                        />
                        <div className={"code_limit_error"}>{codeLimitErrorText}</div>
                        <p className={"go_back_stage"} onClick={props.goToStateOne}>Указать другой номер</p>
                    </div>


                    <span onClick={e => compareCode(props.smsCodeChangeNumber, user.user.id, props.newNumber, props.SelectCountryUser)} className={"change_and_check_number " + props.controllClassConfirm}>
                        Сменить
                    </span>

                </div>
            </div>







        </div>
    );
};

export default CompleteChangeNumber;