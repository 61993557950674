import React, {useContext, useState} from 'react';
import './CardsDelivery.css';
import {Context} from "../../../index";
import TwoCard from "./TwoCard";


const TwoCardsDelivery = (props) => {
    const {settings} = useContext(Context)
    const [openDeliveryTwoBlockInfo,setOpenDeliveryTwoBlockInfo] = useState(false)
    const [indexDeliveryTwoBlockInfo,setIndexDeliveryTwoBlockInfo] = useState(false)
    const clickOpenDelivery = () => {
        setOpenDeliveryTwoBlockInfo(!openDeliveryTwoBlockInfo)
        setIndexDeliveryTwoBlockInfo(props.index)
        props.setHeightTracker(!props.heightTracker)
    }

    return (
        <div className={props.item.conditions.length > 1 ? "conteiner_msc" : "conteiner_msc express"}>
            <div className={props.item.color_block === false ? "child_msc" : "child_msc express"}>
                <div
                    onClick={e => clickOpenDelivery()}
                    className={props.item.color_block === false ? "title_moscow" : "title_moscow express"}
                >
                    <span
                        className={
                            settings.projectWidth && settings.projectWidth < 428 && openDeliveryTwoBlockInfo === true && indexDeliveryTwoBlockInfo === props.index ?
                                "title_msc_arrows close" : "title_msc_arrows"}
                    >{props.item.titleBlock ? props.item.titleBlock : ''}</span>
                </div>
                <div className={props.item.color_block === true ? "content_moscow" : "content_moscow scroll_container"}>
                    {
                        props.item.conditions ? props.item.conditions.map((cond, index) =>
                            <TwoCard key={index} {...cond} item={props.item}/>
                        ) : ""
                    }
                </div>
            </div>
        </div>
    );
};

export default TwoCardsDelivery;