import React, {useContext, useEffect, useState} from 'react';
import './pageAbout.css';
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../../index";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {getOnePageContent} from "../../../http/contentApi";
import OneImageField from "../../administrator/UI/oneImageField/OneImageField";

const PageAbout = () => {

    const {settings} = useContext(Context)

    const [contentAbout, setContentAbout] = useState('')
    const [aboutNoJson, setAboutNoJson] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)

        getOnePageContent(12).then(response => {
            if (response) {
                setContentAbout(response)
            }
            if(response.content) {
                setAboutNoJson(JSON.parse(response.content))
                return false;
            }
            setAboutNoJson('')
        } )

    },[])


    return (
        <div
            style={{marginTop: settings.headerHeight}}
            className={"page_about"}>
            <div
                className={"breadcrumbs_about"}>
                <ContentBreadcrumbs
                    margTop={settings.headerHeight}
                    items={[
                        {name:"Главная", link:"/"},
                        {name:"О бренде", link:"/about/"}
                    ]}
                />
            </div>
            <Helmet>
                <title>{contentAbout.title ? contentAbout.title : ''}</title>
                <meta name="description" content={contentAbout.description ? contentAbout.description : ''} />
            </Helmet>
            <div className={"about_content_wrapper"}>

                {
                    aboutNoJson ? aboutNoJson.map((item, index) => {

                        if(item.type === "twoImg") {
                            return(
                                <div key={index} className={"big_block end"}>
                                    {
                                        item.imgBackground ?
                                            <img className={"big_block_background"} src={process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.imgBackground}
                                                 alt="YouWanna - о компании"
                                            />
                                            : ''
                                    }

                                    <div className={"container_title_and_text"}>
                                        {
                                            item.imgUpper ?
                                                <img className={"about_title_img"} src={process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.imgUpper} alt="YouWanna - о компании"/>
                                                : ''
                                        }

                                        <div className={"big_block_text_wrapper"}>
                                            <h1 className={"title_about"}>{item.title ? item.title : ''}</h1>
                                            <div className={"text_about"}>{item.text ? item.text : ''}</div>
                                        </div>
                                    </div>
                                </div>
                                )
                        }

                        if(item.type === "rightImg") {
                            return(
                                <div key={index} className={"small_block_wrapper"}>
                                    <div className={"small_left_block"}>
                                        <div className={"title_about"}>{item.title ? item.title : ''}</div>
                                        <div className={"text_about grey"}>{item.text ? item.text : ''}</div>
                                    </div>
                                    <div className={"small_right_block"}>
                                        {
                                            item.img ?
                                                <img className={"small_block_img"} src={process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img} alt="YouWanna - о компании"/>
                                                : ''
                                        }
                                    </div>
                                </div>

                            )
                        }

                        if(item.type === "leftImg") {
                            return(
                                <div key={index} className={"small_block_wrapper reflect"}>
                                    <div className={"small_left_block"}>
                                        <div className={"title_about"}>{item.title ? item.title : ''}</div>
                                        <div className={"text_about grey"}>{item.text ? item.text : ''}</div>
                                    </div>
                                    <div className={"small_right_block"}>
                                        {
                                            item.img ?
                                                <img className={"small_block_img"} src={process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img} alt="YouWanna - о компании"/>
                                                : ''
                                        }
                                    </div>
                                </div>
                            )
                        }

                        if(item.type === "centerImg") {
                            return(

                                <div key={index}  className={"big_block three"}>
                                    <div className={"large_block_center"}>
                                        {
                                            item.img ?
                                                <img className={"big_block_photo"} src={process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img} alt="YouWanna - о компании"/>
                                                : ''
                                        }
                                        <div className={"big_block_text_center"}>
                                            <div className={"title_about new_size"}>{item.title ? item.title : ''}</div>
                                            <div className={"text_about new_size"}>{item.text ? item.text : ''}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        if(item.type === "blockButton") {
                            return(
                                <div key={index} className={"big_block finish"}>
                                    <div className={"finish_block_wrapper"}>
                                        {
                                            item.img ?
                                                <img className={"big_block_photo finish"} src={process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img} alt="YouWanna - о компании"/>
                                                : ''
                                        }
                                        <div className={"big_block_finish_text"}>
                                            <div className={"about_new_logo"}>{item.title ? item.title : ''}</div>
                                            <Link to={item.linkButton ? item.linkButton : ''} className={"about_button_catalog"}>
                                                {item.textButton ? item.textButton : ''}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                    }) : ''
                }


            </div>

        </div>
    );
};

export default PageAbout;