import React, {useState} from 'react';
import './allCatalog.css'
import {Link} from "react-router-dom";

const AllCatalog = (props) => {

    const [stateArrowsSubcategories, setStateArrowsSubcategories] = useState(false)
    const [reduceArraySubcategories, setReduceArraySubcategories] = useState(false)
    const [categoriesId, setCategoriesId] = useState('')
    const [temporaryStorageSubcategories, setTemporaryStorageSubcategories] = useState({
        id: '',
        arrows: '',
    })

    const openSubcategories = (item) => {

        if (item) {
            setTemporaryStorageSubcategories({...temporaryStorageSubcategories, id: item.id, arrows: stateArrowsSubcategories })
            setReduceArraySubcategories(item)
            setCategoriesId(item.id)
            setStateArrowsSubcategories(!stateArrowsSubcategories)
            if (temporaryStorageSubcategories.id === item.id && stateArrowsSubcategories === true) {
                setStateArrowsSubcategories(!stateArrowsSubcategories)
            } else {
                setStateArrowsSubcategories(true)
            }
        } else {
            // console.log("Пусто")
        }
    }

    return (
        <div className={"child_items_menu"}>

            {
                props.childItemsMenu.skip === true ?
                    props.childItemsMenu.child.map((item,index) =>
                    <div key={index} className={"subcategory_catalog_item"}>
                        <div className={"subcategory_and_arrows"}>
                            <Link className={"subcategory_catalog"} to={"/catalog/"+item.slug+"/"} key={index}>{item.name}</Link>
                            {
                                item.child_elements && item.child_elements.length > 0 ?
                                    <div
                                        onClick={e => openSubcategories(item)}
                                        className={stateArrowsSubcategories === true && categoriesId === item.id ? "subcategories_arrows hide" : "subcategories_arrows active"}></div>
                                    :
                                    ""
                            }
                        </div>
                        <div className={"subcategory_child_container"}>
                            {
                                stateArrowsSubcategories === true && reduceArraySubcategories && categoriesId === item.id ?
                                    reduceArraySubcategories.child_elements.map((childItem,indexItem) => {
                                        return(<Link className={"subcategory_subcategories"} key={childItem.slug} to={"/catalog/"+item.slug+"/"+childItem.slug+"/"}>{childItem.name}</Link>)
                                    }) : ""
                            }
                        </div>

                    </div>
                    )
                    :
                    props.childItemsMenu.child.map((item,index) =>
                    <div key={index} className={"subcategory_catalog_item"}>
                        <div className={"subcategory_and_arrows"}>
                            <Link className={"subcategory_catalog"} to={"/catalog/"+props.childItemsMenu.slug+"/"+item.slug+"/"} key={index}>{item.name}</Link>
                            {
                                item.child_elements && item.child_elements.length > 0 ?
                                    <div
                                        onClick={e => openSubcategories(item)}
                                        className={stateArrowsSubcategories === true && categoriesId === item.id ? "subcategories_arrows hide" : "subcategories_arrows active"}></div>
                                    :
                                    ""
                            }
                        </div>
                        {
                            stateArrowsSubcategories === true && reduceArraySubcategories && categoriesId === item.id ?
                                reduceArraySubcategories.child_elements.map((childItem,indexItem) => {
                                    return(<Link className={"subcategory_subcategories"} key={indexItem} to={"/catalog/"+props.childItemsMenu.slug+"/"+childItem.slug+"/"}>{childItem.name}</Link>)
                                }) : ""
                        }
                    </div>
                    )
            }

        </div>
    );
};

export default AllCatalog;