import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Helmet} from "react-helmet";

import {Context} from "../../../index";
import ContentBreadcrumbs from "../../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import './Contacts.css';
import ContactsCards from "../../../components/contacts/ContactsCards";
import SocialIcon from "../../../components/contacts/SocialIcon";
import {getOnePageContent} from "../../../http/contentApi";

const Contacts = observer(() => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    const {settings} = useContext(Context)
    const [pageContentContacts, setPageContentContacts] = useState({})
    const [contantNoJson, setContantNoJson] = useState({})
    const [pageProps, setPageProps] = useState(false)


    useEffect(() => {
        getOnePageContent(5).then(response => {
            setPageContentContacts(response)

            if (response.content) {

                setContantNoJson(JSON.parse(response.content))
                const {title, description} = response
                if(title || description)
                    setPageProps({title, description})
                return false;
            }

            setContantNoJson({
                ///
                cardsInfo: [],
                socialNetwork: {},
                activSocial: [],
                requisitesBlock: {}
                ///
            })

        })


    }, [])


    const [heightContacts, setHeightContacts] = useState(0)

    //Считается разница между abs блоком и баннером, задается высота всему блоку с этим контентом, 175 это top блока abs
    useEffect(() => {
        // let trackHeightAbsolut = document.getElementById('height_block').offsetHeight
        // let trackHeightBanner = document.getElementById('contacts_banner').offsetHeight
        // // let trackHeightInfo = (trackHeightAbsolut + 175 - trackHeightBanner) + trackHeightBanner
        // let trackHeightInfo = trackHeightAbsolut + 175
        // setHeightContacts(trackHeightInfo)

    }, [settings.projectWidth, contantNoJson])


    return (
        <div className={"contacts"}>

            {
                pageProps
                &&
                    <Helmet>
                        <title>{pageProps.title}</title>
                        <meta name="description" content={pageProps.description} />
                    </Helmet>
            }

            <div
                // style={{minHeight:heightContacts}}
                style={{marginTop: settings.headerHeight}}
                className={"block_height_banner"}>

                {
                    contantNoJson.img && settings.projectWidth && settings.projectWidth > 768 ?
                        <img className="c_background_img" src={
                            process.env.REACT_APP_API_URL + "uploads/images/social/" + contantNoJson.img
                        } alt="YouWanna - контактная информация компании"/> : ""
                }

                {/*<div*/}

                {/*    id={"contacts_banner"}*/}
                {/*    className={"contacts_banner"}>*/}
                {/*    {*/}
                {/*        contantNoJson.img ?*/}
                {/*            <img className="c_background_img" src={*/}
                {/*                process.env.REACT_APP_API_URL+"uploads/images/social/"+ contantNoJson.img*/}
                {/*            } alt=""/> :  ""*/}
                {/*    }*/}

                {/*    {settings.projectWidth && settings.projectWidth > 1024 ?*/}
                {/*        <ContentBreadcrumbs*/}
                {/*            margTop={settings.headerHeight}*/}
                {/*            items={[*/}
                {/*                {name:"Youwanna", link:"/"},*/}
                {/*                {name:"КОНТАКТЫ", link:"/content/contacts/"}*/}
                {/*            ]}*/}
                {/*        />*/}

                {/*        :*/}

                {/*        ""*/}

                {/*    }*/}


                {/*</div>*/}
                <div id={"height_block"} className={"c_content_absolute"}>
                    <div className={"c_content_static"}>
                        <h1 className={"title_contacts"}>{pageContentContacts.name ? pageContentContacts.name : ""}</h1>
                        <div className={"c_cards_content"}>
                            {
                                contantNoJson.cardsInfo ?
                                    contantNoJson.cardsInfo.map((item, index) =>
                                        <ContactsCards
                                            key={index}
                                            item={item}
                                            index={index}
                                        />
                                    )
                                    : ""
                            }
                        </div>
                    </div>
                </div>
                <div
                    className={"c_social_parants"}>
                    <div className={"c_social"}>
                        <span
                            className={"c_title_social"}>{contantNoJson.socialNetwork ? contantNoJson.socialNetwork.title_social : ""}</span>
                        <span
                            className={"c_text_social"}>{contantNoJson.socialNetwork ? contantNoJson.socialNetwork.text_social : ""}</span>

                        <div className={"wrap_icons_content"}>
                            {
                                contantNoJson.activSocial ? contantNoJson.activSocial.map((item, index) =>
                                        <SocialIcon
                                            key={index}
                                            item={item}
                                            index={index}
                                        />
                                    )
                                    :
                                    ""
                            }
                        </div>


                    </div>
                    {/*<div className={"c_border"}></div>*/}
                    <div className={"c_requisites"}>
                        <span
                            className={"c_title_requisites"}>{contantNoJson.requisitesBlock ? contantNoJson.requisitesBlock.title_requisites : ""}</span>
                        <div className={"c_text_requisites"}>
                            <span
                                className={"requisites_bold transfer"}>{contantNoJson.requisitesBlock ? contantNoJson.requisitesBlock.one_paragraph : ""}</span>
                            <span
                                className={"c_text_paragraph"}>{contantNoJson.requisitesBlock ? contantNoJson.requisitesBlock.two_paragraph : ""}</span>
                            <span
                                className={"c_text_paragraph"}>{contantNoJson.requisitesBlock ? contantNoJson.requisitesBlock.three_paragraph : ""}</span>
                            <div className={"c_without_indentation"}>
                                <span
                                    className={"requisites_bold"}>{contantNoJson.requisitesBlock ? contantNoJson.requisitesBlock.subtitle_paragraph : ""}</span>
                                <span
                                    className={"c_text_paragraph transfer"}>  {contantNoJson.requisitesBlock ? contantNoJson.requisitesBlock.four_paragraph : ""}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    );
});

export default Contacts;