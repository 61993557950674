import React, {useEffect, useState} from 'react';
import MiniCountController from "../../../UI/miniCountController/MiniCountController";
import {priceToUserString} from "../../../../../http/basketApi";
import {searchProductsBySearchValue} from "../../../../../http/orderApi";

const SearchChildProductArea = (props) => {

    const [openSearchArea,setOpenSearchArea] = useState(false)
    const [searchValue,setSearchValue] = useState("");
    const [searchProductResult, setSearchProdoctResult] = useState(null)
    const [openChildProductArea, setOpenChildProductArea] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedChild,setSelectedChild] = useState(null)


    const switchSelectedChild = (item) => {
        setSelectedChild(item)
    }
    const openChildArea = (item) => {
        if(!item) {
            return false;
        }

        setOpenChildProductArea(true);
        setSelectedProduct(item);
        setSelectedChild(item.childProducts[0]);
    }

    useEffect(() => {
        if(searchValue.length < 3) {
            setSearchProdoctResult(null)
            return false;
        }
        searchProductsBySearchValue(searchValue).then(response => {
            if(response.error) {
                setSearchProdoctResult(null)
                return false;
            }

            setSearchProdoctResult(response)


        })

    },[searchValue])


    const goToBack = () => {
        setSelectedProduct(null);
        setSelectedChild(null);
        setOpenChildProductArea(false)
    }


    const addToList = () => {
        console.log(selectedChild)
    }

    return (
        <>
        {props.title && <h2> {props.title} </h2>}
        <div className="one_order_page_ident_container">
            <div className="one_order_page_ident_container_top_line">
                <div className="one_order_page_ident_container_top_line_item_left">
                    <span className={"controll_slider_heading"}>Добавление товаров в ручную подборку</span>
                </div>
                <div className="one_order_page_ident_container_top_line_item_right">
                                   <span
                                       onClick={e => setOpenSearchArea(true)}
                                       className={"one_order_page_ident_add_new_product"}>Добавить товар</span>
                </div>
            </div>
        <div className={openSearchArea ? "add_new_product_to_order_area active" : "add_new_product_to_order_area"}>
            <div className={"one_order_add_area_top_line"}>
                <div className={"one_order_add_area_top_line_notif_custom"}>
                    Поиск по названию товара выполнится автоматически, когда вы введете 3 и более символа.
                </div>

                <span
                    onClick={e => setOpenSearchArea(false)}
                    className={"one_order_add_close_area"}> Скрыть </span>
            </div>
            <div className={"one_order_add_area_search_block"}>
                <input
                    placeholder={"Введите название товара"}
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                    className={"one_order_this_search"} type="text"/>
            </div>

            <div className={"one_order_search_result_area"}>
                {
                    searchProductResult && !openChildProductArea ?

                        <div className={"one_order_search_result_container"}>
                            {
                                searchProductResult.map((item,index) => {
                                    return(
                                        <div
                                            onClick={e => {
                                                openChildArea(item)
                                            } }
                                            key={item.id} className={"one_search_item_for_add_area"}>
                                            <div className={"one_item_search_id"}>{item.id}</div>
                                            <div className={"one_item_search_img"}>
                                                <img src={process.env.REACT_APP_API_URL+"uploads/images/"+item.id+"/214_"+item.previev_photo} alt=""/>
                                            </div>
                                            <div className={"one_item_search_name"}> {item.name} </div>
                                            <div className={"one_item_search_count"}> {"Торг. предложений: "+item.childProducts.length} </div>
                                            <div>
                                                {/*searchAreaOneItemClickHandler*/}
                                                {/*<div></div>*/}
                                                {/*<div></div>*/}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        :
                        <div className={"no_search_and_select_detail"}>
                            {
                                openChildProductArea && selectedChild ?
                                    <div className={"add_new_product_to_order_wrapper"}>
                                        <span className={"one_item_config_title"}>Выберите цвет, который хотите добавить</span>
                                        <div className={"one_item_configurator"}>
                                            <div className={"one_item_configurator_img_container"}>
                                                <img src={process.env.REACT_APP_API_URL+"uploads/images/"+selectedChild.product.id+"/"+selectedChild.id+"/214_"+selectedChild.img_preview} alt=""/>
                                            </div>
                                            <div className="one_item_detail_order_page_content">
                                                                    <span className={"span_for_one_product_order"}>
                                                                        {selectedChild.name}
                                                                    </span>
                                                <span className={"span_for_one_product_articul"}>
                                                                       Артикул: {selectedChild.product.articul}
                                                                    </span>
                                                <div className="wrapper_for_data_span">
                                                    <div className="colors_container_one_order">
                                                        <span className={"color_you_can_take_this"}>Доступные цвета: </span>
                                                        {
                                                            selectedProduct.childProducts.map((item,index) => {
                                                                if(item.color.id ===  selectedChild.color.id) {
                                                                    return(
                                                                        <div
                                                                            key={item.color.id}
                                                                            style={item.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+item.color.id+"/"+item.color.img+")"} : {backgroundColor: item.color.hex}} className={"one_order_color_check active"}>

                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div
                                                                            key={item.color.id}
                                                                            onClick={e => switchSelectedChild(item)}
                                                                            style={item.color.img ? {backgroundImage: "url("+process.env.REACT_APP_API_URL+"uploads/images/colors/"+item.color.id+"/"+item.color.img+")"} : {backgroundColor: item.color.hex}} className={"one_order_color_check"}>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                    <span
                                                        className="one_order_page_color_current_inform">Текущий цвет: {selectedChild.color.color_name}</span>
                                                </div>



                                            </div>

                                        </div>
                                        <div className={"add_new_item_to_order_configurator_panel"}>
                                                                <span className={"conf_panel_text"}>
                                                                    После добавления товара поиск очистится.
                                                                </span>
                                            <div className={"conf_panel_controll_buttons"}>
                                                                        <span
                                                                            onClick={e => goToBack()}
                                                                            className={"btn_style_conf_panel"}>
                                                                            Назад
                                                                        </span>
                                                <span
                                                    onClick={e => {
                                                        props.addProduct(selectedChild,props.sliderId)
                                                    }
                                                        }
                                                    className={"btn_style_conf_panel"}>
                                                                            Добавить выбранный товар
                                                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={"no_search_area_notif"}>
                                        Введите запрос, чтобы вывести доступные товары.
                                    </div>
                            }

                        </div>
                }

            </div>
        </div>
        </div>
        </>
    );
};

export default SearchChildProductArea;