import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../../../../index";
import {countryArr} from "../../../../../utils/consts";
import OptionCountry from "../../../../header/MainHeaderLine/IconsBar/modalAuth/RegistrationForm/OptionCountry";
import InputMask from "react-input-mask";
import {changeNumberGetCode} from "../../../../../http/userAPI";

const ChangeNumber = (props) => {

    const {user} = useContext(Context);
    const [deviceUUID, setDeviceUUID] = useState('')
    const [userAgent, setUserAgent] = useState('')


    useEffect(() => {
        setDeviceUUID(document.cookie.replace(/(?:(?:^|.*;\s*)mindboxDeviceUUID\s*\=\s*([^;]*).*$)|^.*$/, "$1"))
        setUserAgent(window.navigator.userAgent)
    })

    const  ValidateButtonAndGoStepTwo = async (number, lengthNumber) => {


        if(number.length*1 !== lengthNumber*1) {
            return false;
        }

        const {data} = await changeNumberGetCode(number, lengthNumber, user.user, deviceUUID)


        if(data.message) {
            setErrorChange(data.message)
            props.buttonStatuDisactive();
            return false;
        }

        if(!data) {
            setErrorChange('Что-то пошло не так. Обратитесь к администратору.')
            props.buttonStatuDisactive();
            props.setNewNumber('');
            return false;
        }

        props.buttonStatuDisactive();
        props.goToStateTwo();

    }




    // открыто ли окно для выбора стран
    const [searchStateShow, setSearchStateShow] = useState('unactive');


    const userInfo = user.user;
    let countryUser = countryArr.find(city => city.name === userInfo.country)
    if(!countryUser) {
        countryUser = countryArr[0];
    }
    //  Тут хранится маска для вывода маски в поля и данные о стране
    const  [thisCountryUser, setThisCountryUser] = useState(countryUser);



//Устанавливаем значение для инпута и проверяем на соответствие кол-ву цифр.
    const setTelephoneCheckButton = (number) => {

        props.setNewNumberNoValid(number)
        let replacenumber = number.replace(/[^\d]/g, '');
        props.setNewNumber(replacenumber);


        setErrorChange('');
        if ( (replacenumber.length*1) === (thisCountryUser.number*1)) {
            props.buttonStatuActive();
        } else {
            props.buttonStatuDisactive();
        }

    }



// selected инпут - значения для отображения или скрытия окна.
    const ToggleShowContry = () => {
        if (searchStateShow === "unactive") {
            setSearchStateShow('active')
        } else {
            setSearchStateShow('unactive')
        }
    }

// после выбора страны, устанавливаем состояние визуального инпута, затем обнуляем значение инпута с номером.
    const changeModelInput = (object) => {
        setThisCountryUser(object);
        props.setSelectCountryUser(object)
        ToggleShowContry();
        props.setNewNumber("")
        props.buttonStatuDisactive();
    }

    const [searchQuery, setSearchQuery] = useState('');

    const  getSortedCountry = () => {

        if(searchQuery) {
            // return [...countryArr].sort((a,b) => a.name.localeCompare(b.name));
            return [...countryArr].filter(country => country.name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
        }
        return countryArr;

    }

    const sortedArr = getSortedCountry();
    const [errorChange,setErrorChange] = useState('');




    return (

            <div className={"fields_wrapper_change " + props.controllClass}>
                <div className={"contain_change_phone_close"}>
                    <span className={"change_heading"}>Смена номера телефона</span>
                    <div
                        onClick={e => (props.closeModalChangeNumber(),    setSearchStateShow('unactive'))}
                        className={"close_modal_change_phone"}>

                    </div>
                </div>
                <div className={"change_phone_wrapper_text"}>
                    <div className={"text_change_wrapper"}>
                        <div className="text_change_container">
                            <p className={"text_change_p"}>Введите новый номер телефона, который вы хотите привязать к аккаунту</p>
                            <p className={"text_change_p"}>Мы отправим вам SMS на указанный номер с кодом подтверждения</p>
                        </div>
                        <div className={"fields_register_wrapper"}>
                            <span className={"title_fields_phone"}>Введите номер телефона</span>
                            <div className={"registration_select_class"}>
                                <div className={"phone_and_city_wrap"}>
                                    <div onClick={e => ToggleShowContry()} className={"selected_input_wrapper"} >
                                        <img src={thisCountryUser && thisCountryUser.imgPath ? thisCountryUser.imgPath : ""} alt=""/>

                                        {/*<span>{thisCountryUser.name}</span>*/}
                                    </div>
                                    <div className={"searchResults " + searchStateShow}>
                                        <div className={"wrapSearchInput"}>
                                            <input type="text" className={"searchInput"} placeholder={"Введите название страны"} value={searchQuery} onChange={e => setSearchQuery(e.target.value)}   />
                                        </div>

                                        {
                                            sortedArr.length
                                                ?
                                                sortedArr.map((country, name) =>
                                                    <OptionCountry onClick={ e => changeModelInput(country)}  key={name} country={country}/>)
                                                :
                                                <p>К сожалению, такой страны нет. Свяжитесь с менеджером. </p>
                                        }

                                    </div>
                                </div>

                                <InputMask
                                    className={"phoneInput change"}
                                    placeholder={thisCountryUser.placeholder}
                                    mask={thisCountryUser.mask}
                                    value={props.newNumber}
                                    onChange={e => setTelephoneCheckButton(e.target.value)}
                                />
                            </div>

                            <span className={"mesage_error_change_number"}> {errorChange}</span>
                            <span onClick={e => ValidateButtonAndGoStepTwo(props.newNumber, thisCountryUser.number )} className={"change_and_check_number " + props.controllButtonClass}>
                        Получить код
                    </span>
                        </div>

                    </div>
                    <div className={"modal_change_phone_politic"}>
                        Нажимая кнопку “ПОЛУЧИТЬ КОД”, вы соглашаетесь с нашей  <a rel="noreferrer"  className={"change_phone_politic_link"} href={"/personal/privacy-policy/"} target={"_blank"}>политикой кондфиденциальности</a>
                    </div>
                </div>





            </div>

    );
};

export default ChangeNumber;