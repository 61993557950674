import React, {useEffect, useState} from 'react';
import './admAbout.css'
import {getOnePageContent, updateContentPage} from "../../../../http/contentApi";
import OneImageHorizontalField from "../../UI/OneImageHorizontalField/OneImageHorizontalField";
import {contentBackgroundImage, LookBookHorizontalSizePreset} from "../../../../utils/consts";
import {addImages, deleteImage} from "../../../../http/imagesApi";
import OneImageField from "../../UI/oneImageField/OneImageField";

const AdmAbout = () => {

    const [contentAdmAbout, setContentAdmAbout] = useState('')
    const [admAboutNoJson, setAdmAboutNoJson] = useState('')
    const [trigerContentAbout, setTrigerContentAbout] = useState(false)


    useEffect(() => {

        getOnePageContent(12).then(response => {
            if (response) {
                setContentAdmAbout(response)
            }
            if(response.content) {
                setAdmAboutNoJson(JSON.parse(response.content))
                return false;
            }
            setAdmAboutNoJson([])
        } )

    },[])

    useEffect(() => {
        setContentAdmAbout({...contentAdmAbout, content:JSON.stringify(admAboutNoJson)})
    }, [admAboutNoJson])

    const seoFieldsAbout = (e, fieldName) => {
        setContentAdmAbout({...contentAdmAbout, [fieldName]: e.target.value})
        setTrigerContentAbout(true)
    }



    const oneBlockAbout =  {type: 'twoImg', title:"", text: "", imgBackground:"", imgUpper: "" };
    const rightImgBlockAbout =  {type: 'rightImg',title:"", text: "", img:""};
    const leftImgBlockAbout =  {type: 'leftImg',title:"", text: "", img:""};
    const centerImgBlockAbout =  {type: 'centerImg',title:"", text: "", img:""};
    const buttonBlockAbout =  {type: 'blockButton',title:"", textButton: "", linkButton: "", img:""};

    const addNewBlockAbout = (object) => {
        let reduceAbout = [...admAboutNoJson];
        reduceAbout.push(object)
        setAdmAboutNoJson(reduceAbout)
        setTrigerContentAbout(true)
    }


    const uploadImgAbout = (e,fieldName, index) => {

        let newFile = e.target.files[0];

        let formData = new FormData();
        formData.append("image", newFile);

        if (admAboutNoJson[fieldName]){
            deleteImage(admAboutNoJson[fieldName], 'about', [index]).then(response  => {
                setAdmAboutNoJson([...admAboutNoJson], admAboutNoJson[index][fieldName] = "")            })
            setTrigerContentAbout(true)

        }
        addImages(formData,'about',[index]).then(response => {
            if(response.message) {
                setAdmAboutNoJson([...admAboutNoJson],admAboutNoJson[index][fieldName]=response.fileName)
                setTrigerContentAbout(true)
            }
        })

    }

    const deleteImgAbout = (filename, fieldName, index) => {
        deleteImage(admAboutNoJson[index][fieldName], 'about', [index]).then(response  => {
            setAdmAboutNoJson([...admAboutNoJson], admAboutNoJson[index][fieldName] = "")
            setTrigerContentAbout(true)
        })
    }

    const fieldsAboutBlocks = (e, fieldName, index) => {
        setAdmAboutNoJson([...admAboutNoJson], admAboutNoJson[index][fieldName] = e.target.value)
        setTrigerContentAbout(true)
    }

    const deleteAboutBlock = (index) => {
        let deleteAbout = [...admAboutNoJson];
        deleteAbout.splice(index,1)
        setAdmAboutNoJson(deleteAbout)
        setTrigerContentAbout(true)

    }


    const saveContentPageAbout = (date) => {
        updateContentPage(date).then(response => {
            setTrigerContentAbout(false)
        } )
    }

    return (
        <div className={"adm_about_page"}>
            <div className={"about_title"}>Менеджер страницы "О бренде"</div>
            <div className={"seo_adm_about"}>
                {/*<div className={"field_about_wrap"}>*/}
                {/*    <span className="name_field_about">Имя страницы</span>*/}
                {/*    <input*/}
                {/*        value={contentAdmAbout.name ? contentAdmAbout.name : ""}*/}
                {/*        onChange={e => seoFieldsAbout(e,'name')}*/}
                {/*        className="input_field_about" type="text" />*/}
                {/*</div>*/}
                <div className={"field_about_wrap"}>
                    <span className="name_field_about">Title</span>
                    <input
                        value={contentAdmAbout.title ? contentAdmAbout.title : ""}
                        onChange={e => seoFieldsAbout(e,'title')}
                        className="input_field_about" type="text" />
                </div>
                <div className={"field_about_wrap"}>
                    <span className="name_field_about">Description</span>
                    <input
                        value={contentAdmAbout.description ? contentAdmAbout.description : ""}
                        onChange={e => seoFieldsAbout(e,'description')}
                        className="input_field_about" type="text" />
                </div>
            </div>
            <div className={"view_adm_about"}>
                <div className={"choosing_view"}>
                    <div className={"one_view"}>
                        <div className={"name_about_block"}>Вариант 1</div>
                        <div className={"img_view_block"}>
                            <img  src={"/files/images/admAbout/B1.jpg"} alt=""/>
                        </div>
                        <div
                            onClick={e => addNewBlockAbout(Object.assign({}, oneBlockAbout ))}
                            className={"button_view_block"}>Добавить блок</div>
                    </div>
                    <div className={"one_view"}>
                        <div className={"name_about_block"}>Вариант 2</div>
                        <div className={"img_view_block"}>
                            <img  src={"/files/images/admAbout/B2.jpg"} alt=""/>
                        </div>
                        <div
                            onClick={e => addNewBlockAbout(Object.assign({}, rightImgBlockAbout ))}
                            className={"button_view_block"}>Добавить блок</div>
                    </div>
                    <div className={"one_view"}>
                        <div className={"name_about_block"}>Вариант 3</div>
                        <div className={"img_view_block"}>
                            <img  src={"/files/images/admAbout/B3.jpg"} alt=""/>
                        </div>
                        <div
                            onClick={e => addNewBlockAbout(Object.assign({}, leftImgBlockAbout ))}
                            className={"button_view_block"}>Добавить блок</div>
                    </div>
                    <div className={"one_view"}>
                        <div className={"name_about_block"}>Вариант 4</div>
                        <div className={"img_view_block"}>
                            <img  src={"/files/images/admAbout/B4.jpg"} alt=""/>
                        </div>
                        <div
                            onClick={e => addNewBlockAbout(Object.assign({}, centerImgBlockAbout ))}
                            className={"button_view_block"}>Добавить блок</div>
                    </div>
                    <div className={"one_view"}>
                        <div className={"name_about_block"}>Вариант 5</div>
                        <div className={"img_view_block"}>
                            <img  src={"/files/images/admAbout/B5.jpg"} alt=""/>
                        </div>
                        <div
                            onClick={e => addNewBlockAbout(Object.assign({}, buttonBlockAbout ))}
                            className={"button_view_block"}>Добавить блок</div>
                    </div>
                </div>
            </div>



            <div className={"content_adm_about"}>
                {
                    admAboutNoJson ? admAboutNoJson.map((item, index) => {

                        if(item.type === "twoImg") {
                            return(
                                <div key={index} className={"one_block_view"}>

                                    <div className={"block_nesting"}>
                                        <div className={"del_one_view"}>
                                            <div
                                                onClick={e => deleteAboutBlock(index)}
                                                className={"delete_view"}></div>
                                        </div>
                                        <div className={"name_about_block"}>Вариант 1</div>
                                        <div className={"warning_text"}>Важно! Изображения необходимо загружать без фона</div>
                                        <div className={"nested_fields"}>
                                            <div className={"img_about_wrapper"}>
                                                <div className={"one_img_about"}>
                                                    <OneImageField
                                                        text={"Фото в середине баннера"}
                                                        image={ item.imgUpper ?
                                                            process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.imgUpper  : null }
                                                        onChange={ e => uploadImgAbout(e, 'imgUpper', index)}
                                                        deleteImage={ e => deleteImgAbout(e,'imgUpper',index)}
                                                    />
                                                </div>
                                                <div className={"one_img_about"}>
                                                    <OneImageField
                                                        text={"Изображение на фоне"}
                                                        image={ item.imgBackground ?
                                                            process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.imgBackground  : null }
                                                        onChange={ e => uploadImgAbout(e, 'imgBackground', index)}
                                                        deleteImage={ e => deleteImgAbout(e,'imgBackground',index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"container_fields"}>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Заголовок</span>
                                                    <input
                                                        value={item.title ? item.title : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'title', index)}
                                                        className="input_field_about" type="text" />
                                                </div>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Текст</span>
                                                    <textarea
                                                        value={item.text ? item.text : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'text', index)}
                                                        className="input_field_about textarea" type="text" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                 </div>)
                        }
                        if(item.type === "rightImg") {
                            return(
                                <div key={index} className={"one_block_view"}>

                                    <div className={"block_nesting"}>
                                        <div className={"del_one_view"}>
                                            <div
                                                onClick={e => deleteAboutBlock(index)}
                                                className={"delete_view"}></div>
                                        </div>
                                        <div className={"name_about_block"}>Вариант 2</div>
                                        <div className={"nested_fields"}>
                                            <div className={"img_about_wrapper"}>
                                                <div className={"one_img_about"}>
                                                    <OneImageField
                                                        text={"Изображение блока"}
                                                        image={ item.img ?
                                                            process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img  : null }
                                                        onChange={ e => uploadImgAbout(e, 'img', index)}
                                                        deleteImage={ e => deleteImgAbout(e,'img',index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"container_fields"}>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Заголовок</span>
                                                    <input
                                                        value={item.title ? item.title : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'title', index)}
                                                        className="input_field_about" type="text" />
                                                </div>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Текст</span>
                                                    <textarea
                                                        value={item.text ? item.text : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'text', index)}
                                                        className="input_field_about textarea" type="text" />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>)
                        }
                        if(item.type === "leftImg") {
                            return(
                                <div key={index} className={"one_block_view"}>

                                    <div className={"block_nesting"}>
                                        <div className={"del_one_view"}>
                                            <div
                                                onClick={e => deleteAboutBlock(index)}
                                                className={"delete_view"}></div>
                                        </div>
                                        <div className={"name_about_block"}>Вариант 3</div>
                                        <div className={"nested_fields"}>
                                            <div className={"img_about_wrapper"}>
                                                <div className={"one_img_about"}>
                                                    <OneImageField
                                                        text={"Изображение блока"}
                                                        image={ item.img ?
                                                            process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img  : null }
                                                        onChange={ e => uploadImgAbout(e, 'img', index)}
                                                        deleteImage={ e => deleteImgAbout(e,'img',index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"container_fields"}>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Заголовок</span>
                                                    <input
                                                        value={item.title ? item.title : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'title', index)}
                                                        className="input_field_about" type="text" />
                                                </div>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Текст</span>
                                                    <textarea
                                                        value={item.text ? item.text : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'text', index)}
                                                        className="input_field_about textarea" type="text" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>)
                        }
                        if(item.type === "centerImg") {
                            return(
                                <div key={index} className={"one_block_view"}>

                                    <div className={"block_nesting"}>
                                        <div className={"del_one_view"}>
                                            <div
                                                onClick={e => deleteAboutBlock(index)}
                                                className={"delete_view"}></div>
                                        </div>
                                        <div className={"name_about_block"}>Вариант 4</div>
                                        <div className={"warning_text"}>Важно! Изображения необходимо загружать без фона</div>
                                        <div className={"nested_fields"}>
                                            <div className={"img_about_wrapper"}>
                                                <div className={"one_img_about"}>
                                                    <OneImageField
                                                        text={"Изображение блока"}
                                                        image={ item.img ?
                                                            process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img  : null }
                                                        onChange={ e => uploadImgAbout(e, 'img', index)}
                                                        deleteImage={ e => deleteImgAbout(e,'img',index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"container_fields"}>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Заголовок</span>
                                                    <input
                                                        value={item.title ? item.title : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'title', index)}
                                                        className="input_field_about" type="text" />
                                                </div>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Текст</span>
                                                    <textarea
                                                        value={item.text ? item.text : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'text', index)}
                                                        className="input_field_about textarea" type="text" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>)
                        }
                        if(item.type === "blockButton") {
                            return(
                                <div key={index} className={"one_block_view"}>

                                    <div className={"block_nesting"}>
                                        <div className={"del_one_view"}>
                                            <div
                                                onClick={e => deleteAboutBlock(index)}
                                                className={"delete_view"}></div>
                                        </div>
                                        <div className={"name_about_block"}>Вариант 5</div>
                                        <div className={"warning_text"}>Важно! Изображения необходимо загружать без фона</div>
                                        <div className={"nested_fields"}>
                                            <div className={"img_about_wrapper"}>
                                                <div className={"one_img_about"}>
                                                    <OneImageField
                                                        text={"Изображение блока"}
                                                        image={ item.img ?
                                                            process.env.REACT_APP_API_URL+"uploads/images/about/"+index+"/"+item.img  : null }
                                                        onChange={ e => uploadImgAbout(e, 'img', index)}
                                                        deleteImage={ e => deleteImgAbout(e,'img',index)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"container_fields"}>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Заголовок</span>
                                                    <input
                                                        value={item.title ? item.title : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'title', index)}
                                                        className="input_field_about" type="text" />
                                                </div>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Текст кнопки</span>
                                                    <input
                                                        value={item.textButton ? item.textButton : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'textButton', index)}
                                                        className="input_field_about" type="text" />
                                                </div>
                                                <div className={"field_about_wrap"}>
                                                    <span className="name_field_about">Ссылка кнопки</span>
                                                    <input
                                                        value={item.linkButton ? item.linkButton : ""}
                                                        onChange={e => fieldsAboutBlocks(e,'linkButton', index)}
                                                        className="input_field_about" type="text" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>)
                        }

                    }) : ''
                }
            </div>
            <span
                onClick={e => saveContentPageAbout(contentAdmAbout)}
                className={trigerContentAbout ? "save_button_about trig" : "save_button_about"}>
                    Сохранить
            </span>
        </div>
    );
};

export default AdmAbout;