import React, {useContext, useEffect} from 'react';
import './iconsBurger.css';
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router";


const IconsBurger = observer( (props) => {

    const {user} = useContext(Context)

    useEffect( () => {
        if(user.shadowOverlay !== true) {
            props.setActiveMobMenu(false);
        }
    },[user.shadowOverlay])


    let locationCatalog = useLocation();

    useEffect( () => {
        user.setShadowOverlay(false);
        props.setActiveMobMenu(false);
    },[locationCatalog])



    return (
        <div
            onClick={e => props.goBurger()}
            className={"burger"}>
            <div className={"container_burger"}>
                <div className={props.activeMobMenu === true ? "bar1 active" : "bar1"}></div>
                <div className={props.activeMobMenu === true ? "bar2 active" : "bar2"}></div>
                <div className={props.activeMobMenu === true ? "bar3 active" : "bar3"}></div>
            </div>
        </div>
    );
});

export default IconsBurger;