import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../../index";


const UserIcon = observer((props) => {

    const {settings} = useContext(Context);


    return (
        <div onClick={props.onClick} className="bar_icon_wrapper stroke">
            {
                settings.projectWidth && settings.projectWidth > 768 ?
                    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1888 15.397C18.2278 15.397 19 16.1956 19 17.0961V18.1984C19 18.6681 18.8473 19.1303 18.557 19.5204C16.8742 21.7814 14.0736 23 9.99584 23C5.91716 23 3.11902 21.7808 1.44102 19.5196C1.152 19.1301 1 18.6689 1 18.2002V17.0961C1 16.1956 1.77216 15.397 2.81118 15.397H17.1888ZM9.99584 1C12.9341 1 15.246 3.27701 15.246 6.00108C15.246 8.72516 12.9341 11.0022 9.99584 11.0022C7.05756 11.0022 4.74568 8.72517 4.74568 6.00108C4.74568 3.277 7.05755 1 9.99584 1Z" stroke="white" strokeWidth="2"/>
                    </svg>
                    :
                    <div className={"container_lk_title"}>
                        <div className={"mobile_bar_icons"}>Личный кабинет</div>
                    </div>
            }

        </div>
    );
});

export default UserIcon;