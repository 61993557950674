import React from 'react';
import './midleBar.css';
import {Link, useHref} from "react-router-dom";
import {
    ABOUT,
    CONTACTS,
    CONTENT_PAYMENT_PAGE, FAQ, LOOKBOOK, PRIVACY_POLICY, PUBLIC_OFFER,
    REFOUND_AND_DELIVERY_PAGE, REVIEWS, SHOP, STOCKS_AND_TRENDS, VACANCIES
} from "../../../utils/consts";

const MidleBar = (props) => {
    ///

    return (
        <div className="midle_bar_wrapper">

            {
                props.noJsonMenuFooter ? props.noJsonMenuFooter.map((item, index)=>
                    <div key={index} className="one_column">
                        <span className="heading_one_column">{item.nameColumn ? item.nameColumn : ''}</span>
                        {
                            item.fields ? item.fields.map((itemFields, indexFields) =>
                                {
                                    if(itemFields.website === true) {
                                        return(
                                            <Link key={indexFields} to={itemFields.value ? itemFields.value : ''}>{itemFields.name ? itemFields.name : ''} </Link>
                                        )}
                                    if (itemFields.website === false) {
                                        return(
                                            <a  key={indexFields} href={itemFields.value ? itemFields.value : ''}  target={"_blank"} rel="noreferrer">{itemFields.name ? itemFields.name : ''} </a>
                                        )}
                                }

                            ) :''
                        }

                    </div>
                ) :''
            }
        </div>
    );
};

export default MidleBar;