import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Context} from "../../../../../index";
import {observer} from "mobx-react-lite";
import InputMask from "react-input-mask";
import {InvisibleSmartCaptcha} from "@yandex/smart-captcha";

const AuthStep = observer((props) => {

    const {user} = useContext(Context);

    const [childKey, setChildKey] = React.useState(1);
    const [visible, setVisible] = useState(false);

    React.useEffect(() => {
        user.counterSecondCode > 0 && setTimeout(() => user.setCounterSecondCode(user.counterSecondCode - 1), 1000);
    }, [user.counterSecondCode]);

    const handleChallengeHidden = useCallback(() => setVisible(false), []);

    const onSuccessHandle = (token) => {
        setCaptcha(token);
        props.getCodeOneMoreTime();
        setVisible(false);
    }

    const setCaptcha = (token) => {
        if (token) {
            user.token = token;
        }
    }

    const onTokenExpired = () => {
        setVisible(true);
    }

    const handleCombinedClick = () => {
        if (user.counterSecondCode === 0) {
            resetChildComponent();
            setVisible(true);
        }
    };
    const resetChildComponent = () => {
        setChildKey(childKey + 1);
    };

    return (
        <div className={"auth_step " +props.controllStateClass }>
            <div className={"registration_title_wrapper"}>
                <span className={"big_registration_text"}>Войти или зарегистрироваться</span>
            </div>
            <div className={"number_step_fields"}>
                <div className={"number_and_step"}>
                    <span className={"user_info_number"}><noindex>Код отправлен на номер </noindex><span className={"user_info_number_underline"}>{user.phoneNumber}</span> </span>
                    <span
                        className={"goRegistrationStep"}
                        onClick={props.goBack}
                    > <noindex>изменить номер</noindex></span>
                    <p className={"counter_sms"}>
                        <noindex>
                            Код отправлен, вы можете запросить повторную отправку
                            {
                                user.counterSecondCode > 0 ?
                                    " через " + user.counterSecondCode + " сек."
                                    :
                                    " уже сейчас "
                            }
                    </noindex>
                    </p>
                </div>

                <div>
                    <p className={"title_input_masc_auth"}><noindex>Введите код из SMS</noindex></p>
                    <InputMask
                        className={"auth_sms_code"}
                        placeholder={"Код из SMS"}
                        mask={"9999"}
                        value={props.codeValue}
                        autoComplete={"one-time-code"}
                        onChange={props.codeChange}
                        alwaysShowMask={false}
                        maskChar={"X"}
                    />
                    <span
                        className={user.authSmsCode.length === 4 ? "auth_code_button active" : "auth_code_button"}
                        onClick={props.loginFunc}

                    >Войти</span>
                    <span className={"counter_sms_get_code " +props.getCodeButtonClass}
                          onClick={handleCombinedClick}
                    > Запросить повторный код </span>
                    <p className={"error_message_sms"}>{user.codeMessage}</p>
                </div>
                <div style={{display: "none"}} className='captcha'>
                    <InvisibleSmartCaptcha
                        key={childKey}
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                        onSuccess={onSuccessHandle}
                        onTokenExpired={onTokenExpired}
                        onChallengeHidden={handleChallengeHidden}
                        visible={visible}
                        hideShield={true}
                    />
                </div>

                <div className={"modal_auth_politic"}>
                    {/*Нажимая кнопку “ПОЛУЧИТЬ КОД”, вы соглашаетесь с нашей  <a rel="noreferrer"  className={"modal_auth_politic_link"} href={"/personal/privacy-policy/"} target={"_blank"}>политикой кондфиденциальности</a>*/}
                </div>


            </div>



        </div>
    );
});

export default AuthStep;