import React, {useContext} from 'react';
import './resultRequest.css'
import ContentBreadcrumbs from "../Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const ResultRequest = observer(() => {

    const {settings} = useContext(Context)

    return (
        <div
            style={{marginTop: settings.headerHeight}}
            className={"result_request"}>
            <ContentBreadcrumbs
                items={[
                    {name:"Главная", link:"/"},
                    {name:"Возврат", link:"/about/refund/"},
                    {name:"Оформление возврата", link:"/about/refund/making-refund/"}
                ]}
            />
            <div className={"wrap_result_request"}>
                <h1 className={"result_request_title"}>Спасибо, Ваше заявление получено</h1>
                <svg width="174" height="174" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="87" cy="87" r="87" fill="black" fill-opacity="0.03"/>
                    <circle cx="87" cy="87" r="73" fill="black" fill-opacity="0.1"/>
                    <circle cx="87" cy="87" r="60" fill="black"/>
                    <path d="M68 87L81.435 100.435L108.305 73.5649" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>


        </div>
    );
});

export default ResultRequest;