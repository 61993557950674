import React from 'react';
import './spin.css';
const Spiner = () => {
    return (
        <div className={"spin_container"}>
            <div className={"spin_wrapper_custom "}>
                <div className={"spinner_custom"}></div>
            </div>
        </div>
    );
};

export default Spiner;