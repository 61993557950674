import React, {useState} from 'react';
import {updateOneCity} from "../../../../http/settingsApi";

const OneCityLine = (props) => {

    const [trigerName,setTrigerName] = useState(false);
    const [errorSave,setErrorSave] = useState("");
    const valueChangeHandler = (e,fieldName) => {
        props.setCityList([...props.cityList],[props.cityList[props.index][fieldName] = e.target.value])
        setTrigerName(true)
    }

    const saveInformationCity = (id,name, mapurl,activeForModal,sortForModal) => {
        updateOneCity(id,name,mapurl,activeForModal,sortForModal).then(response => {
            if(response.error) {
                setErrorSave(response.error)
                setTrigerName(false)
                return false
            }
            setTrigerName(false)
            setErrorSave("")
            props.setRefreshDbCity(!props.refreshDbCity);
            return false;
        } )
    }


    return (
        <div key={props.item.id} className={"one_item_city_wr "}>
            <div className={"id_city_container"}>id: {props.item.id}</div>
            <input className={"input_city_st name_sity_input"} type={"text"} value={props.item.name}
                   onChange={e => valueChangeHandler(e, "name") }
            />
            <input className={"input_city_st name_sity_input"} type={"text"} value={props.item.map_url}
                   onChange={e => valueChangeHandler(e, "map_url") }
            />
            <div
                onClick={e => saveInformationCity(props.item.id, props.item.name, props.item.map_url, !props.item.active_for_modal)}

                className={props.item.active_for_modal ? "check_indicator_city active" :"check_indicator_city" }>

            </div>
            <div className={"one_city_line_sort_container"}>
                <input
                    value={props.item.sort_for_modal}
                    onChange={e => valueChangeHandler(e, "sort_for_modal") }
                    type="text"/>
            </div>
            <div
                onClick={e => saveInformationCity(props.item.id, props.item.name, props.item.map_url,props.item.active_for_modal,props.item.sort_for_modal)}
                className={trigerName ? "save_city_btn active" : "save_city_btn"}>Сохранить</div>
            <div className={"error_city_line"}>{errorSave}</div>

        </div>
    );
};
//
export default OneCityLine;