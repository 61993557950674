import React, {useContext, useEffect, useMemo, useState} from 'react';
import {priceToUserString} from "../../../http/basketApi";
import CheckBoxPolitic from "../../../components/UI/checkBoxPolitic/CheckBoxPolitic";
import {createNotifyOfReceiptOrder, createPreOrder} from "../../../http/orderApi";
import {Context} from "../../../index";
import './receiptNotification.css';

const ReceiptNotification = (props) => {

    const {settings,user} = useContext(Context)

    const [oneProduct,setOneProduct] = useState(null)
    const [preOrderInfo,setPreOrderInfo] = useState(null);
    const [userPreOrderFields, setUserPreOrderFields] = useState({name: "", telephone: "", email: ""})
    const [successState,setSuccessState] = useState(false);
    const [orderButtonDisable, setOrderButtonDisable] = useState(false);
    const [stateCheсkBoxPreorder, setStateCheсkBoxPreorder] = useState(false)



    const countChangeHandler = (param) => {
        if(param === "min") {
            if(props.preNotificationModalInfo.count === 1) {
                return false;
            }
            props.setPreNotificationModalInfo({...props.preNotificationModalInfo, count: props.preNotificationModalInfo.count - 1})
        }
        if(param === "max") {
            props.setPreNotificationModalInfo({...props.preNotificationModalInfo, count: props.preNotificationModalInfo.count + 1})
        }
        return  false;
    }

    useMemo(() => {
        if (stateCheсkBoxPreorder) {
            setOrderButtonDisable(false)
        }
    }, [stateCheсkBoxPreorder])

    const userPreOrderChangeNameHandler = (value) => {
        let total = 200;
        setOrderButtonDisable(false);
        if (value.length > total) {
            return false;
        }
        setUserPreOrderFields({...userPreOrderFields, name: value})
    }
    const userPreOrderChangeEmailHandler = (value) => {
        setOrderButtonDisable(false);
        setUserPreOrderFields({...userPreOrderFields, email: value})
    }

    const userPreOrderChangeTelephoneHandler = (value) => {
        var reg = /^\d+$/;
        if(!value) {
            setUserPreOrderFields({...userPreOrderFields, telephone: value})
//
        }
        if(!reg.test(value) || value.length > 25) {
            return false;
        }
        setUserPreOrderFields({...userPreOrderFields, telephone: value})
        setOrderButtonDisable(false);
    }


    const [errorPreOrderText,setErrorPreOrderText] = useState(null)

    const onClickNotifyOfReceiptOrderHandler = () => {
        // console.log("CLICH")
        if (orderButtonDisable) {
            return false;
        }
        if (stateCheсkBoxPreorder === false) {
            setErrorPreOrderText('Примите соглашение')
            setOrderButtonDisable(true);
            return false;
        }
        let namePattern = /^[ а-яА-Яa-zA-Z]+$/
        if(!namePattern.test(userPreOrderFields.name) || userPreOrderFields.name.length < 2 ) {
            setErrorPreOrderText("Укажите настоящее имя");
            setOrderButtonDisable(true);
            return false;
        }
        if(userPreOrderFields.telephone.length < 2 ) {
            setErrorPreOrderText("Укажите настоящий телефон");
            setOrderButtonDisable(true);
            return false;
        }
        let emailPattern = /^[-a-z-A-Z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z-A-Z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/;
        if(!emailPattern.test(userPreOrderFields.email) || !userPreOrderFields.email){
            setErrorPreOrderText("Укажите настоящий Email");
            setOrderButtonDisable(true);
            return false;
        }
        setErrorPreOrderText("");
        setOrderButtonDisable(true);

        createNotifyOfReceiptOrder(props.preNotificationModalInfo,userPreOrderFields,user.user).then(response => {
            ///
            if(response.error) {
                return false;
            }

            window.dataLayer.push({
                event: 'in_stock_notification',
                product_name: props.preNotificationModalInfo.item.name,
                product_url: document.location.href
            })

            props.setSuccessStateModal(true);
            props.setPreNotificationModalInfo(null)
            setOrderButtonDisable(false);

        })
    }

    const closeNotificationModal = () => {
        props.setOpenNotificationModal(false);
        user.setShadowOverlay(false);
        props.setPreNotificationModalInfo(null)
    }

    useEffect(() => {
        if(user.shadowOverlay !== true) {
            props.setPreNotificationModalInfo(null)
            props.setOpenNotificationModal(false);
        }

    },[user.shadowOverlay])


    // console.log(props.preNotificationModalInfo)

    return (
        <div>
            <div
                className={ props.openNotificationModal ? "notification_modal_for_new_order active" : "notification_modal_for_new_order"
                }>
                <div className={props.successStateModal ? "notification_modal_wrapper_for_content non_border" : "notification_modal_wrapper_for_content"}>
                    <div className={"notification_modal_heading_line_and_exit"}>
                        { props.preNotificationModalInfo && !props.successStateModal ? <span className={"notification_modal_heading_text"}> Подписка на уведомление</span> : "" }
                        <div onClick={ e => closeNotificationModal() } className={"notification_modal_exit_div"}></div>
                    </div>
                    <div
                        className={props.successStateModal ? "relative_container_for_notification non_border" :  "relative_container_for_notification"}
                    >
                        {
                            props.preNotificationModalInfo && !props.successStateModal ?
                                <div className={"modal_content_notification"}>
                                    <div className="modal_content_notification_heading_line">К сожалению, данный товар закончился, но Вы можете подписаться на уведомление, чтобы не пропустить поступление товара в продажу.
                                    </div>
                                    <div className={"modal_content_notification_wrapper_for_row"}>
                                        <div

                                            // onClick={e => console.log(props.preNotificationModalInfo)}
                                            className={"modal_content_notification_img_container"}>
                                            <img src={props.preNotificationModalInfo.item.productId ? process.env.REACT_APP_API_URL+"uploads/images/"+props.preNotificationModalInfo.item.productId+"/"+props.preNotificationModalInfo.item.id+"/" + "214_"+props.preNotificationModalInfo.item.img_preview : ''}
                                                 alt={ (props.preNotificationModalInfo.item.name ? props.preNotificationModalInfo.item.name : '')+'; цвет: '+(props.preNotificationModalInfo.item.color.color_name ? props.preNotificationModalInfo.item.color.color_name : '')}
                                            />
                                        </div>
                                        <div className={"modal_content_notification_wrapper_for_column_content"}>
                                            <div className="modal_content_notification_name_line">
                                                {props.preNotificationModalInfo.item.name}
                                            </div>
                                            <div className={"modal_content_notification_color_name"}>
                                                <span className={"modal_content_notification_black_span"}>Цвет: </span>
                                                <span>{props.preNotificationModalInfo.item.color.color_name}</span>
                                            </div>
                                            <div className={"modal_content_notification_color_name"}>
                                                <span className={"modal_content_notification_black_span"}>Размер: </span>
                                                <span>{props.preNotificationModalInfo.currentSize}</span>
                                            </div>
                                            <div className={"modal_content_notification_color_name"}>
                                                <span className={"modal_content_notification_black_span"}>Количество: </span>

                                                <div className={"wrapper_for_mini_count_controller_wp"}>
                                                    <div className={"wrapper_for_mini_count_controller min"} onClick={e => countChangeHandler("min")}></div>
                                                    <div className={"wrapper_for_mini_count_controller center"}>
                                                        {props.preNotificationModalInfo.count}
                                                    </div>
                                                    <div className={"wrapper_for_mini_count_controller max"} onClick={e => countChangeHandler("max")}></div>
                                                </div>

                                            </div>
                                            {!props.preNotificationModalInfo.item.price_hidden ?
                                                <div className={"modal_content_notification_color_name"}>
                                                    <span className={"modal_content_notification_black_span"}>Цена: </span>
                                                    <span className={"one_product_page_price"}>
                                                        {
                                                        (props.preNotificationModalInfo.item.sell_price && props.preNotificationModalInfo.item.sell_price > 0) ?
                                                        priceToUserString(props.preNotificationModalInfo.item.sell_price*props.preNotificationModalInfo.count)+" ₽" 
                                                        : 
                                                        priceToUserString(props.preNotificationModalInfo.item.parrent_price*props.preNotificationModalInfo.count)+" ₽"
                                                        }</span>
                                                    <span className={(props.preNotificationModalInfo.item.sell_price && props.preNotificationModalInfo.item.sell_price > 0) ? "standard_price old_price" : "standard_price" }></span>
                                                </div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div className={"notification_fields_modal"}>
                                        <div className={"notification_fields_wrapper"}>
                                            <span>Имя*</span>
                                            <input
                                                value={userPreOrderFields.name ? userPreOrderFields.name : "" }
                                                onChange={ e => userPreOrderChangeNameHandler(e.target.value)}
                                                type="text"/>
                                        </div>
                                        <div className={"notification_fields_wrapper"}>
                                            <span>Телефон*</span>
                                            <input
                                                onChange={ e => userPreOrderChangeTelephoneHandler(e.target.value)}
                                                value={userPreOrderFields.telephone ? userPreOrderFields.telephone : "" }
                                                type="text"/>
                                        </div>
                                        <div className={"notification_fields_wrapper"}>
                                            <span>Email*</span>
                                            <input
                                                onChange={e => userPreOrderChangeEmailHandler(e.target.value)}
                                                value={userPreOrderFields.email ? userPreOrderFields.email : "" }
                                                type="text"/>
                                        </div>
                                    </div>
                                    <span className={"notification_finally_valid_text"}>
                                                    {errorPreOrderText ?  errorPreOrderText : ""}
                                                </span>
                                    <span
                                        onClick={ e => onClickNotifyOfReceiptOrderHandler()}
                                        className={orderButtonDisable ? "notification_finally_button disable" : "notification_finally_button"}>
                                                   Уведомить о поступлении
                                                </span>
                                    <CheckBoxPolitic
                                        setStateCheсkBox = {e=> setStateCheсkBoxPreorder(e)}
                                        stateCheсkBox ={stateCheсkBoxPreorder}
                                    />
                                </div>
                                :
                                ""
                        }

                        {
                            !props.preNotificationModalInfo && props.successStateModal ?
                                <div className={"notification_success"}>
                                    <div className="wrapper_notificator">
                                        <span className="thank_you_notification">Спасибо за подписку!</span>
                                        <div className="thank_text_container">
                                            <span className="thank_text_child">Менеджер свяжется с Вами как только товар поступит в продажу.</span>
                                        </div>
                                        <span
                                            onClick={ e => closeNotificationModal()}
                                            className={"notification_finally_button close"}>
                                                   Закрыть
                                </span>

                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptNotification;
