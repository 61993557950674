import React, {useEffect, useState} from 'react';
import {addNewSize, fetchSizes, findChildProductsAndFixJsonSize} from "../../../http/catalogApi";
import Spiner from "../spiner/Spiner";
import './sizeManager.css'
import OneSizeAdmin from "./oneSizeAdmin/OneSizeAdmin";
const SizeManager = () => {

    const [size,setSize] = useState([])
    const [spinActive,setSpinActive] = useState(true);
    const [addNewSizeName, setAddNewSizeName] = useState('')
    const [message,setMesaage] = useState("");
    const [sizeTriger,setSizeTriger] = useState(false);
    const [updateSizesInChildProductStatus,setUpdateSizesInChildProductStatus] = useState("");

    useEffect(()=> {
        fetchSizes().then(data => {
            setSize(data)
            setSpinActive(false)
        })
    },[sizeTriger])

    const fixJsonSizesHandler = async () => {
        findChildProductsAndFixJsonSize().then(response => {
            if(response.message) {
                setUpdateSizesInChildProductStatus(response.message);
            }
        })
    }



    const inputValueHandler = (value) => {
        value = value.toUpperCase();
        setAddNewSizeName(value);
        setMesaage("");
    }

    const clickAddNewSize = (value) => {
        if(!value) {
            setMesaage("Значение размера не может быть пустым");
            return false;
        }
        addNewSize(addNewSizeName).then(data => {
            if(data.data.error) {
                setMesaage(data.data.error);
                return false;
            }

            if(data.data.message) {
                setMesaage(data.data.message);
                setSizeTriger(!sizeTriger);
                return false;
            }
        } )

    }


    return (
        <div className={"size_page_manager"}>
            {spinActive ?
                <Spiner/>
                    :
                <div className={"size_wrapper"}>
                    <span className={"add_new_size_title"}>Менеджер размеров</span>
                    <div className={"wrapper_settings_size"}>
                        <div className={"settings_size_controller"}>
                            <div className={"text_info"}>
                                Пожалуйста, не используйте, если нет необходимости. <br/>
                                Данные о размерах товара хранятся в JSON. И если у размеров меняется "position", то в товарах он остается прежний (старый).
                                Данная кнопка заставляет сервер искать во всех товарах размеры и сверять их "position", затем перезаписывать в случае,
                                если они не соответствуют актуальным. Затем, размеры в товарах сортируются от меньшего к большему по свойству "position".
                                <br/>
                                Основная причина использования - изменение порядка выводимых размеров, либо добавление нового размера.
                            </div>
                            <div className={"button_wrapper_and_valid"}>
                                <span
                                    onClick={e => fixJsonSizesHandler() }
                                    className={"go_actual_position"}>Актуализировать данные о размерах</span>
                                <span className={"valid_text_st_size"}>
                                    { updateSizesInChildProductStatus ?
                                        "Было проверено и обновлено "+updateSizesInChildProductStatus+" торговых предложений."
                                        : ""
                                    }
                                </span>
                            </div>

                        </div>
                    </div>

                    <div className={"add_new_size_container"}>
                        <div className={"size_wrapper_content_area"}>
                            {
                                size.map((item,index) =>
                                    <OneSizeAdmin
                                        key={item.id}
                                        item={item}
                                        index={index}
                                        size={size}
                                        setSize={setSize}
                                        sizeTriger={sizeTriger}
                                        setSizeTriger={setSizeTriger}
                                    />

                                )
                            }
                        </div>

                        <div className={"input_wrapper_add_size"}>
                            <input className={"inp_text_size"}
                                   value={addNewSizeName}
                                   onChange={e => inputValueHandler(e.target.value)}
                                   type="text" name="" id=""/>

                                   <span
                                       onClick={e => clickAddNewSize(addNewSizeName)}
                                       className={"add_size_input_click"}>Добавить</span>
                                    <span className={"message_size"}> { message ? message : ""}</span>
                        </div>

                    </div>
                </div>
            }

        </div>
    );
};

export default SizeManager;