import React from 'react';
import InputMask from "react-input-mask";
import './birthdayinput.css'
const BirthdayInput = (props) => {

    const {errorNumberText,setErrorNumberText} = props;





    const birthDayValidator = (number) => {

        let numberArraySplit = number.split('')

        let countItemsInArray = numberArraySplit.length;
        if(countItemsInArray === 0) {
            props.errController(false)
        }
        //valid day birth
        if(countItemsInArray >= 3) {
            let days = numberArraySplit.slice(0,2);
            let daysString = days.join('')
            if(daysString > 31) {
                setErrorNumberText('Пожалуйста, укажите корректный день');
                props.errController(true)
                props.setNewValue({...props.userFields,  [props.fieldName]: number});
                return false;
            } else {
                setErrorNumberText('')
                props.errController(false)
            }
        }
        if(countItemsInArray >= 6) {
            let month = numberArraySplit.slice(3,5);

            let monthString = month.join('');

            if(monthString*1 > 12) {
                setErrorNumberText('Пожалуйста, укажите корректный месяц')
                props.errController(true)
                props.setNewValue({...props.userFields,  [props.fieldName]: number});
                return false;
            } else {
                setErrorNumberText('');
                props.errController(false)
            }
        }

        if (countItemsInArray >= 10) {
            let agesUser = numberArraySplit.slice(6,10)
            let agesUserString = agesUser.join('')

            let timeClass = new Date()
            let currentYear = timeClass.getFullYear();
             if (agesUserString*1 < 1936 || agesUserString*1 > currentYear*1) {
                 setErrorNumberText("Пожалуйста, укажите корректный год");
                 props.errController(true)
                 props.setNewValue({...props.userFields,  [props.fieldName]: number});
                 return false;
             } else {
                 setErrorNumberText('')
                 props.errController(false)

             }

        }

        props.setNewValue({...props.userFields,  [props.fieldName]: number});
    }



    return (
        <div className={"birthDayWrapper " + props.errClass }>
            <div className={"title_birthday_lk"}>Дата рождения</div>
            <InputMask
                className={"user_birthday user_field"}
                placeholder={"дд.мм.гггг"}
                value={props.value}
                onChange={e => birthDayValidator(e.target.value) }
                mask={"99.99.9999"}
                alwaysShowMask={true}
                maskChar={""}
            />
            <div className={"error_birthDay"}>
                {errorNumberText}
            </div>
        </div>
    );
};

export default BirthdayInput;