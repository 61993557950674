import React, {useEffect, useState} from 'react';
import './socialManager.css'
import OneSocial from "./OneSocial";
import {addNewSocial, fetchAllSocial} from "../../../../http/settingsApi";

const SocialManager = () => {

    const [socialList,setSocialList] = useState([]);
    const [changeOneSocial, setСhangeOneSocial] = useState([])
    const [contentSocial, setContentSocial] = useState({
        resource_name: "",
        url_social: "",
        svg_social: "",
    })
    const [errorAdditions,setErrorAdditions] = useState('');
    const [refreshDB,setRefreshDB] = useState(false);

    useEffect((fields) => {
        fetchAllSocial(fields).then(response => {
            setSocialList(response)
            return false;
        })
    },[])

    useEffect((fields) => {
        fetchAllSocial(fields).then(response => {
            setSocialList(response)
            return false;
        })
    },[refreshDB])

    const checkSocialFields = (fields) => {
        if(contentSocial.resource_name !== '' && contentSocial.url_social !== '' &&
            contentSocial.svg_social !== '' ) {
            setErrorAdditions('Добавлено');
            addNewSocial(fields).then(res => {
                if(res.message) {
                    setContentSocial({...contentSocial, resource_name:"",url_social: "",svg_social: ""})
                    setRefreshDB(!refreshDB)
                    return false;
                }
                setErrorAdditions('Непредвиденная ошибка, обратитесь к администрации.')
            } )
            return false;
        }
        setErrorAdditions('Заполните все поля')

    }

    const primerSvg = "<svg width='47' height='47' viewBox='0 0 47 47' fill='none'><rect width='46.0579' height='46.0579' rx='11.7026' fill='#1A1A1A'></rect><path d='M35.0593 32.8159C34.5232 34.5002 32.3953 35.8971 30.698 36.305C29.5369 36.58 28.0201 36.7995 22.9143 34.4445C16.3835 31.4344 12.1777 24.0513 11.8499 23.5723C11.536 23.0933 9.21094 19.6629 9.21094 16.115C9.21094 12.5672 10.8305 10.8396 11.4833 10.0979C12.0194 9.48908 12.9055 9.21094 13.7556 9.21094C14.0306 9.21094 14.2778 9.22639 14.5001 9.23875C15.1529 9.26966 15.4807 9.31292 15.9112 10.4595C16.4474 11.8965 17.753 15.4444 17.9086 15.8091C18.0669 16.1737 18.2252 16.6682 18.003 17.1472C17.7947 17.6417 17.6113 17.8611 17.2835 18.2814C16.9557 18.7017 16.6446 19.0231 16.3168 19.4744C16.0168 19.8668 15.6779 20.2871 16.0557 21.0134C16.4335 21.7242 17.7391 24.0946 19.6614 25.9983C22.1421 28.4552 24.1533 29.2402 24.8728 29.574C25.4089 29.8212 26.0478 29.7625 26.4395 29.2989C26.9367 28.7025 27.5507 27.7135 28.1757 26.74C28.6201 26.0416 29.1813 25.955 29.7702 26.2023C30.3702 26.4341 33.5454 28.1802 34.1982 28.5418C34.851 28.9064 35.2816 29.0795 35.4399 29.3855C35.5955 29.6914 35.5955 31.1285 35.0593 32.8159Z' fill='white'></path></svg>"

    return (
        <div className={"social_page_wrapper"}>
            <span className="social_page_title"> Социальные сети </span>
            <div className={"social_page_container"}>
                <div className={"first_social_controller"}>
                    <span className="heading_page_social">Добавление социальных сетей</span>
                    <div className={"container_one_block_social"}>
                        <div className={"left_wrapper_social_controller"}>
                            <div className={"social_manager_field_wrap"}>
                                <span className="social_field_label">* Название</span>
                                <input
                                    value={contentSocial ? contentSocial.resource_name : ""}
                                    onChange = {e =>  setContentSocial({...contentSocial,resource_name:e.target.value})}
                                    className="social_fields_input" placeholder="Ввести название..." type="text"/>
                            </div>
                            <div className={"social_manager_field_wrap"}>
                                <span className="social_field_label">* Картинка формата svg </span>
                                <textarea
                                    value={contentSocial ? contentSocial.svg_social : ""}
                                    onChange = {e =>  setContentSocial({...contentSocial,svg_social:e.target.value})}
                                    className="social_fields_textarea" placeholder="Ввести код SVG..." type="text"/>
                            </div>
                            <div className={"social_manager_field_wrap"}>
                                <span className="social_field_label">* URL соц.сети</span>
                                <input
                                    value={contentSocial ? contentSocial.url_social : ""}
                                    onChange = {e =>  setContentSocial({...contentSocial,url_social:e.target.value})}
                                    className="social_fields_input" placeholder="Ввести ссылку URL..." type="text"/>
                            </div>
                            <div className="addendum_social">
                                <span
                                    onClick={ e => checkSocialFields(Object.assign({}, contentSocial)) }
                                    className="add_new_social_button"> Добавить </span>
                                <span className="error_social_message">{errorAdditions}</span>
                            </div>
                        </div>
                        <div className={"right_wrapper_social_controller"}>
                            <span className="social_field_label">Пример кода картинки SVG</span>
                            <div className={"primer_svg"}>
                                {primerSvg}
                            </div>
                        </div>
                    </div>
                </div>



                <div className={"list_social"}>
                    <span className="heading_page_social red">Обратите внимание, самостоятельное добавление SVG файла может привести к занесению вирусной программы на сайт. Для безопасного добавления обратитесь, пожалуйста, к разработчику!</span>
                    {
                        socialList ? socialList.map((item , index ) =>
                                <OneSocial
                                    key = {index}
                                    item = {item}
                                    index = {index}
                                    setСhangeOneSocial = {e => setСhangeOneSocial(e)}
                                    setRefreshDB = {e => setRefreshDB(e)}
                                    refreshDB = {refreshDB}
                                />
                        )
                        :
                        "Социальные сети еще не созданы"
                    }

                </div>
            </div>
        </div>
    );
};

export default SocialManager;