import React, {useState, useEffect} from 'react'

import './style.css'
import {useParams} from "react-router-dom";
import {createRedirect, deleteRedirect, getRedirects, updateRedirect} from "../../../../http/settingsApi";
import RedirectItem from "./RedirectItem";
import Pagination from "../../../../components/Paginstion";

const limit = 25

const Redirects = () => {

    console.log(useParams())
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')
    if(!page || page < 1)
        page = 1

    const [from, setFrom] = useState('')
    const [to, setTo] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [redirects, setRedirects] = useState({count: 0, rows: []})
    const [error, setError] = useState(false)

    const loadRedirects = async () => {
        const response = await getRedirects(limit, page)

        if(!response.success)
            return setError(response.message)

        setRedirects(response.data)
    }

    const saveRedirectHandler = async (id, from, to) => {
        const response = await updateRedirect(id, from, to)

        if(!response.success)
            return setError(response.message)
    }

    const addNewRedirect = async () => {
        if(!isValid)
            return false

        const response = await createRedirect(from, to)

        if(!response.success)
            return setError(response.message)

        loadRedirects()
    }

    const deleteRedirectHandler = async (id) => {
        const response = await deleteRedirect(id)

        if(!response.success)
            return setError(response.message)

        loadRedirects()
    }

    useEffect(() => {
        loadRedirects()
    }, [page])

    useEffect(() => {
        setIsValid(from !== to && from && to)
    }, [from, to])

    if(error)
        return (<span className={"error_category_message"} >{error}</span>)

    return (
        <div className={"category_wrapper_page"}>
            <div className={"page_container_category"}>
                <div className={"first_category_controller"}>
                       <span className={"heading_page_category"}>
                        Редиректы
                       </span>
                    <div className={"left_wrapper_category_controller"}>
                        <div className={"catalog_manager_field_wrap"}>
                            <span className={"category_field_label"}>* откуда: не забудьте про / в конце</span>
                            <input
                                className={"category_fields_vac"}
                                placeholder={"Ввести url..."}
                                value={from}
                                onChange={({target}) => setFrom(target.value)}
                                type="text"/>
                                <br/>
                            <span className={"category_field_label"}>* куда:</span>
                            <input
                                className={"category_fields_vac"}
                                placeholder={"Ввести url..."}
                                value={to}
                                onChange={({target}) => setTo(target.value)}
                                type="text"/>
                        </div>
                    </div>
                    <div className={"save_and_error"}>
                        <span className={`add_new_category_button ${!isValid ? 'disabled' : ''}`} onClick={addNewRedirect}> Добавить </span>
                        <span className={"error_category_message"} >  </span>
                    </div>
                </div>

                <div className={"all_category_redaction_wrapper"}>
                    {
                        redirects.rows.map(redirect => (
                            <RedirectItem key={redirect.id} {...redirect} deleteItem={deleteRedirectHandler} save={saveRedirectHandler} />
                        ))
                    }
                </div>
                <div className="pagination_adm_wrapper">
                    <Pagination page={page} limit={limit} count={redirects.count} />
                </div>
            </div>
        </div>
    )
}

export default Redirects