import React, {useEffect, useState} from 'react';
import './admImages.css'
import {
    deleteImage,
    fetchAdmImages, fetchAllImages, setSortImages,
} from "../../../../http/contentApi";
import {Link, useNavigate} from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Spiner from "../../spiner/Spiner";

const AdmImages = () => {

    const [triggerContentImage, setTriggerContentImage] = useState(false)
    const [imagesList, setImagesList] = useState([]);
    const [spinActive,setSpinActive] = useState(true);
    const limit = 9999999999;

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchAllImages(limit, 0, true).then(response => {
            console.log(response)
            setImagesList(response.rows);
            setSpinActive(false)
        })
    }, [triggerContentImage])

    const Navigate = useNavigate();

    const addNewLookClick = async (e) => {
        setTriggerContentImage(!triggerContentImage)
        Navigate("/admindashboard/content/images/new/");
    }

    const deleteOneImage = (id) => {
        deleteImage(id).then(response => {
            setTriggerContentImage(!triggerContentImage)
        })
    }

    const onDragEnd = function(result){
        const item = imagesList[result.source.index]
        const targetItem = imagesList[result.destination.index]

        const newListItems = Array.from(imagesList);
        imagesList.splice(result.source.index, 1);
        imagesList.splice(result.destination.index, 0, imagesList[result.source.index]);
        setImagesList(Array.from(newListItems))

        setSpinActive(true)
        setSortImages(item.id, item.sort, targetItem.sort).then(() => {
            fetchAllImages(limit, 0, true).then(response => {
                console.log(response)
                setImagesList(response.rows);
                setSpinActive(false)
            })
        })
    }

    return (
        <div className={"adm_page_lookbook"}>
            <div className={"adm_lookbook_wrapper"}>
                {spinActive ?
                    <Spiner/>
                    :""
                }
                <div className={"adm_lookbook_title"}>
                    Образы
                </div>
                <div className="">
                        <span
                            onClick={e => addNewLookClick()}
                            className={"add_new_look"}>Добавить образ</span>
                </div>
                <div className={"content_lookbook_adm"}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="images">
                            {
                                (provided, snapshot) => {
                                    return (
                                        <div className={""} ref={provided.innerRef} {...provided.droppableProps}>
                                            {
                                                imagesList ?
                                                    imagesList.map((item, index) =>
                                                        <Draggable key={item.id} draggableId={item.id.toString()}  index={index}>
                                                            {provided => {
                                                                return (
                                                                    <div {...provided.draggableProps} ref={provided.innerRef} {...provided.dragHandleProps} className={item.isActive ? "one_line_look" : "one_line_look non_active"} >
                                                                        <Link className={"wrap_look_line"}
                                                                              to={"/admindashboard/content/images/" + item.id + "/"}>
                                                                            <div className={"id_block_on_pr_line"}>ID {item.id ? item.id : ''}</div>
                                                                            <div className={"image_product_or_line"}>
                                                                                {
                                                                                    item.img_mini ?
                                                                                        <img
                                                                                            src={process.env.REACT_APP_API_URL + "uploads/images/images/" + item.id + "/" + item.img_mini}
                                                                                            alt=""/>
                                                                                        :
                                                                                        <img className={"no_image"}
                                                                                             src={process.env.REACT_APP_API_URL + "uploads/images/no-image.jpg"}
                                                                                             alt=""/>
                                                                                }
                                                                            </div>
                                                                            <div className={"look_previews"} key={index}>
                                                                                {item.title ? item.title : ''}
                                                                            </div>
                                                                        </Link>
                                                                        <div className={"look_number_sort"}>Порядковый
                                                                            номер: {item.sort ? item.sort : 'Не заполнен'}</div>
                                                                        <div className={"delete_block_look"}>
                                                                            <div
                                                                                onClick={e => deleteOneImage(item.id)}
                                                                                className={"del_delivery_child"}></div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    )
                                                    : ""
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )
                                }
                            }
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </div>
    );
};

export default AdmImages;