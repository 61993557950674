import React from 'react';
import './breadcrumbsCatalogLanding.css';

const BreadcrumbsCatalogLanding = () => {
    return (
        <div className={"breadcrumbs_block_catalog"}>
            <div className={"breadcrumbs_item"}>YOUWANNA</div>
            <div className={"breadcrumbs_item"}>КАТАЛОГ</div>

        </div>
    );
};

export default BreadcrumbsCatalogLanding;