import React, {useContext} from 'react'
import {priceToUserString} from "../../http/basketApi";
import {Context} from "../../index";

const Split = () => {
  const {basket} = useContext(Context)
  return (
    <div className={"one_step_split_row"}>
                    <span>Можно разделить:</span>
                    <div className={"one_step_btns_block"}>
                        <span> 4 x</span>
                        <span className={"one_step_btns_black"}>{priceToUserString(basket.totalPrice / 4)} ₽</span>
                        <button className={"one_step_btn_first"}>
                            <img alt='' src='/files/images/split2.png' />
                        </button>
                        <button>
                            <img alt='' src='/files/images/split1.png' />
                        </button>
                    </div>
                    </div>
  )
}

export default Split