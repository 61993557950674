import React from 'react';
import './oneUserLine.css';

const OneUserLine = (props) => {
    return (
        <div
            onClick={e => (props.select(props.item), props.setOpen(true))}
            className={"one_use_line_st"}>
            <div className={"one_use_container id"}>
               ID: {props.item.id}
            </div>
            <div className="one_use_container telephone">
             Тел: {props.item.login}
            </div>
            <div className={"one_use_container role"}>
                Роль: {props.item.role}
            </div>
            <div className={"one_use_container name"}>
                Имя: {props.item.name ? props.item.name : "не указанно"}
            </div>
            <div className={"one_use_container surname"}>
                Фамилия: {props.item.surname ? props.item.surname : "не указанно"}
            </div>
            <div className={"one_use_container email"}>
                Email: {props.item.email ? props.item.email : "не указанно"}
            </div>


        </div>
    );
};

export default OneUserLine;