import React, {useContext, useEffect, useState, useRef, useMemo} from 'react';
import './saleAndTrends.css'
import {Link} from "react-router-dom";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Scrollbar} from "swiper";

SwiperCore.use([Scrollbar]);

const StockAndTrends = observer(({ SaleHome, commonName, nameBlockSale, nameBlockTrends, trendsHome }) => {
    const {settings} = useContext(Context)
    const [stateSwitchBlock, setStateSwitchBlock] = useState('trends');
    const sliderRef = useRef();

    const buttonLeftNew = () => {
        sliderRef.current.swiper.slidePrev();
    }
    const buttonRightNew = () => {
        sliderRef.current.swiper.slideNext();
    }

    useEffect(() => {
        if (SaleHome.length) {
            setStateSwitchBlock('sale')
        }
        if (trendsHome.length) {
            setStateSwitchBlock('trends')
        }
    }, [SaleHome, trendsHome])

    const slides = useMemo(() => {
        return (
            <>
                {
                    stateSwitchBlock === 'sale' && SaleHome.length
                        ? SaleHome.map(itemSale =>
                            <SwiperSlide key={itemSale.id}>
                                <Link
                                    className={"link_trading"}
                                    to={`/about/info/${itemSale.id}`}
                                    key={itemSale.id}
                                >
                                    <div
                                        id={"one_block_action"}
                                        className={"one_block_action"}
                                    >
                                        <div className={"img_action"}>
                                            <img
                                                className={""}
                                                src={process.env.REACT_APP_API_URL+"uploads/images/stockAndTrends/"+itemSale.id+"/"+itemSale.image }

                                                alt={itemSale.name ? itemSale.name : ''}
                                            />
                                        </div>
                                        <div className={"name_action"}>{itemSale.name && itemSale.name}</div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                        : null
                }
                {
                    stateSwitchBlock === 'trends' && trendsHome.length
                        ? trendsHome.map(itemTrends =>
                            <SwiperSlide key={itemTrends.id}>
                                <Link
                                    className={"link_trading"}
                                    to={`/about/info/${itemTrends.id}`}
                                    key={itemTrends.id}
                                >
                                    <div
                                        id={"one_block_action"}
                                        className={"one_block_action"}
                                    >
                                        <div className={"img_action"}>
                                            <img
                                                className={''}
                                                src={
                                                    process.env.REACT_APP_API_URL +
                                                    'uploads/images/stockAndTrends/' +
                                                    itemTrends.id +
                                                    '/' +
                                                    itemTrends.image
                                                }
                                                alt={itemTrends.name && itemTrends.name}
                                            />
                                        </div>
                                        <div className={"name_action"}>{itemTrends.name && itemTrends.name}</div>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        )
                        : null
                }
            </>
        )
    }, [SaleHome, trendsHome, stateSwitchBlock])

    return (
        <div className={"block_trends"}>
            {
                (SaleHome.length || trendsHome.length) ?
                    <>
                        <div className={"control_unit"}>
                            {
                                commonName ?
                                    <div className={"title_block_stock"}>
                                        {commonName}
                                    </div>
                                    : ""
                            }

                            <div className={"switch_and_arrows"}>
                            {
                                    (SaleHome.length && trendsHome.length) ?
                                        <div className={"stock_switch"}>
                                        
                                            <div
                                                onClick={e => setStateSwitchBlock('trends')}
                                                className={stateSwitchBlock === 'trends' ? "switch_trends active" : "switch_trends"}
                                            >
                                                {nameBlockTrends ? nameBlockTrends : null}
                                            </div>
                                    
                                            <div
                                                onClick={e => setStateSwitchBlock('sale')}
                                                className={stateSwitchBlock === 'sale' ? "switch_trends trends active" : "switch_trends trends"}
                                            >
                                                {nameBlockSale && nameBlockSale}
                                            </div>
                                                    
                                        </div>
                                    : ""
                            }

                                {
                                    stateSwitchBlock === 'sale' && SaleHome.length > 2 || stateSwitchBlock === 'sale' && settings.projectWidth < 768 ?
                                        <div className={"arrows_switch"}>
                                            <div
                                                onClick={buttonLeftNew}
                                                className={"one_arrows_new"}
                                            >
                                                <svg
                                                    width="20"
                                                    height="10"
                                                    viewBox="0 0 20 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M3.83 6L6.41 8.59L5 10L0 5L5 0L6.41 1.41L3.83 4L20 4V6L3.83 6Z"
                                                        fill="#1A1A1A"
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                onClick={buttonRightNew}
                                                className={"one_arrows_new"}
                                            >
                                                <svg
                                                    width="20"
                                                    height="10"
                                                    viewBox="0 0 20 10"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M16.17 6L13.59 8.59L15 10L20 5L15 0L13.59 1.41L16.17 4L0 4L0 6L16.17 6Z"
                                                        fill="#1A1A1A"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    stateSwitchBlock === 'trends' && trendsHome.length > 2 || stateSwitchBlock === 'trends' && settings.projectWidth < 768
                                        ?
                                            <div className={"arrows_switch"}>
                                                <div
                                                    onClick={buttonLeftNew}
                                                    className={"one_arrows_new"}
                                                >
                                                    <svg
                                                        width="20"
                                                        height="10"
                                                        viewBox="0 0 20 10"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3.83 6L6.41 8.59L5 10L0 5L5 0L6.41 1.41L3.83 4L20 4V6L3.83 6Z"
                                                            fill="#1A1A1A"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    onClick={buttonRightNew}
                                                    className={"one_arrows_new"}
                                                >
                                                    <svg
                                                        width="20"
                                                        height="10"
                                                        viewBox="0 0 20 10"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M16.17 6L13.59 8.59L15 10L20 5L15 0L13.59 1.41L16.17 4L0 4L0 6L16.17 6Z"
                                                            fill="#1A1A1A"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        : null
                                }
                            </div>
                        </div>
                        {!!slides && (
                            <Swiper
                                className={"trends-slider"}
                                slidesPerView="auto"
                                watchSlidesProgress={true}
                                ref={sliderRef}
                                scrollbar={{draggable: true}}
                                observer={true}
                                onObserverUpdate={(swiper) => {
                                    setTimeout(() => {
                                        swiper.update();
                                        swiper.emit('resize');
                                        swiper.updateProgress();
                                    }, 300)
                                }}
                            >
                                {slides}
                            </Swiper>
                        )}

                    </>
                : ""
            }
        </div>
    );
});

export default StockAndTrends;