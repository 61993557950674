import React from 'react';
import './paymentMethods.css'

const PaymentMethods = (props) => {


    return (

        <div className={"payment_methods"}>
            {
                props.paymentFooter ?   <span className={"payment_methods_text"}>Способы оплаты</span> : ''
            }

            <div className={"payment_images_wrapper"}>
                {
                    props.paymentFooter ? props.paymentFooter.map((item, index) =>
                        <div key={index} className={"top_images_payment"}>
                            <img src={process.env.REACT_APP_API_URL+"uploads/images/footer/payment/"+item.img } alt={'Способы оплаты в магазине YouWanna' }/>
                        </div>
                    ) : ''
                }
            </div>
        </div>
    );
};

export default PaymentMethods;