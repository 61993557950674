import React, { useContext, useEffect, useState } from 'react';
import './sitemap.css';
// import {Context} from "../../../index";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ContentBreadcrumbs from '../../components/Breadcrumbs/contentBreadcrumbs/ContentBreadcrumbs';
import { Context } from '../..';
const Sitemap = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const {settings} = useContext(Context)
    return (
        <div
            style={{marginTop: settings.headerHeight ? settings.headerHeight : 150}}
            className={"page_about"}>
            <div
                className={"breadcrumbs_about"}>
                <ContentBreadcrumbs
                    margTop={settings.headerHeight ? settings.headerHeight : 150}
                    items={[
                        { name: "Главная", link: "/" },
                        { name: "Карта сайта", link: "/sitemap/" }
                    ]}
                />
            </div>
            <Helmet>
                <title>Карта сайта</title>
                <meta name="description" content={'Карта сайта you-wanna.ru'} />
            </Helmet>
            <div className={"about_content_wrapper"}>
                <div className={"sitemap_wrapper"}>
                    <div>
                        <Link to="/">Главная</Link>
                    </div>
                    <div>
                        <Link to="/catalog/">Каталог</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/">Верхняя одежда</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/thrench/">Тренчи</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/coat/">Пальто</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/coat/palto-klassicheskie/">Женские пальто классические</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/coat/korotkie/">Женские пальто короткие</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/coat/dlinnye/">Длинные женские пальто</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/coat/s-poyasom/">Женские пальто с поясом</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/coat/palto-pryamye/">Прямые женские пальто</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/coat/demisezonnye/">Женские пальто демисезонные</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/bomber/">Бомберы</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/Puhoviki/zhenskie-zimnie-puhoviki-s-kapyushonom/">Зимние пуховики с капюшоном</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/Puhoviki/zhenskie-puhoviki-udlinennye/">Пуховики удлиненные</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/Puhoviki/zhenskie-puhoviki-ukorochennye/">Пуховики укороченные</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/Puhoviki/zhenskie-zimnie-kurtki-oversajz/">Зимние куртки оверсайз</Link>
                    </div>
                    <div>
                        <Link to="/catalog/outerwear/Puhoviki/zhenskie-puhoviki-militari/">Пуховики милитари</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/">Брюки и юбки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/">Брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/kargo/">Женские брюки карго</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/kargo/belye/">Женские белые брюки карго</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/kargo/chernye/">Женские черные брюки карго</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/kargo/haki/">Женские брюки карго хаки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/kargo/s-vysokoj-taliej/">Женские брюки карго с высокой талией</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/kargo/s-zanizhennoj-taliej/">Женские брюки карго с заниженной талией</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/klesh/">Женские брюки клеш</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/oversajz/">Женские брюки оверсайз</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/zauzhennye/">Женские зауженные брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/klassicheskie/">Классические женские брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/kozhanye/">Кожаные женские брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/trikotazhnye/">Женские трикотажные брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/shirokie/">Широкие женские брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/bryuki-pryamye/">Прямые женские брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/pants/uzkie/">Узкие женские брюки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/skirts/">Юбки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/pants_and_skirts/shorts/">Шорты</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jeans/">Деним</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jeans/denim/">Джинсы</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jeans/jeansthrench/">Джинсовые тренчи</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jeans/jeansskirts/">Джинсовые юбки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/platyaandsarafani/">Платья и сарафаны</Link>
                    </div>
                    <div>
                        <Link to="/catalog/platyaandsarafani/dress_dresses/">Платья</Link>
                    </div>
                    <div>
                        <Link to="/catalog/platyaandsarafani/sundress/">Сарафаны</Link>
                    </div>
                    <div>
                        <Link to="/catalog/platyaandsarafani/dress_shirts/">Платья-рубашки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/tops_and_blouses/">Топы и блузки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/tops_and_blouses/topy/">Топы</Link>
                    </div>
                    <div>
                        <Link to="/catalog/tops_and_blouses/bluzy/">Блузки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/tops_and_blouses/tshirts/">Футболки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/tops_and_blouses/korsety/">Корсеты</Link>
                    </div>
                    <div>
                        <Link to="/catalog/tops_and_blouses/rubashki/">Рубашки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jersey/">Трикотаж</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jersey/sweaters/">Джемпера</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jersey/cardigans/">Кардиганы</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jersey/topyTrik/">Трикотажные топы</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jersey/skirtsTrik/">Трикотажные юбки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jersey/trikotazhplat/">Трикотажные платья</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jaketandkits/">Пиджаки и комплекты</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jaketandkits/jaket/">Пиджаки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/jaketandkits/kits/">Комплекты</Link>
                    </div>
                    <div>
                        <Link to="/catalog/aksessuary/">Аксессуары</Link>
                    </div>
                    <div>
                        <Link to="/catalog/aksessuary/bags/">Сумки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/aksessuary/belt/">Ремни</Link>
                    </div>
                    <div>
                        <Link to="/catalog/aksessuary/Jewelry/">Ювелирные изделия</Link>
                    </div>
                    <div>
                        <Link to="/catalog/aksessuary/golovnieybori/">Головные уборы</Link>
                    </div>
                    <div>
                        <Link to="/catalog/aksessuary/tights/">Колготки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/kollab/">Коллаборации</Link>
                    </div>
                    <div>
                        <Link to="/catalog/kollab/youwannaarnypraht/">YOU WANNA X ARNY PRAHT</Link>
                    </div>
                    <div>
                        <Link to="/catalog/kollab/youwannaslovodna/">YOU WANNA X SLOVODNA</Link>
                    </div>
                    <div>
                        <Link to="/catalog/coming_soon/">Скоро в наличии</Link>
                    </div>
                    <div>
                        <Link to="/catalog/bestseller/">Бестселлеры</Link>
                    </div>
                    <div>
                        <Link to="/catalog/new-collection/">Новинки</Link>
                    </div>
                    <div>
                        <Link to="/catalog/sale/">Скидки на женскую одежду</Link>
                    </div>
                    <div>
                        <Link to="/catalog/backtoschool/">Back to school</Link>
                    </div>
                    <div>
                        <Link to="/catalog/gift/">Сертификаты</Link>
                    </div>
                    <div>
                        <Link to="/about/">О бренде</Link>
                    </div>
                    <div>
                        <Link to="/about/contacts/">Контакты</Link>
                    </div>
                    <div>
                        <Link to="/shops/">Магазины</Link>
                    </div>
                    <div>
                        <Link to="/about/vacancies/">Вакансии</Link>
                    </div>
                    <div>
                        <Link to="/about/delivery/">Доставка</Link>
                    </div>
                    <div>
                        <Link to="/about/refund/">Возврат</Link>
                    </div>
                    <div>
                        <Link to="/about/payment/">Оплата</Link>
                    </div>
                    <div>
                        <Link to="/faq/">Часто задаваемые вопросы</Link>
                    </div>
                    <div>
                        <Link to="/lookbook/">Lookbook</Link>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Sitemap;
