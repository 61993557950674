import React, {useEffect, useState} from 'react';
import './admLookbook.css'
import {
    creatureLookBook,
    deleteLookBook,
} from "../../../../http/contentApi";
import {Link, useNavigate} from "react-router-dom";
import {fetchAdmLookBook} from "../../../../http/contentApi";


const AdmLookbook = () => {

    const [pageContentLookbook,setPageContentLookbook] = useState({});
    const [triggerContentLookbook,setTriggerContentLookbook] = useState(false)
    const [lookList,setLookList] = useState(null);
    const limit = 9999999999;

    useEffect(() => {
        window.scrollTo(0, 0)
        fetchAdmLookBook(limit,0,true).then(response =>  {
            // console.log(response)
            setLookList(response.rows);
            // setAllCount(response.count)
        })
    },[triggerContentLookbook])

    const Navigate = useNavigate()

    const addNewLookClick = async (e) => {
        const {data} = await creatureLookBook();
        if(data.error) {
            return false;
        }
        setTriggerContentLookbook(!triggerContentLookbook)
        Navigate("/admindashboard/content/lookbook/"+data.id+"/")
    }

    const deleteOneLook = (id) => {
        deleteLookBook(id).then(response => {
            setTriggerContentLookbook(!triggerContentLookbook)
        } )
    }

    return (
        <div className={"adm_page_lookbook"}>
            <div className={"adm_lookbook_wrapper"}>
                <div className={"adm_lookbook_title"}>
                    Lookbook
                </div>
                <div className="">
                        <span
                            onClick={e => addNewLookClick()}
                            className={"add_new_look"}>Добавить look</span>
                </div>
                <div className={"content_lookbook_adm"}>

                    <div className={""}>
                        {
                            lookList ? lookList.map((item, index) =>
                                <div key={index} className={item.isActive ? "one_line_look" : "one_line_look non_active"}>
                                    <Link className={"wrap_look_line"} to={"/admindashboard/content/lookbook/"+item.id+"/"}  >
                                        <div className={"id_block_on_pr_line"}>ID {item.id ? item.id : ''}</div>
                                        <div className={"image_product_or_line"}>
                                            {item.img_mini ?
                                                <img src={process.env.REACT_APP_API_URL+"uploads/images/lookbook/"+item.id+"/"+item.img_mini} alt=""/>
                                                :
                                                <img className={"no_image"} src={process.env.REACT_APP_API_URL+"uploads/images/no-image.jpg"} alt=""/>
                                            }
                                        </div>
                                        <div className={"look_previews"} key={index}>
                                            {item.name ? item.name : ''}
                                        </div>
                                    </Link>
                                    <div className={"look_number_sort"}>Порядковый номер: {item.sort ? item.sort : 'Не заполнен'}</div>
                                    <div className={"delete_block_look"}>
                                        <div
                                            onClick={e => deleteOneLook(item.id)}
                                            className={"del_delivery_child"}></div>
                                    </div>
                                </div>


                            ) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdmLookbook;