import React, {useContext} from 'react';
import './favorites.css'
import OneProductCart from "../../../../pages/catalog/OneProductCart";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";

const Favorites = observer((props) => {


    const {settings} = useContext(Context)


    // определяем высоту при переходе, чтобы при возврате проскролить на это же место
    const handleScroll = () => {
        settings.setScrollStatusCatalog(window.scrollY);
    };

    return (
        <div className={"favorites_page_container"+" "+props.active}>
            <div className={"favorites_product_container"}>
                {
                    window.location.pathname != '/personal/favorites/' ? '' :
                        <div className={"wrapper_favorite_product"}>
                            {/*<div className={"favorite_product_child"}>*/}

                            { settings.favoriteArray && settings.favoriteArray != '' ?
                                <h1
                                    className={"title_favorite"}>
                                    Товары, которые вам понравились
                                </h1>
                                : ''

                            }

                            <div
                                className={"products_wrapper standart favorite"}>
                                {
                                    settings.favoriteArray && settings.favoriteArray != '' ? settings.favoriteArray.map((item, index) =>

                                            <OneProductCart
                                                item={item}
                                                index={index}
                                                key={item.id}
                                                handleScroll = {e => handleScroll()}
                                                isFavorites = {true}
                                            />

                                        ) :
                                        <div className={"favorite_error"}>
                                            Список избранных товаров пуст
                                        </div>
                                }
                            </div>
                            {/*</div>*/}
                        </div>
                }

            </div>
        </div>
    );
});

export default Favorites;