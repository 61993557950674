import React from 'react';

const StartPageHomepage = () => {
    return (
        <div>
            <h1>Добро пожаловать.</h1>
            // Стартовую страницу будет удобно использовать для виджетов быстрого доступа (самые используемые места админки). Чтобы можно было быстро перейти туда. Не забыть уточнить.

        </div>
    );
};

export default StartPageHomepage;