import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../../../index';
import { getStockAndTrendsById } from '../../../../http/contentApi';
import StocksAndTrendsDetail from '../StocksAndTrendsDetail/StocksAndTrendsDetail';
import InfoDetail from '../InfoDetail/InfoDetail';
import NotFound from '../../notFound/NotFound';
import Helmet from 'react-helmet';
import RecommendationsSlider from '../../../../components/RecommendationsSlider/RecommendationsSlider';
import './StocksAndTrendsDetailWrapper.css';

const textImgMask = '/##IMAGE_d+##/g';
const regExpInline = new RegExp('(##IMAGE_INLINE_(\\d)##\\s*)+', 'g');
const regexpSlider = new RegExp('(##slider_(.+)##)', 'g');
export const StocksAndTrendsDetailWrapper = () => {
	const { settings } = useContext(Context);
	const { stock_trend_id } = useParams();
	const [pageData, setPageData] = useState({});
	const [error, setError] = useState(false);
	const [component, setComponent] = useState(false);
	const isHaveSlider = useRef(false);
	useEffect(() => {
		window.scrollTo(0, 0);
		const id = +stock_trend_id;
		getStockAndTrendsById(id).then((res) => {
			if (res.status === 404) {
				setError(true);
			}
			if (res.template == 'new') {
				for (const imgKey in res?.text_images) {
					const regExpBlock = new RegExp('##IMAGE_' + imgKey + '##', 'g');
					res.main_text = res.main_text.replace(
						regExpBlock,
						`<div class=stocks-trends__text-pictures>
							<figure>
								<img src="${process.env.REACT_APP_API_URL}uploads/images/stockAndTrends/${id}/${
							res.text_images[imgKey]?.image
						}" className="stocks-trends__gallery-img"\n/>
								<figcaption  class="stocks-trends__gallery-description">${
									res.text_images_desc[imgKey] || ''
								}</figcaption>
							</figure>
	
						</div>`
					);
				}

				const textImagesMatch = res.main_text.match(regExpInline);
				let textImagesReplacement = null;
				if (textImagesMatch) {
					textImagesReplacement = textImagesMatch.map((imgInlineBlock) => {
						const regex = /##IMAGE_INLINE_(\d+)##/g;
						let match;
						const numbers = [];

						while ((match = regex.exec(imgInlineBlock)) !== null) {
							numbers.push(match[1]);
						}
						return numbers
							.map(
								(number) => `
						<figure>
							<img src="${process.env.REACT_APP_API_URL}uploads/images/stockAndTrends/${id}/${
									res.text_images[number]?.image
								}" className="stocks-trends__gallery-img"\n/>
							<figcaption  class="stocks-trends__gallery-description">${
								res.text_images_desc[number] || ''
							}</figcaption>
						</figure>
						`
							)
							.join('');
					});
				}

				textImagesMatch &&
					textImagesMatch.forEach((match, index) => {
						const replacement = `
					<div class=stocks-trends__text-pictures>
						${textImagesReplacement[index]}
					</div>
					`;
						res.main_text = res.main_text.replace(match, replacement);
					});

				const slidersMatch = res.main_text.match(regexpSlider);
				let slidersReplacement = null;
				if (slidersMatch && slidersMatch.length) {
					slidersReplacement = slidersMatch.map((imgInlineBlock) => {
						const regex = /##slider_(.+)##/g;
						let match;
						const codes = [];

						while ((match = regex.exec(imgInlineBlock)) !== null) {
							codes.push(...match.slice(1));
						}
						return codes.map((code) => {
							const slider = res.sliders.find((slider) => slider.slug == code);

							return (
								slider &&
								slider.is_active && (
									<RecommendationsSlider
										sliderName={slider.name}
										items={slider.items}
										id={pageData.id}
										item_id={slider.item_id}
									/>
								)
							);
						});
					});
				}

				const matchesRegexp =
					slidersMatch &&
					new RegExp(slidersMatch.map((item) => '(' + item + ')').join('|'), 'g');
				if (matchesRegexp) {
					isHaveSlider.current = true;
					const splitText = res.main_text.split(matchesRegexp).filter((item) => {
						return (
							(!!item || item == '' || item == ' ') && !slidersMatch.includes(item)
						);
					});
					const newDetailText = splitText.map((textPart, index) => {
						return (
							<>
								<div
									dangerouslySetInnerHTML={{
										__html: textPart.replace(slidersMatch[index], ''),
									}}
								></div>
								{slidersReplacement[index]}
							</>
						);
					});
					res.main_text = newDetailText;
				}
			} else {
				res.main_text = res.main_text.replace(
					/(##IMAGE_\d+##)|(##slider_(.+)##)|(##IMAGE_INLINE_(\d##))+/g,
					''
				);
			}
			//parsing content for replacement (##IMAGE_[xxx]##, ##IMAGE_INLINE_[xxx]##,##slider_[xxx]##)

			// --------------------------end of parsing------------------------

			setPageData(res);
			if (res.template == 'old') {
				setComponent(
					<StocksAndTrendsDetail settings={settings} pageData={res} error={error} />
				);
			} else if (res.template == 'new') {
				setComponent(
					<InfoDetail
						pageData={res}
						settings={settings}
						error={error}
						isHaveSlider={isHaveSlider.current}
					/>
				);
			}
		});
	}, []);

	return error ? (
		<NotFound />
	) : (
		<>
			<Helmet>
				<title>
					{pageData.seo_title
						? pageData.seo_title
						: 'Акции и тренды в интернет магазине YOU WANNA'}
				</title>
				<meta
					name="description"
					content={pageData.seo_description ? pageData.seo_description : ''}
				/>
			</Helmet>

			{component}
		</>
	);
};
