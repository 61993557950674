import React, {useState} from 'react';
import './oneChildLine.css';
import {updateSortOneChildProduct} from "../../../../../http/catalogApi";
import {Link} from "react-router-dom";

const OneChildLine = (props) => {

    const [sort,setSort] = useState(props.item.sort);
    const [updateText,setUpdateText] = useState("")
    const [triggerButton,setTriggerButton] = useState(false);

    const saveSortHandler = (itemId,value) => {
        updateSortOneChildProduct(itemId,value).then(response => {
            if(response.message) {
                setUpdateText(response.message);
                setTriggerButton(false)
                setTimeout(() => {setUpdateText("")},3000)
            }
        })
    }

    return (
        <div className={"one_child_line_element"}>
            <div className={"one_child_line_item id"}>
                {props.item.id}
            </div>
            <div className={"one_child_line_item img"}>
                        <img src={process.env.REACT_APP_API_URL+"uploads/images/"+props.item.product.id+"/"+props.item.id+"/214_"+props.item.img_preview} alt=""/>
            </div>
            <div className="one_child_line_item name">{props.item.name}</div>
            <div className="one_child_line_item color">
                {props.item.color.color_name}
            </div>
            <div className="one_child_line_item color">
                {props.item.code ? props.item.code : "Не задано"}
            </div>
            <div className="one_child_line_item sort">
                {
                    sort ?
                        <input value={sort} onChange={e => (setSort(e.target.value),setTriggerButton(true))} type="text"/>
                        : ""
                }
            </div>
            <div className="one_child_line_item btn_save">
                <span
                    onClick={e => saveSortHandler(props.item.id, sort)}
                    className={triggerButton ? "one_change_sort_btn_save active" : "one_change_sort_btn_save"}>сохранить</span>
            </div>
            <div className="one_child_line_item informer">
                {updateText}
            </div>
            <Link to={"/admindashboard/products/oneitem/"+props.item.product.id+"/"} className={"one_child_line_item link"}>
                Перейти к товару
            </Link>

        </div>
    );
};

export default OneChildLine;