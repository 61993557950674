import React, {useContext, useEffect, useState} from 'react';
import './mobileNavBar.css'
import {Link} from "react-router-dom";
import AllCatalog from "./AllCatalog";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import ControllBar from "../../../personal/controllBar/ControllBar";
import SwitchUserIcon from "../IconsBar/UserIcon/SwitchUserIcon";
import SearchIcon from "../IconsBar/SearchIcon/SearchIcon";
import MyFavoriteProducts from "../IconsBar/MyFavoriteProducts/MyFavoriteProducts";

const MobileNavBar = observer ((props) => {

    const {settings} = useContext(Context)
    const [openCategories, setOpenCategories] = useState('')
    const [stateArrowsCategories, setStateArrowsCategories] = useState(false)
    const [childItemsMenuMobile,setChildItemsMenuMobile] = useState([])


    const [openMenuLk, setOpenMenuLk] = useState(false)

    const [temporaryStorage, setTemporaryStorage] = useState({
        position: '',
        arrows: '',
    })

    const onClickHandler = (item) => {
        if (item.child) {
            setTemporaryStorage({...temporaryStorage, position: item.position, arrows: stateArrowsCategories })
            setChildItemsMenuMobile(item)
            setOpenCategories(item.position)
            setStateArrowsCategories(!stateArrowsCategories)
            if (temporaryStorage.position === item.position && stateArrowsCategories === true) {
                setStateArrowsCategories(!stateArrowsCategories)
                } else {
                    setStateArrowsCategories(true)
                }
        } else {
            // console.log("Пусто")
        }
    }


    return (
        <div
            className={"bulka " + props.className}>
            <div
                style={
                    {height:settings.projectHeight-settings.headerHeight, marginTop:props.stateRunningLine-1}
                }
                className={"parents_categories_mobile"}>
                <div className={"child_categories_mobile"}>
                    {
                        props.headerMenu[0] ? props.headerMenu.map((item, index) =>
                            <div key={index} className={"mobile_menu_wrapper"}>

                                <div className={"mobile_menu_item"}>
                                    <div className={stateArrowsCategories === true && openCategories === item.position ? "mobile_menu_item_child open" : "mobile_menu_item_child"}>
                                        {/*<div>{item.position}</div>*/}
                                        <Link className = {stateArrowsCategories === true && openCategories === item.position ? "categories_item_menu active" : "categories_item_menu"} style={{fontWeight:item.weight}} to={item.slug ? "/catalog/"+item.slug+"/" : "/catalog/"}>{item.name}</Link>
                                        {item.child?.length > 0 ?
                                            <div
                                                onClick={e => onClickHandler(item)}
                                                className= { stateArrowsCategories === true && openCategories === item.position ? "categories_arrows hide" : "categories_arrows active"}>
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>


                                <div className={stateArrowsCategories === true && openCategories === item.position ? "smooth_appearance_categories active" : "smooth_appearance_categories"}>
                                    {
                                        stateArrowsCategories === true && item.name === childItemsMenuMobile.name && item.child && item.child.length > 0 ?
                                            <AllCatalog
                                                childItemsMenu={childItemsMenuMobile}
                                                item = {item}
                                                index = {index}
                                                closeBurger = {props.closeBurger}
                                            />
                                            : ""
                                    }
                                </div>

                            </div>

                        ) : ""

                    }
                </div>
                {
                    settings.projectWidth && settings.projectWidth < 769 ?
                        <div className={openMenuLk === true ? "mob_version_menu_lk open_menu" : "mob_version_menu_lk"}>
                            <SwitchUserIcon
                                onClick={ () => props.openAuthModalFunction()}
                                openMenuLk={openMenuLk}
                                setOpenMenuLk = {e => setOpenMenuLk(e)}
                            />
                            <MyFavoriteProducts
                                openMenuLk={openMenuLk}
                            />
                        </div>
                        :
                        ""
                }


            </div>
        </div>
    );
});

export default MobileNavBar;