import React, {useEffect, useState} from 'react';
import {addImages, deleteImage} from "../../../../http/imagesApi";
import OneImageField from "../../UI/oneImageField/OneImageField";
import {ImagesSizePreset} from "../../../../utils/consts";
import {fetchOneImage, saveImage} from "../../../../http/contentApi";
import {useParams} from "react-router";
import IsActiveIndicator from "../../UI/isActiveIndicator/IsActiveIndicator";
import SearchChildProductArea from "../../products/addOneProduct/searchChildProductsComponent/SearchChildProductArea";
import {getchildsforslider} from "../../../../http/catalogApi";


const OneImage = () => {
    let {id} = useParams();

    const [contentImage, setContentImage] = useState({});
    const [arrayImgImage, setArrayImgImage] = useState('');
    const [messageImage, setMessageImage] = useState('');
    const [triggerContentImage, setTriggerContentImage] = useState(false);
    const [responseProducts, setResponseProducts] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            fetchOneImage(id, true).then(data => {
                if (data) {
                    setContentImage(data);
                }
                if (data.content && data.content !== '') {
                    setArrayImgImage(data.content);
                }
            });
        }
    }, []);
    useEffect(() => {
        setContentImage({...contentImage, content: JSON.stringify(arrayImgImage)})
    }, [arrayImgImage]);
    useEffect(() => {
        if (triggerContentImage === true) {
            setMessageImage('');
        }
    }, [triggerContentImage]);
    useEffect(() => {
        if (contentImage.set) {
            getchildsforslider(JSON.parse(contentImage.set)).then(response => {
                if (response) {
                    setResponseProducts(response);
                }
            });
        }
    }, [contentImage?.set?.length]);

    const addProduct = (data) => {
        let productsArr = contentImage?.set ? JSON.parse(contentImage.set) : [];
        if (productsArr && !productsArr.includes(data.id)) {
            productsArr.push(data.id);
        }
        setContentImage({...contentImage, set: JSON.stringify(productsArr)});
    }

    const deleteProduct = (item) => {
        let parsedContentImage = JSON.parse(contentImage.set);
        let newParsedSet = parsedContentImage.filter(id => id !== item.id);
        setContentImage({...contentImage, set: JSON.stringify(newParsedSet)});
    }

    const uploadImgImage = (e, nameField, LookSizePreset) => {
        let newFile = e.target.files[0];
        let formData = new FormData();

        formData.append("image", newFile);

        if (contentImage[nameField]) {
            deleteImage(contentImage[nameField], "image", '').then(response => {
                setContentImage({...contentImage, [nameField]: ""})
            })
        }

        addImages(formData, 'image', '', LookSizePreset).then(response => {
            if (response.message) {
                setContentImage({...contentImage, [nameField]: response.fileName})
                setTriggerContentImage(true)
            }
        })

    }

    const deletePreviewsImage = (filename, nameField, id) => {
        deleteImage(contentImage[nameField], "lookbook", id).then(response => {
            setContentImage({...contentImage, [nameField]: ""})
            setTriggerContentImage(true)
        })
    }

    const fieldsImage = (e, fieldName) => {
        setContentImage({...contentImage, [fieldName]: e.target.value})
        setTriggerContentImage(true)
    }

    const fieldsSort = (e, fieldName) => {
        let newNumber = e.target.value.replace(/\D/g, '');
        let textboxText = newNumber.replace(/^0+/, '')
        setContentImage({...contentImage, [fieldName]: textboxText})
        setTriggerContentImage(true)
    }

    const changeActiveThumbnail = (fildName) => {
        setContentImage({...contentImage, [fildName]: !contentImage[fildName]})
        setTriggerContentImage(true);
    }

    const saveImageBtn = (data) => {
        if (id) {
            saveImage(data, true).then(response => {
                if (response.message) {
                    setTriggerContentImage(false);
                    setMessageImage("Сохранено!");
                }
                if (response.error) setMessageImage(response.error);
            });
        } else {
            saveImage(data).then(response => {
                if (response.id) {
                    window.location.href = '../' + response.id;
                }
                if (response.error) setMessageImage(response.error);
            });
        }
    }

    return (
        <div className={"one_look"}>
            <div className={"look_previews_photo"}>
                <div className={"container_img_look"}>
                    <OneImageField
                        text={"Превью"}
                        image={contentImage.previewImg ?
                            process.env.REACT_APP_API_URL + "uploads/images/image/" + contentImage.previewImg : null}
                        onChange={e => uploadImgImage(e, 'previewImg', ImagesSizePreset)}
                        deleteImage={e => deletePreviewsImage(e, 'previewImg')}
                    />
                </div>
                <div className={"container_img_look"}>
                    <OneImageField
                        text={"Детальная"}
                        image={contentImage.detailImg ?
                            process.env.REACT_APP_API_URL + "uploads/images/image/" + contentImage.detailImg : null}
                        onChange={e => uploadImgImage(e, 'detailImg', ImagesSizePreset)}
                        deleteImage={e => deletePreviewsImage(e, 'detailImg')}
                    />
                </div>
                <div className={"field_lookbook"}>
                    <div className={"thumbnail_active"}>
                        <span className={"one_field_lookbook"}>Активно</span>
                        <div
                            onClick={e => changeActiveThumbnail("isActive")}
                            className={"is_active_shop"}>
                            <IsActiveIndicator active={contentImage.isActive}/>
                        </div>
                    </div>
                    <div className="one_field_lookbook_wrapper">
                        <span className={"one_field_lookbook"}>Заголовок образа</span>
                        <input
                            value={contentImage.title ? contentImage.title : ""}
                            onChange={e => fieldsImage(e, "title")}
                            className={"lookbook_field_adm"} type="text"/>
                    </div>
                    <div className="two_field_lookbook_wrapper">
                        <div className="one_field_lookbook_wrapper">
                            <span className={"one_field_lookbook"}>Тэг</span>
                            <input
                                value={contentImage.tag ? contentImage.tag : ""}
                                onChange={e => fieldsImage(e, "tag")}
                                className={"lookbook_field_adm"} type="text"/>
                        </div>
                        <div className="one_field_lookbook_wrapper">
                            <span className={"one_field_lookbook"}>Ссылка</span>
                            <input
                                value={contentImage.href ? contentImage.href : ""}
                                onChange={e => fieldsImage(e, "href")}
                                className={"lookbook_field_adm"} type="text"/>
                        </div>
                    </div>
                    <SearchChildProductArea addProduct={addProduct}/>
                    <div className={"products_in_slider"}>
                        {
                            responseProducts ?
                                responseProducts.map((item, index) => {
                                    return (
                                        <div className={"one_slide_product_wrapper"} key={item.id}>
                                            <div className={"one_slide_img_wrapper"}>
                                                <img
                                                    src={process.env.REACT_APP_API_URL + "uploads/images/" + item.product.id + "/" + item.id + "/214_" + item.img_preview}
                                                    alt=""/>
                                            </div>
                                            <span>
                                                {item.name}
                                            </span>
                                            <span onClick={e => deleteProduct(item)}
                                                className={"destroy_one_slide"}>Удалить</span>
                                        </div>
                                    )
                                })
                                : ""
                        }
                    </div>
                </div>
            </div>
            <div
                onClick={e => saveImageBtn(contentImage)}
                className={triggerContentImage ? "save_fields_lookbook trig" : "save_fields_lookbook"}>
                Сохранить
            </div>
            <div className={"message_lookbook"}>{messageImage ? messageImage : ''}</div>
        </div>
    );
};

export default OneImage;