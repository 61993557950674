import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../../../index";


const AuthUser = observer((props) => {

    const {settings, user} = useContext(Context);

    const [arrayLinksUser, setArrayLinksUser] = useState([
        { nameButton:"Профиль",position:"0",toPage:"profile", padding:false, isActive:true, disabled:false },
        // { nameButton:"Избранное",position:"1",toPage:"favorites", isActive:false, padding:false, disabled:false },
        { nameButton:"Покупки",position:"2",toPage:"order", isActive:false, padding:false, disabled:false },
        { nameButton:"Мои бонусы",position:"3",toPage:"bonuses", isActive:false, padding:false, disabled:false },
        { nameButton:"Чат с консультантом",position:"4",toPage:"callmanager", padding:false, isActive:false, disabled:false },
    // { nameButton:"Баллы",position:"4",toPage:"viewballs", isActive:false, padding:false, disabled:true },
    // { nameButton:"Уведомления",position:"5",toPage:"messages", isActive:false, padding:false, disabled:true },
    ])


    const linkPageLk = (item) => {
        user.setCurrentPage(item.toPage);
        user.setShadowOverlay(false)
        props.setOpenMenuLk(false)
        // console.log("ТУТЬ")
    }


//

    // console.log(window.location.pathname); //yields: "/js" (where snippets run)
    // console.log(window.location.href);     //yields: "https://stacksnippets.net/js"
    // console.log("QQQQQQQQQQQQQQQ")
    // console.log(user.currentPage)

    return (
        <div className={""}>
            {   settings.projectWidth && settings.projectWidth > 768 ?
                <div onClick={e =>    user.setCurrentPage('profile')}>
                    <Link to={"/personal/profile/"} className={"bar_icon_wrapper user stroke black"}>
                        <svg width="20" height="24" viewBox="0 0 20 24" fill="black" stroke="black" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.1888 15.397C18.2278 15.397 19 16.1956 19 17.0961V18.1984C19 18.6681 18.8473 19.1303 18.557 19.5204C16.8742 21.7814 14.0736 23 9.99584 23C5.91716 23 3.11902 21.7808 1.44102 19.5196C1.152 19.1301 1 18.6689 1 18.2002V17.0961C1 16.1956 1.77216 15.397 2.81118 15.397H17.1888ZM9.99584 1C12.9341 1 15.246 3.27701 15.246 6.00108C15.246 8.72516 12.9341 11.0022 9.99584 11.0022C7.05756 11.0022 4.74568 8.72517 4.74568 6.00108C4.74568 3.277 7.05755 1 9.99584 1Z" stroke="white" strokeWidth="2"/>
                        </svg>
                    </Link>
                </div>

                :
                // <Link to={"/user"} className={"bar_icon_wrapper"}>
                //
                // </Link>
                <div
                    onClick={e => props.setOpenMenuLk(!props.openMenuLk)}
                    className={props.openMenuLk === true ? "container_lk_title open_menu" : "container_lk_title"}>
                    <div className={props.openMenuLk === true ? "child_container_lk_mobile open" : "child_container_lk_mobile"}>
                        <div className={"mobile_bar_icons"}>Личный кабинет</div>
                        <div className={props.openMenuLk === true ? "mobile_lk_arrows active" : "mobile_lk_arrows"}></div>
                    </div>

                </div>
            }
            {
                settings.projectWidth < 769 && props.openMenuLk === true  && arrayLinksUser ? arrayLinksUser.map((item, index) =>
                        <div
                            key={index}
                            onClick={e => linkPageLk(item)}
                        >
                            {
                                item.toPage == 'callmanager' ?
                                    <div className={"bar_icon_wrapper"}>
                                        <div className={"container_lk_title open_menu"}>
                                            <a className={"mobile_bar_icons"} target="_blank" href="https://api.whatsapp.com/send/?phone=79268877007&text&app_absent=0">
                                                {item.nameButton}
                                            </a>
                                        </div>

                                    </div>
                                    :

                                    <Link to={"/personal/" + item.toPage} className={"bar_icon_wrapper"}>
                                        <div

                                            className={props.openMenuLk === true ? "container_lk_title open_menu" : "container_lk_title"}>
                                            {/*<div className={props.openMenuLk === true ? "child_container_lk_mobile open" : "child_container_lk_mobile"}>*/}
                                            <div className={"mobile_bar_icons"}>{item.nameButton}</div>
                                            {/*</div>*/}
                                        </div>
                                    </Link>
                            }

                        </div>



                ) : ''
            }

        </div>

    );
});

export default AuthUser;