import React from 'react';

const WelcomStep = (props) => {

    return (
            <div className={"welcome_step "+props.controllStateClass}>
                <div className="fix_size_wrapper">
                    <div className={"container_title_sale"}>
                        <span className={"big_registration_text"}><noindex>Добро пожаловать в личный кабинет!</noindex></span>
                    </div>
                    <div className={"container_text_sale"}>
                        <div className={"welcome_banner"}><img src={'/files/images/ui/yw_welcom_logo.png'} alt="YouWanna - интернет-магазин одежды и аксессуаров"/> </div>
                        <span className={"go_profile_info"}
                              onClick={props.closeModal}
                        ><noindex>Перейти в личный кабинет</noindex></span>
                    </div>

                </div>
            </div>
    );
};

export default WelcomStep;