import React, {useEffect, useState} from 'react';
import './sliderController.css';
import SearchChildProductArea from "../searchChildProductsComponent/SearchChildProductArea";
import {login} from "../../../../../http/userAPI";
import {getchildsforslider} from "../../../../../http/catalogApi";







const SliderController = (props) => {

    const [sliderIDArray,setSliderIDArray] = useState([])
    const [responseProducts,setResponseProducts] = useState(null)
    const [selectedCategories,setSelectedCategories] = useState([]);


    const addToSelectOrDeleteOneCategory = (item) => {
        const currentCategories = [...selectedCategories]
        let newArrayCategories = []
        let isFind = false;
        if(selectedCategories.length) {
            selectedCategories.forEach(itemForeach => {
                if(itemForeach.id === item.id) {
                    isFind = true;
                } else {
                    newArrayCategories.push(itemForeach)
                }
            })
        }
        !isFind && newArrayCategories.push(item)
        setSelectedCategories(newArrayCategories)
        props.setOneProductCreate({...props.oneProductCreate, recomendation_category:newArrayCategories})

    }

    useEffect(()=> {
        // console.log(props.oneProductCreate)
        if(props.oneProductCreate.complects_ids){
            const slidesIds = [...props.oneProductCreate.complects_ids];
            setSliderIDArray(slidesIds)
        }
        if(props.oneProductCreate.recomendation_category){
            const recomendCategory = [...props.oneProductCreate.recomendation_category];
            setSelectedCategories(recomendCategory)
        }

    },[props.oneProductCreate])

    const addOneProductToSlide = (item) => {
        let productsSlide = [...props.oneProductCreate.complects_ids];
        if(!productsSlide.includes(item.id)) {
            productsSlide.push(item.id);
            props.setOneProductCreate({...props.oneProductCreate, complects_ids:productsSlide})
        }
    }

    useEffect(() => {
        if(sliderIDArray.length) {
            getchildsforslider(sliderIDArray).then(response => {
                if(response){
                    console.log(setResponseProducts(response));
                }
            })
        }
    },[sliderIDArray])



const deleteOneItem = (item) => {
    let productsSlide = [...props.oneProductCreate.complects_ids];
    let newSlides = [];

    productsSlide.forEach(itemForeach => {
        if(itemForeach === item.id) {

        } else {
            newSlides.push(itemForeach);
        }
    })
        props.setOneProductCreate({...props.oneProductCreate, complects_ids:newSlides})
}

    // console.log("AllCategory");
    // console.log(props.allCategories)


    return (
        <div className={"slide_controller_panel_wrapper"}>
            <span className={"controll_slider_heading"}>Управление слайдером</span>
            <div className={"slide_controller_settings_block"}>
                <div className={"btn_wrapper_slide_controll"}>
                    <div onClick={ e => props.setOneProductCreate({...props.oneProductCreate, active_sliders:!props.oneProductCreate.active_sliders})} className={props.oneProductCreate.active_sliders ? "slide_controller_button active" : "slide_controller_button"}>Блок слайдеров: {props.oneProductCreate.active_sliders ? "<ON>" : "<OFF>"}</div>
                    <span className={"slide_controll_notif_text"}>
                        Активируя / деактивируя эту опцию вы управляете отображением блока слайдеров в карточке товара.
                    </span>
                </div>
                <div className={"btn_wrapper_slide_controll"}>
                    <div onClick={ e => props.setOneProductCreate({...props.oneProductCreate, active_complects:!props.oneProductCreate.active_complects})} className={props.oneProductCreate.active_complects ? "slide_controller_button active" : "slide_controller_button"}>Блок комплектов: {props.oneProductCreate.active_complects ? "<ON>" : "<OFF>"}</div>
                    <span className={"slide_controll_notif_text"}>
                        Активируя / деактивируя эту опцию вы управляете отображением комплектов в слайдере.
                    </span>
                </div>
                <div className={"btn_wrapper_slide_controll"}>
                    <div onClick={ e => props.setOneProductCreate({...props.oneProductCreate, active_recomendation:!props.oneProductCreate.active_recomendation})} className={props.oneProductCreate.active_recomendation ? "slide_controller_button active" : "slide_controller_button"}>Блок рекомендаций: {props.oneProductCreate.active_recomendation ? "<ON>" : "<OFF>"}</div>
                    <span className={"slide_controll_notif_text"}>
                        Активируя / деактивируя эту опцию вы управляете отображением автоматической подборки рекомендаций.
                    </span>
                </div>
            </div>
            <div className={"categories_wrapper_slide"}>
                <div className={"categories_slide_all"}>
                    {
                        props.allCategories && props.allCategories[0] ?
                            props.allCategories.map((item,index) => {
                                return(
                                    <div
                                        onClick={e => addToSelectOrDeleteOneCategory(item)}
                                        className={"one_item_slide_category go_select"} key={item.id}>
                                        {item.name}
                                    </div>
                                )
                            })
                            : ""
                    }
                </div>
                <div className={"wrapper_right_for_heading_slide_categories"}>
                    <span className={"heading_notificator_categories_slide"}>Выбранные категории будут участвовать в списке рекомендаций. Чтобы удалить их из списка - повторно нажмите на категорию</span>
                    <div className={"categories_slide_all right"}>
                        {
                            selectedCategories && selectedCategories ?
                                selectedCategories.map((item,index) => {
                                    return(
                                        <div
                                            onClick={e => addToSelectOrDeleteOneCategory(item)}
                                            className={"one_item_slide_category go_back"} key={item.id}>
                                            {item.name}
                                        </div>
                                    )
                                })
                                : ""
                        }
                    </div>
                </div>


            </div>
            <SearchChildProductArea oneProductCreate={props.oneProductCreate} addProduct={addOneProductToSlide} />
            <span className={"products_in_slider_heading"}>Уже добавленные товары: </span>

            <div className={"products_in_slider"}>
                {
                    responseProducts ?
                        responseProducts.map((item,index) => {
                            return (
                                <div className={"one_slide_product_wrapper"} key={item.id}>
                                    <div className={"one_slide_img_wrapper"}>
                                        <img src={process.env.REACT_APP_API_URL+"uploads/images/"+item.product.id+"/"+item.id+"/214_"+item.img_preview} alt=""/>
                                    </div>
                                    <span>
                                        {item.name}
                                    </span>
                                    <span onClick={e => deleteOneItem(item)} className={"destroy_one_slide"}>Удалить</span>
                                </div>
                            )
                        })
                        : ""
                }
            </div>

        </div>
    );
};

export default SliderController;